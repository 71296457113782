import { Module } from 'vuex';
import { RootState } from '@/common/store';
import {
  ConnectionCountItem,
  ConnectionsItem,
  HardwareItem,
  Query,
} from '@/openapi/postgresqlV7/model';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { overviewPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { TimeRangeInfo } from '@/common/utils';

interface OverviewState {
  hardware: HardwareItem[];
  connections: ConnectionsItem[];
  connectionCount: ConnectionCountItem[];
  queryTime: Query[];
  ratio: number;
  errorStatusText: string;
  requestTime: TimeRangeInfo;
}

export const overview: Module<OverviewState, RootState> = {
  namespaced: true,
  state: {
    connections: [
      {
        maxConnections: 0,
        currentConnections: 0,
        collectTime: '',
      },
    ],
    connectionCount: [
      {
        collectTime: '',
        activeCount: 0,
        idleCount: 0,
        longCount: 0,
        lockCount: 0,
        activePercent: 0,
        idlePercent: 0,
        longPercent: 0,
        lockPercent: 0,
        nonePercent: 0,
      },
    ],
    queryTime: [
      {
        fromTime: '',
        toTime: '',
      },
    ],
    hardware: [
      {
        cpu: 0,
        memory: 0,
        collectTime: '',
      },
    ],
    ratio: 0,
    errorStatusText: '',
    requestTime: {
      fromTime: '',
      toTime: '',
    },
  },
  mutations: {
    setConnections(state: OverviewState, data: ConnectionsItem[]) {
      state.connections = data;
    },
    setConnectionsRatio(state: OverviewState, data: ConnectionsItem) {
      state.ratio =
        Math.floor(
          data.maxConnections ? ((data.currentConnections ?? 0) / data.maxConnections) * 100 : 0,
        ) || 0;
    },
    setHardware(state: OverviewState, data: HardwareItem[]) {
      state.hardware = data;
    },
    setConnectionCount(state: OverviewState, data: ConnectionCountItem[]) {
      state.connectionCount = data;
    },
    setQueryTime(state: OverviewState, data: Query[]) {
      state.queryTime = data;
    },
    setErrorStatusText: (state: OverviewState, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
    setRequestTime: (state: OverviewState, requestTime: TimeRangeInfo) => {
      state.requestTime.fromTime = requestTime.fromTime;
      state.requestTime.toTime = requestTime.toTime;
    },
  },
  actions: {
    async fetchOverview({ commit }, instanceId) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.OVERVIEW;
      try {
        const {
          data: { hardware, connectionCount, connections, fromTime, toTime },
        } = await overviewPostgresqlV7ControllerAxios({
          instanceId,
          period: 'p5s',
          frameName,
        });

        commit('setRequestTime', {
          fromTime: fromTime || '',
          toTime: toTime || '',
        });
        commit(
          'setHardware',
          hardware?.data?.length ? hardware.data : [{ cpu: 0, memory: 0, collectTime: '' }],
        );
        commit(
          'setConnectionCount',
          connectionCount?.data?.length
            ? connectionCount.data
            : [
                {
                  collectTime: '',
                  activeCount: 0,
                  idleCount: 0,
                  longCount: 0,
                  lockCount: 0,
                  activePercent: 0,
                  idlePercent: 0,
                  longPercent: 0,
                  lockPercent: 0,
                  nonePercent: 0,
                },
              ],
        );
        commit('setQueryTime', connectionCount?.query);
        commit(
          'setConnections',
          connections?.data?.length
            ? connections.data
            : [{ maxConnections: 0, currentConnections: 0, collectTime: '' }],
        );
        commit(
          'setConnectionsRatio',
          connections?.data?.length
            ? connections.data[0]
            : { maxConnections: 0, currentConnections: 0, collectTime: '' },
        );

        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getConnections: (state: OverviewState) => state.connections,
    getConnectionsRatio: (state: OverviewState) => state.ratio,
    getHardware: (state: OverviewState) => state.hardware,
    getConnectionCount: (state: OverviewState) => state.connectionCount,
    getQueryTime: (state: OverviewState) => state.queryTime,
    getErrorStatusText: (state: OverviewState) => state.errorStatusText,
    getRequestTime: (state: OverviewState) => state.requestTime,
  },
};
