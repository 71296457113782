import { STATUS_SET } from '@/common/components/molecules/statusTag/statusTag.define';

export const convertStandardStatus = (type: string) => {
  if (type.includes(' ')) {
    return type.split(' ').join('-').toLocaleLowerCase();
  }
  return type.toLocaleLowerCase();
};

// 상태 코드에 따른 색상을 결정하는 함수
const getHttpStatusColor = (status: string): string => {
  const statusCode = Number(status);

  if (statusCode >= 200 && statusCode < 300) {
    return 'green';
  }
  if (statusCode >= 400 && statusCode < 500) {
    return 'yellow';
  }
  if (statusCode >= 500) {
    return 'red';
  }
  return 'gray';
};

export const statusToColor = (status: string): string => {
  if (/^\d+$/.test(status)) {
    return getHttpStatusColor(status);
  }

  const colorKeys = Object.keys(STATUS_SET);
  for (let ix = 0; ix < colorKeys.length; ix++) {
    const statuses = STATUS_SET[colorKeys[ix]];
    const color = colorKeys[ix];
    if (statuses.includes(status)) {
      return color.toLowerCase();
    }
  }
  return 'gray';
};
