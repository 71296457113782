/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  FilterResponse,
  LogAlertMessageResponse,
  LogBaseResponse,
  LogContentRequest,
  LoggingHistoryV8Response,
  LoggingListV8Response,
  LoggingPreviousResponse,
  LoggingViewV8Request,
  PreviousLoggingV8Request,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getLogAlertMessageLoggingViewV8ControllerAxios = ({
  alertRuleId,
  targetId,
  alertResultId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  targetId: string;
  alertResultId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LogAlertMessageResponse> => {
  const localVarPath = '/api/v8/log/alert/message/{alertRuleId}/{targetId}'
    .replace(`{${'alertRuleId'}}`, encodeURIComponent(String(alertRuleId)))
    .replace(`{${'targetId'}}`, encodeURIComponent(String(targetId)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      alertResultId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getLogFiltersLoggingViewV8ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FilterResponse> => {
  const localVarPath = '/api/v8/log/filters';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTargetLogLoggingViewV8ControllerAxios = ({
  logKind,
  logType,
  targetId,
  containerId,
  fromTime,
  offset,
  period,
  previous,
  toTime,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  logKind: string;
  logType: string;
  targetId: string;
  containerId?: string;
  fromTime?: string;
  offset?: number;
  period?:
    | 'p10m'
    | 'p10s'
    | 'p12h'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p1s'
    | 'p30m'
    | 'p30s'
    | 'p31d'
    | 'p3h'
    | 'p3m'
    | 'p3s'
    | 'p5d'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m'
    | 'p7d';
  previous?: boolean;
  toTime?: string;
  request?: LogContentRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LogBaseResponse> => {
  const localVarPath = '/api/v8/log';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      containerId,
      fromTime,
      logKind,
      logType,
      offset,
      period,
      previous,
      targetId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPreviousLoggingLoggingViewV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: PreviousLoggingV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LoggingPreviousResponse> => {
  const localVarPath = '/api/v8/log/detail/previous';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getLogHistoryLoggingViewV8ControllerAxios = ({
  fromTime,
  interval,
  toTime,
  timezone,
  loggingViewRequest,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  interval:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  toTime: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  loggingViewRequest?: LoggingViewV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LoggingHistoryV8Response> => {
  const localVarPath = '/api/v8/log/history';
  const httpMethod = 'post';

  const data: any = loggingViewRequest;
  const config: any = {
    params: {
      fromTime,
      interval,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getLogListLoggingViewV8ControllerAxios = ({
  fromTime,
  interval,
  toTime,
  loggingViewRequest,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime: string;
  interval:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  toTime: string;
  loggingViewRequest?: LoggingViewV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LoggingListV8Response> => {
  const localVarPath = '/api/v8/log/list';
  const httpMethod = 'post';

  const data: any = loggingViewRequest;
  const config: any = {
    params: {
      fromTime,
      interval,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getLogAlertMessageLoggingViewV8ControllerAxios,
  getLogFiltersLoggingViewV8ControllerAxios,
  getTargetLogLoggingViewV8ControllerAxios,
  getPreviousLoggingLoggingViewV8ControllerAxios,
  getLogHistoryLoggingViewV8ControllerAxios,
  getLogListLoggingViewV8ControllerAxios,
};
