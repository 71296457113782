const MAIN_FRAMES = {
  REPORT_HISTORY: 'Report History',
  REPORT_EXPORT: 'Report Export',
  DASHBOARD_REPORT_EXPORT: 'Dashboard Report Export',
} as const;

export const REPORT_FRAME_NAMES = {
  REPORT_HISTORY: {
    REPORT_HISTORY_LIST: `${MAIN_FRAMES.REPORT_HISTORY}/Report History List`,
    DOWNLOAD: `${MAIN_FRAMES.REPORT_HISTORY}/Download`,
  },

  REPORT_EXPORT: {
    REPORT_LIST: `${MAIN_FRAMES.REPORT_EXPORT}/Report List`,
    EXPORT: `${MAIN_FRAMES.REPORT_EXPORT}/Export`,
  },
  DASHBOARD_REPORT_EXPORT: {
    DASHBOARD_REPORT_LIST: `${MAIN_FRAMES.REPORT_EXPORT}/Dashboard Report List`,
    FILTER: `${MAIN_FRAMES.REPORT_EXPORT}/FILTER`,
  },
  BUSINESS_REPORT_EXPORT: {
    BUSINESS_REPORT_LIST: `${MAIN_FRAMES.REPORT_EXPORT}/Business Report List`,
    FILTER: `${MAIN_FRAMES.REPORT_EXPORT}/FILTER`,
  },
} as const;
