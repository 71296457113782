import { i18n } from '@/common/locale';

export const PATTERN_TYPE = {
  DETECTION: 'detection',
  EXCLUDED: 'excluded',
} as const;

export const CATEGORIES = [
  {
    name: 'Process Name',
    value: 'processName',
  },
  {
    name: 'Args',
    value: 'args',
  },
  {
    name: 'User Name',
    value: 'userName',
  },
] as const;

const DATA_TYPE_VALUE = {
  STRING: 'string',
  REGEX: 'regex',
} as const;

export const DATA_TYPES = [
  {
    name: 'String',
    value: DATA_TYPE_VALUE.STRING,
  },
  {
    name: 'REGEX',
    value: DATA_TYPE_VALUE.REGEX,
  },
] as const;

export const MAX_BYTE_BY_DATA_TYPE = {
  [DATA_TYPE_VALUE.STRING]: 16,
  [DATA_TYPE_VALUE.REGEX]: 512,
} as const;

export const PROCESS_DOWN_DEFAULT_PATTERN = {
  category: CATEGORIES[0].value,
  dataType: DATA_TYPES[0].value,
  pattern: '',
  patternErrMsg: i18n.global.t('NOTI.VALIDATION.MUST_BE_ENTERED'),
} as const;
