import { defineAsyncComponent } from 'vue';

export const COMPONENTS = {
  TimeSeriesLineWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/timeSeriesLineWidget/TimeSeriesLineWidget.vue'),
  ),
  TimeSeriesAreaWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/timeSeriesAreaWidget/TimeSeriesAreaWidget.vue'),
  ),
  TimeSeriesBarWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/timeSeriesBarWidget/TimeSeriesBarWidget.vue'),
  ),
  TimeSeriesStackedAreaWidget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/timeSeriesStackedAreaWidget/TimeSeriesStackedAreaWidget.vue'
      ),
  ),
  TimeSeriesStackedBarWidget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/timeSeriesStackedBarWidget/TimeSeriesStackedBarWidget.vue'
      ),
  ),
  GaugeGaugeCircleWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/gaugeWidget/GaugeWidget.vue'),
  ),
  GaugeGaugeHalfCircleWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/gaugeWidget/GaugeWidget.vue'),
  ),
  ScatterWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/scatterWidget/ScatterWidget.vue'),
  ),
  TopListSummaryBarWidget: defineAsyncComponent(
    () =>
      import('@/dashboard/components/widgets/topListSummaryBarWidget/TopListSummaryBarWidget.vue'),
  ),
  EqualizerWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/equalizerWidget/EqualizerWidget.vue'),
  ),
  TableTableWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/tableWidget/TableWidget.vue'),
  ),
  TreeGridTreeGridWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/treeGridWidget/TreeGridWidgetV2.vue'),
  ),
  ActionViewStyle1Widget: defineAsyncComponent(
    () =>
      import('@/dashboard/components/widgets/actionViewStyle1Widget/ActionViewStyle1Widget.vue'),
  ),
  ActionViewStyle2Widget: defineAsyncComponent(
    () =>
      import('@/dashboard/components/widgets/actionViewStyle2Widget/ActionViewStyle2Widget.vue'),
  ),
  OneDayCompareBarWidget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/oneDayCompareWidget/oneDayCompareBarWidget/OneDayCompareBarWidget.vue'
      ),
  ),
  OneDayCompareLineWidget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/oneDayCompareWidget/oneDayCompareLineWidget/OneDayCompareLineWidget.vue'
      ),
  ),
  ScoreboardStyle1Widget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/scoreboard/scoreboardStyle1Widget/ScoreboardStyle1Widget.vue'
      ),
  ),
  ScoreboardStyle2Widget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/scoreboard/scoreboardStyle2Widget/ScoreboardStyle2Widget.vue'
      ),
  ),
  StackedProportionBarWidget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/stackedProportionBarWidget/StackedProportionBarWidget.vue'
      ),
  ),
  StackedProportionBarSingleStackWidget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/stackedProportionBarWidget/StackedProportionBarWidget.vue'
      ),
  ),
  StackedProportionBarMultiStackWidget: defineAsyncComponent(
    () =>
      import(
        '@/dashboard/components/widgets/stackedProportionBarWidget/StackedProportionBarWidget.vue'
      ),
  ),
  PiePieWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/piePieWidget/PiePieWidget.vue'),
  ),
  StatusHexaWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/statusHexaWidget/StatusHexaWidget.vue'),
  ),
  StatusSummaryCardWidget: defineAsyncComponent(
    () =>
      import('@/dashboard/components/widgets/statusSummaryCardWidget/StatusSummaryCardWidget.vue'),
  ),
  StatusSummaryPieWidget: defineAsyncComponent(
    () =>
      import('@/dashboard/components/widgets/statusSummaryPieWidget/StatusSummaryPieWidget.vue'),
  ),
  MemoWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/memoWidget/MemoWidget.vue'),
  ),
  TextWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/textWidget/TextWidget.vue'),
  ),
  ImageWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/imageWidget/ImageWidget.vue'),
  ),
  GroupWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/groupWidget/GroupWidget.vue'),
  ),
  ExclusiveStyle1Widget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/exclusiveStyle1Widget/ExclusiveStyle1Widget.vue'),
  ),
  ServiceCardStyle1Widget: defineAsyncComponent(
    () =>
      import('@/dashboard/components/widgets/serviceCardStyle1Widget/ServiceCardStyle1Widget.vue'),
  ),
  ServiceCardStyle2Widget: defineAsyncComponent(
    () =>
      import('@/dashboard/components/widgets/serviceCardStyle2Widget/ServiceCardStyle2Widget.vue'),
  ),
  TopologyTopologyWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/topologyWidget/TopologyWidget.vue'),
  ),
  ServerServerWidget: defineAsyncComponent(
    () => import('@/dashboard/components/widgets/serverWidget/ServerWidget.vue'),
  ),
};

export const installComponent = (app) => {
  Object.keys(COMPONENTS).forEach((key) => {
    app.component(key, COMPONENTS[key]);
  });
};
