import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { merge } from 'lodash-es';
import {
  DashboardData,
  DashboardUserGroupData,
  UpdateDashboardFavoriteRequest,
} from '@/dashboard/types/dashboard';
import { useViewModeStore } from '@/common/stores/view-mode';
import {
  moveBoardIntegratedDashboardControllerAxios,
  patchBoardIntegratedDashboardControllerAxios,
  patchFavoriteBoardIntegratedDashboardControllerAxios,
  patchGroupIntegratedDashboardControllerAxios,
  setCustomReportIntegratedDashboardControllerAxios,
} from '@/openapi/dashboard/api/integrated-dashboard-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import {
  convertToDashboardData,
  convertToDeleteDashboardsDto,
  convertToUpdateDashboardFavoriteRequestDto,
  convertToUpdateDashboardGroupRequestDto,
} from '@/dashboard/adaptors';
import { PLATFORM_MENU } from '@/common/stores/platform-dashboard';
import {
  getBoardsIntegratedDashboardV8ControllerAxios,
  getBoardsNewIntegratedDashboardV8ControllerAxios,
} from '@/openapi/dashboard/api/integrated-dashboard-v8-controller-api';
import {
  IntegrateDashboardComponentExtends,
  IntegrateDashboardPatchResponse,
} from '@/openapi/metaV6/model';
import { PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import {
  DashboardGroupMoveRequest,
  IntegrateDashboardGroupResponseItem,
} from '@/openapi/dashboard/model';
import { UpdateDashboardGroupRequest } from '@/dashboard/types/dashboard/types';
import { getGroupsIntegratedDashboardGroupControllerAxios } from '@/openapi/dashboard/api/integrated-dashboard-group-controller-api';
import { DashboardCardDataType } from '@/dashboard/components/dashboardCard/types';
import { USER_ENV_KEYS } from '@/common/define/userEnv.define';
import { useUserEnvStore } from '@/common/stores/user-env';
import { convertMenuPaths } from '@/dashboard/utils/dashboardUtils';
import { i18n } from '@/common/locale';
import { FEATURED_DASHBOARD_GROUP_LANGUAGE_KEY } from '../views/dashboardListView/dashboardListView.define';

const envKey = USER_ENV_KEYS.DISPLAYOPTION_dashboard_main_view;

interface MainViewEnvType {
  selectedTab: string;
  showFavoriteOnly: boolean;
  showBoardType: string;
  showDisplayType: string;
  isExpandRecentVisit: boolean;
  isExpandRecentShare: boolean;
}

const initMainViewEnv: MainViewEnvType = {
  selectedTab: 'all',
  showFavoriteOnly: false,
  showBoardType: 'custom',
  showDisplayType: 'list',
  isExpandRecentVisit: true,
  isExpandRecentShare: true,
};

export const useDashboardListViewStore = defineStore('dashboardDashboardListView', () => {
  const { getEnvValue, setEnvValue } = useUserEnvStore();

  const mainViewEnv = ref(initMainViewEnv);
  const setEnv = () => {
    setEnvValue([{ key: envKey, value: JSON.stringify(mainViewEnv.value) }]);
  };
  const initEnv = async () => {
    const savedEnv = getEnvValue(envKey);
    if (savedEnv) {
      mainViewEnv.value = merge(mainViewEnv.value, savedEnv);
    } else {
      mainViewEnv.value = initMainViewEnv;
    }
  };
  watch(() => mainViewEnv, setEnv, { deep: true });

  // 데이터 갱신 트리거
  const refreshCounter = ref<number>(0);
  const refreshTrigger = () => {
    refreshCounter.value += 1;
  };

  const getDashboardTabTypeParam = (v?: string) => {
    switch (v) {
      case 'all':
        return undefined;
      default:
        return v;
    }
  };

  const dashboardUserGroupList = ref<IntegrateDashboardGroupResponseItem[]>([]);
  const dashboardUserGroupListByTab = ref<IntegrateDashboardGroupResponseItem[]>([]);
  const dashboardAllGroupNames = ref<string[]>([]);
  const dashboardList = ref<DashboardData[]>([]); // 이미지 값 없는 데이터. 목록뷰에서 사용.
  const dashboardListByTab = ref<DashboardData[]>([]); // 이미지 값 없는 데이터. 목록뷰에서 사용.
  const { isMaxgaugeMode } = storeToRefs(useViewModeStore());

  const fetchDashboardUserGroupList = async () => {
    try {
      const { data } = await getGroupsIntegratedDashboardGroupControllerAxios({
        withTotal: false,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_USER_GROUP,
      });
      dashboardUserGroupList.value = data?.data ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDashboardUserGroupListByTab = async () => {
    const dashboardTapType = getDashboardTabTypeParam(mainViewEnv.value.selectedTab);

    try {
      const { data } = await getGroupsIntegratedDashboardGroupControllerAxios({
        dashboardTapType,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_USER_GROUP,
      });
      dashboardUserGroupListByTab.value = data?.data ?? [];
    } catch (e) {
      console.log(e);
    }
  };

  const isExemOnePlatform = (platform: string): boolean =>
    Object.values<string>(PLATFORM_MENU).includes(platform);

  const filterMenuPaths = (menuPaths: string[]): string[] => {
    let filteredValues: string[];
    if (isMaxgaugeMode.value) {
      filteredValues = menuPaths.filter((item) => item !== 'database');
    } else {
      const exemOnePlatform = menuPaths.filter((item) => isExemOnePlatform(item));
      const isExistMaxGaugePlatform = menuPaths.some((item) => !isExemOnePlatform(item));
      if (isExistMaxGaugePlatform && !menuPaths.includes('database')) {
        filteredValues = [...exemOnePlatform, 'database'];
      } else {
        filteredValues = exemOnePlatform;
      }
    }
    return filteredValues;
  };

  const fetchDashboardList = async (isImage = false) => {
    try {
      const { data } = await getBoardsIntegratedDashboardV8ControllerAxios({
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_LIST,
        includeImage: isImage,
      });
      const dashboardInfos = data.data ?? [];
      const dashboardListData = dashboardInfos.map(convertToDashboardData).map((info) => ({
        ...info,
        menuPaths: filterMenuPaths(convertMenuPaths(info.menuPaths ?? [])),
        analysisMenuPaths: filterMenuPaths(convertMenuPaths(info.analysisMenuPaths ?? [])),
      }));
      dashboardList.value = dashboardListData;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDashboardListByTab = async () => {
    const dashboardTapType = getDashboardTabTypeParam(mainViewEnv.value.selectedTab);
    try {
      const { data } = await getBoardsNewIntegratedDashboardV8ControllerAxios({
        dashboardTapType,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_LIST,
      });
      const dashboardInfos = data.data ?? [];
      const dashboardListData = dashboardInfos.map(convertToDashboardData).map((info) => ({
        ...info,
        menuPaths: filterMenuPaths(info.menuPaths ?? []),
        analysisMenuPaths: filterMenuPaths(info.analysisMenuPaths ?? []),
      }));
      dashboardList.value = dashboardListData; // note: 개선 전 대시보드 화면에 영향없도록 하기 위함
      dashboardListByTab.value = dashboardListData;
    } catch (e) {
      console.log(e);
    }
  };

  const updateDashboardUserGroup = async (
    { dashboardGroupId, dashboardGroupName }: DashboardUserGroupData,
    deleteWithDashboard = false,
    deleted = false,
    frameName = '',
  ) => {
    try {
      await patchGroupIntegratedDashboardControllerAxios({
        request: {
          dashboardGroupId,
          dashboardGroupName,
          deleteWithDashboard,
          deleted,
        },
        frameName,
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const addDashboardList = async (
    dashboards: IntegrateDashboardComponentExtends[],
  ): PromiseAxiosResponse<IntegrateDashboardPatchResponse> => {
    return patchBoardIntegratedDashboardControllerAxios({
      request: {
        dashboards,
      },
    });
  };

  const updateDashboardList = async (
    dashboards: IntegrateDashboardComponentExtends[],
    frameName?: string,
  ) => {
    if (!dashboards.length) {
      return;
    }

    await patchBoardIntegratedDashboardControllerAxios({
      request: {
        dashboards,
      },
      frameName,
    });

    await fetchDashboardList();
  };

  const deleteDashboardList = async (dashboardIds: number[]) => {
    const deletedDashboards: DashboardData[] = dashboardList.value.reduce(
      (acc: DashboardData[], dashboard) => {
        if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
          acc.push({
            ...dashboard,
            dashboardGroupId: -1,
          });
        }
        return acc;
      },
      [],
    );

    await updateDashboardList(
      deletedDashboards.map(convertToDeleteDashboardsDto),
      FRAME_NAMES.DASHBOARD.DASHBOARD_LIST_UPDATE,
    );
    refreshTrigger();
  };

  const updateDashboardListGroup = async (
    dashboards: DashboardGroupMoveRequest[],
    frameName?: string,
  ) => {
    await moveBoardIntegratedDashboardControllerAxios({
      request: {
        dashboards,
      },
      frameName,
    });

    await fetchDashboardList();
    refreshTrigger();
  };

  const moveGroupForDashboardList = async (dashboardIds: number[], newGroupId: string | null) => {
    const movedDashboards: UpdateDashboardGroupRequest[] = [];
    dashboardList.value.forEach((dashboard) => {
      if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
        movedDashboards.push({
          dashboardId: dashboard.dashboardId,
          dashboardGroupId: newGroupId ? +newGroupId : undefined,
        });
      }
    });

    await updateDashboardListGroup(
      movedDashboards.map(convertToUpdateDashboardGroupRequestDto),
      FRAME_NAMES.DASHBOARD.DASHBOARD_USER_GROUP_MOVE,
    );
  };

  const updateFavoriteDashboardList = async (dashboardIds: number[]) => {
    if (!dashboardIds.length) {
      return;
    }

    const dashboards: UpdateDashboardFavoriteRequest[] = [];
    dashboardList.value.forEach((dashboard) => {
      if (dashboard.dashboardId && dashboardIds.includes(dashboard.dashboardId)) {
        dashboards.push({
          dashboardId: dashboard.dashboardId,
          favoriteId: dashboard.favoriteId,
          deleted: !!dashboard.favoriteId,
        });
      }
    });

    try {
      await patchFavoriteBoardIntegratedDashboardControllerAxios({
        request: {
          dashboards: dashboards.map(convertToUpdateDashboardFavoriteRequestDto),
        },
        frameName: FRAME_NAMES.DASHBOARD.FAVORITE_DASHBOARD_UPDATE,
      });
      await fetchDashboardList();
      refreshTrigger();
    } catch (e) {
      console.log(e);
    }
  };

  const setReportDashboard = async (dashboardId: number, customReport: boolean) => {
    try {
      await setCustomReportIntegratedDashboardControllerAxios({
        request: {
          dashboardId,
          customReport,
        },
        frameName: FRAME_NAMES.DASHBOARD.REPORT_DASHBOARD_SET,
      });

      await fetchDashboardList();
      refreshTrigger();
    } catch (e) {
      console.log(e);
    }
  };

  const openAddDashboardImmediately = ref<boolean>(false);
  const setOpenAddDashboardImmediately = (val: boolean) => {
    openAddDashboardImmediately.value = val;
  };

  const filterFavoriteOnly = (dashboard: DashboardCardDataType) => {
    if (!mainViewEnv.value.showFavoriteOnly) return true;
    return !!dashboard.favoriteId === mainViewEnv.value.showFavoriteOnly;
  };

  const dashboardFeaturedGroupNames = computed<string[]>(() => {
    return Object.values(FEATURED_DASHBOARD_GROUP_LANGUAGE_KEY)
      .map((key) => {
        return i18n.global.availableLocales.map((locale) => i18n.global.t(key, {}, { locale }));
      })
      .flat();
  });
  watch(dashboardUserGroupList, (userGroups) => {
    dashboardAllGroupNames.value = [
      ...dashboardFeaturedGroupNames.value,
      ...userGroups.map((group) => group.dashboardGroupName).filter((name) => name != null),
    ];
  });

  return {
    mainViewEnv,
    initEnv,
    refreshCounter,
    refreshTrigger,

    dashboardUserGroupList,
    dashboardAllGroupNames,
    fetchDashboardUserGroupList,
    dashboardList,
    fetchDashboardList,
    dashboardUserGroupListByTab,
    fetchDashboardUserGroupListByTab,
    dashboardListByTab,
    fetchDashboardListByTab,
    updateDashboardUserGroup,
    addDashboardList,
    updateDashboardList,
    deleteDashboardList,
    moveGroupForDashboardList,
    updateFavoriteDashboardList,
    setReportDashboard,
    openAddDashboardImmediately,
    setOpenAddDashboardImmediately,

    filterFavoriteOnly,
  };
});
