const ORACLE_ENVS = {
  // instance detail
  GRIDCOLUMNS_database_oracleInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_oracleInstanceDetail_activeSession',
  GRIDCOLUMNS_database_oracleInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_oracleInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_oracleInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_oracleInstanceDetail_sqlList',
  GRIDCOLUMNS_database_instanceDetail_waitClass: 'GRIDCOLUMNS_database_instanceDetail_waitClass',
  GRIDCOLUMNS_database_instanceDetail_waitEvent: 'GRIDCOLUMNS_database_instanceDetail_waitEvent',
  GRIDCOLUMNS_database_instanceDetail_waitSqlList:
    'GRIDCOLUMNS_database_instanceDetail_waitSqlList',
  GRIDCOLUMNS_database_oracleInstanceDetail_parameterList:
    'GRIDCOLUMNS_database_oracleInstanceDetail_parameterList',
  FILTERSEARCH_database_instanceDetail_activeSessionTab_oracle_activeSessionList:
    'FILTERSEARCH_database_instanceDetail_activeSessionTab_oracle_activeSessionList',
  FILTERSEARCH_database_instanceDetail_lockInfoTab_oracle_lockSessionList:
    'FILTERSEARCH_database_instanceDetail_lockInfoTab_oracle_lockSessionList',
  FILTERSEARCH_database_instanceDetail_sqlListTab_oracle_sqlList:
    'FILTERSEARCH_database_instanceDetail_sqlListTab_oracle_sqlList',
  FILTERSEARCH_database_instanceDetail_eventTab_eventGrid:
    'FILTERSEARCH_database_instanceDetail_eventTab_eventGrid',
  FILTERSEARCH_database_instanceDetail_eventTab_sqlGrid:
    'FILTERSEARCH_database_instanceDetail_eventTab_sqlGrid',
  FILTERSEARCH_database_instanceDetail_parameterListTab_oracle_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterListTab_oracle_parameterList',

  // parameter detail
  GRIDCOLUMNS_database_instanceDetail_parameterDetail_oracle_parameterList:
    'GRIDCOLUMNS_database_instanceDetail_parameterDetail_oracle_parameterList',
  FILTERSEARCH_database_instanceDetail_parameterDetail_oracle_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterDetail_oracle_parameterList',

  // session detail
  GRIDCOLUMNS_database_oracleSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_oracleSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_oracleSessionDetail_plan: 'GRIDCOLUMNS_database_oracleSessionDetail_plan',
  FILTERSEARCH_database_sessionDetail_oracle_sessionHistoryList:
    'FILTERSEARCH_database_sessionDetail_oracle_sessionHistoryList',

  // sql detail
  GRIDCOLUMNS_database_oracleSqlDetail_plan: 'GRIDCOLUMNS_database_oracleSqlDetail_plan',
  GRIDCOLUMNS_database_oracleSqlDetail_sqlHistory:
    'GRIDCOLUMNS_database_oracleSqlDetail_sqlHistory',
  FILTERSEARCH_database_oracleSqlDetail_sqlHistory:
    'FILTERSEARCH_database_oracleSqlDetail_sqlHistory',

  // multiView
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_sessions:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_sessions',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitChains:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitChains',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitClass:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitClass',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitEvent:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_waitEvent',
  GRIDCOLUMNS_database_oracleMultiview_sessionTab_sqlSummary:
    'GRIDCOLUMNS_database_oracleMultiview_sessionTab_sqlSummary',
  FILTERSEARCH_database_oracleMultiview_sessionTab_sessions:
    'FILTERSEARCH_database_oracleMultiview_sessionTab_sessions',
  FILTERSEARCH_database_oracleMultiview_sessionTab_waitChains:
    'FILTERSEARCH_database_oracleMultiview_sessionTab_waitChains',
  FILTERSEARCH_database_oracleMultiview_sessionTab_waitClass:
    'FILTERSEARCH_database_oracleMultiview_sessionTab_waitClass',
  FILTERSEARCH_database_oracleMultiview_sessionTab_waitEvent:
    'FILTERSEARCH_database_oracleMultiview_sessionTab_waitEvent',
  FILTERSEARCH_database_oracleMultiview_sessionTab_sqlSummary:
    'FILTERSEARCH_database_oracleMultiview_sessionTab_sqlSummary',
  FILTERSEARCH_database_oracleMultiview_sessionTab_event:
    'FILTERSEARCH_database_oracleMultiview_sessionTab_event',

  // rtm session detail
  GRIDCOLUMNS_database_oracleRtm_sessionDetail_plan:
    'GRIDCOLUMNS_database_oracleRtm_sessionDetail_plan',
};

const POSTGRESQL_ENVS = {
  // instance detail
  GRIDCOLUMNS_database_postgresqlInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_postgresqlInstanceDetail_activeSession',
  GRIDCOLUMNS_database_postgresqlInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_postgresqlInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_postgresqlInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_postgresqlInstanceDetail_sqlList',
  GRIDCOLUMNS_database_postgresqlInstanceDetail_parameterList:
    'GRIDCOLUMNS_database_postgresqlInstanceDetail_parameterList',
  FILTERSEARCH_database_instanceDetail_activeSessionTab_pg_activeSessionList:
    'FILTERSEARCH_database_instanceDetail_activeSessionTab_pg_activeSessionList',
  FILTERSEARCH_database_instanceDetail_lockInfoTab_pg_lockSessionList:
    'FILTERSEARCH_database_instanceDetail_lockInfoTab_pg_lockSessionList',
  FILTERSEARCH_database_instanceDetail_sqlListTab_pg_sqlList:
    'FILTERSEARCH_database_instanceDetail_sqlListTab_pg_sqlList',
  FILTERSEARCH_database_instanceDetail_parameterListTab_pg_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterListTab_pg_parameterList',

  // parameter detail
  GRIDCOLUMNS_database_instanceDetail_parameterDetail_pg_parameterList:
    'GRIDCOLUMNS_database_instanceDetail_parameterDetail_pg_parameterList',
  FILTERSEARCH_database_instanceDetail_parameterDetail_pg_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterDetail_pg_parameterList',

  // session detail
  GRIDCOLUMNS_database_postgresqlSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_postgresqlSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_postgresqlSessionDetail_plan:
    'GRIDCOLUMNS_database_postgresqlSessionDetail_plan',
  FILTERSEARCH_database_sessionDetail_pg_sessionHistoryList:
    'FILTERSEARCH_database_sessionDetail_pg_sessionHistoryList',

  // sql detail
  GRIDCOLUMNS_database_postgresqlSqlDetail_plan: 'GRIDCOLUMNS_database_postgresqlSqlDetail_plan',
  GRIDCOLUMNS_database_postgresqlSqlDetail_sqlHistory:
    'GRIDCOLUMNS_database_postgresqlSqlDetail_sqlHistory',
  FILTERSEARCH_database_postgresqlSqlDetail_sqlHistory:
    'FILTERSEARCH_database_postgresqlSqlDetail_sqlHistory',

  // singleView, multiView
  GRIDCOLUMNS_database_postgresqlRtm_topObjectWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_topObjectWindow',
  GRIDCOLUMNS_database_postgresqlRtm_topSqlWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_topSqlWindow',
  GRIDCOLUMNS_database_postgresqlRtm_fileUsageWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_fileUsageWindow',
  GRIDCOLUMNS_database_postgresqlRtm_sessionDetail_plan:
    'GRIDCOLUMNS_database_postgresqlRtm_sessionDetail_plan',
  GRIDCOLUMNS_database_postgresqlRtm_lockTree: 'GRIDCOLUMNS_database_postgresqlRtm_lockTree',
  GRIDCOLUMNS_database_postgresqlRtm_activeBackends:
    'GRIDCOLUMNS_database_postgresqlRtm_activeBackends',
  GRIDCOLUMNS_database_postgresqlRtm_vacuumingBackendsWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_vacuumingBackendsWindow',
  GRIDCOLUMNS_database_postgresqlRtm_parameterWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_parameterWindow',
  GRIDCOLUMNS_database_postgresqlRtm_topObjectTablespaceWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_topObjectTablespaceWindow',
  GRIDCOLUMNS_database_postgresqlRtm_oldStatisticsWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_oldStatisticsWindow',
  GRIDCOLUMNS_database_postgresqlRtm_slowQueryWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_slowQueryWindow',
  GRIDCOLUMNS_database_postgresqlRtm_sqlElapsedWindow:
    'GRIDCOLUMNS_database_postgresqlRtm_sqlElapsedWindow',
  GRIDCOLUMNS_database_postgresqlRtm_rank: 'GRIDCOLUMNS_database_postgresqlRtm_rank',
  FILTERSEARCH_database_postgresqlRtm_lockTree: 'FILTERSEARCH_database_postgresqlRtm_lockTree',
  FILTERSEARCH_database_postgresqlRtm_activeBackends:
    'FILTERSEARCH_database_postgresqlRtm_activeBackends',
};

const MYSQL_ENVS = {
  // instance detail
  GRIDCOLUMNS_database_mysqlInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_mysqlInstanceDetail_activeSession',
  GRIDCOLUMNS_database_mysqlInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_mysqlInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_mysqlInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_mysqlInstanceDetail_sqlList',
  GRIDCOLUMNS_database_mysqlInstanceDetail_parameterList:
    'GRIDCOLUMNS_database_mysqlInstanceDetail_parameterList',
  FILTERSEARCH_database_instanceDetail_activeSessionTab_mysql_activeSessionList:
    'FILTERSEARCH_database_instanceDetail_activeSessionTab_mysql_activeSessionList',
  FILTERSEARCH_database_instanceDetail_lockInfoTab_mysql_lockSessionList:
    'FILTERSEARCH_database_instanceDetail_lockInfoTab_mysql_lockSessionList',
  FILTERSEARCH_database_instanceDetail_sqlListTab_mysql_sqlList:
    'FILTERSEARCH_database_instanceDetail_sqlListTab_mysql_sqlList',
  FILTERSEARCH_database_instanceDetail_parameterListTab_mysql_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterListTab_mysql_parameterList',
  FILTERSEARCH_database_instanceDetail_cloudEventTab_alarmLogList:
    'FILTERSEARCH_database_instanceDetail_cloudEventTab_alarmLogList',

  // parameter detail
  GRIDCOLUMNS_database_instanceDetail_parameterDetail_mysql_parameterList:
    'GRIDCOLUMNS_database_instanceDetail_parameterDetail_mysql_parameterList',
  FILTERSEARCH_database_instanceDetail_parameterDetail_mysql_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterDetail_mysql_parameterList',

  // session detail
  GRIDCOLUMNS_database_mysqlSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_mysqlSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_mysqlSessionDetail_plan: 'GRIDCOLUMNS_database_mysqlSessionDetail_plan',
  FILTERSEARCH_database_sessionDetail_mysql_sessionHistoryList:
    'FILTERSEARCH_database_sessionDetail_mysql_sessionHistoryList',

  // sql detail
  GRIDCOLUMNS_database_mysqlSqlDetail_plan: 'GRIDCOLUMNS_database_mysqlSqlDetail_plan',
  GRIDCOLUMNS_database_mysqlSqlDetail_sqlHistory: 'GRIDCOLUMNS_database_mysqlSqlDetail_sqlHistory',
  FILTERSEARCH_database_mysqlSqlDetail_sqlHistory:
    'FILTERSEARCH_database_mysqlSqlDetail_sqlHistory',

  // singleView, multiView
  GRIDCOLUMNS_database_mysqlRtm_topObjectWindow: 'GRIDCOLUMNS_database_mysqlRtm_topObjectWindow',
  GRIDCOLUMNS_database_mysqlRtm_globalTempWindow: 'GRIDCOLUMNS_database_mysqlRtm_globalTempWindow',
  GRIDCOLUMNS_database_mysqlRtm_sessionTempWindow:
    'GRIDCOLUMNS_database_mysqlRtm_sessionTempWindow',
  GRIDCOLUMNS_database_mysqlRtm_topSqlWindow: 'GRIDCOLUMNS_database_mysqlRtm_topSqlWindow',
  GRIDCOLUMNS_database_mysqlRtm_topTransactionWindow:
    'GRIDCOLUMNS_database_mysqlRtm_topTransactionWindow',
  GRIDCOLUMNS_database_mysqlRtm_slowQueryWindow_plan:
    'GRIDCOLUMNS_database_mysqlRtm_slowQueryWindow_plan',
  GRIDCOLUMNS_database_mysqlRtm_thread: 'GRIDCOLUMNS_database_mysqlRtm_thread',
  GRIDCOLUMNS_database_mysqlRtm_lockTree: 'GRIDCOLUMNS_database_mysqlRtm_lockTree',
  GRIDCOLUMNS_database_mysqlRtm_metaLockTree: 'GRIDCOLUMNS_database_mysqlRtm_metaLockTree',
  GRIDCOLUMNS_database_mysqlRtm_rank: 'GRIDCOLUMNS_database_mysqlRtm_rank',
  GRIDCOLUMNS_database_mysql_sessionDetailWindow_plan:
    'GRIDCOLUMNS_database_mysql_sessionDetailWindow_plan',
  GRIDCOLUMNS_database_mysqlRtm_topEventWindow: 'GRIDCOLUMNS_database_mysqlRtm_topEventWindow',
  GRIDCOLUMNS_database_mysqlRtm_topSqlErrorWindow:
    'GRIDCOLUMNS_database_mysqlRtm_topSqlErrorWindow',
  GRIDCOLUMNS_database_mysqlRtm_topFullScanWindow:
    'GRIDCOLUMNS_database_mysqlRtm_topFullScanWindow',
  GRIDCOLUMNS_database_mysqlRtm_parameterWindow: 'GRIDCOLUMNS_database_mysqlRtm_parameterWindow',
  GRIDCOLUMNS_database_mysqlRtm_topTablespaceWindow:
    'GRIDCOLUMNS_database_mysqlRtm_topTablespaceWindow',
  GRIDCOLUMNS_database_mysqlRtm_slowQueryWindow: 'GRIDCOLUMNS_database_mysqlRtm_slowQueryWindow',
  GRIDCOLUMNS_database_mysqlRtm_sqlElapsedWindow: 'GRIDCOLUMNS_database_mysqlRtm_sqlElapsedWindow',
  FILTERSEARCH_database_mysqlRtm_thread: 'FILTERSEARCH_database_mysqlRtm_thread',
  FILTERSEARCH_database_mysqlRtm_lockTree: 'FILTERSEARCH_database_mysqlRtm_lockTree',
  FILTERSEARCH_database_mysqlRtm_metaLockTree: 'FILTERSEARCH_database_mysqlRtm_metaLockTree',
};

const SQLSERVER_ENVS = {
  // instance detail
  GRIDCOLUMNS_database_sqlserverInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_sqlserverInstanceDetail_activeSession',
  GRIDCOLUMNS_database_sqlserverInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_sqlserverInstanceDetail_sqlList',
  GRIDCOLUMNS_database_sqlserverInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_sqlserverInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_sqlserverInstanceDetail_parameterList:
    'GRIDCOLUMNS_database_sqlserverInstanceDetail_parameterList',
  FILTERSEARCH_database_instanceDetail_activeSessionTab_sqlserver_activeSessionList:
    'FILTERSEARCH_database_instanceDetail_activeSessionTab_sqlserver_activeSessionList',
  FILTERSEARCH_database_instanceDetail_lockInfoTab_sqlserver_lockSessionList:
    'FILTERSEARCH_database_instanceDetail_lockInfoTab_sqlserver_lockSessionList',
  FILTERSEARCH_database_instanceDetail_sqlListTab_sqlserver_sqlList:
    'FILTERSEARCH_database_instanceDetail_sqlListTab_sqlserver_sqlList',
  FILTERSEARCH_database_instanceDetail_parameterListTab_sqlserver_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterListTab_sqlserver_parameterList',

  // parameter detail
  GRIDCOLUMNS_database_instanceDetail_parameterDetail_sqlserver_parameterList:
    'GRIDCOLUMNS_database_instanceDetail_parameterDetail_sqlserver_parameterList',
  FILTERSEARCH_database_instanceDetail_parameterDetail_sqlserver_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterDetail_sqlserver_parameterList',

  // session detail
  GRIDCOLUMNS_database_sqlserverSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_sqlserverSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_sqlserverSessionDetail_plan:
    'GRIDCOLUMNS_database_sqlserverSessionDetail_plan',
  FILTERSEARCH_database_sessionDetail_sqlserver_sessionHistoryList:
    'FILTERSEARCH_database_sessionDetail_sqlserver_sessionHistoryList',

  // sql detail
  GRIDCOLUMNS_database_sqlserverSqlDetail_plan: 'GRIDCOLUMNS_database_sqlserverSqlDetail_plan',
  GRIDCOLUMNS_database_sqlserverSqlDetail_sqlHistory:
    'GRIDCOLUMNS_database_sqlserverSqlDetail_sqlHistory',
  FILTERSEARCH_database_sqlserverSqlDetail_sqlHistory:
    'FILTERSEARCH_database_sqlserverSqlDetail_sqlHistory',

  // singleView
  GRIDCOLUMNS_database_sqlserverRtm_sessionListWindow:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionListWindow',
  GRIDCOLUMNS_database_sqlserverRtm_sqlStatWindow:
    'GRIDCOLUMNS_database_sqlserverRtm_sqlStatWindow',
  GRIDCOLUMNS_database_sqlserverRtm_sessionTab_session:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionTab_session',
  GRIDCOLUMNS_database_sqlserverRtm_sessionTab_blocked:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionTab_blocked',
  GRIDCOLUMNS_database_sqlserverRtm_sessionManager_session:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionManager_session',
  GRIDCOLUMNS_database_sqlserverRtm_sessionManager_blocked:
    'GRIDCOLUMNS_database_sqlserverRtm_sessionManager_blocked',
  GRIDCOLUMNS_database_sqlserverRtm_session: 'GRIDCOLUMNS_database_sqlserverRtm_session',
  GRIDCOLUMNS_database_sqlserverRtm_blocked: 'GRIDCOLUMNS_database_sqlserverRtm_blocked',
  FILTERSEARCH_database_sqlserverRtm_sessionTab_session:
    'FILTERSEARCH_database_sqlserverRtm_sessionTab_session',
  FILTERSEARCH_database_sqlserverRtm_sessionTab_blocked:
    'FILTERSEARCH_database_sqlserverRtm_sessionTab_blocked',
};

const CUBRID_ENVS = {
  // instance detail
  GRIDCOLUMNS_database_cubridInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_cubridInstanceDetail_activeSession',
  GRIDCOLUMNS_database_cubridInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_cubridInstanceDetail_lockInfo',
  FILTERSEARCH_database_instanceDetail_activeSessionTab_cubrid_activeSessionList:
    'FILTERSEARCH_database_instanceDetail_activeSessionTab_cubrid_activeSessionList',
  FILTERSEARCH_database_instanceDetail_lockInfoTab_cubrid_lockSessionList:
    'FILTERSEARCH_database_instanceDetail_lockInfoTab_cubrid_lockSessionList',

  // broker detail
  GRIDCOLUMNS_database_cubrid_broker_activeCasList:
    'GRIDCOLUMNS_database_cubrid_broker_activeCasList',
};

const MONGO_ENVS = {
  // instance detail
  GRIDCOLUMNS_database_mongodbInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_mongodbInstanceDetail_activeSession',
  FILTERSEARCH_database_instanceDetail_activeSessionTab_mongo_activeSessionList:
    'FILTERSEARCH_database_instanceDetail_activeSessionTab_mongo_activeSessionList',

  // mongos
  GRIDCOLUMNS_database_mongos_clusterMap: 'GRIDCOLUMNS_database_mongos_clusterMap',
};

const REDIS_ENVS = {
  // redis key browser
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_list:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_list',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_set:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_set',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_sortedSet:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_sortedSet',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_streamData:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_streamData',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_streamConsumerGroups:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_streamConsumerGroups',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser_hash:
    'GRIDCOLUMNS_database_instanceDetail_keyBrowser_hash',
  FILTERSEARCH_database_instanceDetail_keyBrowser_list:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_list',
  FILTERSEARCH_database_instanceDetail_keyBrowser_set:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_set',
  FILTERSEARCH_database_instanceDetail_keyBrowser_sortedSet:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_sortedSet',
  FILTERSEARCH_database_instanceDetail_keyBrowser_streamData:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_streamData',
  FILTERSEARCH_database_instanceDetail_keyBrowser_streamConsumerGroups:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_streamConsumerGroups',
  FILTERSEARCH_database_instanceDetail_keyBrowser_hash:
    'FILTERSEARCH_database_instanceDetail_keyBrowser_hash',
  FILTERSEARCH_database_instanceDetail_keyBrowser:
    'FILTERSEARCH_database_instanceDetail_keyBrowser',
  FILTERSEARCH_database_instanceDetail_keyBrowserScanMatch:
    'FILTERSEARCH_database_instanceDetail_keyBrowserScanMatch',
};

const ALTIBASE_ENVS = {
  // instance detail
  GRIDCOLUMNS_database_altibaseInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_altibaseInstanceDetail_activeSession',
  GRIDCOLUMNS_database_altibaseInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_altibaseInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_altibaseInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_altibaseInstanceDetail_sqlList',
  GRIDCOLUMNS_database_altibaseInstanceDetail_parameterList:
    'GRIDCOLUMNS_database_altibaseInstanceDetail_parameterList',
  FILTERSEARCH_database_instanceDetail_activeSessionTab_altibase_activeSessionList:
    'FILTERSEARCH_database_instanceDetail_activeSessionTab_altibase_activeSessionList',
  FILTERSEARCH_database_instanceDetail_lockInfoTab_altibase_lockSessionList:
    'FILTERSEARCH_database_instanceDetail_lockInfoTab_altibase_lockSessionList',
  FILTERSEARCH_database_instanceDetail_sqlListTab_altibase_sqlList:
    'FILTERSEARCH_database_instanceDetail_sqlListTab_altibase_sqlList',
  FILTERSEARCH_database_instanceDetail_parameterListTab_altibase_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterListTab_altibase_parameterList',

  // parameter detail
  GRIDCOLUMNS_database_instanceDetail_parameterDetail_altibase_parameterList:
    'GRIDCOLUMNS_database_instanceDetail_parameterDetail_altibase_parameterList',
  FILTERSEARCH_database_instanceDetail_parameterDetail_altibase_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterDetail_altibase_parameterList',

  // session detail
  GRIDCOLUMNS_database_altibaseSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_altibaseSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_altibaseSessionDetail_plan:
    'GRIDCOLUMNS_database_altibaseSessionDetail_plan',
  FILTERSEARCH_database_sessionDetail_altibase_sessionHistoryList:
    'FILTERSEARCH_database_sessionDetail_altibase_sessionHistoryList',

  // sql detail
  GRIDCOLUMNS_database_altibaseSqlDetail_plan: 'GRIDCOLUMNS_database_altibaseSqlDetail_plan',
  GRIDCOLUMNS_database_altibaseSqlDetail_sqlHistory:
    'GRIDCOLUMNS_database_altibaseSqlDetail_sqlHistory',
  FILTERSEARCH_database_altibaseSqlDetail_plan: 'FILTERSEARCH_database_altibaseSqlDetail_plan',
  FILTERSEARCH_database_altibaseSqlDetail_sqlHistory:
    'FILTERSEARCH_database_altibaseSqlDetail_sqlHistory',
};

const TIBERO_ENVS = {
  // instance detail
  GRIDCOLUMNS_database_tiberoInstanceDetail_activeSession:
    'GRIDCOLUMNS_database_tiberoInstanceDetail_activeSession',
  GRIDCOLUMNS_database_tiberoInstanceDetail_lockInfo:
    'GRIDCOLUMNS_database_tiberoInstanceDetail_lockInfo',
  GRIDCOLUMNS_database_tiberoInstanceDetail_sqlList:
    'GRIDCOLUMNS_database_tiberoInstanceDetail_sqlList',
  GRIDCOLUMNS_database_tiberoInstanceDetail_parameterList:
    'GRIDCOLUMNS_database_tiberoInstanceDetail_parameterList',
  FILTERSEARCH_database_instanceDetail_activeSessionTab_tibero_activeSessionList:
    'FILTERSEARCH_database_instanceDetail_activeSessionTab_tibero_activeSessionList',
  FILTERSEARCH_database_instanceDetail_lockInfoTab_tibero_lockSessionList:
    'FILTERSEARCH_database_instanceDetail_lockInfoTab_tibero_lockSessionList',
  FILTERSEARCH_database_instanceDetail_sqlListTab_tibero_sqlList:
    'FILTERSEARCH_database_instanceDetail_sqlListTab_tibero_sqlList',
  FILTERSEARCH_database_instanceDetail_parameterListTab_tibero_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterListTab_tibero_parameterList',

  // parameter detail
  GRIDCOLUMNS_database_instanceDetail_parameterDetail_tibero_parameterList:
    'GRIDCOLUMNS_database_instanceDetail_parameterDetail_tibero_parameterList',
  FILTERSEARCH_database_instanceDetail_parameterDetail_tibero_parameterList:
    'FILTERSEARCH_database_instanceDetail_parameterDetail_tibero_parameterList',

  // session detail
  GRIDCOLUMNS_database_tiberoSessionDetail_activeSessionHistory:
    'GRIDCOLUMNS_database_tiberoSessionDetail_activeSessionHistory',
  GRIDCOLUMNS_database_tiberoSessionDetail_plan: 'GRIDCOLUMNS_database_tiberoSessionDetail_plan',
  FILTERSEARCH_database_sessionDetail_tibero_sessionHistoryList:
    'FILTERSEARCH_database_sessionDetail_tibero_sessionHistoryList',

  // sql detail
  GRIDCOLUMNS_database_tiberoSqlDetail_plan: 'GRIDCOLUMNS_database_tiberoSqlDetail_plan',
  GRIDCOLUMNS_database_tiberoSqlDetail_sqlHistory:
    'GRIDCOLUMNS_database_tiberoSqlDetail_sqlHistory',
  FILTERSEARCH_database_tiberoSqlDetail_plan: 'FILTERSEARCH_database_tiberoSqlDetail_plan',
  FILTERSEARCH_database_tiberoSqlDetail_sqlHistory:
    'FILTERSEARCH_database_tiberoSqlDetail_sqlHistory',
};

export const DATABASE = {
  ...ORACLE_ENVS,
  ...POSTGRESQL_ENVS,
  ...MYSQL_ENVS,
  ...SQLSERVER_ENVS,
  ...CUBRID_ENVS,
  ...MONGO_ENVS,
  ...REDIS_ENVS,
  ...ALTIBASE_ENVS,
  ...TIBERO_ENVS,

  GRIDCOLUMNS_database_instance_instanceList: 'GRIDCOLUMNS_database_instance_instanceList',
  FILTERSEARCH_database_instanceView_instanceList:
    'FILTERSEARCH_database_instanceView_instanceList',
  FILTER_database_instanceView: 'FILTER_database_instanceView',

  // database-specific tabs
  GRIDCOLUMNS_database_instanceDetail_clientList: 'GRIDCOLUMNS_database_instanceDetail_clientList',
  GRIDCOLUMNS_database_instanceDetail_commandStat:
    'GRIDCOLUMNS_database_instanceDetail_commandStat',
  GRIDCOLUMNS_database_instanceDetail_log: 'GRIDCOLUMNS_database_instanceDetail_log',
  GRIDCOLUMNS_database_instanceDetail_cloudEvent: 'GRIDCOLUMNS_database_instanceDetail_cloudEvent',
  GRIDCOLUMNS_database_clusterDetail_clusterMap: 'GRIDCOLUMNS_database_clusterDetail_clusterMap',
  GRIDCOLUMNS_database_instanceDetail_keyBrowser: 'GRIDCOLUMNS_database_instanceDetail_keyBrowser',
  FILTERSEARCH_database_instanceDetail_clientListTab_clientList:
    'FILTERSEARCH_database_instanceDetail_clientListTab_clientList',
  FILTERSEARCH_database_instanceDetail_commandStatTab_commandStatList:
    'FILTERSEARCH_database_instanceDetail_commandStatTab_commandStatList',

  // dashboard jump setting
  DATABASE_INSTANCE_DETAIL_DASHBOARD_SETTING_LIST:
    'DATABASE_INSTANCE_DETAIL_DASHBOARD_SETTING_LIST',
  DATABASE_BROKER_DETAIL_DASHBOARD_SETTING_LIST: 'DATABASE_BROKER_DETAIL_DASHBOARD_SETTING_LIST',
  DATABASE_INSTANCE_CLUSTER_DETAIL_DASHBOARD_SETTING_LIST:
    'DATABASE_INSTANCE_CLUSTER_DETAIL_DASHBOARD_SETTING_LIST',

  GRIDCOLUMNS_database_slowQueryDetail_plan: 'GRIDCOLUMNS_database_slowQueryDetail_plan',
  GRIDCOLUMNS_database_rtm_alertLogs: 'GRIDCOLUMNS_database_rtm_alertLogs',
};
