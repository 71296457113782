import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { store } from '@/common/store';
import { useDashboardListViewStore } from '@/dashboard/stores/dashboard-list-view';
import { useUserEnvStore } from '@/common/stores/user-env';
import { checkRole } from '@/common/auth/auth.utils';

export interface DashboardList {
  dashboardId: number;
  dashboardName: string;
  url?: string;
  isNewWindow?: boolean;
}

export interface UrlInfo {
  checked: boolean;
  url: string;
  urlName: string;
  index: number;
  isGlobal: boolean;
  id: string;
  isNewWindow?: boolean;
}

export interface UrlInfoForUi extends Omit<UrlInfo, 'index' | 'isGlobal' | 'id'> {
  id?: string;
  isGlobal?: boolean;
  urlErrorMsg?: string;
  nameErrorMsg?: string;
}

export interface DashboardJumpData {
  name: string;
  dashboardList: any[];
  urlList: any[];
  checked: boolean;
}

export const useDashboardPopupEnvStore = defineStore('dashboardPopupEnvStore', () => {
  // setupDashBoardPopup.ts 에서 onMounted 훅에서 보장한다는 가정
  const dashboardListViewStore = useDashboardListViewStore();
  const { dashboardList } = storeToRefs(dashboardListViewStore);
  const dashboardListMap = computed(() => {
    return new Map(dashboardList.value.map((v) => [v.dashboardId, v]));
  });

  const dashboardName = ref('');
  const dashboardPopupListCnt = ref(0);
  const dashboardPopupList = ref<DashboardList[]>([]);
  const urlInfoList = ref<UrlInfoForUi[]>([]);

  const userEnvStore = useUserEnvStore();
  const isMaster = computed(() => {
    const myRole = store.getters['myInfo/getAccountInfo'].role;
    return checkRole.isMaster(myRole);
  });

  const setupDashBoardData = ref<DashboardJumpData>({
    name: '',
    dashboardList: [] as any,
    urlList: [] as any,
    checked: false,
  });

  const initDashboardPopupListData = () => {
    dashboardPopupListCnt.value = 0;
    dashboardPopupList.value = [];
  };

  const initSetupDashboardData = () => {
    setupDashBoardData.value = {
      name: '',
      dashboardList: [],
      urlList: [],
      checked: false,
    };
  };

  const mergeObjects = (globalObj: UrlInfo[], envObj: UrlInfo[]) => {
    const mergedList = [...globalObj];

    envObj.forEach((item2) => {
      if (item2.isGlobal) {
        const foundIndex = mergedList.findIndex((item1) => item1.isGlobal && item1.id === item2.id);

        if (foundIndex !== -1) {
          mergedList[foundIndex] = {
            ...mergedList[foundIndex],
            checked: item2.checked,
          };
        }
      } else {
        mergedList.push(item2);
      }
    });

    return mergedList;
  };

  const getFilteredEnvData = (envDashboardList: DashboardJumpData['dashboardList']) => {
    return (
      envDashboardList
        ?.filter((v) => dashboardListMap.value.get(v.dashboardId))
        .map((v) => ({
          ...v,
          dashboardName:
            dashboardListMap.value.get(v.dashboardId)?.dashboardName || v.dashboardName,
        })) ?? []
    );
  };
  const fetchDashboardPopupEnvInfoForKey = async (envKey?: string) => {
    initDashboardPopupListData();
    if (!envKey) return;
    await userEnvStore.fetchEnvDataByKeys([`${envKey}_global`], true);
    const globalEnvData = userEnvStore.getEnvValue<DashboardJumpData>(`${envKey}_global`);
    const envData = userEnvStore.getEnvValue<DashboardJumpData>(envKey);

    if ((isMaster.value && globalEnvData) || (!envData && globalEnvData)) {
      const filteredDashboardList = getFilteredEnvData(globalEnvData?.dashboardList ?? []);

      if (globalEnvData.urlList?.length > 0) {
        globalEnvData.urlList.forEach((v) => {
          if (v.checked) {
            filteredDashboardList.push({
              dashboardId: null,
              dashboardName: v.urlName,
              url: v.url,
              isNewWindow: v.isNewWindow,
            });
          }
        });
      }
      setupDashBoardData.value = {
        ...globalEnvData,
        dashboardList: filteredDashboardList,
        urlList:
          globalEnvData.urlList?.map((v) => ({
            ...v,
            isNewWindow: v.isNewWindow ?? false,
          })) || [],
      };
      dashboardPopupListCnt.value = setupDashBoardData.value.dashboardList.length;
      dashboardPopupList.value = setupDashBoardData.value.dashboardList;
      dashboardName.value = setupDashBoardData.value.name;
      urlInfoList.value = setupDashBoardData.value.urlList || [];
    } else if (envData) {
      const filteredUrlList =
        globalEnvData?.urlList?.length || envData.urlList?.length > 0
          ? mergeObjects(globalEnvData?.urlList || [], envData.urlList)
          : [];
      const filteredDashboardList = getFilteredEnvData([...envData.dashboardList]);

      if (filteredUrlList?.length > 0) {
        filteredUrlList.forEach((v) => {
          if (v.checked) {
            filteredDashboardList.push({
              dashboardId: null,
              dashboardName: v.urlName,
              url: v.url,
              isNewWindow: v.isNewWindow,
            });
          }
        });
      }

      setupDashBoardData.value = {
        ...envData,
        dashboardList: filteredDashboardList,
        urlList:
          filteredUrlList?.map((v) => ({
            ...v,
            isNewWindow: v.isNewWindow ?? false,
          })) || [],
      };
      dashboardPopupListCnt.value = setupDashBoardData.value.dashboardList.length;
      dashboardPopupList.value = setupDashBoardData.value.dashboardList;
      dashboardName.value = setupDashBoardData.value.name;
      urlInfoList.value = setupDashBoardData.value.urlList || [];
    } else {
      dashboardName.value = '';
      urlInfoList.value = [];
    }
  };

  const saveEnvInfoForKey = async (envKey: string, value: string) => {
    if (isMaster.value) {
      await userEnvStore.setEnvValue([{ key: `${envKey}_global`, value }], true);
    } else {
      await userEnvStore.setEnvValue([{ key: envKey, value }]);
    }

    dashboardName.value = setupDashBoardData.value.name;
  };

  return {
    dashboardPopupListCnt,
    dashboardPopupList,
    setupDashBoardData,
    fetchDashboardPopupEnvInfoForKey,
    saveEnvInfoForKey,
    initSetupDashboardData,
    dashboardName,
    urlInfoList,
  };
});
