import { WidgetChartData, WidgetChartType, WidgetCreateType } from '@/dashboard/utils/types';
import { CurrentRawData } from '@/worker/commands/dashboard/current';
import { MetricRawData } from '@/worker/commands/dashboard/metrics';
import { WidgetScatterRawData } from '@/worker/commands/dashboard/scatter';
import { TableRawData } from '@/worker/commands/dashboard/table';
import { isNil } from 'lodash-es';
import { DataResource } from '../editSettings/dataResource.types';
import {
  ReferenceInfo,
  ReferenceVariable,
} from '../widgetSettingsWindow/referenceSettingOption/referenceSettingOption.setup';
import {
  ChartRawData,
  ChartType,
  CurrentChartType,
  CurrentDataField,
  MetricChartType,
  MetricDataField,
  ScatterChartType,
  ScatterDataField,
  TableChartType,
  WidgetChartDataWithIndex,
} from './widgets.types';

export const isMetricRawData = (rawData: any): rawData is MetricRawData => {
  return (
    typeof rawData === 'object' &&
    rawData !== null &&
    'chartData' in rawData &&
    'unitList' in rawData &&
    'triggerInfoList' in rawData
  );
};

export const isCurrentRawData = (rawData: any): rawData is CurrentRawData => {
  return (
    typeof rawData === 'object' &&
    rawData !== null &&
    'currentData' in rawData &&
    'targets' in rawData &&
    'metrics' in rawData
  );
};

export const isTableRawData = (rawData: any): rawData is TableRawData => {
  return (
    typeof rawData === 'object' &&
    rawData !== null &&
    'tableData' in rawData &&
    'targets' in rawData
  );
};

export const isScatterRawData = (rawData: any): rawData is WidgetScatterRawData => {
  return (
    typeof rawData === 'object' &&
    rawData !== null &&
    'chartData' in rawData &&
    'totalCount' in rawData &&
    'maxValue' in rawData &&
    'requestTimeList' in rawData &&
    'unitList' in rawData
  );
};

const isChartType = <T extends ChartType>(
  chartType: string,
  validTypes: readonly T[],
): chartType is T => {
  return validTypes.includes(chartType as T);
};

export const isMetricChartType = (chartType: any): chartType is MetricChartType =>
  isChartType(chartType, MetricChartType);

export const isCurrentChartType = (chartType: any): chartType is CurrentChartType =>
  isChartType(chartType, CurrentChartType);

export const isTableChartType = (chartType: any): chartType is TableChartType =>
  isChartType(chartType, TableChartType);

export const isScatterChartType = (chartType: any): chartType is ScatterChartType =>
  isChartType(chartType, ScatterChartType);

export const extractReferenceInfo = (
  statId: string,
  referenceInfo?: ReferenceInfo,
): ReferenceVariable[] | null => {
  if (!referenceInfo) return null;

  return (
    Object.values(referenceInfo).find((variables) =>
      variables.find((variable) => variable.key.statId === statId),
    ) ?? null
  );
};

export const checkValidTargetByCreateType =
  (createType: WidgetCreateType) => (widgetChartData: WidgetChartData) => {
    const hasTargets = createType === 'reference' ? true : widgetChartData.targets.length > 0;

    return hasTargets;
  };
export const checkValidCategoryByCreateType =
  (createType: WidgetCreateType) => (widgetChartData: WidgetChartData) => {
    return createType === 'reference' || widgetChartData.category !== 'custom';
  };

export const isChartDataValid =
  (createType: WidgetCreateType, dataResource: DataResource) =>
  (widgetChartData: WidgetChartData) => {
    const hasDataId = !!widgetChartData.dataId;

    switch (dataResource) {
      case 'exemOne':
        return (
          hasDataId &&
          checkValidTargetByCreateType(createType)(widgetChartData) &&
          checkValidCategoryByCreateType(createType)(widgetChartData)
        );
      case 'prometheus':
        return (
          hasDataId &&
          checkValidTargetByCreateType(createType)(widgetChartData) &&
          checkValidCategoryByCreateType(createType)(widgetChartData) &&
          !!widgetChartData.externalOptions?.query
        );
      case 'elasticsearch':
        if (widgetChartData.externalOptions?.createBy === 'queryBuilder') {
          if (widgetChartData.externalOptions.queryType === 'metrics') {
            const showBuckets =
              widgetChartData.externalOptions.buckets?.filter((bucket) => bucket.show) ?? [];
            const showMetrics =
              widgetChartData.externalOptions.metrics?.filter((metric) => metric.show) ?? [];
            return (
              hasDataId &&
              checkValidTargetByCreateType(createType)(widgetChartData) &&
              checkValidCategoryByCreateType(createType)(widgetChartData) &&
              widgetChartData.externalOptions &&
              showBuckets.length > 0 &&
              showBuckets.every((bucket) => bucket.isValid()) &&
              showMetrics.length > 0 &&
              showMetrics.every((metrics) => metrics.isValid())
            );
          }
          if (widgetChartData.externalOptions.queryType === 'logs') {
            return (
              hasDataId &&
              checkValidTargetByCreateType(createType)(widgetChartData) &&
              checkValidCategoryByCreateType(createType)(widgetChartData) &&
              widgetChartData.externalOptions &&
              !isNil(widgetChartData.externalOptions.options.limit)
            );
          }
        } else if (widgetChartData.externalOptions?.createBy === 'manual') {
          return (
            hasDataId &&
            checkValidTargetByCreateType(createType)(widgetChartData) &&
            checkValidCategoryByCreateType(createType)(widgetChartData) &&
            widgetChartData.externalOptions &&
            widgetChartData.externalOptions.queries &&
            widgetChartData.externalOptions.queries.filter(({ query }) => query.trim() !== '')
              .length > 0
          );
        }
        return false;
      default:
        throw new Error(`Unsupported dataResource: ${dataResource}`);
    }
  };

export const filterChartDataByValidity =
  (_chartType: WidgetChartType) =>
  (createType: WidgetCreateType, dataResource: DataResource) =>
  (widgetChartData: WidgetChartData) => {
    return isChartDataValid(createType, dataResource)(widgetChartData);
  };

export const appendChartDataIndex = (
  widgetChartDataList: WidgetChartData[],
): WidgetChartDataWithIndex[] => {
  return widgetChartDataList.map((widgetChartData, index) => ({
    ...widgetChartData,
    chartDataIndex: index,
  }));
};

export const getFieldList = (chartType: WidgetChartType, rawData?: ChartRawData) => {
  if (isTableChartType(chartType) && isTableRawData(rawData)) {
    return rawData?.tableData?.flatMap(({ columns }) => columns).map(({ name }) => name) ?? [];
  }
  if (isMetricChartType(chartType)) {
    return MetricDataField;
  }
  if (isCurrentChartType(chartType)) {
    return CurrentDataField;
  }
  if (isScatterChartType(chartType)) {
    return ScatterDataField;
  }
  return [];
};
