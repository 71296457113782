import { computed, getCurrentInstance } from 'vue';
import { isNil } from 'lodash-es';
import { useViewModeStore } from '@/common/stores/view-mode';
import router from '@/common/router';
import { confirmMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { USER_GENERAL_SETTING_ITEMS } from '@/common/utils';
import { checkRole } from '@/common/auth/auth.utils';
import { useMyInfoData } from './myInfoPopup.uses';
import type { GroupItem, PopupListButtonData } from './myInfoPopup.types';
import { useShowSubPopup } from '../popups.uses';

export interface Props {
  isFixed: boolean;
}

export interface Emit {
  (e: 'click-setting'): void;
  (e: 'mouseleave'): void;
}

export const setup = (props: Props, emit: Emit) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const { productName } = useViewModeStore();

  const {
    userProfile,
    languageInfo,
    themeInfo,
    groupItems,

    selectedGroup,
    isGroupListOpened,

    changeGroup,
    changeLanguage,
    changeTheme,
  } = useMyInfoData();

  const { mainMenuRef, subMenuRef, subMenuPopupStyle, setHoveredItemPosition } = useShowSubPopup();

  const isMaster = computed<boolean>(() => checkRole.isMaster(userProfile.value.role));

  const onLogout = () => {
    confirmMsg(ctx, {
      msgStr: t('MESSAGE.LOGOUT'),
      okCallback: () => router.push('/logout'),
      confirmBtnText: t('WORD.GNB.PROFILE_LOGOUT'),
    });
  };

  const toggleGroupList = (value?: boolean) => {
    isGroupListOpened.value = isNil(value) ? !isGroupListOpened.value : value;
  };

  const onChangeGroup = (group: GroupItem) => {
    changeGroup(group);
  };

  const onChangeLanguage = (language?: string) => {
    changeLanguage(language);
  };

  const onChangeTheme = (theme?: string) => {
    changeTheme(theme);
  };

  const onClickMonitoringGroup = (e?: MouseEvent) => {
    if (isMaster.value) {
      return;
    }

    setHoveredItemPosition(e);
    toggleGroupList();
  };

  const onClickOutside = () => {
    toggleGroupList(false);
  };

  const onClickSetting = () => {
    emit('click-setting');
  };

  const onLeavePopup = () => {
    if (props.isFixed) {
      return;
    }

    toggleGroupList(false);
    emit('mouseleave');
  };

  const POPUP_LIST_BUTTONS: PopupListButtonData[] = [
    {
      icon: 'icon-popup-settings',
      textKey: 'WORD.GNB.SETTING',
      key: 'setting',
      onClick: onClickSetting,
    },
    { icon: 'icon-popup-language', textKey: 'WORD.LANGUAGE', key: 'language' },
    { icon: 'icon-popup-theme', textKey: 'WORD.THEME', key: 'theme' },
    {
      icon: 'icon-popup-group',
      textKey: 'WORD.MONITORING_GROUP',
      key: 'group',
      disabled: isMaster.value,
      onClick: onClickMonitoringGroup,
    },
    {
      icon: 'icon-popup-logout',
      textKey: 'WORD.GNB.PROFILE_LOGOUT',
      key: 'logout',
      onClick: onLogout,
    },
  ];

  const LANGUAGE_OPTIONS = USER_GENERAL_SETTING_ITEMS.lang.map<{ label: string }>((item) => ({
    label: item.name,
  }));

  const THEME_OPTIONS = USER_GENERAL_SETTING_ITEMS.theme.map<{ label: string }>((item) => ({
    label: item.name,
  }));

  return {
    t,

    mainMenuRef,
    subMenuRef,
    subMenuPopupStyle,

    productName,
    userProfile,
    languageInfo,
    themeInfo,
    groupItems,
    selectedGroup,

    isGroupListOpened,

    POPUP_LIST_BUTTONS,
    LANGUAGE_OPTIONS,
    THEME_OPTIONS,

    onClickOutside,
    onChangeGroup,
    onChangeLanguage,
    onChangeTheme,
    onLeavePopup,
  };
};
