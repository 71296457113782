import dayjs from 'dayjs';
import { fileDownload } from '@/common/utils/commonUtils';
import { type PromiseAxiosResponse } from '@/worker/commands/config/apiInstance';
import type { ExportExcelOption } from './excel.types';

export const exportToExcelFromServer = async <RequestParams, Response>(
  exportToExcelApiFn: (params: RequestParams) => PromiseAxiosResponse<Response>,
  params: RequestParams,
  option: ExportExcelOption = {},
) => {
  const { data: excelFile } = await exportToExcelApiFn(params);
  const serverResponseData = excelFile as unknown as string;

  const filename = `${option.title ?? 'export'}_${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`;
  fileDownload({ fileName: filename, fileData: serverResponseData, fileType: 'excel' });
};
