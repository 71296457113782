const MAIN_FRAMES = {
  CONFIG_BUSINESS: 'Config - Business',
  CONFIG_BUSINESS_GROUP_WINDOW: 'Config - Business Group Setting Window',
  CONFIG_BUSINESS_WINDOW: 'Config - Business Setting Window',

  LOG_SETTING: 'Setting - Log',

  CONFIG_REPORT_DASHBOARD_EXPORT: 'Config - Report > Dashboard Export',
  CONFIG_REPORT_DASHBOARD_EXPORT_GROUP_WINDOW: 'Config - Dashboard Export Group Setting Window',
  CONFIG_REPORT_DAShBOARD_EXPORT_WINDOW: 'Config - Dashboard Export Setting Window',
  CONFIG_REPORT_BUSINESS_DASHBOARD_EXPORT: 'Config - Report > Business Dashboard Export',
  CONFIG_REPORT_BUSINESS_DASHBOARD_EXPORT_WINDOW:
    'Config - Business Dashboard Export Setting Window',
  CONFIG_REPORT_SCHEDULE: 'Config - Report > Schedule',
  CONFIG_REPORT_SCHEDULE_GROUP_WINDOW: 'Config - Schedule Group Setting Window',
  CONFIG_REPORT_SCHEDULE_WINDOW: 'Config - Schedule Setting Window',

  CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT: 'Config - Custom Stat > Schedule Management',
} as const;

export const CONFIG_MONITORING_FRAME_NAMES = {
  // 비즈니스
  CONFIG_BUSINESS: {
    BUSINESS_GROUP_LIST: `${MAIN_FRAMES.CONFIG_BUSINESS}/Business Group List`,
    BUSINESS_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_BUSINESS}/[Delete] Business Group`,
    BUSINESS_LIST: `${MAIN_FRAMES.CONFIG_BUSINESS}/Business List`,
    BUSINESS_MOVE: `${MAIN_FRAMES.CONFIG_BUSINESS}/[Move] Business`,
    BUSINESS_DELETE: `${MAIN_FRAMES.CONFIG_BUSINESS}/[Delete] Business`,
  },
  CONFIG_BUSINESS_GROUP_WINDOW: {
    BUSINESS_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_BUSINESS_GROUP_WINDOW}/[Save] Business Group`,
  },
  CONFIG_BUSINESS_WINDOW: {
    CUSTOM_ICON_DATA: `${MAIN_FRAMES.CONFIG_BUSINESS_WINDOW}/Custom Icon Data`,
    BUSINESS_INFORMATION: `${MAIN_FRAMES.CONFIG_BUSINESS_WINDOW}/Business Information`,
    BUSINESS_SAVE: `${MAIN_FRAMES.CONFIG_BUSINESS_WINDOW}/[Save] Business`,
  },
  CONFIG_REPORT_BUSINESS_DASHBOARD_EXPORT: {
    BUSINESS_DASHBOARD_EXPORT_LIST: `${MAIN_FRAMES.CONFIG_REPORT_BUSINESS_DASHBOARD_EXPORT}/Business Dashboard Export List`,
    BUSINESS_DASHBOARD_EXPORT: `${MAIN_FRAMES.CONFIG_REPORT_BUSINESS_DASHBOARD_EXPORT}/Business Dashboard Export`,
  },
  CONFIG_REPORT_BUSINESS_DASHBOARD_EXPORT__WINDOW: {
    BUSINESS_DASHBOARD_EXPORT_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_BUSINESS_DASHBOARD_EXPORT_WINDOW}/[Save] Business Dashboard Export`,
  },

  // 로그
  LOG_SETTING: {
    LOGGING_GROUP_LIST: `${MAIN_FRAMES.LOG_SETTING}/Logging Group List`,
    LOGGING_GROUP_SAVE: `${MAIN_FRAMES.LOG_SETTING}/[Save] Logging Group`,
    LOGGING_GROUP_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Logging Group`,
    LOGGING_GROUP_DELETE: `${MAIN_FRAMES.LOG_SETTING}/[Delete] Logging Group`,
    LOGGING_LIST: `${MAIN_FRAMES.LOG_SETTING}/Logging List`,
    LOGGING_SAVE: `${MAIN_FRAMES.LOG_SETTING}/[Save] Logging`,
    LOGGING_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Logging`,
    LOGGING_DELETE: `${MAIN_FRAMES.LOG_SETTING}/[Delete] Logging`,
    LOGGING_ACTIVE: `${MAIN_FRAMES.LOG_SETTING}/[Active] Logging`,
    LOGGING_INFO: `${MAIN_FRAMES.LOG_SETTING}/Logging Info`,
    PATH_LIST_TARGET_TYPE: `${MAIN_FRAMES.LOG_SETTING}/Path List Target Type`,
    PATH_LIST_USED: `${MAIN_FRAMES.LOG_SETTING}/Path List Used`,
    PATH_LIST: `${MAIN_FRAMES.LOG_SETTING}/Path List`,
    PATH_LIST_BY_LOG_TYPE: `${MAIN_FRAMES.LOG_SETTING}/Path List By Log Type`,
    PATH_SAVE: `${MAIN_FRAMES.LOG_SETTING}/[Save] Path`,
    PATH_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Path`,
    PATH_DELETE: `${MAIN_FRAMES.LOG_SETTING}/[Delete] Path`,
    PATH_INFO: `${MAIN_FRAMES.LOG_SETTING}/Path Info`,
    LIVE_TAIL_LIST: `${MAIN_FRAMES.LOG_SETTING}/Live Tail List`,
    LIVE_TAIL_INFO: `${MAIN_FRAMES.LOG_SETTING}/Live Tail Info`,
    LIVE_TAIL_ACTIVATE: `${MAIN_FRAMES.LOG_SETTING}/[Activate] Live Tail`,
    LIVE_TAIL_DEACTIVATE: `${MAIN_FRAMES.LOG_SETTING}/[Deactivate] Live Tail`,
    LIVE_TAIL_GROUP_LIST: `${MAIN_FRAMES.LOG_SETTING}/Live Tail Group List`,
    LIVE_TAIL_ISOLATE: `${MAIN_FRAMES.LOG_SETTING}/[Isolate] Live Tail`,
    LIVE_TAIL_GROUP_PARSER_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Live Tail Group Parser`,
    LIVE_TAIL_CUSTOM_PARSER_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Live Tail Custom Parser`,
    PARSER_LIST: `${MAIN_FRAMES.LOG_SETTING}/Parser List`,
    PARSER_SAVE: `${MAIN_FRAMES.LOG_SETTING}/[Save] Parser`,
    PARSER_UPDATE: `${MAIN_FRAMES.LOG_SETTING}/[Update] Parser`,
    PARSER_DELETE: `${MAIN_FRAMES.LOG_SETTING}/[Delete] Parser`,
    PARSER_INFO: `${MAIN_FRAMES.LOG_SETTING}/Parser Info`,
    PARSER_SIMULATION: `${MAIN_FRAMES.LOG_SETTING}/Parser Simulation`,
    ACCESS_LOG_LIST: `${MAIN_FRAMES.LOG_SETTING}/Access Log List`,
  },

  // 리포트
  CONFIG_REPORT_DASHBOARD_EXPORT: {
    DASHBOARD_EXPORT_GROUP_LIST: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/Dashboard Export Group List`,
    DASHBOARD_EXPORT_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/[Delete] Dashboard Export Group List`,
    DASHBOARD_EXPORT_LIST: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/Dashboard EXPORT LIST`,
    DASHBOARD_EXPORT_MOVE: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/[Move] Dashboard Export List`,
    DASHBOARD_EXPORT_DELETE: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT}/[Delete] Dashboard Export List`,
  },
  CONFIG_REPORT_DASHBOARD_EXPORT_GROUP_WINDOW: {
    DASHBOARD_EXPORT_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_DASHBOARD_EXPORT_GROUP_WINDOW}/[Save] Report Dashboard Export Group`,
  },
  CONFIG_REPORT_DASHBOARD_EXPORT_WINDOW: {
    REPORT_TEMPLATE: `${MAIN_FRAMES.CONFIG_REPORT_DAShBOARD_EXPORT_WINDOW}/Report Template`,
    REPORT_DASHBOARD_EXPORT_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_DAShBOARD_EXPORT_WINDOW}/[Save] Report Dashboard Export`,
    GLOBAL_VARIABLE: `${MAIN_FRAMES.CONFIG_REPORT_DAShBOARD_EXPORT_WINDOW}/Global Variable`,
  },
  CONFIG_REPORT_SCHEDULE: {
    SCHEDULE_GROUP_LIST: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/Schedule Group List`,
    SCHEDULE_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/[Delete] Schedule Group List`,
    SCHEDULE_LIST: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/Schedule List`,
    SCHEDULE_MOVE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/[Move] Schedule List`,
    SCHEDULE_DELETE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/[Delete] Schedule List`,
    SCHEDULE_ENABLED: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE}/[Enabled] Schedule`,
  },
  CONFIG_REPORT_SCHEDULE_GROUP_WINDOW: {
    REPORT_SCHEDULE_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_GROUP_WINDOW}/[Save] Report Schedule Group`,
  },
  CONFIG_REPORT_SCHEDULE_WINDOW: {
    SCHEDULE_INFORMATION: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_WINDOW}/Schedule Information`,
    SCHEDULE_SAVE: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_WINDOW}/[Save] Schedule`,
    REPORT_LIST: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_WINDOW}/Report List`,
    USER_LIST: `${MAIN_FRAMES.CONFIG_REPORT_SCHEDULE_WINDOW}/User List (Email)`,
  },
} as const;
