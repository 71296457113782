<script lang="ts" setup>
import { CHECK_BY_TARGET, FIXED_CONTENT } from '@/alert/utils/define';
import {
  getInitReceiveInfo,
  Props,
  setup,
} from '@/alert/components/alertDetail/alertInformationNotification.setup';
import InformationTitle from '@/common/components/molecules/InformationTitle.vue';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';

const props = withDefaults(defineProps<Props>(), {
  ruleCriteria: CHECK_BY_TARGET,
  data: () => ({
    groupId: '',
    groupName: '',
    email: getInitReceiveInfo(),
    slack: getInitReceiveInfo(),
    sms: getInitReceiveInfo(),
    telegram: getInitReceiveInfo(),
    webhook: getInitReceiveInfo(),
    onSite: getInitReceiveInfo(),
  }),
});

const { title, tabPanels, selectedTab, LABEL_INFO, receiveItems, getDisplayServerType, t } =
  setup(props);
</script>

<template>
  <div class="alert-detail-information__notification alert-rule-notification">
    <information-title>
      {{ title }}
    </information-title>
    <ev-tabs
      v-model="selectedTab"
      :panels="tabPanels"
      stretch
      class="alert-notification"
    >
      <ev-tab-panel
        v-for="item in tabPanels"
        :key="item.value"
        :text="item.text"
        :value="item.value"
      >
        <div class="alert-rule-card">
          <dl
            v-if="['email', 'sms'].includes(selectedTab)"
            class="alert-rule-row server-type"
          >
            <dt class="alert-rule-row__label">
              {{ t('WORD.ALERT_SERVER_TYPE') }}({{ t('WORD.ALERT_SERVER_NAME') }})
            </dt>
            <dd class="alert-rule-row__value">
              {{
                getDisplayServerType(
                  props.data[item.value]?.transportServerType,
                  props.data[item.value]?.transportServerName,
                )
              }}
            </dd>
          </dl>
          <dl class="alert-rule-row">
            <dt class="alert-rule-row__label">
              {{ `${LABEL_INFO[item.value]} (${receiveItems[item.value]?.length ?? 0})` }}
            </dt>
            <dd class="alert-rule-row__value ids">
              <template v-if="props.data?.[item.value]?.ids?.length">
                <tag-item
                  v-for="(name, idx) in receiveItems[item.value]"
                  :key="`${name}${idx}`"
                  size="xsmall"
                >
                  {{ name }}
                </tag-item>
              </template>
            </dd>
          </dl>
          <dl
            v-if="selectedTab === 'sms'"
            class="alert-rule-row"
          >
            <dt class="alert-rule-row__label">{{ t('MESSAGE.ALERT_WINDOW.SWITCH_LMS') }}</dt>
            <dd class="alert-rule-row__value">
              {{
                props.data?.[item.value]?.smsType === 'LMS'
                  ? t('MESSAGE.ALERT_WINDOW.ALLOWED')
                  : t('MESSAGE.ALERT_WINDOW.NOT_ALLOWED')
              }}
            </dd>
          </dl>
          <dl class="alert-rule-row write-content">
            <dt class="alert-rule-row__label">{{ t('WORD.CONTENT') }}</dt>
            <dd class="alert-rule-row__value">
              <p v-if="props.data[item.value]?.writeContent?.title">
                {{ props.data[item.value].writeContent.title }}
              </p>
              <p v-if="props.data[item.value]?.writeContent?.content">
                {{ props.data[item.value].writeContent.content }}
              </p>
              <div>
                <pre>{{ FIXED_CONTENT[props.ruleCriteria].trim() }}</pre>
              </div>
            </dd>
          </dl>
        </div>
      </ev-tab-panel>
    </ev-tabs>
  </div>
</template>

<style lang="scss">
.alert-rule-notification {
  border: none;
  background-color: inherit;
  pre {
    font-family: inherit !important;
    line-height: 1.5em;
  }
  .information-title {
    min-height: auto;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 700;
  }
  .alert-detail-information {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .ev-tabs {
    border-right: 1px solid var(--color-gray-06-04);
    border-left: 1px solid var(--color-gray-06-04);
  }
  /* stylelint-disable */
  .alert-notification {
    .ev-tabs-title.has-icon {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 0 14px;
    }
    i {
      position: static;
      width: 16px;
      height: 16px;
    }
    .ev-tabs-body {
      padding: 10px;
      border-bottom: 1px solid var(--color-gray-06-04);
    }
    .alert-rule-card {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 16px 10px 12px;
      border: 1px solid var(--color-gray-06-04);
      border-radius: 2px;
    }
    .alert-rule-row {
      align-items: start;
      padding: 0;
      border-bottom: none !important;
      &__label {
        min-width: 144px;
        font-weight: 500;
        color: var(--color-gray-00-12);
      }
      &__value {
        margin-right: 0;
        color: var(--alert-detail-info-val);
        .tag-item {
          max-width: 100%;
          margin: 4px 4px 4px 0;
          @include shortening(inline-block, auto);
        }
      }
      &.write-content p {
        margin-bottom: 8px;
        word-break: break-all;
      }
      &.server-type {
        align-items: center;
        .alert-rule-row__label {
          margin-top: 0;
        }
      }
    }
  }
  /* stylelint-enable */
}
</style>
