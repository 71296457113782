import { useInternational } from '@/common/locale';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { confirmMsg } from '@/common/utils/commonUtils';
import { WidgetCreateType } from '@/dashboard/utils/types';
import { getCurrentInstance, ref, watch } from 'vue';

interface Props {
  createType: WidgetCreateType;
}

interface Emits {
  (event: 'update:createType', value: WidgetCreateType): void;
}

export const useWidgetCreateType = (props: Props, emit: Emits) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();

  const useReferenceStat = ref(props.createType === 'reference');

  watch(useReferenceStat, (newIsReferenceWidget) => {
    if (newIsReferenceWidget) {
      emit('update:createType', 'reference');
    } else {
      confirmMsg(ctx, {
        msgStr: t('MESSAGE.NOT_USE_CUSTOM_STAT'),
        okCallback: async () => {
          emit('update:createType', 'base');
        },
        cancelCallback: () => {
          useReferenceStat.value = true;
        },
      });
    }
  });

  return { useReferenceStat };
};

export const useWidgetArgumentList = () => {
  const { getStatInfo } = useStatInfoStore();

  const getArgumentList = (
    commonArguments: Readonly<string[]> | undefined,
    statId: string,
    category: string,
  ) => {
    const statInfo = getStatInfo({ statId, childCategory: category });
    if (category === 'custom') {
      return (
        statInfo?.arguments
          ?.map(({ name }) => name)
          .filter((name) => !commonArguments?.includes(name)) ?? []
      );
    }
    return ['fromTime', 'toTime', 'targetIds'].filter((name) => !commonArguments?.includes(name));
  };

  return { getArgumentList };
};
