import { axiosInstance } from '@/worker/commands/config/apiInstance';
import { AxiosResponse } from 'axios';

export interface Token {
  accessToken: string;
  refreshToken: string;
}

export const postV1RefreshTokenAxios = (tokenInfo: Token): Promise<AxiosResponse<Token>> =>
  axiosInstance.post('/api/v1/refresh-token', tokenInfo);
