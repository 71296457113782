/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  FieldResponse,
  InfraHostAgentV7Response,
  InfraHostContainerDetailV7Response,
  InfraHostContainerV7Response,
  InfraHostDiskV7Response,
  InfraHostInfoV7Response,
  InfraHostInstallPackageV7Response,
  InfraHostNetworkV7Response,
  InfraHostProcessV7Response,
  InfraHostRelationV7Response,
  InfraHostV7Response,
  InfraProcessLimitsMessage,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getHostListInfraHostV7ControllerAxios = ({
  column,
  hostGroupIds,
  isReport,
  report,
  reportColumnList,
  search,
  server,
  sortType,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  column?: string;
  hostGroupIds?: Array<string>;
  isReport?: boolean;
  report?: boolean;
  reportColumnList?: Array<string>;
  search?: string;
  server?: Array<string>;
  sortType?: 'ASC' | 'DESC';
  timezone?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostV7Response> => {
  const localVarPath = '/api/v7/infra/hosts';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      column,
      hostGroupIds,
      isReport,
      report,
      reportColumnList,
      search,
      server,
      sortType,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getAgentInfraHostV7ControllerAxios = ({
  hostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostAgentV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/agent'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getAlertProcessInfraHostV7ControllerAxios = ({
  hostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostProcessV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/alert-process'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getContainerInfraHostV7ControllerAxios = ({
  containerHostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  containerHostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostContainerV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{containerHostId}/container'.replace(
    `{${'containerHostId'}}`,
    encodeURIComponent(String(containerHostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getContainerDetailInfraHostV7ControllerAxios = ({
  collectTime,
  containerHostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  collectTime: string;
  containerHostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostContainerDetailV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{containerHostId}/container/detail'.replace(
    `{${'containerHostId'}}`,
    encodeURIComponent(String(containerHostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      collectTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDiskInfraHostV7ControllerAxios = ({
  hostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostDiskV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/disk'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHostListFieldsInfraHostV7ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<FieldResponse> => {
  const localVarPath = '/api/v7/infra/hosts/fields';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHostInfoInfraHostV7ControllerAxios = ({
  hostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostInfoV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/info'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getPackageInfraHostV7ControllerAxios = ({
  hostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostInstallPackageV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/install-package'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNetworkInfraHostV7ControllerAxios = ({
  hostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostNetworkV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/network'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getProcessInfraHostV7ControllerAxios = ({
  hostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostProcessV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/process'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getProcessLimitsInfraHostV7ControllerAxios = ({
  hostId,
  pid,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  pid: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraProcessLimitsMessage> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/process/limits'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      pid,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHostRelationInfoInfraHostV7ControllerAxios = ({
  hostId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  hostId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<InfraHostRelationV7Response> => {
  const localVarPath = '/api/v7/infra/hosts/{hostId}/relation-info'.replace(
    `{${'hostId'}}`,
    encodeURIComponent(String(hostId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getHostListInfraHostV7ControllerAxios,
  getAgentInfraHostV7ControllerAxios,
  getAlertProcessInfraHostV7ControllerAxios,
  getContainerInfraHostV7ControllerAxios,
  getContainerDetailInfraHostV7ControllerAxios,
  getDiskInfraHostV7ControllerAxios,
  getHostListFieldsInfraHostV7ControllerAxios,
  getHostInfoInfraHostV7ControllerAxios,
  getPackageInfraHostV7ControllerAxios,
  getNetworkInfraHostV7ControllerAxios,
  getProcessInfraHostV7ControllerAxios,
  getProcessLimitsInfraHostV7ControllerAxios,
  getHostRelationInfoInfraHostV7ControllerAxios,
};
