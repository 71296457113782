import { computed, nextTick, onMounted, ref, watch } from 'vue';
import dayjs from 'dayjs';
import { useInternational } from '@/common/locale';
import { useCalendar } from './calendar.composables';

export interface Props {
  startDate: Date | undefined;
  endDate: Date | undefined;
  minDate?: Date;
  maxDate?: Date;
  yearRange?: number[];
  disabled?: boolean;
  disabledSelectedBeforeDay?: string;
  disabledToday?: boolean;
}

export interface Emit {
  (e: 'update:startDate', value: Date | undefined): void;
  (e: 'update:endDate', value: Date | undefined): void;
  (e: 'changed', value: (Date | undefined)[]): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const vueDatePickerRef = ref();

  const {
    userLanguage,
    monthYearButtonDirection,
    makers,
    checkDisabledDate,
    today,
    isToday,
    isSunday,
    getFormattedDay,
  } = useCalendar(props.disabledSelectedBeforeDay, {
    disabledToday: props.disabledToday ?? true,
  });

  const selectedValues = computed<Date[]>(() => {
    const result: Date[] = [];
    if (props.startDate) {
      result.push(props.startDate);
    }

    if (props.startDate && props.endDate) {
      result.push(props.endDate);
    }

    return result;
  });

  const onChangeStart = (date: Date) => {
    emit('update:startDate', date);
    emit('update:endDate', undefined);
  };

  const onChangeEnd = (date: Date) => {
    if (props.startDate && props.startDate.getTime() > date.getTime()) {
      emit('update:startDate', date);
      emit('update:endDate', props.startDate);
    } else {
      emit('update:endDate', date);
    }
  };

  watch(selectedValues, (newValues: (Date | undefined)[]) => {
    emit('changed', newValues);
  });

  const setCalendarMonthYear = ({ month, year }: { month: number; year: number }) => {
    vueDatePickerRef?.value?.setMonthYear({
      month,
      year,
    });
  };

  onMounted(async () => {
    if (vueDatePickerRef.value) {
      await nextTick();

      let prevMonth: number;
      if (props.endDate) {
        const endMonth = dayjs(props.endDate).month();
        prevMonth = endMonth === 0 ? 11 : endMonth - 1;
      } else {
        const currentMonth = today.month();
        prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      }

      const year = props.endDate ? dayjs(props.endDate).year() : today.year();

      vueDatePickerRef?.value?.setMonthYear({
        month: prevMonth,
        year: prevMonth === 11 ? year - 1 : year,
      });
    }
  });

  return {
    t,
    vueDatePickerRef,
    userLanguage,
    checkDisabledDate,
    monthYearButtonDirection,
    selectedValues,
    makers,
    isToday,
    isSunday,
    getFormattedDay,
    onChangeStart,
    onChangeEnd,
    setCalendarMonthYear,
  };
};
