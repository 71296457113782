import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { LockTreeV7Item } from '@/openapi/postgresqlV7/model';
import { getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { locktreeLockV7ControllerAxios } from '@/openapi/postgresqlV7/api/lock-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

export interface LockTreeState {
  lockTree: LockTreeV7Item[];
  currentLockTree: LockTreeV7Item[];
  errorStatusText: string;
}
export const lockTree: Module<LockTreeState, RootState> = {
  namespaced: true,
  state: {
    lockTree: [],
    currentLockTree: [],
    errorStatusText: '',
  },
  mutations: {
    setLockTree(state: LockTreeState, data: LockTreeV7Item[]) {
      state.lockTree = data;
    },
    setCurrentLockTree(state: LockTreeState, data: LockTreeV7Item[]) {
      state.currentLockTree = data;
    },
    setErrorStatusText(state: LockTreeState, errorStatusText: string) {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchLockTree({ commit }, instanceId) {
      const frameName = FRAME_NAMES.SESSION_TAB.LOCK_TREE;
      if (!instanceId) {
        return;
      }
      try {
        const {
          data: { data = [] },
        } = await locktreeLockV7ControllerAxios({
          instanceId,
          period: 'p5s',
          frameName,
        });
        commit('setLockTree', data);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
    async fetchCurrentLockTree({ commit }, requestParam) {
      const { data } = await locktreeLockV7ControllerAxios({
        instanceId: requestParam.instanceId,
        frameName: FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.OVERVIEW_LOCK_TREE,
        fromTime: requestParam?.fromTime,
        toTime: requestParam?.toTime,
      });
      commit('setCurrentLockTree', data.data);
    },
  },
  getters: {
    getLockTree: (state: LockTreeState) => state.lockTree,
    getCurrentLockTree: (state: LockTreeState) => state.currentLockTree,
    getErrorStatusText: (state: LockTreeState) => state.errorStatusText,
  },
};
