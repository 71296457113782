/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  AsmByIntervalV7Response,
  AsmGroupV7Response,
  AsmSizeForecastV7Response,
  BindSqlResponse,
  LastParameterResponse,
  LongActiveSessionResponse,
  MultiInstanceRequest,
  MultiSessionKillResponse,
  MultiSessionTargetForm,
  OverviewV7Response,
  ParameterDetailResponse,
  ParameterResponse,
  PlanAnalysisListResponse,
  PlanByIntervalV7Response,
  PlanDiffResponse,
  PlanHashValuesBySqlIdV7Response,
  SessionByUsageV7Response,
  SessionDetailV7Response,
  SessionListV7Response,
  SessionMetricV4Response,
  SessionSearchListV7Response,
  SessionTargetDetailV7Response,
  SessionTypeV7Response,
  SqlByPlanV7Response,
  SqlByWaitEventV7Response,
  SqlIntervalV7Response,
  SqlListByIntervalV7Response,
  SqlMetricResponse,
  SqlPlanReasonV7Response,
  SqlPlanV4Response,
  SqlRtmSummaryV7Response,
  SqlSummaryListV7Response,
  SqlSummaryV7Response,
  SqlTextBySqlIdV7Response,
  TablespaceByIntervalV7Response,
  TempIntervalSummaryV7Response,
  TimeModelByIntervalV7Response,
  TopModuleV7Response,
  TopSchemaV7Response,
  TopSegmentV7Response,
  TopSqlV7Response,
  TopTablespaceV7Response,
  UndoIntervalSummaryV7Response,
  UndoTempRatioV7Response,
  WaitChainWithDeadLockV7Response,
  WaitClassRatioV7Response,
  WaitClassV7Response,
  WaitEventResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getSessionDetailOracleV7ControllerAxios = ({
  serialNumber,
  sid,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  serialNumber: number;
  sid: number;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionDetailV7Response> => {
  const localVarPath = '/api/v7/oracle/active-session-detail/{sid}'.replace(
    `{${'sid'}}`,
    encodeURIComponent(String(sid)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      serialNumber,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlTextOracleV7ControllerAxios = ({
  sqlId,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sqlId: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlTextBySqlIdV7Response> => {
  const localVarPath = '/api/v7/oracle/active-session-detail/sql-text';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getActiveSessionListOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  isFullText,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionListV7Response> => {
  const localVarPath = '/api/v7/oracle/active-session-list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const asmByIntervalOracleV7ControllerAxios = ({
  instanceIds,
  asmGroup,
  fromTime,
  interval,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  asmGroup?: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AsmByIntervalV7Response> => {
  const localVarPath = '/api/v7/oracle/asm-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      asmGroup,
      fromTime,
      instanceIds,
      interval,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const asmGroupOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AsmGroupV7Response> => {
  const localVarPath = '/api/v7/oracle/asm-groups';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const asmSizeForecastOracleV7ControllerAxios = ({
  instanceIds,
  asmGroup,
  fromTime,
  interval,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  asmGroup?: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AsmSizeForecastV7Response> => {
  const localVarPath = '/api/v7/oracle/asm-size-forecast';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      asmGroup,
      fromTime,
      instanceIds,
      interval,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getBindSqlOracleV7ControllerAxios = ({
  sqlId,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sqlId: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<BindSqlResponse> => {
  const localVarPath = '/api/v7/oracle/bind-sql';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getLongActiveSessionOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  isFullText,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LongActiveSessionResponse> => {
  const localVarPath = '/api/v7/oracle/long-active-session';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const overviewOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  metricNames,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  metricNames?: Array<string>;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<OverviewV7Response> => {
  const localVarPath = '/api/v7/oracle/overview';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      metricNames,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterListOracleV7ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<LastParameterResponse> => {
  const localVarPath = '/api/v7/oracle/parameter/{instanceId}'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterHistoryOracleV7ControllerAxios = ({
  baseTime,
  compareTime,
  instanceId,
  differentSelectors,
  name,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  baseTime: string;
  compareTime: string;
  instanceId: string;
  differentSelectors?: Array<boolean>;
  name?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ParameterResponse> => {
  const localVarPath = '/api/v7/oracle/parameter/history';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      baseTime,
      compareTime,
      differentSelectors,
      instanceId,
      name,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getParameterHistoryDetailOracleV7ControllerAxios = ({
  instanceId,
  name,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  name: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ParameterDetailResponse> => {
  const localVarPath = '/api/v7/oracle/parameter/history/detail';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      instanceId,
      name,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const planAnalysisListOracleV7ControllerAxios = ({
  fromTime,
  instanceId,
  isFullText,
  period,
  size,
  sqlId,
  sqlPlanCount,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  fromTime?: string;
  instanceId?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  size?: number;
  sqlId?: string;
  sqlPlanCount?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PlanAnalysisListResponse> => {
  const localVarPath = '/api/v7/oracle/plan-analysis-list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      isFullText,
      period,
      size,
      sqlId,
      sqlPlanCount,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const planDetailOracleV7ControllerAxios = ({
  instanceId,
  planHashValue,
  sqlId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  planHashValue: number;
  sqlId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlPlanV4Response> => {
  const localVarPath = '/api/v7/oracle/{instanceId}/plan'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      planHashValue,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const planByIntervalOracleV7ControllerAxios = ({
  sqlId,
  fromTime,
  instanceId,
  interval,
  period,
  stat,
  tenantId,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sqlId: string;
  fromTime?: string;
  instanceId?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  stat?: 'bufferGets' | 'cpuTime' | 'diskReads' | 'elapsedTime' | 'rowsProcessed';
  tenantId?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PlanByIntervalV7Response> => {
  const localVarPath = '/api/v7/oracle/plan-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      interval,
      period,
      sqlId,
      stat,
      tenantId,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const planDiffOracleV7ControllerAxios = ({
  sourcePlan,
  sqlId,
  targetPlan,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sourcePlan: number;
  sqlId: string;
  targetPlan: number;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PlanDiffResponse> => {
  const localVarPath = '/api/v7/oracle/plan-diff';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      sourcePlan,
      sqlId,
      targetPlan,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const planHashValuesBySqlIdOracleV7ControllerAxios = ({
  sqlId,
  fromTime,
  instanceId,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sqlId: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<PlanHashValuesBySqlIdV7Response> => {
  const localVarPath = '/api/v7/oracle/{sqlId}/plan-hash-values'.replace(
    `{${'sqlId'}}`,
    encodeURIComponent(String(sqlId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionsByUsageOracleV7ControllerAxios = ({
  instanceIds,
  sort,
  fromTime,
  isFullText,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  sort: 'sortUsage' | 'undoUsage';
  fromTime?: string;
  isFullText?: boolean;
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionByUsageV7Response> => {
  const localVarPath = '/api/v7/oracle/session-by-usage';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      size,
      sort,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionCountByTypeOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  tenantId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  tenantId?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTypeV7Response> => {
  const localVarPath = '/api/v7/oracle/session-count';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      tenantId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionsOracleV7ControllerAxios = ({
  instanceIds,
  conditionalType,
  fromTime,
  isFullText,
  isReport,
  machine,
  module,
  pageNum,
  pageSize,
  period,
  program,
  schema,
  sid,
  sqlId,
  sqlText,
  tenantId,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  conditionalType?: 'and' | 'none' | 'or';
  fromTime?: string;
  isFullText?: boolean;
  isReport?: boolean;
  machine?: string;
  module?: string;
  pageNum?: number;
  pageSize?: number;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  program?: string;
  schema?: string;
  sid?: number;
  sqlId?: string;
  sqlText?: string;
  tenantId?: string;
  timezone?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionSearchListV7Response> => {
  const localVarPath = '/api/v7/oracle/sessions';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      conditionalType,
      fromTime,
      instanceIds,
      isFullText,
      isReport,
      machine,
      module,
      pageNum,
      pageSize,
      period,
      program,
      schema,
      sid,
      sqlId,
      sqlText,
      tenantId,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSessionTargetDetailOracleV7ControllerAxios = ({
  instanceId,
  serialNumber,
  sid,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  serialNumber: number;
  sid: number;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTargetDetailV7Response> => {
  const localVarPath = '/api/v7/oracle/{instanceId}/sessions/{sid}'
    .replace(`{${'instanceId'}}`, encodeURIComponent(String(instanceId)))
    .replace(`{${'sid'}}`, encodeURIComponent(String(sid)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      serialNumber,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionMetricV4OracleV7ControllerAxios = ({
  instanceId,
  sid,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  sid: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionMetricV4Response> => {
  const localVarPath = '/api/v7/oracle/{instanceId}/sessions/metric'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      sid,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlByIntervalOracleV7ControllerAxios = ({
  column,
  instanceIds,
  interval,
  fromTime,
  module,
  period,
  planHashValue,
  schema,
  sqlId,
  tenantId,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  column:
    | 'bufferGets'
    | 'cpuTime'
    | 'diskReads'
    | 'elapsedTime'
    | 'executions'
    | 'recursiveCalls'
    | 'userCalls';
  instanceIds: Array<string>;
  interval:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  fromTime?: string;
  module?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  planHashValue?: number;
  schema?: string;
  sqlId?: string;
  tenantId?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlIntervalV7Response> => {
  const localVarPath = '/api/v7/oracle/sql-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      column,
      fromTime,
      instanceIds,
      interval,
      module,
      period,
      planHashValue,
      schema,
      sqlId,
      tenantId,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlGroupByPlanHashValueOracleV7ControllerAxios = ({
  sqlId,
  fromTime,
  instanceId,
  period,
  sort,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sqlId: string;
  fromTime?: string;
  instanceId?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  sort?: 'bufferGets' | 'cpuTime' | 'diskReads' | 'elapsedTime' | 'executions' | 'rowsProcessed';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlByPlanV7Response> => {
  const localVarPath = '/api/v7/oracle/sql-by-plan';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceId,
      period,
      sort,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getSqlListOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlListByIntervalV7Response> => {
  const localVarPath = '/api/v7/oracle/sql-list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlListByIntervalOracleV7ControllerAxios = ({
  instanceIds,
  interval,
  fromTime,
  module,
  period,
  planHashValue,
  schema,
  sqlId,
  tenantId,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  interval:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  fromTime?: string;
  module?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  planHashValue?: number;
  schema?: string;
  sqlId?: string;
  tenantId?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlListByIntervalV7Response> => {
  const localVarPath = '/api/v7/oracle/sql-list-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      interval,
      module,
      period,
      planHashValue,
      schema,
      sqlId,
      tenantId,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlPlanReasonOracleV7ControllerAxios = ({
  childNumber,
  instanceId,
  sqlId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  childNumber: number;
  instanceId: string;
  sqlId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlPlanReasonV7Response> => {
  const localVarPath = '/api/v7/oracle/{instanceId}/sql-plan-reason'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      childNumber,
      fromTime,
      period,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlSummaryOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  interval,
  period,
  tenantId,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  tenantId?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlSummaryV7Response> => {
  const localVarPath = '/api/v7/oracle/sql-summary';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      interval,
      period,
      tenantId,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlSummaryListOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  isFullText,
  module,
  period,
  planHashValue,
  schema,
  sqlId,
  sqlText,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  isFullText?: boolean;
  module?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  planHashValue?: number;
  schema?: string;
  sqlId?: string;
  sqlText?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlSummaryListV7Response> => {
  const localVarPath = '/api/v7/oracle/sql-summary-list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      module,
      period,
      planHashValue,
      schema,
      sqlId,
      sqlText,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlRtmSummaryOracleV7ControllerAxios = ({
  instanceIds,
  sort,
  summaryType,
  fromTime,
  isFullText,
  period,
  size,
  sqlText,
  tenantId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  sort: 'bufferGets' | 'cpuTime' | 'diskReads' | 'elapsedTime' | 'executions' | 'rowsProcessed';
  summaryType: 'avg' | 'sum';
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  size?: number;
  sqlText?: string;
  tenantId?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlRtmSummaryV7Response> => {
  const localVarPath = '/api/v7/oracle/sqls';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      period,
      size,
      sort,
      sqlText,
      summaryType,
      tenantId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlMetricOracleV7ControllerAxios = ({
  childNumber,
  instanceId,
  sqlId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  childNumber: number;
  instanceId: string;
  sqlId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlMetricResponse> => {
  const localVarPath = '/api/v7/oracle/{instanceId}/sqls/metric'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      childNumber,
      sqlId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const tablespaceByIntervalOracleV7ControllerAxios = ({
  instanceIds,
  asmGroup,
  fromTime,
  interval,
  period,
  tablespace,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  asmGroup?: string;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  tablespace?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TablespaceByIntervalV7Response> => {
  const localVarPath = '/api/v7/oracle/tablespace-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      asmGroup,
      fromTime,
      instanceIds,
      interval,
      period,
      tablespace,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const tempIntervalSummaryOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  interval,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TempIntervalSummaryV7Response> => {
  const localVarPath = '/api/v7/oracle/temp-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      interval,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const timeModelByIntervalOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  interval,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TimeModelByIntervalV7Response> => {
  const localVarPath = '/api/v7/oracle/time-model-by-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      interval,
      period,
      size,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topModuleOracleV7ControllerAxios = ({
  instanceIds,
  size,
  sort,
  fromTime,
  module,
  period,
  planHashValue,
  schema,
  sqlId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  size: number;
  sort: 'bufferGets' | 'cpuTime' | 'diskReads' | 'elapsedTime' | 'executions' | 'rowsProcessed';
  fromTime?: string;
  module?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  planHashValue?: number;
  schema?: string;
  sqlId?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopModuleV7Response> => {
  const localVarPath = '/api/v7/oracle/top-module';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      module,
      period,
      planHashValue,
      schema,
      size,
      sort,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topSchemaOracleV7ControllerAxios = ({
  instanceIds,
  size,
  sort,
  fromTime,
  module,
  period,
  planHashValue,
  schema,
  sqlId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  size: number;
  sort: 'bufferGets' | 'cpuTime' | 'diskReads' | 'elapsedTime' | 'executions' | 'rowsProcessed';
  fromTime?: string;
  module?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  planHashValue?: number;
  schema?: string;
  sqlId?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSchemaV7Response> => {
  const localVarPath = '/api/v7/oracle/top-schema';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      module,
      period,
      planHashValue,
      schema,
      size,
      sort,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topSegmentOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  size,
  tablespace,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  size?: number;
  tablespace?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSegmentV7Response> => {
  const localVarPath = '/api/v7/oracle/top-segment';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      size,
      tablespace,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topSqlOracleV7ControllerAxios = ({
  instanceIds,
  size,
  sort,
  fromTime,
  isFullText,
  module,
  planHashValue,
  schema,
  sqlId,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  size: number;
  sort: 'bufferGets' | 'cpuTime' | 'diskReads' | 'elapsedTime' | 'executions' | 'rowsProcessed';
  fromTime?: string;
  isFullText?: boolean;
  module?: string;
  planHashValue?: number;
  schema?: string;
  sqlId?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopSqlV7Response> => {
  const localVarPath = '/api/v7/oracle/top-sql';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      module,
      planHashValue,
      schema,
      size,
      sort,
      sqlId,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topTablespaceOracleV7ControllerAxios = ({
  instanceIds,
  asmGroup,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  asmGroup?: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TopTablespaceV7Response> => {
  const localVarPath = '/api/v7/oracle/top-tablespace';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      asmGroup,
      fromTime,
      instanceIds,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const undoIntervalSummaryOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  interval,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UndoIntervalSummaryV7Response> => {
  const localVarPath = '/api/v7/oracle/undo-interval';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      interval,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topUndoTempOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UndoTempRatioV7Response> => {
  const localVarPath = '/api/v7/oracle/undo-temp-ratio';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const waitChainWithDeadLockOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  isFullText,
  isReport,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  isFullText?: boolean;
  isReport?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  timezone?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<WaitChainWithDeadLockV7Response> => {
  const localVarPath = '/api/v7/oracle/wait-chain';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      isReport,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const waitClassOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  interval,
  period,
  tenantId,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  tenantId?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<WaitClassV7Response> => {
  const localVarPath = '/api/v7/oracle/wait-class';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      interval,
      period,
      tenantId,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const waitClassRatioOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<WaitClassRatioV7Response> => {
  const localVarPath = '/api/v7/oracle/wait-class-ratio';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const waitEventOracleV7ControllerAxios = ({
  instanceIds,
  fromTime,
  period,
  size,
  sort,
  tenantId,
  toTime,
  waitClass,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  size?: number;
  sort?: 'avgTimeWaited' | 'timeWaited' | 'waitCount';
  tenantId?: string;
  toTime?: string;
  waitClass?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<WaitEventResponse> => {
  const localVarPath = '/api/v7/oracle/wait-event';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      period,
      size,
      sort,
      tenantId,
      toTime,
      waitClass,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sqlListByWaitEventOracleV7ControllerAxios = ({
  instanceIds,
  summaryType,
  waitEvent,
  fromTime,
  isFullText,
  period,
  size,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceIds: Array<string>;
  summaryType: 'avg' | 'sum';
  waitEvent: string;
  fromTime?: string;
  isFullText?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p12s'
    | 'p1d'
    | 'p1h'
    | 'p1m'
    | 'p30m'
    | 'p3h'
    | 'p3m'
    | 'p5m'
    | 'p5s'
    | 'p6h'
    | 'p6m';
  size?: number;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SqlByWaitEventV7Response> => {
  const localVarPath = '/api/v7/oracle/wait-sql-list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      instanceIds,
      isFullText,
      period,
      size,
      summaryType,
      toTime,
      waitEvent,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const overviewPostOracleV7ControllerAxios = ({
  request,
  metricNames,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: MultiInstanceRequest;
  metricNames?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<OverviewV7Response> => {
  const localVarPath = '/api/v7/oracle/overview';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      metricNames,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const sessionCountByTypePostOracleV7ControllerAxios = ({
  request,
  tenantId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: MultiInstanceRequest;
  tenantId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SessionTypeV7Response> => {
  const localVarPath = '/api/v7/oracle/session-count';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      tenantId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const killSessionOracleV7ControllerAxios = ({
  instanceId,
  serialNumber,
  sid,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  serialNumber: number;
  sid: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v7/oracle/{instanceId}/sessions/kill-session'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      serialNumber,
      sid,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const killSessionsOracleV7ControllerAxios = ({
  sessions,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  sessions: Array<MultiSessionTargetForm>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MultiSessionKillResponse> => {
  const localVarPath = '/api/v7/oracle/sessions/kill-sessions';
  const httpMethod = 'post';

  const data: any = sessions;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const timeModelByIntervalPostOracleV7ControllerAxios = ({
  request,
  interval,
  size,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: MultiInstanceRequest;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  size?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TimeModelByIntervalV7Response> => {
  const localVarPath = '/api/v7/oracle/time-model-by-interval';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      interval,
      size,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const topUndoTempPostOracleV7ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: MultiInstanceRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<UndoTempRatioV7Response> => {
  const localVarPath = '/api/v7/oracle/undo-temp-ratio';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const waitClassPostOracleV7ControllerAxios = ({
  request,
  interval,
  tenantId,
  timezone,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: MultiInstanceRequest;
  interval?:
    | 'I100ms'
    | 'I10m'
    | 'I10s'
    | 'I15m'
    | 'I15s'
    | 'I1d'
    | 'I1h'
    | 'I1m'
    | 'I1s'
    | 'I20m'
    | 'I24h'
    | 'I2h'
    | 'I30m'
    | 'I30s'
    | 'I3m'
    | 'I3s'
    | 'I5d'
    | 'I5h'
    | 'I5m'
    | 'I5s'
    | 'none';
  tenantId?: string;
  timezone?:
    | 'Africa_Cairo'
    | 'America_Argentina_BuenosAires'
    | 'America_Caracas'
    | 'America_Chicago'
    | 'America_GlaceBay'
    | 'America_New_York'
    | 'America_Nome'
    | 'America_Noronha'
    | 'America_Phoenix'
    | 'America_StJohns'
    | 'America_Tijuana'
    | 'Asia_Anadyr'
    | 'Asia_Bangkok'
    | 'Asia_Dhaka'
    | 'Asia_Kabul'
    | 'Asia_Kathmandu'
    | 'Asia_Kolkata'
    | 'Asia_Rangoon'
    | 'Asia_Seoul'
    | 'Asia_Shanghai'
    | 'Asia_Tashkent'
    | 'Asia_Yerevan'
    | 'Atlantic_Azores'
    | 'Australia_Adelaide'
    | 'Australia_Brisbane'
    | 'Australia_Darwin'
    | 'Europe_Berlin'
    | 'Europe_London'
    | 'Europe_Moscow'
    | 'Pacific_Apia'
    | 'Pacific_Honolulu'
    | 'Pacific_Midway'
    | 'Pacific_Noumea';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<WaitClassV7Response> => {
  const localVarPath = '/api/v7/oracle/wait-class';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      interval,
      tenantId,
      timezone,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getSessionDetailOracleV7ControllerAxios,
  sqlTextOracleV7ControllerAxios,
  getActiveSessionListOracleV7ControllerAxios,
  asmByIntervalOracleV7ControllerAxios,
  asmGroupOracleV7ControllerAxios,
  asmSizeForecastOracleV7ControllerAxios,
  getBindSqlOracleV7ControllerAxios,
  getLongActiveSessionOracleV7ControllerAxios,
  overviewOracleV7ControllerAxios,
  getParameterListOracleV7ControllerAxios,
  getParameterHistoryOracleV7ControllerAxios,
  getParameterHistoryDetailOracleV7ControllerAxios,
  planAnalysisListOracleV7ControllerAxios,
  planDetailOracleV7ControllerAxios,
  planByIntervalOracleV7ControllerAxios,
  planDiffOracleV7ControllerAxios,
  planHashValuesBySqlIdOracleV7ControllerAxios,
  sessionsByUsageOracleV7ControllerAxios,
  sessionCountByTypeOracleV7ControllerAxios,
  sessionsOracleV7ControllerAxios,
  getSessionTargetDetailOracleV7ControllerAxios,
  sessionMetricV4OracleV7ControllerAxios,
  sqlByIntervalOracleV7ControllerAxios,
  sqlGroupByPlanHashValueOracleV7ControllerAxios,
  getSqlListOracleV7ControllerAxios,
  sqlListByIntervalOracleV7ControllerAxios,
  sqlPlanReasonOracleV7ControllerAxios,
  sqlSummaryOracleV7ControllerAxios,
  sqlSummaryListOracleV7ControllerAxios,
  sqlRtmSummaryOracleV7ControllerAxios,
  sqlMetricOracleV7ControllerAxios,
  tablespaceByIntervalOracleV7ControllerAxios,
  tempIntervalSummaryOracleV7ControllerAxios,
  timeModelByIntervalOracleV7ControllerAxios,
  topModuleOracleV7ControllerAxios,
  topSchemaOracleV7ControllerAxios,
  topSegmentOracleV7ControllerAxios,
  topSqlOracleV7ControllerAxios,
  topTablespaceOracleV7ControllerAxios,
  undoIntervalSummaryOracleV7ControllerAxios,
  topUndoTempOracleV7ControllerAxios,
  waitChainWithDeadLockOracleV7ControllerAxios,
  waitClassOracleV7ControllerAxios,
  waitClassRatioOracleV7ControllerAxios,
  waitEventOracleV7ControllerAxios,
  sqlListByWaitEventOracleV7ControllerAxios,
  overviewPostOracleV7ControllerAxios,
  sessionCountByTypePostOracleV7ControllerAxios,
  killSessionOracleV7ControllerAxios,
  killSessionsOracleV7ControllerAxios,
  timeModelByIntervalPostOracleV7ControllerAxios,
  topUndoTempPostOracleV7ControllerAxios,
  waitClassPostOracleV7ControllerAxios,
};
