const MAIN_FRAMES = {
  GET_NOTICE_LIST: 'Setting - Notice',

  SETTING_USER_TAG: 'Setting - Tag',
  SETTING_USER_TAG_KEY_WINDOW: 'Setting - Tag Key Window',
  SETTING_USER_TAG_VALUE_WINDOW: 'Setting - Tag Value Window',
  SETTING_USER_TAG_INFORMATION: 'Setting - Tag Information',

  CONFIG_AGENT: 'Config - Agent List',
  CONFIG_AGENT_LIST_WINDOW: 'Config - Agent List Setting Window',
  CONFIG_AGENT_DETAIL: 'Config - Agent Detail',
  CONFIG_AGENT_EXECUTION_DETAIL: 'Config - Agent Execution Detail',

  CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT: 'Config - Custom Stat > Schedule Management',

  IP_CONTROL: 'Config - Security - Login Ip Control',
  IP_CONTROL_WINDOW: 'Config - Security - Login Ip Control Window',
  LOGIN_FAIL: 'Config - Security - Login Fail History',

  DATA_RETENTION: 'Data Retention',
} as const;

export const CONFIG_MANAGEMENT_FRAME_NAMES = {
  // 공지사항
  SETTING_NOTICE: {
    GET_POPUP_CHECK: `${MAIN_FRAMES.GET_NOTICE_LIST}/Popup Check`,
    GET_NOTICE_LIST: `${MAIN_FRAMES.GET_NOTICE_LIST}/Notice List`,
    GET_NOTICE_DETAIL: `${MAIN_FRAMES.GET_NOTICE_LIST}/Notice Detail`,
    GET_NOTICE_EDIT_DETAIL: `${MAIN_FRAMES.GET_NOTICE_LIST}/Notice Edit Detail`,
    ADD_NOTICE: `${MAIN_FRAMES.GET_NOTICE_LIST}/[ADD] Notice`,
    EDIT_NOTICE: `${MAIN_FRAMES.GET_NOTICE_LIST}/[EDIT] Notice`,
    DELETE_NOTICE: `${MAIN_FRAMES.GET_NOTICE_LIST}/[DELETE] Notice`,
    TEST_NOTICE: `${MAIN_FRAMES.GET_NOTICE_LIST}/Test Notice`,
    USER_GROUP_LIST: `${MAIN_FRAMES.GET_NOTICE_LIST}/User Group List`,
    USER_LIST: `${MAIN_FRAMES.GET_NOTICE_LIST}/User List`,
  },

  // 태그
  SETTING_USER_TAG: {
    GET_USER_TAG_KEYS: `${MAIN_FRAMES.SETTING_USER_TAG}/Tag Keys`,
    GET_USER_TAG_VALUES: `${MAIN_FRAMES.SETTING_USER_TAG}/Tag Values`,
    DELETE_USER_TAG_KEY: `${MAIN_FRAMES.SETTING_USER_TAG}/[DELETE] Tag Key`,
    DELETE_USER_TAG_VALUES: `${MAIN_FRAMES.SETTING_USER_TAG}/[DELETE] Tag Values`,
  },
  SETTING_TAG_KEY_WINDOW: {
    GET_USER_TAG_KEYS: `${MAIN_FRAMES.SETTING_USER_TAG_KEY_WINDOW}/Tag Keys`,
    ADD_USER_TAG_KEY: `${MAIN_FRAMES.SETTING_USER_TAG_KEY_WINDOW}/[ADD] Tag Key`,
    EDIT_USER_TAG_KEY: `${MAIN_FRAMES.SETTING_USER_TAG_KEY_WINDOW}/[EDIT] Tag Key`,
  },
  SETTING_TAB_VALUE_WINDOW: {
    GET_USER_TAG_KEYS: `${MAIN_FRAMES.SETTING_USER_TAG_VALUE_WINDOW}/Tag Keys`,
    GET_USER_TAG_VALUES: `${MAIN_FRAMES.SETTING_USER_TAG_VALUE_WINDOW}/[GET] Tag Values`,
    ADD_USER_TAG_VALUE: `${MAIN_FRAMES.SETTING_USER_TAG_VALUE_WINDOW}/[ADD] Tag Value`,
    EDIT_USER_TAG_VALUE: `${MAIN_FRAMES.SETTING_USER_TAG_VALUE_WINDOW}/[EDIT] Tag Value`,
  },
  SETTING_TAG_INFORMATION: {
    GET_CUSTOM_TAG_BY_TARGET: `${MAIN_FRAMES.SETTING_USER_TAG_INFORMATION}/Tags By Target`,
  },

  // 에이전트
  CONFIG_AGENT_LIST: {
    AGENT_TYPES: `${MAIN_FRAMES.CONFIG_AGENT}/Agent Types`,
    AGENT_LIST: `${MAIN_FRAMES.CONFIG_AGENT}/Agent List`,
    RESTART_AGENT: `${MAIN_FRAMES.CONFIG_AGENT}/[Restart] Agent`,
    STOP_AGENT: `${MAIN_FRAMES.CONFIG_AGENT}/[Stop] Agent`,
    DELETE_AGENT: `${MAIN_FRAMES.CONFIG_AGENT}/[Delete] Agent`,
  },
  CONFIG_AGENT_WINDOW: {
    GENERATE_TOKEN: `${MAIN_FRAMES.CONFIG_AGENT}/[Generate] Token`,
    SAVE_AGENT: `${MAIN_FRAMES.CONFIG_AGENT}/[Save] Agent`,
    UPDATE_AGENT: `${MAIN_FRAMES.CONFIG_AGENT}/[Update] Agent`,
  },
  CONFIG_AGENT_DETAIL: {
    AGENT_INFORMATION: `${MAIN_FRAMES.CONFIG_AGENT_DETAIL}/Agent Information`,
    AGENT_TARGET: `${MAIN_FRAMES.CONFIG_AGENT_DETAIL}/Agent Target`,
    AGENT_PATCH_HISTORY: `${MAIN_FRAMES.CONFIG_AGENT_DETAIL}/Agent Patch History`,
  },
  CONFIG_AGENT_EXECUTION_DETAIL: {
    EXECUTION_INFORMATION: `${MAIN_FRAMES.CONFIG_AGENT_EXECUTION_DETAIL}/Execution Information`,
    STOP_PATCH: `${MAIN_FRAMES.CONFIG_AGENT_EXECUTION_DETAIL}/[Stop] Patch`,
    RETRY_PATCH: `${MAIN_FRAMES.CONFIG_AGENT_EXECUTION_DETAIL}/[Retry] Patch`,
  },

  // 사용자 지표
  CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT: {
    SCHEDULE_GROUP_LIST: `${MAIN_FRAMES.CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT}/Schedule Group List`,
    SCHEDULE_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT}/[Delete] Schedule Group List`,
    SCHEDULE_LIST: `${MAIN_FRAMES.CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT}/Schedule List`,
    SCHEDULE_DELETE: `${MAIN_FRAMES.CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT}/[Delete] Schedule List`,
    SCHEDULE: `${MAIN_FRAMES.CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT}/Schedule`,
    COLLECTION_LOG_LIST: `${MAIN_FRAMES.CONFIG_CUSTOM_STAT_SCHEDULE_MANAGEMENT}/Collection Log List`,
  },

  // 보안
  CONFIG_SECURITY: {
    IP_CONTROL_GET: `${MAIN_FRAMES.IP_CONTROL}/[Get] Login Ip Control`,
    IP_CONTROL_DELETE: `${MAIN_FRAMES.IP_CONTROL}/[Delete] Login Ip Control`,
    IP_CONTROL_TOGGLE: `${MAIN_FRAMES.IP_CONTROL}/[Patch] Login Ip Control Toggle`,
    IP_CONTROL_WINDOW_POST: `${MAIN_FRAMES.IP_CONTROL_WINDOW}/[Post]Login Ip Control Window`,
    IP_CONTROL_WINDOW_PUT: `${MAIN_FRAMES.IP_CONTROL_WINDOW}/[Put]Login Ip Control Window`,
    LOGIN_FAIL: `${MAIN_FRAMES.LOGIN_FAIL}/Login Fail History`,
  },

  // 데이터 보관 주기
  DATA_RETENTION: {
    GET_CATEGORY: `${MAIN_FRAMES.DATA_RETENTION}/Category`,
    GET_TABLE: `${MAIN_FRAMES.DATA_RETENTION}/Table`,
    EDIT_TABLE_RETENTION: `${MAIN_FRAMES.DATA_RETENTION}/[EDIT] Table Retention`,
    GET_METRICS_DELTA: `${MAIN_FRAMES.DATA_RETENTION}/Metrics delta`,
    GET_METRICS_CURRENT: `${MAIN_FRAMES.DATA_RETENTION}/Metrics current`,
  },
} as const;
