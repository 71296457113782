const MAIN_FRAMES = {
  K8S_CLUSTER_TOPOLOGY_VIEW: 'Cluster Topology View',
  K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL: 'Alert Detail - Cluster Topology',
  K8S_OBJECT_TOPOLOGY_VIEW: 'Object Topology View',
  K8S_OBJECT_TOPOLOGY_ALERT_DETAIL: 'Alert Detail - Object Topology',
  K8S_LIST_DETAIL: 'Kubernetes List Detail',
  K8S_CLUSTER_VIEW: 'Cluster View',
  K8S_CLUSTER_ALERT_DETAIL: 'Alert Detail - Cluster',
  K8S_POD_ALERT_DETAIL: 'Alert Detail - Pod',
  K8S_NAMESPACE_ALERT_DETAIL: 'Alert Detail - Namespace',
  K8S_NODE_ALERT_DETAIL: 'Alert Detail - Node',
  K8S_DEPLOYMENT_ALERT_DETAIL: 'Alert Detail - Deployment',
  K8S_STATEFUL_SET_ALERT_DETAIL: 'Alert Detail - StatefulSet',
  K8S_DAEMON_SET_ALERT_DETAIL: 'Alert Detail - DaemonSet',
  K8S_REPLICA_SET_ALERT_DETAIL: 'Alert Detail - ReplicaSet',
  K8S_HPA_ALERT_DETAIL: 'Alert Detail - HPA',
  K8S_JOB_ALERT_DETAIL: 'Alert Detail - Job',
  K8S_CRON_JOB_ALERT_DETAIL: 'Alert Detail - CronJob',
  K8S_SERVICE_ALERT_DETAIL: 'Alert Detail - Network Service',
  K8S_POD_VIEW: 'Pod View',
  K8S_POD_DETAIL: 'Pod Detail',
  K8S_NAMESPACE_VIEW: 'Namespace View',
  K8S_NAMESPACE_DETAIL: 'Namespace Detail',
  K8S_NODE_VIEW: 'Node View',
  K8S_NODE_DETAIL: 'Node Detail',
  K8S_DEPLOYMENT_VIEW: 'Deployment View',
  K8S_DEPLOYMENT_DETAIL: 'Deployment Detail',
  K8S_STATEFUL_SET_VIEW: 'StatefulSet View',
  K8S_STATEFUL_SET_DETAIL: 'StatefulSet Detail',
  K8S_DAEMON_SET_VIEW: 'DaemonSet View',
  K8S_DAEMON_SET_DETAIL: 'DaemonSet Detail',
  K8S_REPLICA_SET_VIEW: 'ReplicaSet View',
  K8S_REPLICA_SET_DETAIL: 'ReplicaSet Detail',
  K8S_HPA_VIEW: 'HPA View',
  K8S_HPA_DETAIL: 'HPA Detail',
  K8S_JOB_VIEW: 'Job View',
  K8S_JOB_DETAIL: 'Job Detail',
  K8S_CRONJOB_VIEW: 'CronJob View',
  K8S_CRONJOB_DETAIL: 'CronJob Detail',
  K8S_SERVICE_VIEW: 'Service View (Kubernetes)',
  K8S_SERVICE_DETAIL: 'Service Detail (Kubernetes)',
  K8S_INGRESS_VIEW: 'Ingress View',
  K8S_INGRESS_DETAIL: 'Ingress Detail',
  K8S_CONFIG_STORAGE_VIEW: 'Config & Storage View',
  K8S_CONFIG_STORAGE_DETAIL: 'Config & Storage Detail',
  K8S_CONFIGMAP_VIEW: 'ConfigMap View',
  K8S_CONFIGMAP_DETAIL: 'ConfigMap Detail',
  K8S_PERSISTENT_VOLUME_VIEW: 'Persistent Volume View',
  K8S_PERSISTENT_VOLUME_DETAIL: 'Persistent Volume Detail',
  K8S_PERSISTENT_VOLUME_CLAIM_VIEW: 'Persistent Volume Claim View',
  K8S_PERSISTENT_VOLUME_CLAIM_DETAIL: 'Persistent Volume Claim Detail',
  K8S_STORAGE_CLASSES_VIEW: 'Storage Classes View',
  K8S_STORAGE_CLASSES_DETAIL: 'Storage Classes Detail',
  K8S_ROLE_VIEW: 'Role View',
  K8S_ROLE_DETAIL: 'Role Detail',
  K8S_ROLE_BINDING_VIEW: 'RoleBinding View',
  K8S_ROLE_BINDING_DETAIL: 'RoleBinding Detail',
  K8S_CLUSTER_ROLE_VIEW: 'ClusterRole View',
  K8S_CLUSTER_ROLE_DETAIL: 'ClusterRole Detail',
  K8S_CLUSTER_ROLE_BINDING_VIEW: 'ClusterRoleBinding View',
  K8S_CLUSTER_ROLE_BINDING_DETAIL: 'ClusterRoleBinding Detail',
  K8S_SERVICE_ACCOUNT_VIEW: 'Service Account View',
  K8S_SERVICE_ACCOUNT_DETAIL: 'Service Account Detail',
} as const;

export const K8S_FRAME_NAMES = {
  K8S_CLUSTER_TOPOLOGY_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_VIEW}/Filters`,
    TOPOLOGY_DATA: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_VIEW}/Topology Data`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_VIEW}/Alert Count`,
  },
  K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_OBJECT_TOPOLOGY_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_VIEW}/Filters`,
    TOPOLOGY_DATA: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_VIEW}/Topology Data`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_VIEW}/Alert Count`,
  },
  K8S_OBJECT_TOPOLOGY_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_OBJECT_TOPOLOGY_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_LIST_DETAIL: {
    NODE: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Node List`,
    NAMESPACE: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Namespace List`,
    POD: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Pod List`,
    SERVICE: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Service List`,
    INGRESS: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Ingress List`,
    PERSISTENT_VOLUME: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Persistent Volume List`,
    PERSISTENT_VOLUME_CLAIMS: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Persistent Volume Claims List`,
    STORAGE_CLASS: `${MAIN_FRAMES.K8S_LIST_DETAIL}/Storage Class List`,
  },
  K8S_CLUSTER_VIEW: {
    CLUSTER_LIST: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Cluster List`,
    STAT_LIST: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Stat List`,
    OVERVIEW: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Overview`,
    USER_PRESET: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/User Preset`,
    MAP_CHART: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Map Chart`,
    STAT_SETTING_WINDOW: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/[Save] Stat Setting Window`,
    CHART_SETTING_WINDOW: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/[Save] Chart Setting Window`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_CLUSTER_VIEW}/Alert Count`,
  },
  K8S_CLUSTER_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_CLUSTER_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_CLUSTER_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_CLUSTER_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_POD_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_POD_VIEW}/Filters`,
    POD_LIST: `${MAIN_FRAMES.K8S_POD_VIEW}/Pod List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_POD_VIEW}/Alert Count`,
  },
  K8S_POD_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_POD_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_POD_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_POD_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_POD_DETAIL: {
    DELETE: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Delete] Pod`,
    INFORMATION: `${MAIN_FRAMES.K8S_POD_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.K8S_POD_DETAIL}/Metric`,
    EVENT: `${MAIN_FRAMES.K8S_POD_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_POD_DETAIL}/YAML`,
    LOG_CONTAINERS: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Get] Log Container List`,
    LOGS: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Get] Log`,
    CONTAINERS: `${MAIN_FRAMES.K8S_POD_DETAIL}/Containers`,
    PVC: `${MAIN_FRAMES.K8S_POD_DETAIL}/PVC`,
    APPLICATION_GET: `${MAIN_FRAMES.K8S_POD_DETAIL}/[GET] Application`,
    ACTIVES: `${MAIN_FRAMES.K8S_POD_DETAIL}/Actives`,
    ACTIVES_DETAIL: `${MAIN_FRAMES.K8S_POD_DETAIL}/Actives Detail`,
    ACTIVES_DETAIL_KILL_THREAD: `${MAIN_FRAMES.K8S_POD_DETAIL}/Actives Detail - Kill Thread`,
    DATA_SOURCE: `${MAIN_FRAMES.K8S_POD_DETAIL}/Data Source`,
    TRANSACTIONS: `${MAIN_FRAMES.K8S_POD_DETAIL}/Transactions`,
    RELATIONAL_RESOURCE: `${MAIN_FRAMES.K8S_POD_DETAIL}/Relational Resource`,
    POD_SHELL_INIT: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Init] Shell`,
    POD_SHELL_KILL: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Kill] Shell`,
    POD_SHELL_CMD: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Command] Shell`,
    POD_SHELL_CMD_RESULT: `${MAIN_FRAMES.K8S_POD_DETAIL}/[Command] Shell Result`,
    ALERT: `${MAIN_FRAMES.K8S_POD_DETAIL}/Alert`,
  },
  K8S_NAMESPACE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_NAMESPACE_VIEW}/Filters`,
    NAMESPACE_LIST: `${MAIN_FRAMES.K8S_NAMESPACE_VIEW}/Namespace List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_NAMESPACE_VIEW}/Alert Count`,
  },
  K8S_NAMESPACE_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_NAMESPACE_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_NAMESPACE_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_NAMESPACE_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_NAMESPACE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_NAMESPACE_DETAIL}/Information`,
    YAML: `${MAIN_FRAMES.K8S_NAMESPACE_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_NAMESPACE_DETAIL}/Pod`,
    ALERT: `${MAIN_FRAMES.K8S_NAMESPACE_DETAIL}/Alert`,
  },
  K8S_NODE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_NODE_VIEW}/Filters`,
    NODE_LIST: `${MAIN_FRAMES.K8S_NODE_VIEW}/Node List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_NODE_VIEW}/Alert Count`,
  },
  K8S_NODE_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_NODE_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_NODE_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_NODE_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_NODE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Metric`,
    EVENT: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_NODE_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Pod`,
    ALERT: `${MAIN_FRAMES.K8S_NODE_DETAIL}/Alert`,
  },
  K8S_DEPLOYMENT_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_DEPLOYMENT_VIEW}/Filters`,
    STATUS_CARD: `${MAIN_FRAMES.K8S_DEPLOYMENT_VIEW}/Status Card`,
    DEPLOYMENT_LIST: `${MAIN_FRAMES.K8S_DEPLOYMENT_VIEW}/Deployment List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_DEPLOYMENT_VIEW}/Alert Count`,
  },
  K8S_DEPLOYMENT_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_DEPLOYMENT_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_DEPLOYMENT_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_DEPLOYMENT_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_DEPLOYMENT_DETAIL}/Pod`,
  },
  K8S_DEPLOYMENT_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_DEPLOYMENT_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_DEPLOYMENT_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_DEPLOYMENT_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_STATEFUL_SET_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_STATEFUL_SET_VIEW}/Filters`,
    STATUS_CARD: `${MAIN_FRAMES.K8S_STATEFUL_SET_VIEW}/Status Card`,
    STATEFUL_SETS_LIST: `${MAIN_FRAMES.K8S_STATEFUL_SET_VIEW}/Stateful Sets List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_STATEFUL_SET_VIEW}/Alert Count`,
  },
  K8S_STATEFUL_SET_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_STATEFUL_SET_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_STATEFUL_SET_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_STATEFUL_SET_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_STATEFUL_SET_DETAIL}/Pod`,
  },
  K8S_STATEFUL_SET_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_STATEFUL_SET_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_STATEFUL_SET_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_STATEFUL_SET_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_DAEMON_SET_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_DAEMON_SET_VIEW}/Filters`,
    STATUS_CARD: `${MAIN_FRAMES.K8S_DAEMON_SET_VIEW}/Status Card`,
    DAEMON_SETS_LIST: `${MAIN_FRAMES.K8S_DAEMON_SET_VIEW}/Daemon Sets List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_DAEMON_SET_VIEW}/Alert Count`,
  },
  K8S_DAEMON_SET_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_DAEMON_SET_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_DAEMON_SET_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_DAEMON_SET_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_DAEMON_SET_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_DAEMON_SET_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_DAEMON_SET_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_DAEMON_SET_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_DAEMON_SET_DETAIL}/Pod`,
  },
  K8S_REPLICA_SET_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_REPLICA_SET_VIEW}/Filters`,
    STATUS_CARD: `${MAIN_FRAMES.K8S_REPLICA_SET_VIEW}/Status Card`,
    REPLICA_SETS_LIST: `${MAIN_FRAMES.K8S_REPLICA_SET_VIEW}/Replica Sets List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_REPLICA_SET_VIEW}/Alert Count`,
  },
  K8S_REPLICA_SET_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_REPLICA_SET_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_REPLICA_SET_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_REPLICA_SET_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_REPLICA_SET_DETAIL}/Pod`,
  },
  K8S_REPLICA_SET_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_REPLICA_SET_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_REPLICA_SET_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_REPLICA_SET_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_HPA_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_HPA_VIEW}/Filters`,
    HPA_LIST: `${MAIN_FRAMES.K8S_HPA_VIEW}/Hpa List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_HPA_VIEW}/Alert Count`,
  },
  K8S_HPA_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_HPA_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.K8S_HPA_DETAIL}/Metric`,
    POD: `${MAIN_FRAMES.K8S_HPA_DETAIL}/Pod`,
    EVENT: `${MAIN_FRAMES.K8S_HPA_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_HPA_DETAIL}/YAML`,
  },
  K8S_HPA_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_HPA_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_HPA_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_HPA_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_JOB_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_JOB_VIEW}/Filters`,
    JOB_LIST: `${MAIN_FRAMES.K8S_JOB_VIEW}/Jobs List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_JOB_VIEW}/Alert Count`,
  },
  K8S_JOB_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_JOB_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_JOB_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_JOB_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_JOB_DETAIL}/Pod`,
  },
  K8S_JOB_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_JOB_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_JOB_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_JOB_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_CRONJOB_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CRONJOB_VIEW}/Filters`,
    CRONJOB_LIST: `${MAIN_FRAMES.K8S_CRONJOB_VIEW}/CronJobs List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_CRONJOB_VIEW}/Alert Count`,
  },
  K8S_CRONJOB_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/YAML`,
    JOB: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/Job`,
    POD: `${MAIN_FRAMES.K8S_CRONJOB_DETAIL}/Pod`,
  },
  K8S_CRON_JOB_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_CRON_JOB_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_CRON_JOB_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_CRON_JOB_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_SERVICE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_SERVICE_VIEW}/Filters`,
    SERVICE_LIST: `${MAIN_FRAMES.K8S_SERVICE_VIEW}/service List`,
    ALERT_COUNT: `${MAIN_FRAMES.K8S_SERVICE_VIEW}/Alert Count`,
  },
  K8S_SERVICE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_SERVICE_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_SERVICE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_SERVICE_DETAIL}/YAML`,
    POD: `${MAIN_FRAMES.K8S_SERVICE_DETAIL}/Pod`,
  },
  K8S_SERVICE_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.K8S_SERVICE_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.K8S_SERVICE_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.K8S_SERVICE_ALERT_DETAIL}/Clear System Alert`,
  },
  K8S_INGRESS_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_INGRESS_VIEW}/Filters`,
    INGRESS_LIST: `${MAIN_FRAMES.K8S_INGRESS_VIEW}/Ingress List`,
  },
  K8S_INGRESS_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/YAML`,
    ROLES: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/Roles`,
    LOAD_BALANCER: `${MAIN_FRAMES.K8S_INGRESS_DETAIL}/Load Balancer`,
  },
  K8S_CONFIG_STORAGE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_VIEW}/Filters`,
    CONFIG_STORAGE_LIST: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_VIEW}/Config & Storage List`,
  },
  K8S_CONFIG_STORAGE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_DETAIL}/YAML`,
    PODS: `${MAIN_FRAMES.K8S_CONFIG_STORAGE_DETAIL}/Pods`,
  },
  K8S_CONFIGMAP_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CONFIGMAP_VIEW}/Filters`,
    CONFIGMAP_LIST: `${MAIN_FRAMES.K8S_CONFIGMAP_VIEW}/ConfigMap List`,
  },
  K8S_CONFIGMAP_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CONFIGMAP_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CONFIGMAP_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CONFIGMAP_DETAIL}/YAML`,
    DATA: `${MAIN_FRAMES.K8S_CONFIGMAP_DETAIL}/Data`,
  },
  K8S_PERSISTENT_VOLUME_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_VIEW}/Filters`,
    PERSISTENT_VOLUME_LIST: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_VIEW}/Persistent List`,
  },
  K8S_PERSISTENT_VOLUME_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_DETAIL}/YAML`,
    CLAIM: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_DETAIL}/Claim`,
  },
  K8S_PERSISTENT_VOLUME_CLAIM_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_VIEW}/Filters`,
    PERSISTENT_VOLUME_CLAIM_LIST: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_VIEW}/Persistent Volume Claim List`,
  },
  K8S_PERSISTENT_VOLUME_CLAIM_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/Metric`,
    POD: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/Pod`,
    EVENT: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/YAML`,
    SELECTOR: `${MAIN_FRAMES.K8S_PERSISTENT_VOLUME_CLAIM_DETAIL}/Selector`,
  },
  K8S_STORAGE_CLASSES_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_VIEW}/Filters`,
    STORAGE_CLASSES_LIST: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_VIEW}/Storage Classes List`,
  },
  K8S_STORAGE_CLASSES_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/YAML`,
    PARAMETER: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/Parameter`,
    PERSISTENT_VOLUMES: `${MAIN_FRAMES.K8S_STORAGE_CLASSES_DETAIL}/Persistent Volumes`,
  },
  K8S_ROLE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_ROLE_VIEW}/Filters`,
    ROLE_LIST: `${MAIN_FRAMES.K8S_ROLE_VIEW}/Role List`,
  },
  K8S_ROLE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/YAML`,
    ROLES: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/Roles`,
    RULES: `${MAIN_FRAMES.K8S_ROLE_DETAIL}/Rules`,
  },
  K8S_ROLE_BINDING_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_ROLE_BINDING_VIEW}/Filters`,
    ROLES_BINDINGS_LIST: `${MAIN_FRAMES.K8S_ROLE_BINDING_VIEW}/Roles Bindings List`,
  },
  K8S_ROLE_BINDING_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/Event`,
    REFERENCE: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/Reference`,
    BINDING: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/Binding`,
    YAML: `${MAIN_FRAMES.K8S_ROLE_BINDING_DETAIL}/YAML`,
  },
  K8S_CLUSTER_ROLE_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_VIEW}/Filters`,
    CLUSTER_ROLE_LIST: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_VIEW}/Cluster Role List`,
  },
  K8S_CLUSTER_ROLE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_DETAIL}/YAML`,
    RULE: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_DETAIL}/Rule`,
  },
  K8S_CLUSTER_ROLE_BINDING_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_VIEW}/Filters`,
    CLUSTER_ROLES_BINDING_LIST: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_VIEW}/Cluster Roles Binding List`,
  },
  K8S_CLUSTER_ROLE_BINDING_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/Event`,
    YAML: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/YAML`,
    REFERENCE: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/Reference`,
    BINDINGS: `${MAIN_FRAMES.K8S_CLUSTER_ROLE_BINDING_DETAIL}/Bindings`,
  },
  K8S_SERVICE_ACCOUNT_VIEW: {
    FILTERS: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_VIEW}/Filters`,
    SERVICE_ACCOUNTS_LIST: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_VIEW}/Service Accounts List`,
  },
  K8S_SERVICE_ACCOUNT_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_DETAIL}/Information`,
    EVENT: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_DETAIL}/Event`,
    SECRETS: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_DETAIL}/Secrets`,
    YAML: `${MAIN_FRAMES.K8S_SERVICE_ACCOUNT_DETAIL}/YAML`,
  },
} as const;
