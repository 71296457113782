import { AwsServiceType } from './types';

export const COST_DECIMAL = 1e8;

export const SUMMARY_STATUS = {
  SUCCESS: 'success',
  COLLECT_OFF: 'collectOff',
  NO_PERMISSION: 'noPermission',
} as const;

export const SUMMARY_STAT = {
  Average: 'AVERAGE',
  Max: 'MAX',
  Min: 'MIN',
  SampleCount: 'SAMPLE_COUNT',
  Sum: 'SUM',
} as const;

export const AWS_PERIOD_ITEMS = [
  { name: 'PAST_30_MINUTES', value: 'p30m', shortName: '30m' },
  { name: 'PAST_1_HOURS', value: 'p1h', shortName: '1h' },
  { name: 'PAST_3_HOURS', value: 'p3h', shortName: '3h' },
  { name: 'PAST_6_HOURS', value: 'p6h', shortName: '6h' },
  { name: 'PAST_12_HOURS', value: 'p12h', shortName: '12h' },
  { name: 'PAST_1_DAY', value: 'p1d', shortName: '1d' },
];

export const AWS_S3_PERIOD_ITEM = [
  {
    name: 'PAST_7_DAY',
    value: 'p7d',
    shortName: '7d',
  },
  {
    name: 'PAST_14_DAY',
    value: 'p14d',
    shortName: '14d',
  },
];

export const AWS_DETAIL_METRIC_PERIOD_ITEMS = [
  { name: 'PAST_30_MINUTES', value: 'p30m', shortName: '30m' },
  { name: 'PAST_1_HOURS', value: 'p1h', shortName: '1h' },
  { name: 'PAST_3_HOURS', value: 'p3h', shortName: '3h' },
  { name: 'PAST_6_HOURS', value: 'p6h', shortName: '6h' },
  { name: 'PAST_12_HOURS', value: 'p12h', shortName: '12h' },
  { name: 'PAST_1_DAY', value: 'p1d', shortName: '1d' },
];

export const awsServices = [
  'aws_aurora',
  'aws_documentdb',
  'aws_dynamodb',
  'aws_ec2',
  'aws_elasticache_cluster',
  'aws_elasticache_node',
  'aws_elasticache_serverless',
  'aws_elasticache_shard',
  'aws_lambda',
  'aws_lightsail_database',
  'aws_lightsail_instance',
  'aws_rds',
  'aws_redshift',
  'aws_s3',
  'aws_vpc',
  'aws_task',
  'dms_replinstance',
] as const;

export const AWS_SERVICE: { [key in AwsServiceType]: key } = {
  AURORA: 'AURORA',
  DOCUMENTDB: 'DOCUMENTDB',
  DYNAMODB: 'DYNAMODB',
  EC2: 'EC2',
  ELASTICACHE_CLUSTER: 'ELASTICACHE_CLUSTER',
  ELASTICACHE_NODE: 'ELASTICACHE_NODE',
  ELASTICACHE_SERVERLESS: 'ELASTICACHE_SERVERLESS',
  ELASTICACHE_SHARD: 'ELASTICACHE_SHARD',
  LAMBDA: 'LAMBDA',
  LIGHTSAIL_DATABASE: 'LIGHTSAIL_DATABASE',
  LIGHTSAIL_INSTANCE: 'LIGHTSAIL_INSTANCE',
  RDS: 'RDS',
  REDSHIFT: 'REDSHIFT',
  S3: 'S3',
  VPC: 'VPC',
  DMS_TASK: 'DMS_TASK',
  DMS_REPLINSTANCE: 'DMS_REPLINSTANCE',
};
