import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useInternational } from '@/common/locale';
import { useViewModeStore } from '@/common/stores/view-mode';
import { getVersion } from '@/common/utils/commonUtils';
import { VIEW_MODE_SERVICE } from '../baseMenu.define';

interface ModuleInfo {
  title: string;
  category: 'system' | 'k8s' | 'application' | 'database';
}
export const setup = () => {
  const { t } = useInternational();
  const { viewMode } = storeToRefs(useViewModeStore());

  const moduleInfo = computed<ModuleInfo[]>(() => {
    const version = getVersion();
    const currentViewMode = t(`WORD.${viewMode.value.toUpperCase()}`);
    const modules: ModuleInfo[] = [
      {
        title: t('WORD.GNB.HELP.SYSTEM_MODULE_VERSION', { version, viewMode: currentViewMode }),
        category: 'system',
      },
      {
        title: t('WORD.GNB.HELP.K8S_MODULE_VERSION', { version, viewMode: currentViewMode }),
        category: 'k8s',
      },
      {
        title: t('WORD.GNB.HELP.APPLICATION_MODULE_VERSION', {
          version,
          viewMode: currentViewMode,
        }),
        category: 'application',
      },
      {
        title:
          viewMode.value === 'exemone'
            ? t('WORD.GNB.HELP.DATABASE_MODULE_VERSION', {
                version,
                viewMode: currentViewMode,
              })
            : t('WORD.GNB.HELP.VIEW_MODE_MODULE_VERSION', {
                version,
                viewMode: currentViewMode,
              }),
        category: 'database',
      },
    ];

    return modules.filter((module) => VIEW_MODE_SERVICE[viewMode.value]?.includes(module.category));
  });

  return {
    t,

    moduleInfo,
  };
};
