/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  ApiResponseKafkaListWithColumnResponse,
  KafkaBrokerListResponse,
  KafkaConsumerGroupListResponse,
  KafkaDetailFunctionResponse,
  KafkaInfoResponse,
  KafkaTopicListResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getKafkaListKafkaControllerAxios = ({
  kafkaGroupIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  kafkaGroupIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseKafkaListWithColumnResponse> => {
  const localVarPath = '/api/v8/message-queue/kafka';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      kafkaGroupIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getKafkaBrokerListKafkaControllerAxios = ({
  kafkaInstanceId,
  kafkaInstanceType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  kafkaInstanceId: string;
  kafkaInstanceType: 'broker' | 'cluster' | 'topic';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<KafkaBrokerListResponse> => {
  const localVarPath = '/api/v8/message-queue/kafka/{kafkaInstanceType}/{kafkaInstanceId}/broker'
    .replace(`{${'kafkaInstanceId'}}`, encodeURIComponent(String(kafkaInstanceId)))
    .replace(`{${'kafkaInstanceType'}}`, encodeURIComponent(String(kafkaInstanceType)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getKafkaConsumerGroupListKafkaControllerAxios = ({
  kafkaInstanceId,
  kafkaInstanceType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  kafkaInstanceId: string;
  kafkaInstanceType: 'broker' | 'cluster' | 'topic';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<KafkaConsumerGroupListResponse> => {
  const localVarPath =
    '/api/v8/message-queue/kafka/{kafkaInstanceType}/{kafkaInstanceId}/consumer-group'
      .replace(`{${'kafkaInstanceId'}}`, encodeURIComponent(String(kafkaInstanceId)))
      .replace(`{${'kafkaInstanceType'}}`, encodeURIComponent(String(kafkaInstanceType)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getKafkaDetailFunctionKafkaControllerAxios = ({
  targetId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  targetId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<KafkaDetailFunctionResponse> => {
  const localVarPath = '/api/v8/message-queue/kafka/detail-function';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      targetId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getKafkaInstanceInfoKafkaControllerAxios = ({
  kafkaInstanceId,
  kafkaInstanceType,
  brokerId,
  clusterId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  kafkaInstanceId: string;
  kafkaInstanceType: 'broker' | 'cluster' | 'topic';
  brokerId?: string;
  clusterId?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<KafkaInfoResponse> => {
  const localVarPath = '/api/v8/message-queue/kafka/{kafkaInstanceType}/{kafkaInstanceId}/info'
    .replace(`{${'kafkaInstanceId'}}`, encodeURIComponent(String(kafkaInstanceId)))
    .replace(`{${'kafkaInstanceType'}}`, encodeURIComponent(String(kafkaInstanceType)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      brokerId,
      clusterId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getKafkaTopicListKafkaControllerAxios = ({
  kafkaInstanceId,
  kafkaInstanceType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  kafkaInstanceId: string;
  kafkaInstanceType: 'broker' | 'cluster' | 'topic';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<KafkaTopicListResponse> => {
  const localVarPath = '/api/v8/message-queue/kafka/{kafkaInstanceType}/{kafkaInstanceId}/topic'
    .replace(`{${'kafkaInstanceId'}}`, encodeURIComponent(String(kafkaInstanceId)))
    .replace(`{${'kafkaInstanceType'}}`, encodeURIComponent(String(kafkaInstanceType)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getKafkaListKafkaControllerAxios,
  getKafkaBrokerListKafkaControllerAxios,
  getKafkaConsumerGroupListKafkaControllerAxios,
  getKafkaDetailFunctionKafkaControllerAxios,
  getKafkaInstanceInfoKafkaControllerAxios,
  getKafkaTopicListKafkaControllerAxios,
};
