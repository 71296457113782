import {
  DisplayStyleType,
  WidgetChartData,
  WidgetChartType,
  WidgetCreateType,
} from '@/dashboard/utils/types';
import { DataResource } from '../editSettings/dataResource.types';
import {
  checkValidTargetByCreateType,
  filterChartDataByValidity,
  isChartDataValid,
} from './widgets.utils';

export const ChartDataFilter: Record<
  Exclude<
    WidgetChartType,
    | 'TEXT'
    | 'IMAGE'
    | 'MEMO'
    | 'GROUP'
    | 'TAB_GROUP'
    | 'HORIZONTAL_FILTER'
    | 'VERTICAL_FILTER'
    | 'LEGEND'
  >,
  (
    createType: WidgetCreateType,
    dataResource: DataResource,
  ) => (chartData: WidgetChartData) => boolean
> = {
  // graphs widget
  TIME_SERIES: filterChartDataByValidity('TIME_SERIES'),
  SCATTER: filterChartDataByValidity('SCATTER'),
  ONE_DAY_COMPARE: filterChartDataByValidity('ONE_DAY_COMPARE'),
  EQUALIZER: filterChartDataByValidity('EQUALIZER'),
  SCOREBOARD: filterChartDataByValidity('SCOREBOARD'),
  PIE: filterChartDataByValidity('PIE'),
  STACKED_PROPORTION_BAR: filterChartDataByValidity('STACKED_PROPORTION_BAR'),
  ACTION_VIEW: isChartDataValid,
  GAUGE: filterChartDataByValidity('GAUGE'),
  // list widget
  TABLE: filterChartDataByValidity('TABLE'),
  TREE_GRID: filterChartDataByValidity('TREE_GRID'),
  TOP_LIST: filterChartDataByValidity('TOP_LIST'),
  // architecture widget
  EXCLUSIVE: isChartDataValid,
  STATUS: checkValidTargetByCreateType,
  STATUS_SUMMARY: isChartDataValid,
  TOPOLOGY: checkValidTargetByCreateType,
  SERVER: checkValidTargetByCreateType,
  // business widget
  SERVICE_CARD: checkValidTargetByCreateType,
} as const;

export const getLimitSeriesCountByChartType = (
  chartType: WidgetChartType,
  displayStyle?: DisplayStyleType,
): number | undefined => {
  if (['ONE_DAY_COMPARE', 'PIE', 'EQUALIZER', 'GAUGE'].includes(chartType)) {
    return 1;
  }
  if (chartType === 'SCOREBOARD' && displayStyle === 'STYLE_2') {
    return 2;
  }
  return undefined;
};
