<template>
  <div
    class="summary-type-toggle"
    :class="`summary-type-toggle--${toggleValue ? 'inactive' : 'active'}`"
  >
    <span class="summary-type-toggle__text summary-type-toggle__text--active">
      <slot name="active">
        {{
          t(`WORD.${props.activeText.toUpperCase()}`, {
            default: convertCamelToCapital(props.activeText),
          })
        }}
      </slot>
    </span>
    <ev-toggle
      v-model="toggleValue"
      class="size-small no-icon"
      @change="onChange"
    />
    <span class="summary-type-toggle__text summary-type-toggle__text--inactive">
      <slot name="inactive">
        {{
          t(`WORD.${props.inactiveText.toUpperCase()}`, {
            default: convertCamelToCapital(props.inactiveText),
          })
        }}
      </slot>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PA_TOGGLE_DATA_TYPE } from '@/common/utils/define';
import { convertCamelToCapital } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';

interface Props {
  toggleDataType?: string;
  activeText?: string;
  inactiveText?: string;
  activeColor?: string;
  inactiveColor?: string;
}

interface Emit {
  (e: 'update:toggleDataType', value: string): void;
  (e: 'change-value', value: boolean): void;
}

const props = withDefaults(defineProps<Props>(), {
  toggleDataType: PA_TOGGLE_DATA_TYPE.SUM,
  activeText: PA_TOGGLE_DATA_TYPE.SUM,
  inactiveText: PA_TOGGLE_DATA_TYPE.AVG,
  activeColor: '#50BC5E',
  inactiveColor: '#FF7171',
});

const emit = defineEmits<Emit>();

const { t } = useInternational();
const toggleValue = computed<boolean>({
  get: () => props.toggleDataType === props.inactiveText,
  set: (newVal) => emit('update:toggleDataType', newVal ? props.inactiveText : props.activeText),
});

const onChange = (newVal: boolean) => {
  toggleValue.value = newVal;
  emit('change-value', newVal);
};
</script>

<style lang="scss">
.summary-type-toggle {
  display: flex;
  gap: 4px;
  align-items: center;

  &__text {
    font-size: 12px;
    color: var(--grid-toggle-font-color);
  }
  &--active {
    .summary-type-toggle__text--active {
      color: v-bind('$props.activeColor') !important;
      i {
        background-color: v-bind('$props.activeColor');
      }
    }
  }
  &--inactive {
    .summary-type-toggle__text--inactive {
      color: v-bind('$props.inactiveColor') !important;
      i {
        background-color: v-bind('$props.inactiveColor');
      }
    }
  }
  .ev-toggle {
    border-color: v-bind('$props.activeColor') !important;
    background-color: v-bind('$props.activeColor') !important;
    &:hover {
      border-color: v-bind('$props.activeColor') !important;
    }
    &.checked {
      border-color: v-bind('$props.inactiveColor') !important;
      background-color: v-bind('$props.inactiveColor') !important;
      &:hover {
        border-color: v-bind('$props.inactiveColor') !important;
        background-color: v-bind('$props.inactiveColor') !important;
      }
    }
  }
}
</style>
