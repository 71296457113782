import { generateUUID } from '@/common/utils/commonUtils';
import {
  BucketAggregation,
  BucketAggregationsType,
  DateHistogramBucketAggregations,
  FiltersBucketAggregations,
  HistogramBucketAggregations,
  TermsBucketAggregations,
} from './BucketAggregation';

export class BucketAggregationFactory {
  static createBucketAggregation(type: BucketAggregationsType): BucketAggregation {
    switch (type) {
      case 'dateHistogram':
        return new DateHistogramBucketAggregations(generateUUID());
      case 'filters':
        return new FiltersBucketAggregations(generateUUID());
      case 'histogram':
        return new HistogramBucketAggregations(generateUUID());
      case 'terms':
        return new TermsBucketAggregations(generateUUID());
      default:
        throw new Error(`Unknown bucket aggregation type: ${type}`);
    }
  }
}
