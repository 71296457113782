import router from '@/common/router';
import { getWidgetMappingInfoDashboardWidgetV8ControllerAxios } from '@/openapi/metav8Over/api/dashboard-widget-v8-controller-api';

export const useLoginRoute = () => {
  const getStartDashboardId = async () => {
    let startScreenId: number;
    try {
      const { data } = await getWidgetMappingInfoDashboardWidgetV8ControllerAxios({
        startScreen: true,
      });
      startScreenId = data.data?.[0].dashboardId ?? -1;
    } catch (e) {
      startScreenId = -1;
    }
    return startScreenId;
  };

  const goDashboard = async () => {
    const startScreenId = await getStartDashboardId();
    if (startScreenId > 0) {
      await router.push({
        name: 'dashboard_Dashboard View',
        params: {
          id: startScreenId,
        },
      });
    } else {
      await router.push('/dashboard/list');
      // await router.push('/home');
    }
  };

  return {
    getStartDashboardId,
    goDashboard,
  };
};
