import { computed } from 'vue';
import { RoleIdMap } from '@/common/auth/auth.defines';
import type { UserProfile } from './myInfoPopup.types';

export interface Props {
  userProfile: UserProfile;
}

export const setup = (props: Props) => {
  const userRoleName = computed<string | null>(() => {
    const role = Object.entries(RoleIdMap).find(
      ([_, value]) => value === props.userProfile.role,
    )?.[0];
    return role ?? null;
  });

  return { userRoleName };
};
