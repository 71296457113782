import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { RouteRecordRaw } from 'vue-router';

const homeMenu: RouteRecordRaw[] = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/home/views/HomeView.vue'),
    beforeEnter: [requireAuth, setDayjsTimezone],
    children: [],
  },
];

export { homeMenu };
