<script lang="ts" setup>
import SlideOutWindow from '@/common/components/templates/slideOutWindow/SlideOutWindow.vue';
import AlertListDetail from '@/common/components/organisms/alertListDetail/AlertListDetail.vue';
import {
  setup,
  Props,
  Emit,
} from '@/common/components/organisms/alertListDetail/alertListDetailSlide.setup';

const props = withDefaults(defineProps<Props>(), {
  isVisible: false,
  type: 'database',
});
const emit = defineEmits<Emit>();

const { isShow, slideWidth, onExpandSlide, onClickOpenPopup, t } = setup(props, emit);
</script>

<template>
  <slide-out-window
    v-model:is-visible="isShow"
    class="alert-detail"
    :title="t('WORD.ALERT')"
    :options="{
      width: slideWidth,
      hasExpand: false,
    }"
    @open-popup="onClickOpenPopup"
  >
    <alert-list-detail
      :is-show="isShow"
      :type="props.type"
      :alert-info="props.alertInfo"
      @expand-slide="onExpandSlide"
      @open-detail="() => emit('openDetail')"
    />
  </slide-out-window>
</template>

<style lang="scss" scoped>
.alert-detail {
  :deep(.slide-out-window__body) {
    padding: 0;
    background-color: var(--alert-detail-body-bg);
  }
}
</style>
