import { getByInstanceIdMetaDatabaseInstanceV8ControllerAxios } from '@/openapi/metav8Over/api/meta-database-instance-v8-controller-api';
import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { InstanceInfoRes } from '@/common/api/resTypes/instanceRes';
import { DBType } from '@/common/utils/types';
import { getV2InstancesAxios } from '@/openapi/meta/api/instance-v2-controller-api';
import { getV1AgentsAxios } from '@/openapi/meta/api/xm-agent-controller-api';
import { AWS_CREDENTIAL_TYPE } from '@/config/views/platform/database/instance/uses/awsCredential.uses';

export interface AgentInfo {
  id: string;
  name: string;
  state: string;
  type: 'all' | 'db' | 'os';
}

interface State {
  initViewType: string;
  isShowWindow: boolean;
  agentList: AgentInfo[];
  detailedInstanceId: string;
  instances: InstanceInfoRes[];
  instanceInfo: InstanceInfoRes;
}

export const getInitDbInfo = () => ({
  type: 'oracle' as DBType,
  dbAgentId: '',
  enabled: false,
  name: '',
  dbName: '',
  hostName: '',
  port: null,
  user: '',
  password: '',
});
export const getInitOsResourceInfo = (platform = 'disable') => {
  let osConfig = {};
  switch (platform) {
    case 'aws':
      osConfig = {
        awsRegion: '',
        awsIsCluster: '',
        awsCluster: '',
        awsIdentifier: '',
        awsCredentialType: AWS_CREDENTIAL_TYPE.TOKEN,
        // 토큰 방식에서만 사용
        awsAccessKey: '',
        awsSecretAccessKey: '',
        // IAM Roles Anywhere 방식에서만 사용
        awsRolesAnyWhereCertificate: '',
        awsRolesAnyWhereCertificateFileName: '',
        awsRolesAnyWherePrivateKey: '',
        awsRolesAnyWherePrivateKeyFileName: '',
        awsRolesAnyWhereProfileArn: '',
        awsRolesAnyWhereRoleArn: '',
        awsRolesAnyWhereTrustAnchorArn: '',
        // VPC Endpoint
        awsVpcEndPoint: '', // 'true', 'false': API에서 모든 값을 String으로 처리하고 있어서 boolean 타입으로 변경 불가
        awsVpcEc2: '',
        awsVpcRds: '',
        awsVpcMonitoring: '',
        awsVpcRegion: '',
        // enhanced monitoring
        awsEnhancedMonitoring: '', // 'true', 'false': API에서 모든 값을 String으로 처리하고 있어서 boolean 타입으로 변경 불가
        awsEnhancedLogStreamName: '',
        awsEnhancedLogGroup: '',
      };
      break;
    case 'azure':
      osConfig = {
        azureTenantId: '',
        azureClientId: '',
        azureClientSecret: '',
        azureSubscription: '',
        azureResourceId: '',
        azureIdentifier: '',
        azureServerType: '',
        mysqlAzureSlowquery: '',
        mysqlAzureStorageId: '',
        mysqlAzureSlowqueryContainerId: '',
        mysqlAzureSlowquerySecret: '',
      };
      break;
    case 'oci':
      osConfig = {
        ociTenantId: '',
        ociClientId: '',
        ociRegion: '',
        ociFingerprint: '',
        ociPrivateKey: '',
        ociResourceId: '',
      };
      break;
    case 'ncp':
      osConfig = {
        ncpServiceType: '',
        ncpServerType: '',
        ncpAccessKey: '',
        ncpZoneCode: '',
        ncpDbInstanceIdentifier: '',
        ncpSecretKey: '',
      };
      break;
    case 'kt':
      osConfig = {
        ktAccessKey: '',
        ktSecretKey: '',
        ktRegion: '',
        ktDbInstanceIdentifier: '',
        ktDbMetricInstanceIdentifier: '',
      };
      break;
    default:
      break;
  }
  return {
    osAgentId: 'default',
    platform,
    osConfig,
  };
};
export const getInitCustomConfigInfo = () => ({
  customConfig: [],
});
const getInitInstanceInfoObj = (): InstanceInfoRes => ({
  ...getInitDbInfo(),
  ...getInitOsResourceInfo(),
  ...getInitCustomConfigInfo(),
  createdDate: '',
  description: '',
  folders: [],
  instanceId: '',
  instanceState: {
    connectedTime: '',
    cpuCore: null,
    dbState: '',
    osState: '',
    packetReceivedTime: '',
    packets: null,
    updatedTime: '',
    version: '',
  },
});

export const monitoringInstance: Module<State, RootState> = {
  namespaced: true,
  state: {
    // Window
    initViewType: '',
    isShowWindow: false,
    detailedInstanceId: '',
    agentList: [],

    // Instance List
    instances: [],

    // Instance Response
    instanceInfo: getInitInstanceInfoObj(),
  },
  mutations: {
    setInitViewType: (state: State, viewType: string) => {
      state.initViewType = viewType;
    },
    setIsShowWindow: (state: State, isShow: boolean) => {
      state.isShowWindow = isShow;
    },
    setDetailedInstanceId: (state: State, instanceId: string) => {
      state.detailedInstanceId = instanceId;
    },
    setAgentList: (state: State, list: AgentInfo[]) => {
      state.agentList = list;
    },
    setInstances: (state: State, list: InstanceInfoRes[]) => {
      state.instances = list;
    },
    setInstanceInfo: (state: State, info: InstanceInfoRes) => {
      state.instanceInfo = info;
    },
    initDetailedInstanceId: (state: State) => {
      state.detailedInstanceId = '';
    },
    initInstanceInfo: (state: State) => {
      state.instanceInfo = getInitInstanceInfoObj();
    },
    initInstances: (state: State) => {
      state.instances.length = 0;
    },
  },
  actions: {
    dispatchInstances: async ({ commit }) => {
      const { data } = await getV2InstancesAxios();
      await commit('setInstances', data.data ?? []);
    },
    dispatchInstanceInfo: async ({ commit }, instanceId: string) => {
      const { data } = await getByInstanceIdMetaDatabaseInstanceV8ControllerAxios({ instanceId });
      await commit('setInstanceInfo', data.data?.[0] ?? getInitInstanceInfoObj());
    },
    dispatchAgentList: async ({ commit }) => {
      const { data } = await getV1AgentsAxios();
      await commit(
        'setAgentList',
        data.map((item) => ({
          id: item.agentId,
          name: item.name,
          state: item.agentState?.state,
          type: item.type?.toLowerCase(),
        })),
      );
    },
  },
  getters: {
    getInitViewType: (state: State) => state.initViewType,
    getIsShowWindow: (state: State) => state.isShowWindow,
    getAgentList: (state: State) => state.agentList,
    getDetailedInstanceId: (state: State) => state.detailedInstanceId,
    getInstances: (state: State) => state.instances,
    getInstanceInfo: (state: State) => state.instanceInfo,
    getInstanceById: (_, getters) => (instanceId: string) =>
      getters.getInstances.find((instance) => instance.instanceId === instanceId),
  },
};
