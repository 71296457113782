<script setup lang="ts">
import { setup } from './productionModuleInfo.setup';

const {
  t,

  moduleInfo,
} = setup();
</script>

<template>
  <div class="module-info">
    <div class="module-info__title">
      <span>{{ t('WORD.GNB.HELP.MODULE_VERSION') }} : </span>
    </div>
    <div class="module-info__list">
      <ul class="module-info__item-group">
        <li
          v-for="moduleName in moduleInfo"
          :key="moduleName"
        >
          {{ moduleName.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.module-info {
  padding: 12px 6px 12px 8px;

  &__list {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
  }

  &__item-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
    user-select: text;
  }

  span {
    white-space: nowrap;
  }
}
</style>
