import { RouteRecordRaw } from 'vue-router';
import {
  requireAuth,
  requireInstances,
  setDayjsTimezone,
  validateDatabaseOverviewPath,
} from '@/common/router/navigationGuard';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import PaViewport from '@/common/components/templates/PaViewport.vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { MaxgaugeDatabaseSubMenu } from '@/common/utils';

const PREFIX_PG = 'postgresql_';

const pgMenu: RouteRecordRaw[] = [
  {
    path: '/postgresql/',
    name: `${PREFIX_PG}Real Time Monitoring`,
    component: RtmViewport,
    children: [
      {
        path: 'multiView',
        name: `${PREFIX_PG}Multi View`,
        component: () =>
          import(/* webpackChunkName: "pg-multi-view" */ '../views/multiView/MultiView.vue'),
      },
      {
        path: 'singleView',
        name: `${PREFIX_PG}Single View`,
        component: () =>
          import(/* webpackChunkName: "single-view" */ '../views/singleView/SingleView.vue'),
      },
    ],
    beforeEnter: [requireAuth, requireInstances, setDayjsTimezone, validateDatabaseOverviewPath],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD,
    },
  },
  {
    path: '/postgresql/',
    name: `${PREFIX_PG}SQL Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'topNAnalysis',
        name: `${PREFIX_PG}Top N Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "pg-top-n-analysis" */ '../views/topNAnalysis/TopNAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'searchSql',
        name: `${PREFIX_PG}Search SQL`,
        component: () =>
          import(/* webpackChunkName: "pg-search-sql" */ '../views/searchSql/SearchSqlView.vue'),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_POSTGRESQL_SQL_ANALYSIS,
      },
    },
  },
  {
    path: '/postgresql/',
    name: `${PREFIX_PG}Session Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'searchSession',
        name: `${PREFIX_PG}Search Session`,
        component: () =>
          import(
            /* webpackChunkName: "postgresql-search-session" */ '../views/searchSession/SearchSession.vue'
          ),
      },
    ],
    beforeEnter: [requireAuth, requireInstances],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_POSTGRESQL_SESSION_ANALYSIS,
      },
    },
  },
  {
    path: '/postgresql/',
    name: `${PREFIX_PG}Performance Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'trendAnalysis',
        name: `${PREFIX_PG}Trend Analysis`,
        component: () =>
          import(
            /* webpackChunkName: "pg-trend-analysis" */ '../views/trendAnalysis/TrendAnalysis.vue'
          ),
        beforeEnter: [requireInstances],
      },
      {
        path: 'parameterHistory',
        name: `${PREFIX_PG}Parameter History`,
        component: () =>
          import(
            /* webpackChunkName: "pg-parameter-history" */ '../views/parameterHistory/ParameterHistory.vue'
          ),
        beforeEnter: [requireInstances],
      },
    ],
    beforeEnter: [requireAuth],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]:
          ROLE_PERMISSION_KEY.MONITORING.MONITORING_POSTGRESQL_PERFORMANCE_ANALYSIS,
      },
    },
  },
  {
    path: '/postgresql/',
    name: `${PREFIX_PG}DataSpace Analysis`,
    component: PaViewport,
    children: [
      {
        path: 'objectSize',
        name: `${PREFIX_PG}Object Size`,
        component: () =>
          import(
            /* webpackChunkName: "pg-object-size-analysis" */ '../views/objectSize/ObjectSize.vue'
          ),
      },
    ],
    beforeEnter: [requireAuth, requireInstances],
    meta: {
      rolePermission: {
        [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_DATABASE,
        [VIEW_MODE.MAXGAUGE]:
          ROLE_PERMISSION_KEY.MONITORING.MONITORING_POSTGRESQL_DATASPACE_ANALYSIS,
      },
    },
  },
];

const subMenuMappingPg = {
  instanceView: `${PREFIX_PG}Real Time Monitoring`,
  sqlAnalysis: `${PREFIX_PG}SQL Analysis`,
  sessionAnalysis: `${PREFIX_PG}Session Analysis`,
  performanceAnalysis: `${PREFIX_PG}Performance Analysis`,
  dataSpaceAnalysis: `${PREFIX_PG}DataSpace Analysis`,
};

const getPgNaviMenu = (subMenu?: MaxgaugeDatabaseSubMenu['postgresql']) => {
  const filteredPgMenu = extractAccessibleRoutes(pgMenu);

  if (subMenu && subMenuMappingPg[subMenu]) {
    return filteredPgMenu.filter((menu) => menu.name === subMenuMappingPg[subMenu]);
  }

  return filteredPgMenu.filter((menu) => menu.name !== subMenuMappingPg.instanceView);
};

export { pgMenu, getPgNaviMenu };
