export type ActionTypeMap = {
  dashboardList: 'settingFeaturedDashboard' | 'deleteFeaturedDashboard';
  dashboardEdit: 'createViewPack';
  wasDetail: 'createDump' | 'killThread';
  instanceDetail: 'sessionKill';
  sessionDetail: 'sessionKill';
  logging: 'logKeyword';
};

export type PageType = keyof ActionTypeMap;

export const RoleIdMap = {
  Master: '9f480b7d-47bd-4dff-afae-fb0dd0b79037',
  Admin: 'fe80227f-6784-47cb-8b86-e2e7bafd613c',
  User: 'f5fa6d61-856f-4320-a91d-a9788b77f441',
} as const;

export type Role = keyof typeof RoleIdMap;
export type RoleId = (typeof RoleIdMap)[Role];

export const Supervisors: Role[] = ['Master', 'Admin'];
export const Master: Role[] = ['Master'];
export const AllUsers: Role[] = ['Master', 'Admin', 'User'];

type AuthSpecType<T extends Record<string, string>> = {
  [K in keyof T]: Record<T[K], Role[]>;
};

export const AuthSpec: AuthSpecType<ActionTypeMap> = {
  dashboardList: {
    settingFeaturedDashboard: [],
    deleteFeaturedDashboard: [],
  },
  dashboardEdit: {
    createViewPack: [...AllUsers],
  },
  wasDetail: {
    createDump: [...Supervisors],
    killThread: [...Supervisors],
  },
  instanceDetail: {
    sessionKill: [...Supervisors],
  },
  sessionDetail: {
    sessionKill: [...Supervisors],
  },
  logging: {
    logKeyword: [...Supervisors],
  },
};
