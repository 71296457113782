const MAIN_FRAMES = {
  CONFIG_USER_ALERT: 'Config - User Alert',
  CONFIG_USER_ALERT_GROUP_WINDOW: 'Config - User Alert Group Setting Window',
  CONFIG_USER_ALERT_RULE_WINDOW: 'Config - User Alert Rule Setting Window',

  CONFIG_SYSTEM_ALERT_INFRASTRUCTURE: 'Config - System Alert > Infrastructure',
  CONFIG_SYSTEM_ALERT_NETWORK_DEVICE: 'Config - System Alert > Network Device',
  CONFIG_SYSTEM_ALERT_KUBERNETES: 'Config - System Alert > Kubernetes',
  CONFIG_SYSTEM_ALERT_APPLICATION: 'Config - System Alert > Application',
  CONFIG_SYSTEM_ALERT_DATABASE: 'Config - System Alert > Database',
  CONFIG_SYSTEM_ALERT_CLOUD: 'Config - System Alert > Cloud',
  CONFIG_GROUP_NOTIFICATION_SETTING: 'Config - Group Notification Setting',
  CONFIG_INTERFACE_SETTING_WINDOW: 'Config - Interface Setting Window',

  CONFIG_NOTIFICATION: 'Config - Notification',
  CONFIG_NOTIFICATION_WINDOW: 'Config - Notification Setting Window',
} as const;

export const CONFIG_ALERT_FRAME_NAMES = {
  // 사용자 알람
  CONFIG_USER_ALERT: {
    ALERT_GROUP_LIST: `${MAIN_FRAMES.CONFIG_USER_ALERT}/Alert Group List`,
    ALERT_GROUP_DELETE: `${MAIN_FRAMES.CONFIG_USER_ALERT}/[Delete] Alert Group`,
    RULE_LIST: `${MAIN_FRAMES.CONFIG_USER_ALERT}/Rule List`,
    RULE_DELETE: `${MAIN_FRAMES.CONFIG_USER_ALERT}/[Delete] Rule`,
    RULE_ACTIVATE: `${MAIN_FRAMES.CONFIG_USER_ALERT}/[Activate] Rule`,
    RULE_MUTE: `${MAIN_FRAMES.CONFIG_USER_ALERT}/[MUTE] Rule`,
    TAGS: `${MAIN_FRAMES.CONFIG_USER_ALERT}/Tags`,
  },
  CONFIG_USER_ALERT_GROUP_WINDOW: {
    ALERT_GROUP_SAVE: `${MAIN_FRAMES.CONFIG_USER_ALERT_GROUP_WINDOW}/[Save] Alert Group`,
  },
  CONFIG_USER_ALERT_RULE_WINDOW: {
    RULE_DATA: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Rule Data`,
    RULE_SAVE: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/[Save] Rule`,
    TREND: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Trend`,
    NOTIFICATION: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Notification`,
    USER_GROUP: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Notification > User Group`,
    USER_LIST: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Notification > User List`,
    TAGS: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Tags`,
    PATH_LIST: `${MAIN_FRAMES.CONFIG_USER_ALERT_RULE_WINDOW}/Path List`,
  },

  // 시스템 알람
  CONFIG_SYSTEM_ALERT_INFRASTRUCTURE: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_INFRASTRUCTURE}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_INFRASTRUCTURE}/Host Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_INFRASTRUCTURE}/Host List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_INFRASTRUCTURE}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_NETWORK_DEVICE: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_NETWORK_DEVICE}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_NETWORK_DEVICE}/Network Device Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_NETWORK_DEVICE}/Device List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_NETWORK_DEVICE}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_KUBERNETES: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_KUBERNETES}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_KUBERNETES}/Cluster List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_KUBERNETES}/Node List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_KUBERNETES}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_APPLICATION: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_APPLICATION}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_APPLICATION}/WAS Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_APPLICATION}/WAS List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_APPLICATION}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_DATABASE: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_DATABASE}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_DATABASE}/Instance Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_DATABASE}/Instance List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_DATABASE}/Alert Setting`,
  },
  CONFIG_SYSTEM_ALERT_CLOUD: {
    GROUP_NOTIFICATION: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_CLOUD}/Group Notification`,
    GROUP_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_CLOUD}/Instance Group List`,
    TARGET_LIST: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_CLOUD}/Instance List`,
    ALERT_SETTING: `${MAIN_FRAMES.CONFIG_SYSTEM_ALERT_CLOUD}/Alert Setting`,
  },
  CONFIG_GROUP_NOTIFICATION_SETTING: {
    INFORMATION: `${MAIN_FRAMES.CONFIG_GROUP_NOTIFICATION_SETTING}/Information`,
    GROUP_NOTIFICATION_SAVE: `${MAIN_FRAMES.CONFIG_GROUP_NOTIFICATION_SETTING}/[Save] Group Notification`,
  },
  CONFIG_INTERFACE_SETTING_WINDOW: {
    INTERFACE_LIST: `${MAIN_FRAMES.CONFIG_INTERFACE_SETTING_WINDOW}/Interface List`,
    INTERFACE_SAVE: `${MAIN_FRAMES.CONFIG_INTERFACE_SETTING_WINDOW}/[Save] Interface`,
  },

  // 알람 관리
  CONFIG_NOTIFICATION: {
    MESSENGER_TYPE_LIST: `${MAIN_FRAMES.CONFIG_NOTIFICATION}/Messenger Type LIST`,
    NOTIFICATION_LIST: `${MAIN_FRAMES.CONFIG_NOTIFICATION}/Notification List`,
    NOTIFICATION_DELETE: `${MAIN_FRAMES.CONFIG_NOTIFICATION}/[Delete] Notification`,
    NOTIFICATION_UPDATE: `${MAIN_FRAMES.CONFIG_NOTIFICATION}/[Update] Notification`,
  },
  CONFIG_NOTIFICATION_WINDOW: {
    NOTIFICATION_ADD: `${MAIN_FRAMES.CONFIG_NOTIFICATION_WINDOW}/[Add] Notification`,
    NOTIFICATION_EDIT: `${MAIN_FRAMES.CONFIG_NOTIFICATION_WINDOW}/[Edit] Notification`,
  },
} as const;
