import { computed, getCurrentInstance } from 'vue';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { useStore, type RootState } from '@/common/store';
import { Store } from 'vuex';
import {
  findPermissionByTranslateId,
  useRolePermission,
} from '@/common/permission/permission.utils';
import { checkRole } from '@/common/auth/auth.utils';

interface DashboardPermissionData {
  isMyDashboard: boolean;
  isFeatured?: boolean;
  isFeatureReport?: boolean;
  showError?: boolean;
}

type DashboardPermissionCheckFn = (data: DashboardPermissionData) => boolean;

export const convertPermissionToBoolean = (
  value?: 'FALSE' | 'INDETERMINATE' | 'TRUE',
): boolean | 'INDETERMINATE' => {
  if (!value) return false;
  if (value === 'INDETERMINATE') return 'INDETERMINATE';
  return value === 'TRUE';
};

export const deniedToRouteToEditDashboard: DashboardPermissionCheckFn = ({
  isFeatured,
  isMyDashboard,
  isFeatureReport,
}) => {
  if (isMyDashboard) return false;

  // 기본 대시보드, 기본 리포트 둘 다 설정돼있을 경우 둘 다 권한이 없어야 true
  if (
    !!isFeatured &&
    !!isFeatureReport &&
    !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_EDIT)
      ?.permission &&
    !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_EDIT)
      ?.permission
  ) {
    return true;
  }

  // 기본 대시보드, 기본 리포트 둘 중 하나만 있는 경우
  if (
    !(!!isFeatured && !!isFeatureReport) &&
    ((!!isFeatured &&
      !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_EDIT)
        ?.permission) ||
      (!!isFeatureReport &&
        !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_EDIT)
          ?.permission))
  ) {
    return true;
  }

  // 기본 대시보드, 기본 리포트 둘 다 아닌 경우
  return (
    !isFeatured &&
    !isFeatureReport &&
    !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_EDIT)?.permission
  );
};

export const useDashboardPermission = () => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const store: Store<RootState> = useStore();
  const isMaster = computed(() => {
    const myRole = store.getters['myInfo/getAccountInfo'].role;
    return checkRole.isMaster(myRole);
  });
  const { t } = useInternational();
  const { isPermissionDenied } = useRolePermission();

  const deniedToAddDashboard = () => {
    return isPermissionDenied({
      type: 'permission',
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_EDIT,
    });
  };

  const deniedToAddDashboardList = ({ showError }) => {
    return isPermissionDenied({
      type: 'permission',
      showError,
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_EDIT,
    });
  };

  const deniedToAddGroup = ({ showError }) => {
    return isPermissionDenied({
      type: 'permission',
      showError,
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_GROUP_EDIT,
    });
  };

  const deniedToMoveDashboard = () => {
    return isPermissionDenied({
      type: 'permission',
      showError: false,
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_MOVE,
    });
  };

  const deniedToMoveDashboardList = () => {
    return isPermissionDenied({
      type: 'permission',
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_MOVE,
    });
  };

  const deniedToDeleteDashboard = ({ showError }) => {
    return isPermissionDenied({
      type: 'permission',
      showError,
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_EDIT,
    });
  };

  const deniedToExportDashboard: DashboardPermissionCheckFn = ({
    isFeatured,
    isMyDashboard,
    isFeatureReport,
  }) => {
    if (isMyDashboard) return false;

    // 기본 대시보드, 기본 리포트 둘 다 설정돼있을 경우 둘 다 권한이 없어야 true
    if (
      !!isFeatured &&
      !!isFeatureReport &&
      !findPermissionByTranslateId(
        ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_EXPORT,
      )?.permission &&
      !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_EXPORT)
        ?.permission
    ) {
      return true;
    }

    // 기본 대시보드, 기본 리포트 둘 중 하나만 있는 경우
    if (
      !(!!isFeatured && !!isFeatureReport) &&
      ((!!isFeatured &&
        isPermissionDenied({
          type: 'permission',
          showError: false,
          rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_EXPORT,
        })) ||
        (!!isFeatureReport &&
          isPermissionDenied({
            type: 'permission',
            showError: false,
            rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_EXPORT,
          })))
    ) {
      return true;
    }

    // 기본 대시보드, 기본 리포트 둘 다 아닌 경우
    return (
      !isFeatured &&
      !isFeatureReport &&
      isPermissionDenied({
        type: 'permission',
        showError: false,
        rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_EXPORT,
      })
    );
  };

  const deniedToShareDashboard = ({ showError }) => {
    return isPermissionDenied({
      type: 'permission',
      showError,
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_SHARE,
    });
  };

  const deniedToSetReportDashboard: DashboardPermissionCheckFn = ({ isMyDashboard }) => {
    if (isMaster.value) return false;

    if (isMyDashboard) {
      return isPermissionDenied({
        type: 'permission',
        showError: false,
        rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_SET_REPORT,
      });
    }

    return true;
  };

  const deniedToSetupDashboardByMenu: DashboardPermissionCheckFn = ({
    isFeatured,
    isMyDashboard,
    isFeatureReport,
  }: DashboardPermissionData) => {
    if (isMyDashboard) return false;

    // 기본 대시보드, 기본 리포트 둘 다 설정돼있을 경우 둘 다 권한이 없어야 true
    if (
      !!isFeatured &&
      !!isFeatureReport &&
      !findPermissionByTranslateId(
        ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_SETUP_DASHBOARD_BY_MENU,
      )?.permission &&
      !findPermissionByTranslateId(
        ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_SETUP_DASHBOARD_BY_MENU,
      )?.permission
    ) {
      return true;
    }

    // 기본 대시보드, 기본 리포트 둘 중 하나만 있는 경우
    if (
      !(!!isFeatured && !!isFeatureReport) &&
      ((!!isFeatured &&
        isPermissionDenied({
          type: 'permission',
          showError: false,
          rolePermissionKey:
            ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_SETUP_DASHBOARD_BY_MENU,
        })) ||
        (!!isFeatureReport &&
          isPermissionDenied({
            type: 'permission',
            showError: false,
            rolePermissionKey:
              ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_SETUP_DASHBOARD_BY_MENU,
          })))
    ) {
      return true;
    }

    return (
      !isFeatured &&
      !isFeatureReport &&
      isPermissionDenied({
        type: 'permission',
        showError: false,
        rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_SETUP_DASHBOARD_BY_MENU,
      })
    );
  };

  const deniedToSetupAnalysisBoardByMenu: DashboardPermissionCheckFn = ({
    isFeatured,
    isMyDashboard,
    isFeatureReport,
  }: DashboardPermissionData) => {
    if (isMyDashboard) return false;

    // 기본 대시보드, 기본 리포트 둘 다 설정돼있을 경우 둘 다 권한이 없어야 true
    if (
      !!isFeatured &&
      !!isFeatureReport &&
      !findPermissionByTranslateId(
        ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_ANALYSIS_BOARD_SETUP_DASHBOARD_BY_MENU,
      )?.permission &&
      !findPermissionByTranslateId(
        ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_SETUP_ANALYSIS_BOARD_BY_MENU,
      )?.permission
    ) {
      return true;
    }

    // 기본 대시보드, 기본 리포트 둘 중 하나만 있는 경우
    if (
      !(!!isFeatured && !!isFeatureReport) &&
      ((!!isFeatured &&
        isPermissionDenied({
          type: 'permission',
          showError: false,
          rolePermissionKey:
            ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_ANALYSIS_BOARD_SETUP_DASHBOARD_BY_MENU,
        })) ||
        (!!isFeatureReport &&
          isPermissionDenied({
            type: 'permission',
            showError: false,
            rolePermissionKey:
              ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_SETUP_ANALYSIS_BOARD_BY_MENU,
          })))
    ) {
      return true;
    }

    return (
      !isFeatured &&
      !isFeatureReport &&
      isPermissionDenied({
        type: 'permission',
        showError: false,
        rolePermissionKey:
          ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_SETUP_ANALYSIS_BOARD_BY_MENU,
      })
    );
  };

  const deniedToImportDashboard = () => {
    return isPermissionDenied({
      type: 'permission',
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_IMPORT,
    });
  };

  const deniedToImportViewpack = () => {
    return isPermissionDenied({
      type: 'permission',
      rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_IMPORT_VIEWPACK,
    });
  };

  const deniedToSetAsStartScreen: DashboardPermissionCheckFn = ({
    isFeatured,
    isMyDashboard,
    isFeatureReport,
  }) => {
    if (isMyDashboard) return false;

    // 기본 대시보드, 기본 리포트 둘 다 설정돼있을 경우 둘 다 권한이 없어야 true
    if (
      !!isFeatured &&
      !!isFeatureReport &&
      !findPermissionByTranslateId(
        ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_SET_AS_START_SCREEN,
      )?.permission &&
      !findPermissionByTranslateId(
        ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_SET_AS_START_SCREEN,
      )?.permission
    ) {
      return true;
    }

    return (
      !(!!isFeatured && !!isFeatureReport) &&
      ((!!isFeatured &&
        isPermissionDenied({
          type: 'permission',
          showError: false,
          rolePermissionKey:
            ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_SET_AS_START_SCREEN,
        })) ||
        (!!isFeatureReport &&
          isPermissionDenied({
            type: 'permission',
            showError: false,
            rolePermissionKey:
              ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_SET_AS_START_SCREEN,
          })))
    );
  };

  const deniedToEditDashboard: DashboardPermissionCheckFn = ({
    isFeatured,
    isMyDashboard,
    isFeatureReport,
    showError = true,
  }) => {
    if (isMyDashboard) return false;

    // 기본 대시보드, 기본 리포트 둘 다 설정돼있을 경우 둘 다 권한이 없어야 true
    if (
      !!isFeatured &&
      !!isFeatureReport &&
      !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_EDIT)
        ?.permission &&
      !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_EDIT)
        ?.permission
    ) {
      if (showError) {
        showErrorMsg(ctx, t('MESSAGE.NO_PERMISSION'));
      }
      return true;
    }

    // 기본 대시보드, 기본 리포트 둘 중 하나만 있는 경우
    if (
      !(!!isFeatured && !!isFeatureReport) &&
      ((!!isFeatured &&
        isPermissionDenied({
          type: 'permission',
          showError,
          rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD_EDIT,
        })) ||
        (!!isFeatureReport &&
          isPermissionDenied({
            type: 'permission',
            showError,
            rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT_EDIT,
          })))
    ) {
      return true;
    }

    // 기본 대시보드, 기본 리포트 둘 다 아닌 경우
    return (
      !isFeatured &&
      !isFeatureReport &&
      isPermissionDenied({
        type: 'permission',
        showError,
        rolePermissionKey: ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_LIST_EDIT,
      })
    );
  };

  return {
    deniedToAddDashboard,
    deniedToAddDashboardList,
    deniedToAddGroup,
    deniedToMoveDashboard,
    deniedToMoveDashboardList,
    deniedToDeleteDashboard,
    deniedToExportDashboard,
    deniedToShareDashboard,
    deniedToSetReportDashboard,
    deniedToSetupDashboardByMenu,
    deniedToSetupAnalysisBoardByMenu,
    deniedToImportDashboard,
    deniedToImportViewpack,
    deniedToSetAsStartScreen,
    deniedToEditDashboard,
  };
};

export const deniedDashboard: DashboardPermissionCheckFn = ({
  isMyDashboard,
  isFeatured,
  isFeatureReport,
}) => {
  if (isMyDashboard) return false;

  // 기본 대시보드, 기본 리포트 둘 다 설정돼있을 경우 둘 다 권한이 없어야 true
  if (
    !!isFeatured &&
    !!isFeatureReport &&
    !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT)
      ?.listOnOff &&
    !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD)
      ?.listOnOff
  ) {
    return true;
  }

  return (
    !(!!isFeatured && !!isFeatureReport) &&
    ((!!isFeatured &&
      !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_DASHBOARD)
        ?.listOnOff) ||
      (!!isFeatureReport &&
        !findPermissionByTranslateId(ROLE_PERMISSION_KEY.DASHBOARD.DASHBOARD_FEATURED_REPORT)
          ?.listOnOff))
  );
};
