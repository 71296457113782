import { CustomColumn, ExtendedGridDataType, GridColumnType } from '@/common/utils';
import {
  FilterItem,
  FilterSearchOperator,
  FilterSearchSpecificFilterFnKey,
  FilterSelectedValues,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { isArray, isBoolean, isNumber, isString, isObject } from 'lodash-es';
import {
  AGE_UNITS,
  AgeUnit,
  FILTER_SEARCH_OPERATORS_BY_SPECIFIC_FILTER_FN,
} from '@/common/components/molecules/filterSearch/filterSearch.define';

type Visible = boolean;

export const getOperatorsByType = (
  type: ExtendedGridDataType,
  specificFilterFnKey?: FilterSearchSpecificFilterFnKey,
): FilterSearchOperator[] => {
  const columnType = specificFilterFnKey
    ? FILTER_SEARCH_OPERATORS_BY_SPECIFIC_FILTER_FN[specificFilterFnKey]
    : type;

  switch (columnType) {
    case 'float':
    case 'number':
    case 'stringNumber':
    case 'age': // 삭제 예정
      return ['==', '!=', '>', '>=', '<', '<='];
    case 'boolean':
      return ['==', '!='];
    case 'string':
    default:
      return ['==', '!=', 'like', 'not like'];
  }
};

export const getDefaultUnitBySpecificFilterFnKey = (
  specificFilterFnKey?: FilterSearchSpecificFilterFnKey,
): string => {
  switch (specificFilterFnKey) {
    case 'toBytes':
      return 'byte';
    case 'MBtoBytes':
      return 'MB';
    case 'toMs':
      return 'ms';
    case 'rendererTypeSec':
      return 's';
    case 'attrsSecTrue':
      return 's';
    case 'toBps':
      return 'bit/s';
    default:
      return '';
  }
};

const stringToNumber = (str: string) => {
  const targetString = str.replace(/,/g, '');
  return parseFloat(targetString);
};

const getNormalizeSpacesString = (str: string) => {
  return str.replace(/\s{2,}/g, ' ');
};

const isEqual = (
  cellValue: unknown,
  searchValues: string[],
  type?: ExtendedGridDataType,
): Visible => {
  if (cellValue !== undefined && (isNumber(cellValue) || type === 'number')) {
    const normalizedCellValue = isString(cellValue) ? stringToNumber(cellValue) : cellValue;
    return searchValues.some((value) => normalizedCellValue === stringToNumber(value));
  }

  if (isBoolean(cellValue)) {
    return searchValues.some((value) => cellValue.toString() === value.toLowerCase());
  }

  if (isString(cellValue)) {
    return searchValues.some(
      (value) => getNormalizeSpacesString(cellValue).toLowerCase() === value.toLowerCase(),
    );
  }

  if (isArray(cellValue)) {
    return cellValue?.length ? cellValue?.some((value) => isEqual(value, searchValues)) : false;
  }

  if (isObject(cellValue)) {
    return Object.values(cellValue).some((value) => isEqual(value, searchValues));
  }

  return false;
};

const isNotEqual = (
  cellValue: unknown,
  searchValues: string[],
  type?: ExtendedGridDataType,
): Visible => {
  if (cellValue === null) {
    return true;
  }

  if (cellValue !== undefined && (isNumber(cellValue) || type === 'number')) {
    const normalizedCellValue = isString(cellValue) ? stringToNumber(cellValue) : cellValue;
    return searchValues.every((value) => normalizedCellValue !== stringToNumber(value));
  }

  if (isBoolean(cellValue)) {
    return searchValues.every((value) => cellValue.toString() !== value.toLowerCase());
  }

  if (isString(cellValue)) {
    return searchValues.every(
      (value) => getNormalizeSpacesString(cellValue).toLowerCase() !== value.toLowerCase(),
    );
  }

  if (isArray(cellValue)) {
    return cellValue?.length ? cellValue?.every((value) => isNotEqual(value, searchValues)) : true;
  }

  if (isObject(cellValue)) {
    return !Object.values(cellValue).some((value) => !isNotEqual(value, searchValues));
  }

  return false;
};

const isLike = (cellValue: unknown, searchValue: string): Visible => {
  if (isString(cellValue)) {
    return getNormalizeSpacesString(cellValue).toLowerCase().includes(searchValue.toLowerCase());
  }

  if (isNumber(cellValue)) {
    return `${cellValue}`.includes(searchValue.toLowerCase());
  }

  if (isBoolean(cellValue)) {
    return `${cellValue}`.toLowerCase().includes(searchValue.toLowerCase());
  }

  if (isArray(cellValue)) {
    return cellValue.length ? cellValue.some((value) => isLike(value, searchValue)) : false;
  }

  if (isObject(cellValue)) {
    return Object.values(cellValue).some((value) => isLike(value, searchValue));
  }

  return false;
};

const isNotLike = (cellValue: unknown, searchValue: string): Visible => {
  if (cellValue === null) {
    return true;
  }

  if (isString(cellValue)) {
    return !getNormalizeSpacesString(cellValue).toLowerCase().includes(searchValue.toLowerCase());
  }

  if (isNumber(cellValue)) {
    return !`${cellValue}`.includes(searchValue.toLowerCase());
  }

  if (isBoolean(cellValue)) {
    return !`${cellValue}`.toLowerCase().includes(searchValue.toLowerCase());
  }

  if (isArray(cellValue)) {
    return cellValue.length ? cellValue.every((value) => isNotLike(value, searchValue)) : true;
  }

  if (isObject(cellValue)) {
    return !Object.values(cellValue).some((value) => !isNotLike(value, searchValue));
  }

  return false;
};

const isGreaterThan = (cellValue: unknown, searchValue: string): Visible => {
  if (isNumber(cellValue)) {
    return cellValue > stringToNumber(searchValue);
  }

  if (isString(cellValue)) {
    return stringToNumber(getNormalizeSpacesString(cellValue)) > stringToNumber(searchValue);
  }

  if (isArray(cellValue)) {
    return cellValue.some((value) => isGreaterThan(value, searchValue));
  }

  if (isObject(cellValue)) {
    return Object.values(cellValue).some((value) => isGreaterThan(value, searchValue));
  }

  return false;
};

const isGreaterThanOrEqual = (cellValue: unknown, searchValue: string): Visible => {
  if (isNumber(cellValue)) {
    return cellValue >= stringToNumber(searchValue);
  }

  if (isString(cellValue)) {
    return stringToNumber(getNormalizeSpacesString(cellValue)) >= stringToNumber(searchValue);
  }

  if (isArray(cellValue)) {
    return cellValue.some((value) => isGreaterThanOrEqual(value, searchValue));
  }

  if (isObject(cellValue)) {
    return Object.values(cellValue).some((value) => isGreaterThanOrEqual(value, searchValue));
  }

  return false;
};

const isLessThan = (cellValue: unknown, searchValue: string): Visible => {
  if (isNumber(cellValue)) {
    return cellValue < stringToNumber(searchValue);
  }

  if (isString(cellValue)) {
    return stringToNumber(getNormalizeSpacesString(cellValue)) < stringToNumber(searchValue);
  }

  if (isArray(cellValue)) {
    return cellValue.some((value) => isLessThan(value, searchValue));
  }

  if (isObject(cellValue)) {
    return Object.values(cellValue).some((value) => isLessThan(value, searchValue));
  }

  return false;
};

const isLessThanOrEqual = (cellValue: unknown, searchValue: string): Visible => {
  if (isNumber(cellValue)) {
    return cellValue <= stringToNumber(searchValue);
  }

  if (isString(cellValue)) {
    return stringToNumber(getNormalizeSpacesString(cellValue)) <= stringToNumber(searchValue);
  }

  if (isArray(cellValue)) {
    return cellValue.some((value) => isLessThanOrEqual(value, searchValue));
  }

  if (isObject(cellValue)) {
    return Object.values(cellValue).some((value) => isLessThanOrEqual(value, searchValue));
  }

  return false;
};

export const normalizeAgeToSeconds = (ageStr: string): number => {
  const pattern = /(\d+)([smhdMy]?)/g;
  let totalSeconds = 0;

  const matches = Array.from(ageStr.matchAll(pattern));

  matches.forEach((match) => {
    const [, value, unit] = match;
    const normalizedUnit = unit || 's';
    totalSeconds += parseInt(value, 10) * AGE_UNITS[normalizedUnit as AgeUnit];
  });

  return totalSeconds;
};

export const compareNumberValues = ({
  cellValue,
  searchValue,
  operator,
}: {
  cellValue: string | number | undefined;
  searchValue: string | number | undefined;
  operator: string;
}): Visible => {
  if (cellValue === undefined || searchValue === undefined) {
    return false;
  }

  let compareA: number;
  if (typeof cellValue === 'string') {
    const convertedValueNumber = stringToNumber(cellValue);
    if (Number.isNaN(convertedValueNumber)) {
      return false;
    }
    compareA = convertedValueNumber;
  } else {
    compareA = cellValue;
  }

  const compareB: number =
    typeof searchValue === 'string' ? stringToNumber(searchValue) : searchValue;

  switch (operator) {
    case '==':
      return compareA === compareB;
    case '!=':
      return compareA !== compareB;
    case '>':
      return compareA > compareB;
    case '>=':
      return compareA >= compareB;
    case '<':
      return compareA < compareB;
    case '<=':
      return compareA <= compareB;
    default:
      return false;
  }
};

export const filterFunctionByOperator = ({
  targetValue,
  operator,
  searchValues,
  type,
}: {
  targetValue: string;
  operator: string;
  searchValues: string[];
  type?: ExtendedGridDataType;
}): Visible => {
  if (type === 'age') {
    const cellValue = normalizeAgeToSeconds(targetValue);
    const searchValue = normalizeAgeToSeconds(searchValues[0]);
    return compareNumberValues({ cellValue, searchValue, operator });
  }

  switch (operator) {
    case '==':
      return isEqual(targetValue, searchValues, type);
    case '!=':
      return isNotEqual(targetValue, searchValues, type);
    case 'like':
      return isLike(targetValue, searchValues[0]);
    case 'not like':
      return isNotLike(targetValue, searchValues[0]);
    case '>':
      return isGreaterThan(targetValue, searchValues[0]);
    case '>=':
      return isGreaterThanOrEqual(targetValue, searchValues[0]);
    case '<':
      return isLessThan(targetValue, searchValues[0]);
    case '<=':
      return isLessThanOrEqual(targetValue, searchValues[0]);
    default:
      return false;
  }
};

export const getFilterItemsByGridColumns = (
  columns: CustomColumn[] | Readonly<CustomColumn[]>,
  additional?: FilterItem[],
): FilterItem[] => {
  const filterItems: FilterItem[] = columns
    .filter(({ searchable }) => searchable)
    .map(({ caption, field, type, specificFilterFnKey }) => {
      return {
        key: {
          id: field,
          name: caption,
        },
        operators: getOperatorsByType(type as GridColumnType, specificFilterFnKey),
        values: {
          multi: type === 'boolean',
          items:
            type === 'boolean'
              ? [
                  { id: 'true', name: 'True' },
                  { id: 'false', name: 'False' },
                ]
              : [],
        },
        defaultUnit: getDefaultUnitBySpecificFilterFnKey(specificFilterFnKey),
      };
    });

  if (additional) {
    additional.forEach((addItem) => {
      const targetIndex = filterItems.findIndex((item) => item.key.id === addItem.key.id);
      if (targetIndex === -1) {
        filterItems.push(addItem);
      } else {
        filterItems[targetIndex] = addItem;
      }
    });
  }

  return filterItems;
};

// 24.11.05 삭제 예정입니다. 사용하지 마세요. env.define에서 key 정의해서 쓰세요
export const getFormattedFilterSearchStorageKey = (
  componentName: string,
  index?: number,
): string => {
  return `filterSearch_${window.location.pathname}_${componentName}_${index ?? ''}`;
};

export const operatorMap = {
  '==': 'is',
  '!=': 'is not',
  like: 'like',
  'not like': 'not like',
  '>': 'above',
  '>=': 'more',
  '<': 'below',
  '<=': 'less',
};

export const transformOperator = (data: FilterSelectedValues) => {
  return {
    logicalOperator: data.logicalOperator,
    tokens: data.tokens.map(({ key, operator, value }) => {
      return {
        key,
        operator: operatorMap[operator],
        value,
      };
    }),
  };
};
