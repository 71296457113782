export const STATUS = {
  CLOUD_WATCH_ALARM: {
    BLUE: ['OK'],
    RED: ['ALARM'],
  },
  EC2: {
    BLUE: ['RUNNING'],
    YELLOW: ['PENDING', 'STOPPING', 'SHUTTING_DOWN'],
    RED: ['STOPPED', 'TERMINATED'],
  },
  DOCUMENTDB: {
    CLUSTER: {
      BLUE: ['ACTIVE'],
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'BACKING_UP',
        'CREATING',
        'DELETING',
        'FAILING_OVER',
        'MAINTENANCE',
        'MIGRATING',
        'MIGRATION_FAILED',
        'MODIFYING',
        'RENAMING',
        'RESETTING_MASTER_CREDENTIALS',
        'UPGRADING',
      ],
      RED: ['INACCESSIBLE_ENCRYPTION_CREDENTIALS', 'STOPPED'],
    },
    INSTANCE: {
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'BACKING_UP',
        'CONFIGURING_LOG_EXPORTS',
        'CREATING',
        'DELETING',
        'MAINTENANCE',
        'MODIFYING',
        'REBOOTING',
        'RENAMING',
        'RESETTING_MASTER_CREDENTIALS',
        'STARTING',
        'STOPPING',
      ],
      RED: [
        'FAILED',
        'INCOMPATIBLE_NETWORK',
        'INACCESSIBLE_ENCRYPTION_CREDENTIALS',
        'RESTORE_ERROR',
        'STOPPED',
        'STORAGE_FULL',
      ],
    },
  },
  DYNAMODB: {
    BLUE: ['ACTIVE'],
    YELLOW: ['CREATING', 'UPDATING', 'DELETING', 'ARCHIVING', 'ARCHIVED'],
    RED: ['INACCESSIBLE_ENCRYPTION_CREDENTIALS'],
  },
  REDSHIFT: {
    GREEN: ['AVAILABLE'],
    YELLOW: [
      'AVAILABLE_PREP_FOR_RESIZE',
      'AVAILABLE_RESIZE_CLEANUP',
      'CANCELLING_RESIZE',
      'CREATING',
      'DELETING',
      'FINAL_SNAPSHOT',
      'MODIFYING',
      'REBOOTING',
      'RENAMING',
      'RESIZING',
      'ROTATING_KEYS',
      'UPDATING_HSM',
    ],
    RED: [
      'HARDWARE_FAILURE',
      'INCOMPATIBLE_HSM',
      'INCOMPATIBLE_NETWORK',
      'INCOMPATIBLE_PARAMETERS',
      'INCOMPATIBLE_RESTORE',
      'PAUSED',
      'STORAGE_FULL',
    ],
  },
  ELASTICACHE: {
    CLUSTER: {
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'CREATING',
        'DELETING',
        'MODIFYING',
        'REBOOTING_CLUSTER_NODES',
        'RESTORE_FAILED',
        'SNAPSHOTTING',
      ],
      RED: ['DELETED', 'INCOMPATIBLE_NETWORK', 'RESTORE_FAILED'],
    },
    SHARD: {
      GREEN: ['AVAILABLE'],
      YELLOW: ['CREATING', 'MODIFYING', 'DELETING'],
    },
    NODE: {
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'CREATING',
        'DELETING',
        'MODIFYING',
        'UPDATING',
        'REBOOTING_CACHE_CLUSTER_NODES',
        'SNAPSHOTTING',
      ],
      RED: ['RESTORE_FAILED', 'INCOMPATIBLE_NETWORK', 'INCOMPATIBLE_PARAMETERS'],
    },
    SERVERLESS: {
      GREEN: ['AVAILABLE'],
      YELLOW: ['CREATING', 'DELETING', 'MODIFYING'],
      RED: ['CREATE_FAILED'],
    },
  },
  DMS: {
    TASK: {
      GREEN: ['READY'],
      BLUE: ['RUNNING', 'LOAD_RUNNING', 'LOAD_COMPLETE_REPLICATION_ONGOING', 'LOAD_COMPLETE'],
      YELLOW: ['CREATING', 'STARTING', 'STOPPING', 'RUNNING_WITH_ERRORS'],
      RED: ['STOPPED', 'FAILED', 'ERRORS'],
    },
    REPLINSTANCE: {
      GREEN: ['AVAILABLE'],
      YELLOW: [
        'CREATING',
        'DELETING',
        'MODIFYING',
        'UPGRADING',
        'REBOOTING',
        'RESETTING_MASTER_CREDENTIALS',
        'MAINTENANCE',
      ],
      RED: [
        'DELETED',
        'FAILED',
        'STORAGE_FULL',
        'INCOMPATIBLE_CREDENTIALS',
        'INCOMPATIBLE_NETWORK',
      ],
    },
  },
};
