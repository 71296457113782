import { isNil } from 'lodash-es';
import { CustomColumn, DynamicGridOption } from '@/common/utils';

export const getDynamicColumnFields = ({
  columns,
  baseGridColumns,
  option,
}: {
  columns: CustomColumn[];
  baseGridColumns: CustomColumn[];
  option?: DynamicGridOption;
}) => {
  const { excludeFields = [], includeFields = [] } = option || {};

  const excludedFields = new Set(excludeFields);
  const baseFields = new Set(baseGridColumns.map(({ field }) => field).concat(includeFields));

  return columns
    .filter(({ field }) => !excludedFields.has(field) && !baseFields.has(field))
    .map(({ field }) => field);
};

export const getDynamicGridData = ({
  row,
  columns,
  baseGridColumns,
  option,
}: {
  row: any;
  columns: CustomColumn[];
  baseGridColumns: CustomColumn[];
  option?: DynamicGridOption;
}) => {
  if (!row) return {};

  const dynamicColumnFields = getDynamicColumnFields({ columns, baseGridColumns, option });

  return dynamicColumnFields.reduce(
    (acc, key) => {
      acc[key] = row[key];
      return acc;
    },
    {} as Record<string, any>,
  );
};

export const applyUserTags = ({ row, dynamicDataKey }: { row: any; dynamicDataKey: string[] }) => {
  row.userTags?.forEach(({ tagName, tagValues }) => {
    row[tagName] = tagValues;
  });

  dynamicDataKey.forEach((key) => {
    if (isNil(row[key])) {
      row[key] = null;
    }
  });
};
