import { type Module } from 'vuex';
import { type RootState } from '@/common/store';
import type { InstanceInfoReq } from '@/common/api/reqTypes/instanceReq';
import type { ViewType } from '@/common/utils/types';
import {
  getV2FoldersAxios,
  getV2FoldersByFolderIdAxios,
} from '@/openapi/meta/api/folder-controller-api';
import type { FolderInstanceItem } from '@/openapi/meta/model';

export interface BaseFolderInfo {
  folderId?: string;
  name: string;
}
export interface FolderInfo extends BaseFolderInfo {
  description: string;
  instances?: InstanceInfoReq[];
}
interface State {
  isShowWindow: boolean;
  initViewType: ViewType;
  selectedFolderId: string;
  detailedFolderId: string;
  folders: FolderInstanceItem[];
  folderInfo: FolderInstanceItem;
}

export const DEFAULT_FOLDER_INFO = {
  folderId: '',
  name: '',
  description: '',
  instances: [],
};

export const monitoringFolder: Module<State, RootState> = {
  namespaced: true,
  state: {
    isShowWindow: false,
    initViewType: 'view',
    selectedFolderId: '',
    detailedFolderId: '',
    folders: [],
    folderInfo: structuredClone(DEFAULT_FOLDER_INFO),
  },
  mutations: {
    setIsShowWindow: (state: State, isShow: boolean) => {
      state.isShowWindow = isShow;
    },
    setInitViewType: (state: State, viewType: ViewType) => {
      state.initViewType = viewType;
    },
    setSelectedFolderId: (state: State, folderId: string) => {
      state.selectedFolderId = folderId;
    },
    setDetailedFolderId: (state: State, folderId: string) => {
      state.detailedFolderId = folderId;
    },
    setFolders: (state: State, list: FolderInstanceItem[]) => {
      state.folders = list;
    },
    setFolderInfo: (state: State, info: FolderInstanceItem) => {
      state.folderInfo = info;
    },
    initSelectedFolderId: (state: State) => {
      state.selectedFolderId = '';
    },
    initDetailedFolderId: (state: State) => {
      state.detailedFolderId = '';
    },
    initFolderInfo: (state: State) => {
      state.folderInfo = {
        folderId: '',
        name: '',
        description: '',
        instances: [],
      };
    },
    initFolders: (state: State) => {
      state.folders.length = 0;
    },
  },
  actions: {
    dispatchFolders: async ({ commit }): Promise<void> => {
      const { data } = await getV2FoldersAxios();
      commit('setFolders', data.data ?? []);
    },
    dispatchFolderInfo: async ({ commit }, folderId: string): Promise<void> => {
      const { data } = await getV2FoldersByFolderIdAxios({ folderId });
      commit('setFolderInfo', data?.data?.[0] ?? {});
    },
  },
  getters: {
    getIsShowWindow: (state: State) => state.isShowWindow,
    getInitViewType: (state: State) => state.initViewType,
    getSelectedFolderId: (state: State) => state.selectedFolderId,
    getDetailedFolderId: (state: State) => state.detailedFolderId,
    getFolders: (state: State) => state.folders,
    getFolderInfo: (state: State) => state.folderInfo,
    getFolderById: (_, getters) => (folderId: string) =>
      getters.getFolders.find((folder) => folder.folderId === folderId),
  },
};
