import { COMMON_FRAME_NAMES } from './apiTraceKeys/common.keys.define';
import { CONFIG_ACCOUNT_FRAME_NAMES } from './apiTraceKeys/configView/account.keys.define';
import { CONFIG_ALERT_FRAME_NAMES } from './apiTraceKeys/configView/alert.keys.define';
import { CONFIG_MANAGEMENT_FRAME_NAMES } from './apiTraceKeys/configView/management.keys.define';
import { CONFIG_MONITORING_FRAME_NAMES } from './apiTraceKeys/configView/monitoring.keys.define';
import { CONFIG_PLATFORM_FRAME_NAMES } from './apiTraceKeys/configView/platform.keys.define';
import { ALERT_FRAME_NAMES } from './apiTraceKeys/mainView/alert.keys.define';
import { APPLICATION_FRAME_NAMES } from './apiTraceKeys/mainView/application.keys.define';
import { BUSINESS_FRAME_NAMES } from './apiTraceKeys/mainView/business.keys.define';
import { CLOUD_FRAME_NAMES } from './apiTraceKeys/mainView/cloud.keys.define';
import { DASHBOARD_FRAME_NAMES } from './apiTraceKeys/mainView/dashboard.keys.define';
import { DATABASE_FRAME_NAMES } from './apiTraceKeys/mainView/database.keys.define';
import { INFRA_FRAME_NAMES } from './apiTraceKeys/mainView/infrastructure.keys.define';
import { MESSAGE_QUEUE_FRAME_NAMES } from './apiTraceKeys/mainView/messageQueue.keys.define';
import { K8S_FRAME_NAMES } from './apiTraceKeys/mainView/kubernetes.keys.define';
import { LOG_FRAME_NAMES } from './apiTraceKeys/mainView/logs.keys.define';
import { PA_FRAME_NAMES } from './apiTraceKeys/mainView/performanceAnalysis.keys.define';
import { REPORT_FRAME_NAMES } from './apiTraceKeys/mainView/report.keys.define';
import { HOME_FRAME_NAMES } from './apiTraceKeys/mainView/home.keys.define';

export const MESSAGE_ACTION = {
  GET_API_INFO: 'GET_API_INFO',
  INIT: 'INIT',
  SET_TARGET_FRAME_INFO: 'SET_TARGET_FRAME_INFO',
} as const;

export const FRAME_NAMES = {
  ...COMMON_FRAME_NAMES,

  // Main
  ...DASHBOARD_FRAME_NAMES,
  ...INFRA_FRAME_NAMES,
  ...MESSAGE_QUEUE_FRAME_NAMES,
  ...CLOUD_FRAME_NAMES,
  ...K8S_FRAME_NAMES,
  ...APPLICATION_FRAME_NAMES,
  ...DATABASE_FRAME_NAMES,
  ...BUSINESS_FRAME_NAMES,
  ...LOG_FRAME_NAMES,
  ...ALERT_FRAME_NAMES,
  ...PA_FRAME_NAMES,
  ...REPORT_FRAME_NAMES,
  ...HOME_FRAME_NAMES,

  // Config
  ...CONFIG_ACCOUNT_FRAME_NAMES,
  ...CONFIG_PLATFORM_FRAME_NAMES,
  ...CONFIG_MONITORING_FRAME_NAMES,
  ...CONFIG_ALERT_FRAME_NAMES,
  ...CONFIG_MANAGEMENT_FRAME_NAMES,
} as const;
