<script lang="ts" setup>
import StatusTag from '@/common/components/molecules/statusTag/StatusTag.vue';
import AlertTargetList from '@/alert/components/alertTargetList/AlertTargetList.vue';
import { Props, setup } from '@/alert/components/alertRuleCard/alertRuleCard.setup';
import { ALERT_TYPE_VALUE } from '@/alert/utils/define';
import TagItem from '@/common/components/molecules/tag/TagItem.vue';
import { isArray } from 'lodash-es';
import BaseChipsCellWithTooltip from '@/common/components/molecules/baseChipsCellWithTooltip/BaseChipsCellWithTooltip.vue';
import BadgeItem from '@/common/components/molecules/badge/BadgeItem.vue';

const props = defineProps<Props>();
const { info, isCheckByCombination, statInfo, getTargetList, getEventTypeRow } = setup(props);
</script>

<template>
  <div :class="['alert-rule-card', props.userAlertType.toLowerCase()]">
    <template v-if="props.userAlertType === ALERT_TYPE_VALUE.METRIC">
      <dl class="alert-rule-row">
        <dt class="alert-rule-row__label">{{ info.name.label }}</dt>
        <dd class="alert-rule-row__value">{{ statInfo.name }}</dd>

        <template v-if="info.processName">
          <dt class="alert-rule-row__label">{{ info.processName.label }}</dt>
          <dd class="alert-rule-row__value">{{ info.processName.value }}</dd>
        </template>

        <template v-if="isCheckByCombination">
          <dt class="alert-rule-row__label">{{ info.aggregation.label }}</dt>
          <dd class="alert-rule-row__value">{{ info.aggregation.value }}</dd>
        </template>
      </dl>

      <dl class="alert-rule-row">
        <dd class="alert-rule-row__value evaluate-value">
          {{ info.evaluateValue.value[0] }}
          <template v-if="info.evaluateValue.value[2]">
            <span class="dim">{{ info.evaluateValue.value[1] }}</span>
            {{ info.evaluateValue.value[2] }}
          </template>
        </dd>

        <dd class="alert-rule-row__value threshold">
          <div class="threshold__item comparison">
            {{ info.comparison.value }}
          </div>
          <div class="threshold__item value">
            <status-tag :type="info.warning.label">
              {{ info.warning.label }}
            </status-tag>
            {{ info.warning.value }}
          </div>
          <div class="threshold__item value">
            <status-tag :type="info.critical.label">
              {{ info.critical.label }}
            </status-tag>
            {{ info.critical.value }}
          </div>
          <div class="threshold__item unit">
            {{ statInfo.unit }}
          </div>
        </dd>
      </dl>

      <dl class="alert-rule-row tag">
        <dt class="alert-rule-row__label">
          {{ info.tags.label }}
        </dt>
        <dd class="alert-rule-row__value">
          <alert-target-list
            v-if="Array.isArray(info.tags.value) && info.tags.value?.length"
            :target-list="getTargetList(info.tags.value)"
            :is-expand="!isCheckByCombination"
          />
        </dd>
      </dl>

      <dl class="alert-rule-row target">
        <dt class="alert-rule-row__label">
          {{ info.targets.label }}
        </dt>
        <dd class="alert-rule-row__value">
          <alert-target-list
            v-if="Array.isArray(info.targets.value) && info.targets.value?.length"
            :target-list="getTargetList(info.targets.value)"
            :is-expand="!isCheckByCombination"
            is-clickable
          />
        </dd>
      </dl>
    </template>

    <template v-else-if="userAlertType === ALERT_TYPE_VALUE.EVENT">
      <dl
        v-for="(item, key, idx) in getEventTypeRow(info)"
        :key="key"
        class="alert-rule-row tag-list"
      >
        <slot :name="key">
          <dt class="alert-rule-row__label">
            {{ item.label?.text || item.label }}
            <badge-item
              v-if="item.label?.badge"
              size="xsmall"
              html-type="span"
              >{{ item.label.badge }}</badge-item
            >
          </dt>
          <dd class="alert-rule-row__value">
            <status-tag
              v-if="key === 'level'"
              :type="`${info.level.value}`"
            >
              {{ info.level.value }}
            </status-tag>
            <template v-else-if="key === 'messageText'">
              {{ info.messageText.value }}
            </template>
            <base-chips-cell-with-tooltip
              v-else-if="item.type === 'multi-chip-cell' && isArray(item.value)"
              :chips="item.value as string[]"
              :row-index="idx"
              :use-wheel-event="false"
            />
            <tag-item
              v-for="(name, nameIdx) in item.value"
              v-else
              :key="`${name}${nameIdx}`"
              :title="name"
              size="xsmall"
              html-type="span"
            >
              {{ name }}
            </tag-item>
          </dd>
        </slot>
      </dl>

      <dl
        v-if="info.tags"
        class="alert-rule-row tag"
      >
        <dt class="alert-rule-row__label">
          {{ info.tags.label }}
        </dt>
        <dd class="alert-rule-row__value">
          <alert-target-list
            v-if="Array.isArray(info.tags.value) && info.tags.value?.length"
            :target-list="getTargetList(info.tags.value)"
            :is-expand="!isCheckByCombination"
          />
        </dd>
      </dl>

      <dl
        v-if="info.targets"
        class="alert-rule-row target"
      >
        <dt class="alert-rule-row__label">
          {{ info.targets.label }}
        </dt>
        <dd class="alert-rule-row__value">
          <alert-target-list
            v-if="Array.isArray(info.targets.value) && info.targets.value?.length"
            :target-list="getTargetList(info.targets.value)"
            :is-expand="!isCheckByCombination"
          />
        </dd>
      </dl>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.alert-rule-card {
  padding: 0 12px;
  border: 1px solid var(--alert-rule-card-border-color);
  border-radius: 2px;
  background: var(--alert-rule-card-bg-color);
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  .alert-rule-row {
    &__label {
      :deep(.badge-item) {
        margin-left: 4px;
      }
    }
    &__value {
      &.evaluate-value {
        padding-right: 12px;
        margin: 0;
        &::after {
          display: none;
        }
      }
      .dim {
        padding: 0 12px;
      }
      &.threshold {
        display: flex;
        gap: 12px;
        align-items: center;
      }
    }
    .threshold__item {
      &.comparison {
        font-weight: 700;
      }
      &.value {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: flex-start;
        text-align: center;
      }
      &.unit {
        text-align: center;
      }
    }
    &.tag-list {
      .alert-rule-row__value {
        width: 100%;
      }
      :deep(.tag-item) {
        max-width: unset !important;
        margin: 4px 4px 4px 0;
      }
    }
    &.target,
    &.tag {
      align-items: start;
      .alert-rule-row__label {
        min-width: 96px;
        margin: 5px 8px 4px 0;
      }
      .alert-rule-row__value {
        flex: 1;
        margin: 0;
      }
    }
  }
}
</style>
