import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { convertCamelToCapitalStart } from '@/dashboard/utils/dashboardUtils';
import { ASTER_KEY } from '@/dashboard/utils/define';
import {
  getTargetIdsListByTagTagControllerAxios,
  getTargetKeyValueTagControllerAxios,
} from '@/openapi/metaV6/api/tag-controller-api';
import { TagsRequest } from '@/openapi/metaV6/model';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { SERVER_TAG_SEPARATOR } from '@/common/define/common.define';
import { TargetTagKind } from '../utils';

const DEFAULT_TAG = 'default_tag';

export const useTargetTagStore = defineStore('targetTagStore', () => {
  const TAG_MAP_KEY_CONNECTIVE = '//';
  const tagMap = ref<Map<string, string>>(new Map());
  const tagKindMap = ref<Map<string, TargetTagKind>>(new Map());

  const getTagKind = (tagKey: string): TargetTagKind =>
    (tagKindMap.value.get(tagKey) as TargetTagKind) || DEFAULT_TAG;

  const getTagMapKey = ({
    id = '',
    category = '',
    tagKind = DEFAULT_TAG,
  }: {
    id?: string;
    category?: string;
    tagKind?: TargetTagKind;
  }) =>
    `${id}${TAG_MAP_KEY_CONNECTIVE}${category}${TAG_MAP_KEY_CONNECTIVE}${
      tagKind === DEFAULT_TAG ? getTagKind(category) : tagKind
    }`;

  const getCategoryName = (category: string) =>
    convertCamelToCapitalStart(category?.replace(SERVER_TAG_SEPARATOR, '') ?? '');

  const getTagMapValue = ({
    id = '',
    category = '',
    tagKind = DEFAULT_TAG,
  }: {
    id?: string;
    category?: string;
    tagKind?: TargetTagKind;
  }) =>
    id === ASTER_KEY
      ? `${getCategoryName(category)}:${ASTER_KEY}`
      : tagMap.value.get(getTagMapKey({ id, category, tagKind })) ?? '';

  const setTagMapValue = ({
    id = '',
    category = '',
    name = '',
    tagKind = DEFAULT_TAG,
  }: {
    id?: string;
    category?: string;
    name?: string;
    tagKind?: TargetTagKind;
  }) => {
    tagMap.value.set(
      getTagMapKey({ id, category, tagKind }),
      `${getCategoryName(category)}:${name}`,
    );
  };

  const setTagKindMap = ({
    category = '',
    tagKind = DEFAULT_TAG,
  }: {
    category?: string;
    tagKind?: TargetTagKind | string;
  }) => tagKindMap.value.set(category, tagKind as TargetTagKind);

  const fetchData = async (requests: TagsRequest[]) => {
    try {
      const { data } = await getTargetIdsListByTagTagControllerAxios({
        requests,
        frameName: FRAME_NAMES.TAG.TAG_ID_LIST,
      });
      if (data?.data?.length) {
        tagMap.value.clear();
        tagKindMap.value.clear();

        data.data.forEach((item) => {
          setTagKindMap({ category: item.category!, tagKind: item.tagKind! });
          setTagMapValue({
            id: item.id,
            category: item.category,
            name: item.name,
            tagKind: item.tagKind === 'user_tag' ? 'user_tag' : DEFAULT_TAG,
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDataByCategories = async (categories: string[]) => {
    try {
      const { data } = await getTargetKeyValueTagControllerAxios({
        tagKind: 'tag',
        frameName: FRAME_NAMES.TAG.TARGET_TAGS_CATEGORY_BY_VALUE(categories.join(',')),
        categories,
      });
      if (data?.data?.length) {
        tagMap.value.clear();
        tagKindMap.value.clear();

        data.data.forEach((item) => {
          const category = item.tagKey;
          setTagKindMap({ category, tagKind: item.tagKind! });
          item.tagValue?.forEach((v) => {
            setTagMapValue({
              id: v.id,
              category,
              name: v.name,
              tagKind: item.tagKind === 'user_tag' ? 'user_tag' : DEFAULT_TAG,
            });
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAllData = async (frameName?: string) => {
    try {
      const { data } = await getTargetKeyValueTagControllerAxios({
        tagKind: 'tag',
        frameName: frameName ?? FRAME_NAMES.TAG.TARGET_TAGS,
      });
      if (data?.data?.length) {
        tagMap.value.clear();
        tagKindMap.value.clear();

        data.data.forEach((item) => {
          const category = item.tagKey;
          setTagKindMap({ category, tagKind: item.tagKind! });
          item.tagValue?.forEach((v) => {
            setTagMapValue({
              id: v.id,
              category,
              name: v.name,
              tagKind: item.tagKind === 'user_tag' ? 'user_tag' : DEFAULT_TAG,
            });
          });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    TAG_MAP_KEY_CONNECTIVE,
    tagMap,
    tagKindMap,
    fetchData,
    fetchAllData,
    fetchDataByCategories,
    getTagMapValue,
  };
});
