import { Module } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { RootState } from '@/common/store';
import { getAPIErrorStatusText, getUtcOffset } from '@/common/utils/commonUtils';
import type { Query, SlowQueryListItemV7 } from '@/openapi/postgresqlV7/model';
import { getSlowqueryPostgresqlV7ControllerAxios } from '@/openapi/postgresqlV7/api/postgresql-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface State {
  slowQuery: SlowQueryListItemV7[];
  queryTime: Query;
  errorStatusText: string;
}

export const slowQuery: Module<State, RootState> = {
  namespaced: true,
  state: {
    slowQuery: [],
    queryTime: {
      fromTime: '',
      toTime: '',
    },
    errorStatusText: '',
  },
  mutations: {
    setSlowQuery: (state: State, list: SlowQueryListItemV7[]) => {
      state.slowQuery = list;
    },
    setSlowQueryQueryTime: (state: State, { fromTime, toTime }: Query) => {
      state.queryTime.fromTime = fromTime;
      state.queryTime.toTime = toTime;
    },
    setErrorStatusText: (state: State, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchSlowQuery({ commit }, instanceId) {
      const frameName = FRAME_NAMES.POSTGRESQL_SINGLE_VIEW.SLOW_QUERY;
      try {
        const { data } = await getSlowqueryPostgresqlV7ControllerAxios({
          instanceId,
          frameName,
        });
        commit('setSlowQuery', data.data);
        commit('setSlowQueryQueryTime', data.query);
        commit('setErrorStatusText', '');
        commit('postgresqlSingleViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit(
          'postgresqlSingleViewEnv/setFramesByFailedApi',
          { frameName, statusText },
          { root: true },
        );
      }
    },
  },
  getters: {
    getSlowQuery: (state: State) => state.slowQuery,
    getElapsed: (state: State) => state.slowQuery.map((v) => v.elapsedTime),
    getLabels: (state: State) =>
      state.slowQuery.map((v): number => {
        const utcOffsetMin = getUtcOffset();
        return +dayjs(v.collectTime).add(utcOffsetMin, 'm');
      }),
    getSlowQueryQueryTime: (state: State) => state.queryTime,
    getErrorStatusText: (state: State) => state.errorStatusText,
  },
};
