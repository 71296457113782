import { type Module } from 'vuex';
import { type RootState } from '@/common/store';
import { DB_TYPE } from '@/common/utils/define';
import { getInstanceByDbType } from '@/common/utils/commonUtils';
import type { FolderInstanceItem } from '@/openapi/meta/model';

export interface State {
  selectedFolderId: string | null;
  selectedInstanceId: string | null;
  selectedInstanceName: string;
  selectedInstanceType: string;
  selectedFromTime: string;
  selectedToTime: string;
  selectedFromUtc0Time: string;
  selectedToUtc0Time: string;
}

export const oraclePaCondition: Module<State, RootState> = {
  namespaced: true,
  state: {
    selectedFolderId: null,
    selectedInstanceId: null,
    selectedInstanceName: '',
    selectedInstanceType: '',
    selectedFromTime: '',
    selectedToTime: '',
    selectedFromUtc0Time: '',
    selectedToUtc0Time: '',
  },
  mutations: {
    setSelectedFolderId: (state: State, folderId: string | null) => {
      state.selectedFolderId = folderId;
    },
    setSelectedInstanceId: (state: State, instanceId: string) => {
      state.selectedInstanceId = instanceId;
    },
    setSelectedInstanceName: (state: State, instanceName: string) => {
      state.selectedInstanceName = instanceName;
    },
    setSelectedInstanceType: (state: State, instanceType: string) => {
      state.selectedInstanceType = instanceType;
    },
    setSelectedFromTime: (state: State, date: string) => {
      state.selectedFromTime = date;
    },
    setSelectedToTime: (state: State, date: string) => {
      state.selectedToTime = date;
    },
    setSelectedFromUtc0Time: (state: State, date: string) => {
      state.selectedFromUtc0Time = date;
    },
    setSelectedToUtc0Time: (state: State, date: string) => {
      state.selectedToUtc0Time = date;
    },
    initSelectedInfo: (state: State) => {
      state.selectedFolderId = null;
      state.selectedInstanceId = null;
      state.selectedInstanceName = '';
      state.selectedInstanceType = '';
      state.selectedFromTime = '';
      state.selectedToTime = '';
      state.selectedFromUtc0Time = '';
      state.selectedToUtc0Time = '';
    },
  },
  getters: {
    getSelectedFolderId: (state: State): string | null => state.selectedFolderId,
    getSelectedInstanceId: (state: State): string | null => state.selectedInstanceId,
    getSelectedInstanceName: (state: State): string => state.selectedInstanceName,
    getSelectedInstanceType: (state: State): string => state.selectedInstanceType,
    getSelectedFromTime: (state: State): string => state.selectedFromTime,
    getSelectedToTime: (state: State): string => state.selectedToTime,
    getSelectedFromUtc0Time: (state: State): string => state.selectedFromUtc0Time,
    getSelectedToUtc0Time: (state: State): string => state.selectedToUtc0Time,

    // 전체 정보 조회 (폴더API에서 DBType을 설정하는게 보류되어 우선 클라이언트에서 Oracle 필터링 로직 추가)
    getAllFolders: (_, __, ___, rootGetters) =>
      rootGetters['monitoringFolder/getFolders'].filter(
        (folder: FolderInstanceItem) =>
          !!folder.instances?.length && folder.instances.some(getInstanceByDbType(DB_TYPE.ORACLE)),
      ),
    getAllInstances: (_, __, ___, rootGetters) =>
      rootGetters['monitoringInstance/getInstances'].filter(getInstanceByDbType(DB_TYPE.ORACLE)),
  },
};
