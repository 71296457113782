/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
import { ChartDataStat } from '@/common/components/organisms/statIndicatorSelect/statIndicatorSelect.types';
import { StatDataType } from '@/common/components/organisms/statWindow';
import { i18n } from '@/common/locale';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { UUID, WidgetChartDataStatusInfo } from '@/common/utils/types';
import { WidgetChartData } from '@/dashboard/utils/types';
import { TableRawData } from '@/worker/commands/dashboard/table';
import { isNil } from 'lodash-es';
import { CurrentRawData } from '@/worker/commands/dashboard/current';

export abstract class ChartDataStatus {
  protected seriesCountLimit: number | undefined = undefined;

  protected dataType: StatDataType;

  protected chartDataStatus: WidgetChartDataStatusInfo[] = [];

  protected constructor(seriesCountLimit: number | undefined, dataType: StatDataType) {
    this.seriesCountLimit = seriesCountLimit;
    this.dataType = dataType;
  }

  static getInstance(seriesCountLimit: number | undefined, dataType: StatDataType) {}

  private removeChartDataStatus(chartDataId: UUID) {
    this.chartDataStatus = this.chartDataStatus.filter(
      (status) => status.chartDataId !== chartDataId,
    );
    return this.chartDataStatus;
  }

  private updateChartDataStatus(chartDataId: UUID, statusInfo: WidgetChartDataStatusInfo) {
    const isExist = this.chartDataStatus.some((status) => status.chartDataId === chartDataId);
    if (!isExist) {
      this.chartDataStatus.push(statusInfo);
    } else {
      this.chartDataStatus = this.chartDataStatus.map((status) => {
        if (status.chartDataId === chartDataId) {
          return statusInfo;
        }
        return status;
      });
    }
    return this.chartDataStatus;
  }

  static getStatInfo({ childCategory, statId }: { childCategory: string; statId: string }) {
    const { getStatInfo } = useStatInfoStore();
    const statInfo = getStatInfo({ childCategory, statId });
    return statInfo;
  }

  private isCE001Error(
    chartData: Pick<WidgetChartData, 'category' | 'dataId'>,
    _isReferenceWidget: boolean,
  ) {
    const statInfo = ChartDataStatus.getStatInfo({
      childCategory: chartData.category,
      statId: chartData.dataId,
    });
    if (!_isReferenceWidget && chartData.category === 'custom') {
      return true;
    }

    if (!statInfo) {
      return false;
    }
    const statDataType = statInfo.dataType;
    if (statDataType.includes(this.dataType)) {
      return false;
    }
    return true;
  }

  // CE001 에러만 화면에서 처리
  private setCE001Error(chartDataList: WidgetChartData[], _isReferenceWidget: boolean) {
    this.chartDataStatus = chartDataList.map((data) => {
      const chartDataInfo = this.chartDataStatus.find(
        (statusData) => statusData.chartDataId === data.id,
      ) ?? { chartDataId: data.id, status: 'success' };
      if (this.isCE001Error(data, _isReferenceWidget)) {
        return {
          ...chartDataInfo,
          status: 'fail',
          type: 'custom',
          errorCode: 'CE001',
          reason: i18n.global.t('ERROR.CHART_DATA.CE001'),
        };
      }
      return chartDataInfo;
    });
    return this;
  }

  private setCE002Error() {
    this.chartDataStatus = this.chartDataStatus.map((status, idx) => {
      if (!isNil(this.seriesCountLimit) && this.seriesCountLimit <= idx) {
        return {
          ...status,
          status: 'fail',
          type: 'custom',
          reason: i18n.global.t('ERROR.CHART_DATA.CE002', {
            value: this.seriesCountLimit,
          }),
          errorCode: 'CE002',
        };
      }
      return status;
    });
    return this;
  }

  private setErrorMessage() {
    this.chartDataStatus = this.chartDataStatus.map((statusInfo) => {
      if (statusInfo.status === 'fail') {
        let errorMessage = '';
        const { type, reason, errorCode } = statusInfo;
        if (errorCode && !reason) {
          errorMessage = i18n.global.t(`ERROR.CHART_DATA.${errorCode}`);
        } else if (type === 'api') {
          errorMessage = reason || i18n.global.t('MESSAGE.UI.DASHBOARD.UNSUPPORTED_SERIES');
        } else {
          errorMessage = reason;
        }
        return { ...statusInfo, reason: errorMessage };
      }
      return { ...statusInfo, info: statusInfo.info !== 'NO_DATA' ? statusInfo.info ?? '' : '' };
    });
    return this;
  }

  public getChartDataStatus() {
    return this.chartDataStatus;
  }

  public setChartDataStatusByStatInfo(
    chartDataId: UUID,
    chartDataStat: ChartDataStat | undefined,
    _isReferenceWidget: boolean = false,
  ) {
    if (!chartDataStat) {
      return this.removeChartDataStatus(chartDataId);
    }
    if (
      this.isCE001Error(
        {
          category: chartDataStat.childCategory,
          dataId: chartDataStat.statId,
        },
        _isReferenceWidget,
      )
    ) {
      return this.updateChartDataStatus(chartDataId, {
        chartDataId,
        status: 'fail',
        type: 'custom',
        errorCode: 'CE001',
        reason: i18n.global.t('ERROR.CHART_DATA.CE001'),
      });
    }
    return this.updateChartDataStatus(chartDataId, {
      chartDataId,
      status: 'success',
    });
  }

  abstract applyChartDataStatus(chartDataStatus: WidgetChartDataStatusInfo[]): this;

  public checkChartDataStatus(
    chartDataStatus: WidgetChartDataStatusInfo[],
    chartDataList: WidgetChartData[],
    _isReferenceWidget: boolean,
  ): ChartDataStatus {
    this.applyChartDataStatus(chartDataStatus)
      .setCE001Error(chartDataList, _isReferenceWidget)
      .setCE002Error()
      .setErrorMessage();
    return this;
  }
}

export class DefaultChartDataStatus extends ChartDataStatus {
  private static instance: DefaultChartDataStatus;

  static override getInstance(seriesCountLimit: number | undefined, dataType: StatDataType) {
    if (!this.instance) {
      this.instance = new DefaultChartDataStatus(seriesCountLimit, dataType);
    } else {
      if (seriesCountLimit !== this.instance.seriesCountLimit) {
        this.instance.seriesCountLimit = seriesCountLimit;
      }
      if (dataType !== this.instance.dataType) {
        this.instance.dataType = dataType;
      }
    }
    return this.instance;
  }

  override applyChartDataStatus(chartDataStatus: WidgetChartDataStatusInfo[]) {
    this.chartDataStatus = chartDataStatus;
    return this;
  }
}

export class ScoreboardStyle2ChartDataStatus extends ChartDataStatus {
  private static instance: ScoreboardStyle2ChartDataStatus;

  private rawData: CurrentRawData | undefined = undefined;

  setRawData(rawData: CurrentRawData) {
    this.rawData = rawData;
    return this;
  }

  static override getInstance(seriesCountLimit: number | undefined, dataType: StatDataType) {
    if (!this.instance) {
      this.instance = new ScoreboardStyle2ChartDataStatus(seriesCountLimit, dataType);
    }
    return this.instance;
  }

  static isUnitDiffError(chartDataList: WidgetChartData[]) {
    const firstStat = chartDataList.at(0);
    const secondStat = chartDataList.at(1);
    if (firstStat && secondStat) {
      const firstStatInfo = ChartDataStatus.getStatInfo({
        childCategory: firstStat.category,
        statId: firstStat.dataId,
      });
      const secondStatInfo = ChartDataStatus.getStatInfo({
        childCategory: secondStat.category,
        statId: secondStat.dataId,
      });
      const firstStatUnit = firstStatInfo?.unit;
      const secondStatUnit = secondStatInfo?.unit;

      return firstStatUnit !== secondStatUnit;
    }
    return false;
  }

  override applyChartDataStatus(chartDataStatus: WidgetChartDataStatusInfo[]) {
    if (!this.rawData) {
      return this;
    }

    const { currentData } = this.rawData;
    const firstStatUnit = currentData?.at(0)?.at(0)?.unit ?? '';

    this.chartDataStatus = chartDataStatus.map((apiChartDataStatus, index) => {
      if (index === 1) {
        const secondStatUnit = currentData?.at(index)?.at(0)?.unit ?? '';
        if (firstStatUnit && secondStatUnit && firstStatUnit !== secondStatUnit) {
          return {
            status: 'fail',
            type: 'custom',
            chartDataId: apiChartDataStatus.chartDataId,
            reason: i18n.global.t('MESSAGE.UI.DASHBOARD.SCOREBOARD_STAT_UNIT_DIFF'),
          };
        }
      }
      return apiChartDataStatus;
    });
    return this;
  }
}
export class EqualizerChartDataStatus extends ChartDataStatus {
  private static instance: EqualizerChartDataStatus;

  private rawData: TableRawData | undefined = undefined;

  static override getInstance(seriesCountLimit: number | undefined, dataType: StatDataType) {
    if (!this.instance) {
      this.instance = new EqualizerChartDataStatus(seriesCountLimit, dataType);
    }
    return this.instance;
  }

  setRawData(rawData: TableRawData) {
    this.rawData = rawData;
    return this;
  }

  override applyChartDataStatus(chartDataStatus: WidgetChartDataStatusInfo[]) {
    if (!this.rawData) {
      return this;
    }
    const { tableData } = this.rawData;

    this.chartDataStatus = chartDataStatus.map((item, idx) => {
      if (item.status === 'fail') {
        return item;
      }
      const isExistTargetId = tableData
        ?.at(idx)
        ?.columns.some((columnInfo) => columnInfo.dataAttribute === 'TargetId');
      const isExistNumberType = tableData
        ?.at(idx)
        ?.columns.some((columnInfo) => columnInfo.dataType === 'Number');
      if (!isExistTargetId) {
        return {
          status: 'fail',
          type: 'custom',
          chartDataId: item.chartDataId,
          reason: i18n.global.t('MESSAGE.UI.DASHBOARD.EQUALIZER_NOT_SUPPORT'),
        };
      }
      if (!isExistNumberType) {
        return {
          status: 'fail',
          type: 'custom',
          chartDataId: item.chartDataId,
          reason: i18n.global.t('MESSAGE.UI.DASHBOARD.EQUALIZER_NOT_NUMBER'),
        };
      }
      return item;
    });
    return this;
  }
}
