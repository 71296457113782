import { IconType } from '@/types/icon.types';
import dayjs from 'dayjs';
import { DEFAULT_DASHBOARD_DATA } from './dashboard.datas';
import { DashboardPort } from './dashboard.ports';

export const convertToDashboardData: DashboardPort['convertToDashboardData'] = (dashboardDto) => {
  return {
    dashboardId: dashboardDto.dashboardId ?? DEFAULT_DASHBOARD_DATA.dashboardId,
    dashboardGroupId: dashboardDto.dashboardGroupId ?? DEFAULT_DASHBOARD_DATA.dashboardGroupId,
    dashboardName: dashboardDto.dashboardName ?? DEFAULT_DASHBOARD_DATA.dashboardName,
    description: dashboardDto.description,
    image: dashboardDto.image,
    preset: dashboardDto.preset ?? DEFAULT_DASHBOARD_DATA.preset,
    shared: dashboardDto.shared ?? DEFAULT_DASHBOARD_DATA.shared,
    sharing: dashboardDto.sharing ?? DEFAULT_DASHBOARD_DATA.sharing,
    startScreen: dashboardDto.startScreen ?? DEFAULT_DASHBOARD_DATA.startScreen,
    favoriteId: dashboardDto.favoriteId,
    createUser: dashboardDto.createUser ?? DEFAULT_DASHBOARD_DATA.createUser,
    creator: {
      name: dashboardDto.createUserName ?? DEFAULT_DASHBOARD_DATA.creator.name,
      activeId: dashboardDto.createUserActiveId ?? DEFAULT_DASHBOARD_DATA.creator.activeId,
      roleId: dashboardDto.createUserRole ?? DEFAULT_DASHBOARD_DATA.creator.roleId,
      profileImage:
        dashboardDto.createUserProfileImage ?? DEFAULT_DASHBOARD_DATA.creator.profileImage,
    },
    lastModified: dashboardDto.lastModified,
    featuredReport: dashboardDto.featuredReport ?? DEFAULT_DASHBOARD_DATA.featuredReport,
    customReport: dashboardDto.customReport ?? DEFAULT_DASHBOARD_DATA.customReport,
    menuPaths: dashboardDto.menuPaths,
    analysisMenuPaths: dashboardDto.analysisMenuPaths,
    dashboardOption: dashboardDto.dashboardOption
      ? JSON.parse(dashboardDto.dashboardOption)
      : DEFAULT_DASHBOARD_DATA.dashboardOption,
    iconId: dashboardDto.iconId ?? DEFAULT_DASHBOARD_DATA.iconId,
  };
};

export const convertToDeleteDashboardsDto: DashboardPort['convertToDeleteDashboardsDto'] = (
  dashboardData,
) => {
  return {
    customReport: dashboardData.customReport,
    dashboardGroupId: -1,
    dashboardId: dashboardData.dashboardId,
    dashboardName: dashboardData.dashboardName,
    dashboardOption: JSON.stringify(dashboardData.dashboardOption),
    deleted: true,
    description: dashboardData.description,
    featuredReport: dashboardData.featuredReport,
    image: dashboardData.image,
    preset: dashboardData.preset,
    startScreen: dashboardData.startScreen,
  };
};

export const convertToEditDashboardRequestDto: DashboardPort['convertToEditDashboardRequestDto'] = (
  editDashboardRequest,
) => {
  return {
    customReport: editDashboardRequest.customReport,
    dashboardGroupId: editDashboardRequest.dashboardGroupId,
    dashboardId: editDashboardRequest.dashboardId,
    dashboardName: editDashboardRequest.dashboardName,
    dashboardOption: JSON.stringify(editDashboardRequest.dashboardOption),
    deleted: false,
    description: editDashboardRequest.description,
    featuredReport: editDashboardRequest.featuredReport,
    image: editDashboardRequest.image,
    preset: editDashboardRequest.preset,
    startScreen: editDashboardRequest.startScreen,
  };
};

export const convertToUpdateDashboardFavoriteRequestDto: DashboardPort['convertToUpdateDashboardFavoriteRequestDto'] =
  (updateFavoriteRequest) => {
    return {
      dashboardId: updateFavoriteRequest.dashboardId,
      deleted: updateFavoriteRequest.deleted,
      favoriteId: updateFavoriteRequest.favoriteId,
    };
  };

export const convertToUpdateDashboardGroupRequestDto: DashboardPort['convertToUpdateDashboardGroupRequestDto'] =
  (updateGroupRequest) => {
    return {
      dashboardId: updateGroupRequest.dashboardId,
      dashboardGroupId: updateGroupRequest.dashboardGroupId,
    };
  };

export const convertToDashboardGroup: DashboardPort['convertToDashboardGroup'] = (
  dashboardGroupDto,
) => {
  return {
    dashboardGroupName: dashboardGroupDto.dashboardGroupName ?? '',
    dashboardGroupId: dashboardGroupDto.dashboardGroupId,
  };
};

export const convertToFeaturedCardData: DashboardPort['convertToFeaturedCardData'] = (
  dashboardDto,
) => {
  return {
    dashboardId: dashboardDto.dashboardId ?? DEFAULT_DASHBOARD_DATA.dashboardId,
    dashboardGroupId: DEFAULT_DASHBOARD_DATA.dashboardGroupId,
    dashboardName: dashboardDto.dashboardName ?? DEFAULT_DASHBOARD_DATA.dashboardName,
    description: dashboardDto.description,
    image: dashboardDto.image || DEFAULT_DASHBOARD_DATA.image,
    preset: true,
    shared: DEFAULT_DASHBOARD_DATA.shared,
    sharing: DEFAULT_DASHBOARD_DATA.sharing,
    startScreen: dashboardDto.startScreen ?? DEFAULT_DASHBOARD_DATA.startScreen,
    favoriteId: dashboardDto.favoriteId,
    createUser: DEFAULT_DASHBOARD_DATA.createUser,
    creator: DEFAULT_DASHBOARD_DATA.creator,
    featuredReport: dashboardDto.featuredReport ?? DEFAULT_DASHBOARD_DATA.featuredReport,
    customReport: dashboardDto.customReport ?? DEFAULT_DASHBOARD_DATA.customReport,
    menuPaths: dashboardDto.menuPaths,
    analysisMenuPaths: dashboardDto.analysisMenuPaths,
    dashboardOption: DEFAULT_DASHBOARD_DATA.dashboardOption,
    iconId: (dashboardDto.iconId ?? DEFAULT_DASHBOARD_DATA.iconId) as IconType,
    originDesc: dashboardDto.description ?? '',
    originName: dashboardDto.dashboardName ?? DEFAULT_DASHBOARD_DATA.dashboardName,
  };
};

export const convertToShareCardData: DashboardPort['convertToShareCardData'] = (dashboardDto) => {
  return {
    dashboardId: dashboardDto.dashboardId ?? DEFAULT_DASHBOARD_DATA.dashboardId,
    dashboardGroupId: DEFAULT_DASHBOARD_DATA.dashboardGroupId,
    dashboardName: dashboardDto.dashboardName ?? DEFAULT_DASHBOARD_DATA.dashboardName,
    description: dashboardDto.description,
    preset: DEFAULT_DASHBOARD_DATA.preset,
    shared: true,
    sharing: DEFAULT_DASHBOARD_DATA.sharing,
    startScreen: dashboardDto.startScreen ?? DEFAULT_DASHBOARD_DATA.startScreen,
    favoriteId: dashboardDto.favoriteId,
    createUser: DEFAULT_DASHBOARD_DATA.createUser,
    creator: {
      name: dashboardDto.sharedUser ?? DEFAULT_DASHBOARD_DATA.creator.name,
      activeId: DEFAULT_DASHBOARD_DATA.creator.activeId,
      profileImage:
        dashboardDto.sharedUserProfileImage ?? DEFAULT_DASHBOARD_DATA.creator.profileImage,
    },
    featuredReport: dashboardDto.featuredReport ?? DEFAULT_DASHBOARD_DATA.featuredReport,
    customReport: dashboardDto.customReport ?? DEFAULT_DASHBOARD_DATA.customReport,
    menuPaths: dashboardDto.menuPaths,
    analysisMenuPaths: dashboardDto.analysisMenuPaths,
    dashboardOption: DEFAULT_DASHBOARD_DATA.dashboardOption,
    iconId: (dashboardDto.iconId ?? DEFAULT_DASHBOARD_DATA.iconId) as IconType,
    originDesc: dashboardDto.description ?? '',
    originName: dashboardDto.dashboardName ?? DEFAULT_DASHBOARD_DATA.dashboardName,
    displayDate: dayjs(dashboardDto.sharedDate).format('YYYY-MM-DD HH:mm:ss'),
    new: dashboardDto.new,
    sharedUserEmail: dashboardDto.sharedUserEmail,
    sharedUserRole: dashboardDto.sharedUserRole,
  };
};

export const convertToVisitCardData: DashboardPort['convertToVisitCardData'] = (dashboardDto) => {
  return {
    dashboardId: dashboardDto.dashboardId ?? DEFAULT_DASHBOARD_DATA.dashboardId,
    dashboardGroupId: DEFAULT_DASHBOARD_DATA.dashboardGroupId,
    dashboardName: dashboardDto.dashboardName ?? DEFAULT_DASHBOARD_DATA.dashboardName,
    description: dashboardDto.description,
    preset: dashboardDto.preset ?? DEFAULT_DASHBOARD_DATA.preset,
    shared: dashboardDto.shared ?? DEFAULT_DASHBOARD_DATA.shared,
    sharing: dashboardDto.sharing ?? DEFAULT_DASHBOARD_DATA.sharing,
    startScreen: dashboardDto.startScreen ?? DEFAULT_DASHBOARD_DATA.startScreen,
    favoriteId: dashboardDto.favoriteId,
    createUser: DEFAULT_DASHBOARD_DATA.createUser,
    creator: DEFAULT_DASHBOARD_DATA.creator,
    featuredReport: dashboardDto.featuredReport ?? DEFAULT_DASHBOARD_DATA.featuredReport,
    customReport: dashboardDto.customReport ?? DEFAULT_DASHBOARD_DATA.customReport,
    menuPaths: dashboardDto.menuPaths,
    analysisMenuPaths: dashboardDto.analysisMenuPaths,
    dashboardOption: DEFAULT_DASHBOARD_DATA.dashboardOption,
    iconId: (dashboardDto.iconId ?? DEFAULT_DASHBOARD_DATA.iconId) as IconType,
    originDesc: dashboardDto.description ?? '',
    originName: dashboardDto.dashboardName ?? DEFAULT_DASHBOARD_DATA.dashboardName,
    displayDate: dayjs(dashboardDto.visitDate).format('YYYY-MM-DD HH:mm:ss'),
  };
};
