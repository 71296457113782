import { useInternational } from '@/common/locale';
import { useNoticeStore } from '@/config/stores/notice';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { webStorageController } from '@/common/utils/webStorage.util';
import router from '@/common/router';

export const setup = () => {
  const { t } = useInternational();
  const noticeStore = useNoticeStore();
  const { checkNotShowNoticePopupFunc, getPopupInfo, setClicked } = noticeStore;
  const { isShowNoticeWindowPopup, popupInfo, expandNoticeId } = storeToRefs(noticeStore);

  const expandItemIndex = computed(() => {
    if (!expandNoticeId.value) return 0;
    return popupInfo.value.findIndex((item) => item.id === expandNoticeId.value);
  });

  const isShowPopupCheck = ref(false);
  const onCloseWindow = () => {
    isShowNoticeWindowPopup.value = false;
    expandNoticeId.value = 0;
  };

  const onExpandItem = (isExapnd: boolean, noticeId: number) => {
    if (!isExapnd) return;
    setClicked(noticeId);
  };

  watch(isShowPopupCheck, (value) => {
    const data = {
      isShowPopupCheck: value,
      popupInfo: popupInfo.value.map((item) => ({
        id: item.id,
        fromTime: item.popupPeriod?.fromTime,
        toTime: item.popupPeriod?.toTime,
      })),
    };
    webStorageController.setItem({
      type: 'local',
      key: 'noticePopupInfo',
      value: JSON.stringify(data),
    });
  });

  watch(router.currentRoute, async () => {
    if (router.currentRoute.value.name === 'BrowserPopup') {
      return;
    }
    await getPopupInfo();
    if (popupInfo.value.length > 0) {
      isShowNoticeWindowPopup.value = true;
    }
    await checkNotShowNoticePopupFunc();
  });

  watch(
    () => isShowNoticeWindowPopup.value,
    async (isShow) => {
      if (isShow) {
        const expandItem = popupInfo.value[expandItemIndex.value];
        if (expandItem && !expandItem.clicked) {
          await setClicked(expandItem.id);
        }
      } else {
        expandNoticeId.value = 0;
      }
    },
  );

  return {
    onCloseWindow,
    isShowPopupCheck,
    isShowNoticeWindowPopup,
    popupInfo,
    expandItemIndex,
    onExpandItem,
    t,
  };
};
