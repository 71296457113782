import { store } from '@/common/store';
import { usePropertyStore } from '@/common/stores/property';
import { EvMessage } from 'evui';
import dayjs from 'dayjs';
import { TIMEZONE_ITEMS } from '@/common/utils/define';
import { setHeaderConfig } from '@/worker/commands/config/apiInstance';
import { getUtcOffset } from '@/common/utils/commonUtils';
import { i18n } from '@/common/locale';
import { useAuthStore } from '@/common/stores/auth';
import { useLoginRoute } from '@/login/components/loginForm/loginForm.uses';
import { storeToRefs } from 'pinia';
import { useRolePermissionStore } from '@/config/stores/rolePermission';
import { useViewManagerStore } from '@/common/stores/view-manager';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { useInstanceStore } from '@/common/stores/instance';
import { useViewModeStore } from '@/common/stores/view-mode';
import { RouteLocationNormalized } from 'vue-router';
import { findPermissionByTranslateId } from '@/common/permission/permission.utils';
import { VIEW_MODE } from '@/common/define/common.define';
import { useUserEnvStore } from '@/common/stores/user-env';
import { validateDatabaseOverviewRoute } from '@/database/utils/path.util';
import { isRoleIdIncluded } from '../auth/auth.utils';
import { useBaseMenuStore } from '../stores/base-menu';

export const PATH = {
  ROOT: '/',
  LOGOUT: '/logout',
  DASHBOARD_LIST: '/dashboard/list',
  AUTH_FAIL: '/401',
  NOT_FOUND: '/404',
  FORBIDDEN: '/403',
};

const showErrorMsg = (msg: string) => {
  EvMessage({
    type: 'error',
    message: msg,
  });
};

const ControlPageLoading = {
  active: () => {
    const { isLoadingRouterForEach } = storeToRefs(useViewManagerStore());
    isLoadingRouterForEach.value = true;
  },
  inactive: () => {
    const { isLoadingRouterForEach } = storeToRefs(useViewManagerStore());
    isLoadingRouterForEach.value = false;
  },
};

const validateToken = async () => {
  // 토큰 검증 로직
  // -> 화면 api요청 시 사용하는 토큰 기반으로 검증
  try {
    let { userId } = await store.getters['myInfo/getAccountInfo'];
    if (userId === -1) {
      ControlPageLoading.active();
      await store.dispatch('myInfo/dispatchMyInfo');
      userId = await store.getters['myInfo/getAccountInfo'].userId;
    }
    return !!userId;
  } catch (e: any) {
    return false;
  }
};

const clearModule = () => {
  useAuthStore().clear();
  useBaseMenuStore().resetMenuInfo();
  useUserEnvStore().initUserEnvMap();

  store.commit('postgresqlPaCondition/initSelectedInfo');
  store.commit('oraclePaCondition/initSelectedInfo');
  store.commit('postgresqlSingleViewEnv/initSelectedInfo');
  store.commit('postgresqlSingleViewEnv/initFramesByFailedApi');
  store.commit('mysqlSingleViewEnv/initSelectedInfo');
  store.commit('oracleMultiViewEnv/initSelectedInfo');
  store.commit('myInfo/initAccountInfo');
  store.commit('userEnv/initUserEnvMap');
  store.commit('monitoringFolder/initFolderInfo');
  store.commit('monitoringFolder/initFolders');
  store.commit('monitoringInstance/initInstanceInfo');
  store.commit('monitoringInstance/initInstances');
};

const skipLogin = async () => {
  const authStore = useAuthStore();
  await authStore.setInitialInfo();
  const hasAuth = authStore.getToken('accessToken');

  if (hasAuth) {
    // 사용자 정보 검증
    const isValid = await validateToken();
    if (!isValid) {
      clearModule();
      return undefined;
    }
    const startScreenId = await useLoginRoute().getStartDashboardId();
    if (startScreenId > 0) {
      return {
        name: 'dashboard_Dashboard View',
        params: {
          id: startScreenId,
        },
      };
    }
    return PATH.DASHBOARD_LIST;
  }

  clearModule();
  return undefined;
};

// 로그인 auth 확인
const requireAuth = async (to: RouteLocationNormalized) => {
  const authStore = useAuthStore();
  await authStore.setInitialInfo();

  // 토큰 검증
  const hasAuth = authStore.getToken('accessToken');
  if (!hasAuth) {
    showErrorMsg(i18n.global.t('NOTI.PRODUCT.RE_LOGIN'));
    return PATH.LOGOUT;
  }

  // 사용자 정보 검증
  const isValidToken = await validateToken();
  if (!isValidToken) {
    showErrorMsg(i18n.global.t('NOTI.PRODUCT.RE_LOGIN'));
    return PATH.LOGOUT;
  }

  // 비밀번호 변경 필요 여부 검증
  const { isValidPasswordByStorage } = authStore;
  if (isValidPasswordByStorage()) {
    showErrorMsg(i18n.global.t('NOTI.PRODUCT.RE_LOGIN'));
    return PATH.LOGOUT;
  }

  /**
   * maxgauge instance count 조회
   * Oracle, MySQL, PostgreSQL, SQL Server GNB에서 사용
   */
  const viewModeStore = useViewModeStore();
  const instanceStore = useInstanceStore();
  if (instanceStore.instanceCount == null && viewModeStore.viewMode === VIEW_MODE.MAXGAUGE) {
    await instanceStore.getInstanceCount();
  }

  // UserEnv 호출 여부 확인
  const hasUserEnvInfo = await store.getters['userEnv/getHasUserEnvInfo'];
  if (!hasUserEnvInfo) {
    ControlPageLoading.active();
    await store.dispatch('userEnv/fetchUserEnvInfo', { global: false });
    await store.dispatch('userEnv/fetchUserEnvInfo', { global: true });
  }

  // stat info data 초기화
  const statInfoStore = useStatInfoStore();
  if (statInfoStore.statInfoListByCategory == null) {
    ControlPageLoading.active();
    await statInfoStore.fetchData();
  }

  const utcOffsetConfig = { key: 'UtcOffset', value: getUtcOffset() };
  setHeaderConfig([utcOffsetConfig]);

  const { role: roleId, rolePermissionId } = store.getters['myInfo/getAccountInfo'];
  const rolePermissionStore = useRolePermissionStore();
  const { setGlobalPermissionList } = rolePermissionStore;
  await setGlobalPermissionList(rolePermissionId);

  if (to.meta.invisibleViewMode?.includes(viewModeStore.viewMode)) {
    return PATH.NOT_FOUND;
  }

  if (
    (to.meta.roles && isRoleIdIncluded(to.meta.roles, roleId) === false) ||
    (to.meta.rolePermission &&
      (findPermissionByTranslateId(to.meta.rolePermission)?.menuOnOff ??
        findPermissionByTranslateId(to.meta.rolePermission)?.listOnOff) === false)
  ) {
    return PATH.FORBIDDEN;
  }

  return undefined;
};

const requireInstances = async () => {
  await store.dispatch('monitoringFolder/dispatchFolders');
  await store.dispatch('monitoringInstance/dispatchInstances');
  return undefined;
};

// 최초 접속 및 refresh 시 sessionStorage에 timezone이 있다면 dayjs의 tz를 세팅해줌
const setDayjsTimezone = () => {
  const accountInfo = store.getters['myInfo/getAccountInfo'];
  if (accountInfo?.timezone) {
    const timezoneId = TIMEZONE_ITEMS.find((v) => v.value === accountInfo.timezone)?.value;
    if (timezoneId) {
      dayjs.tz.setDefault(timezoneId);
    }
  }
};

const startLoading = () => {
  const { isPageLoading } = storeToRefs(useViewManagerStore());
  isPageLoading.value = true;

  store.commit('setIsLoading', true);
};

const logoutApp = async () => {
  const { propertyData } = storeToRefs(usePropertyStore());

  await useAuthStore().setTokenLogout();
  clearModule();

  if (propertyData.value?.sso?.ssoMode) {
    window.location.replace(propertyData.value?.sso?.redirectionLogoutUrl || '/');
    return false;
  }

  return PATH.ROOT;
};
const ssoCheck = (to) => {
  if (!to.query.id || to.query.error || to.query.errorCode) {
    let errorParams = '';

    if (to.query.error) {
      errorParams += `error=${to.query.error}`;
    }

    if (to.query.errorCode) {
      errorParams += `${errorParams ? '&&' : ''}errorCode=${to.query.errorCode}`;
    }

    return `${PATH.AUTH_FAIL}?${errorParams}`;
  }
  return undefined;
};

const validateDatabaseOverviewPath = (to: RouteLocationNormalized) => {
  return validateDatabaseOverviewRoute(to).then((path) => path);
};

export {
  ControlPageLoading,
  clearModule,
  skipLogin,
  validateToken,
  requireAuth,
  requireInstances,
  startLoading,
  setDayjsTimezone,
  logoutApp,
  ssoCheck,
  validateDatabaseOverviewPath,
};
