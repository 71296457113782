import { listViewGridOption, listViewTreeGridOption } from '@/common/define/grid.define';
import { type DBType } from '@/common/utils';
import type { GridOption, TreeGridOption } from '@/common/utils/types';

export type InstanceMonitoringType = 'Single View' | 'Multi View' | 'Detail';
type UnambiguousDBType = NonNullable<DBType>;

export const DISPLAY_DB_TYPE = {
  POSTGRESQL: 'PostgreSQL',
  ORACLE: 'Oracle',
  MYSQL: 'MySQL',
  SQLSERVER: 'SQL Server',
  REDIS: 'Redis',
  MONGODB: 'MongoDB',
  CUBRID: 'CUBRID',
  ALTIBASE: 'Altibase',
  TIBERO: 'Tibero',
} as const;

export const checkBoxGridOption: GridOption = {
  ...listViewGridOption,
  useCheckbox: {
    use: true,
    mode: 'multi',
    headerCheck: true,
  },
};

export const checkBoxTreeGridOption: TreeGridOption = {
  ...listViewTreeGridOption,
  useCheckbox: {
    use: true,
    mode: 'multi',
    headerCheck: true,
  },
};

export const DATABASE_SINGLE_VIEW_TYPES: Partial<
  Record<UnambiguousDBType, InstanceMonitoringType>
> = {
  mysql: 'Single View',
  postgresql: 'Single View',
  sqlserver: 'Single View',
  // cubrid: 'Single View', // NOTE: 대시보드 구성안됨
} as const;

export const DATABASE_MULTI_VIEW_TYPES: Partial<Record<UnambiguousDBType, InstanceMonitoringType>> =
  {
    oracle: 'Multi View',
    mysql: 'Multi View',
    postgresql: 'Multi View',
    // cubrid: 'Multi View', // NOTE: 대시보드 구성안됨
  } as const;

export const RATIO_BAR_COLUMN = ['cpuUsage', 'memoryUsage'];
