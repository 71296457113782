<script setup lang="ts">
import BaseModalWindow from '@/common/components/templates/BaseModalWindow.vue';
import ToggleContents from '@/infrastructure/components/toggleContents/ToggleContents.vue';
import { utcZeroTimeToStandardTime } from '@/common/utils/commonUtils';
import { checkRole } from '@/common/auth/auth.utils';
import { setup } from './noticeWindow.setup';

const {
  isShowNoticeWindowPopup,
  onCloseWindow,
  isShowPopupCheck,
  popupInfo,
  expandItemIndex,
  onExpandItem,
  t,
} = setup();
</script>

<template>
  <base-modal-window
    v-model:visible="isShowNoticeWindowPopup"
    size-type="EXTRA_LARGE_EXPAND"
    class-name="notice-window"
  >
    <template #header>
      {{ t('WORD.GNB.NOTICE') }}
    </template>
    <template #default>
      <div class="notice-title">
        {{ t('WORD.NOTICE_LIST') }}
      </div>
      <div class="form-wrap">
        <div
          v-for="(content, index) in popupInfo"
          :key="`${content.title}_${index}`"
          class="notice-item"
        >
          <toggle-contents
            :is-expand="index === expandItemIndex"
            :class="[
              `popup-toggle-info`,
              `popup-toggle-info--${index}`,
              { 'not-read': !content.clicked },
            ]"
            :title="content.title ?? 'title'"
            icon="icon-arrow-right"
            @update:is-expand="(isExpand) => onExpandItem(isExpand, content.id)"
          >
            <template #header-suffix>
              <span class="popup-toggle-info__tail">
                {{ utcZeroTimeToStandardTime(content.popupPeriod?.fromTime, 'YYYY-MM-DD HH:mm') }} ~
                {{ utcZeroTimeToStandardTime(content.popupPeriod?.toTime, 'YYYY-MM-DD HH:mm') }}
              </span>
            </template>
            <dl class="notice-info">
              <dt>{{ t('WORD.CREATOR') }}</dt>
              <dd v-if="checkRole.isMaster(content.roleId)">{{ t('WORD.SYSTEM') }}</dd>
              <dd v-else>
                {{ content.name }}
                (<span class="notice-info__user-id">
                  {{ content.activeId ? content.activeId : '' }} </span
                >)
              </dd>
              <dt>{{ t('WORD.NOTICE_START_DATE') }}</dt>
              <dd>{{ utcZeroTimeToStandardTime(content.noticeStartDate) }}</dd>
            </dl>
            <div class="notice-content">
              <ev-text-field
                v-model="content.content"
                type="textarea"
                class="editor-area__textarea"
                readonly
              />
            </div>
          </toggle-contents>
        </div>
      </div>
    </template>

    <template #tail>
      <div class="window-footer-btn-field">
        <ev-checkbox v-model="isShowPopupCheck">
          {{ t('TUTORIAL.TOOLTIP.DONT_SHOW_AGAIN') }}
        </ev-checkbox>
        <ev-button
          type="primary"
          @click="onCloseWindow"
        >
          {{ t('WORD.CLOSE') }}
        </ev-button>
      </div>
    </template>
  </base-modal-window>
</template>

<style lang="scss">
.notice-window .window-content--has-tail {
  height: calc(100% - 38px) !important;
}
</style>
<style lang="scss" scoped>
.notice-window {
  .window-footer-btn-field {
    padding: 0;
    .ev-checkbox {
      margin: auto;
    }
  }
  .form-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
  .notice-title {
    display: flex;
    align-items: flex-end;
    height: 32px;
    padding: 4px 0 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
  }
  .notice-info {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    padding: 12px 0;
    dt {
      position: relative;
      padding-right: 8px;
      margin-right: 8px;
      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 12px;
        background-color: var(--color-gray-05-05);
        transform: translateY(-50%);
        content: '';
      }
    }
    dd:not(:last-child) {
      margin-right: 24px;
    }

    &__user-id {
      color: var(--color-gray-02-09);
    }
  }
  .notice-content {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    align-self: stretch;
    .editor-area__textarea {
      height: 476px;
    }
    :deep(.ev-text-field-wrapper) {
      display: flex;
      flex-direction: column;
      height: 100% !important;
    }
    :deep(.ev-textarea) {
      flex: 1;
      height: 100% !important;
      padding: 0 !important;
      border: none !important;
      background-color: transparent !important;
      color: var(--color-gray-00-12) !important;
    }
  }
  .popup-toggle-info {
    position: relative;
    margin-top: 12px;
    border: 1px solid var(--color-gray-05-05);
    &--0 {
      margin-top: 0;
    }
    &__tail {
      margin-left: auto;
      font-size: 12px;
    }
    :deep(.detail-information__header) {
      height: 32px;
      margin-top: 0;
      background-color: var(--detail-information-header-bg-color) !important;
    }
    :deep(.detail-information__content) {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      padding-top: 0;
      margin: 0;
    }
    :deep(.detail-information__header-icon) {
      margin-right: 10px;
    }
    &.not-read::before {
      position: absolute;
      top: 15px;
      left: 32px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--color-red-05-05);
      content: '';
    }
  }
}
</style>
