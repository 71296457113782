import {
  AvgMetricsAggregations,
  CountMetricsAggregations,
  MaxMetricsAggregations,
  MetricsAggregation,
  MinMetricsAggregations,
  PercentilesMetricsAggregations,
  RateMetricsAggregations,
  StatsMetricsAggregations,
  SumMetricsAggregations,
  TopMetricsMetricsAggregations,
} from './MetricsAggregation';

export function isCountMetrics(data: MetricsAggregation): data is CountMetricsAggregations {
  return data instanceof CountMetricsAggregations;
}

export function isAvgMetrics(data: MetricsAggregation): data is AvgMetricsAggregations {
  return data instanceof AvgMetricsAggregations;
}

export function isMaxMetrics(data: MetricsAggregation): data is MaxMetricsAggregations {
  return data instanceof MaxMetricsAggregations;
}

export function isMinMetrics(data: MetricsAggregation): data is MinMetricsAggregations {
  return data instanceof MinMetricsAggregations;
}

export function isSumMetrics(data: MetricsAggregation): data is SumMetricsAggregations {
  return data instanceof SumMetricsAggregations;
}

export function isPercentilesMetrics(
  data: MetricsAggregation,
): data is PercentilesMetricsAggregations {
  return data instanceof PercentilesMetricsAggregations;
}

export function isRateMetrics(data: MetricsAggregation): data is RateMetricsAggregations {
  return data instanceof RateMetricsAggregations;
}

export function isStatsMetrics(data: MetricsAggregation): data is StatsMetricsAggregations {
  return data instanceof StatsMetricsAggregations;
}

export function isTopMetricsMetrics(
  data: MetricsAggregation,
): data is TopMetricsMetricsAggregations {
  return data instanceof TopMetricsMetricsAggregations;
}
