import { useInternational } from '@/common/locale';
import { computed, onMounted, ref, watch } from 'vue';

type PeriodUnit = 'm' | 'h' | 'd';
export type AlertPostPeriod = string | null;

export interface Props {
  modelValue: AlertPostPeriod;
}

export interface Emit {
  (e: 'update:modelValue', value: AlertPostPeriod): void;
}

const postPeriodValidation = {
  isNumber: (val: string) => /^[0-9]+$/.test(val),
  inRange: ({ val, unit }: { val: string; unit: PeriodUnit }) => {
    const valNum = parseInt(val, 10);
    const range = {
      m: {
        min: 5,
        max: 4320,
      },
      h: {
        min: 1,
        max: 72,
      },
      d: {
        min: 1,
        max: 3,
      },
    };
    return valNum >= range[unit].min && valNum <= range[unit].max;
  },
};

const formatPostPeriod = ({ val, unit }: { val?: string | number; unit?: PeriodUnit } = {}) =>
  !val || !unit ? null : `p${val}${unit}`;

export const parsePostPeriod = (period: string) =>
  (/^p([0-9]*)([a-z])$/.exec(period || '')?.slice(1) || []) as [val: string, unit: PeriodUnit];

export const validatePostPeriod = (period: string | null) => {
  if (period === null) {
    return true;
  }
  const [val, unit] = parsePostPeriod(period);
  if (!val || !unit) {
    return false;
  }
  return postPeriodValidation.inRange({ val, unit });
};

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const periodUnitItems = [
    { name: t('WORD.MINUTE'), value: 'm' },
    { name: t('WORD.HOUR'), value: 'h' },
    { name: t('WORD.DAY'), value: 'd' },
  ];

  const selectedPeriodUnit = ref<PeriodUnit>('d');
  const selectedPeriodVal = ref('3');

  const selectedPeriod = computed<string | null>({
    get: () => (props.modelValue != null ? 'custom' : null),
    set: (period) => {
      if (period !== null) {
        emit(
          'update:modelValue',
          period === 'custom' ? `p${selectedPeriodVal.value}${selectedPeriodUnit.value}` : period,
        );
        return;
      }
      emit('update:modelValue', null);
    },
  });

  const periodErrorMsg = ref('');
  const onPeriodValInput = (val: string) => {
    if (!postPeriodValidation.isNumber(val)) {
      selectedPeriodVal.value = selectedPeriodVal.value.slice(0, -1);
      return;
    }

    const periodInfo = { val, unit: selectedPeriodUnit.value };
    selectedPeriod.value = formatPostPeriod(periodInfo);

    if (!postPeriodValidation.inRange(periodInfo)) {
      periodErrorMsg.value = t('MESSAGE.ALERT_OWN_POSTING_PERIOD_ERR');
      return;
    }

    periodErrorMsg.value = '';
  };

  watch(selectedPeriodUnit, (unit) => {
    const periodInfo = { val: selectedPeriodVal.value, unit };
    selectedPeriod.value = formatPostPeriod(periodInfo);

    if (!postPeriodValidation.inRange(periodInfo)) {
      periodErrorMsg.value = t('MESSAGE.ALERT_OWN_POSTING_PERIOD_ERR');
      return;
    }

    periodErrorMsg.value = '';
  });

  onMounted(() => {
    const period = props.modelValue;
    if (!period) {
      return;
    }

    const [val, unit] = parsePostPeriod(period);
    selectedPeriodUnit.value = (unit || 'd') as PeriodUnit;
    selectedPeriodVal.value = val || '3';
  });

  return {
    selectedPeriod,
    periodUnitItems,
    selectedPeriodUnit,
    selectedPeriodVal,
    periodErrorMsg,
    onPeriodValInput,
  };
};
