import { extractAccessibleRoutes } from '@/common/auth/auth.utils';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { VIEW_MODE } from '@/common/define/common.define';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { RouteRecordRaw } from 'vue-router';

const PREFIX_MESSAGE_QUEUE = 'message-queue_';

const messageQueueMenu: RouteRecordRaw[] = [
  {
    path: '/message-queue/',
    name: `${PREFIX_MESSAGE_QUEUE}Message-Queue`,
    component: RtmViewport,
    beforeEnter: [requireAuth, setDayjsTimezone],
    meta: {
      rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MESSAGEQUEUE,
      invisibleViewMode: [VIEW_MODE.MAXGAUGE],
    },
    children: [
      {
        path: 'kafka',
        name: `${PREFIX_MESSAGE_QUEUE}Kafka`,
        component: () => import('@/messageQueue/views/kafka/KafkaView.vue'),
        meta: {
          rolePermission: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MESSAGEQUEUE_KAFKA,
          invisibleViewMode: [VIEW_MODE.MAXGAUGE],
        },
      },
    ],
  },
];

const getMessageQueueMenu = (): RouteRecordRaw[] => {
  const filteredMessageQueueMenu = extractAccessibleRoutes(messageQueueMenu);

  return [...filteredMessageQueueMenu];
};

export { messageQueueMenu, getMessageQueueMenu };
