import { ASTER_KEY } from '@/dashboard/utils/define';
import { WidgetChartType } from '@/dashboard/utils/types';
import { ElasticSearchProcessRequest } from '@/openapi/data/model';
import {
  Average,
  Count,
  DateHistogram,
  Filters,
  Histogram,
  Max,
  Min,
  Percentiles,
  Rate,
  Stats,
  Sum,
  Terms,
  TopMetrics,
} from '@/openapi/metaV6/model';
import { isNil } from 'lodash-es';
import { useExternalResources } from '@/dashboard/stores/external-variables';
import { storeToRefs } from 'pinia';
import { computed, ComputedRef } from 'vue';
import { useGlobalFilterStore } from '@/dashboard/stores/global-filter';
import {
  BucketAggregation,
  DateHistogramBucketAggregations,
  FiltersBucketAggregations,
  HistogramBucketAggregations,
  TermsBucketAggregations,
} from '../widgetSettingsWindow/chartDataSettings/elasticsearch/bucketSettings/BucketAggregation';
import {
  isDateHistogramBucket,
  isFiltersBucket,
  isHistogramBucket,
  isTermsBucket,
} from '../widgetSettingsWindow/chartDataSettings/elasticsearch/bucketSettings/bucketSettings.utils';
import {
  AvgMetricsAggregations,
  CountMetricsAggregations,
  MaxMetricsAggregations,
  MetricsAggregation,
  MinMetricsAggregations,
  PercentilesMetricsAggregations,
  RateMetricsAggregations,
  StatsMetricsAggregations,
  SumMetricsAggregations,
  TopMetricsMetricsAggregations,
} from '../widgetSettingsWindow/chartDataSettings/elasticsearch/metricsSettings/MetricsAggregation';
import {
  isAvgMetrics,
  isCountMetrics,
  isMaxMetrics,
  isMinMetrics,
  isPercentilesMetrics,
  isRateMetrics,
  isStatsMetrics,
  isSumMetrics,
  isTopMetricsMetrics,
} from '../widgetSettingsWindow/chartDataSettings/elasticsearch/metricsSettings/metricsSettings.utils';
import { useWidgetDataParams, WidgetApiOptions } from './uses';
import { WidgetChartDataWithIndex, WidgetProps } from './widgets.types';
import { ExternalVariablesOptions } from '../externalVariableWindow/externalVariableWindow.type';

const trimAndValidateString = (value: string) => {
  return value.trim() === '' ? undefined : value;
};

const convertToDateHistogram = (
  bucketAggregation: DateHistogramBucketAggregations,
): DateHistogram => {
  return {
    type: 'dateHistogram',
    field: trimAndValidateString(bucketAggregation.config.field),
    interval: trimAndValidateString(bucketAggregation.config.interval),
    minDocCount: bucketAggregation.config.minDocCount,
    offset: trimAndValidateString(bucketAggregation.config.offset),
  };
};

const convertToFilters = (bucketAggregation: FiltersBucketAggregations): Filters => {
  return {
    type: 'filters',
    filter: bucketAggregation.config.queryLabels.map((queryLabel) => ({
      label: trimAndValidateString(queryLabel.label),
      query: trimAndValidateString(queryLabel.query),
    })),
  };
};

const convertToHistogram = (bucketAggregation: HistogramBucketAggregations): Histogram => {
  return {
    type: 'histogram',
    field: trimAndValidateString(bucketAggregation.config.field),
    interval: bucketAggregation.config.interval,
    minDocCount: bucketAggregation.config.minDocCount,
  };
};

const convertToTerms = (bucketAggregation: TermsBucketAggregations): Terms => {
  return {
    type: 'terms',
    field: trimAndValidateString(bucketAggregation.config.field),
    minDocCount: bucketAggregation.config.minDocCount,
    missing: trimAndValidateString(bucketAggregation.config.missing),
    order: trimAndValidateString(bucketAggregation.config.order),
    orderBy: trimAndValidateString(bucketAggregation.config.orderBy),
    size: bucketAggregation.config.size,
  };
};

export const convertToBucketAggregations = (bucketAggregation: BucketAggregation) => {
  if (isDateHistogramBucket(bucketAggregation)) {
    return convertToDateHistogram(bucketAggregation);
  }
  if (isFiltersBucket(bucketAggregation)) {
    return convertToFilters(bucketAggregation);
  }
  if (isHistogramBucket(bucketAggregation)) {
    return convertToHistogram(bucketAggregation);
  }
  if (isTermsBucket(bucketAggregation)) {
    return convertToTerms(bucketAggregation);
  }
  throw new Error('Invalid bucket aggregation');
};

const convertToCount = (metricsAggregation: CountMetricsAggregations): Count => {
  return {
    type: 'count',
    field: metricsAggregation.config.field,
  };
};

const convertToAverage = (metricsAggregation: AvgMetricsAggregations): Average => {
  return {
    type: 'average',
    field: trimAndValidateString(metricsAggregation.config.field),
    missing: metricsAggregation.config.missing,
    script: trimAndValidateString(metricsAggregation.config.script),
  };
};

const convertToMax = (metricsAggregation: MaxMetricsAggregations): Max => {
  return {
    type: 'max',
    field: trimAndValidateString(metricsAggregation.config.field),
    missing: metricsAggregation.config.missing,
    script: trimAndValidateString(metricsAggregation.config.script),
  };
};

const convertToMin = (metricsAggregation: MinMetricsAggregations): Min => {
  return {
    type: 'min',
    field: trimAndValidateString(metricsAggregation.config.field),
    missing: metricsAggregation.config.missing,
    script: trimAndValidateString(metricsAggregation.config.script),
  };
};

const convertToSum = (metricsAggregation: SumMetricsAggregations): Sum => {
  return {
    type: 'sum',
    field: trimAndValidateString(metricsAggregation.config.field),
    missing: metricsAggregation.config.missing,
    script: trimAndValidateString(metricsAggregation.config.script),
  };
};

const convertToPercentiles = (metricsAggregation: PercentilesMetricsAggregations): Percentiles => {
  return {
    type: 'percentiles',
    field: trimAndValidateString(metricsAggregation.config.field),
    missing: trimAndValidateString(metricsAggregation.config.missing),
    percentiles: trimAndValidateString(metricsAggregation.config.percentiles),
    script: trimAndValidateString(metricsAggregation.config.script),
  };
};

const convertToRate = (metricsAggregation: RateMetricsAggregations): Rate => {
  return {
    type: 'rate',
    field: trimAndValidateString(metricsAggregation.config.field),
    mode: trimAndValidateString(metricsAggregation.config.mode),
    script: trimAndValidateString(metricsAggregation.config.script),
    unit: trimAndValidateString(metricsAggregation.config.unit),
  };
};

const convertToStats = (metricsAggregation: StatsMetricsAggregations): Stats => {
  return {
    type: 'stats',
    field: trimAndValidateString(metricsAggregation.config.field),
    stats: metricsAggregation.config.stats
      .map(trimAndValidateString)
      .filter((value) => !isNil(value)) as string[],
  };
};

const convertToTopMetrics = (metricsAggregation: TopMetricsMetricsAggregations): TopMetrics => {
  return {
    type: 'topMetrics',
    metrics: metricsAggregation.config.metrics
      .map(trimAndValidateString)
      .filter((value) => !isNil(value)) as string[],
    order: trimAndValidateString(metricsAggregation.config.order),
    orderBy: trimAndValidateString(metricsAggregation.config.orderBy),
  };
};

export const convertToMetricsAggregations = (metricsAggregation: MetricsAggregation) => {
  if (isCountMetrics(metricsAggregation)) {
    return convertToCount(metricsAggregation);
  }
  if (isAvgMetrics(metricsAggregation)) {
    return convertToAverage(metricsAggregation);
  }
  if (isMaxMetrics(metricsAggregation)) {
    return convertToMax(metricsAggregation);
  }
  if (isMinMetrics(metricsAggregation)) {
    return convertToMin(metricsAggregation);
  }
  if (isSumMetrics(metricsAggregation)) {
    return convertToSum(metricsAggregation);
  }
  if (isPercentilesMetrics(metricsAggregation)) {
    return convertToPercentiles(metricsAggregation);
  }
  if (isRateMetrics(metricsAggregation)) {
    return convertToRate(metricsAggregation);
  }
  if (isStatsMetrics(metricsAggregation)) {
    return convertToStats(metricsAggregation);
  }
  if (isTopMetricsMetrics(metricsAggregation)) {
    return convertToTopMetrics(metricsAggregation);
  }

  throw new Error('Invalid bucket aggregation');
};

export const useElasticsearchApiParamsGlobalFilterTargets = (
  targetServerId: ComputedRef<string | undefined>,
) => {
  const externalResources = useExternalResources();
  const { externalVariables } = storeToRefs(externalResources);

  const globalFilterStore = useGlobalFilterStore();
  const { selectedGlobalVariables } = storeToRefs(globalFilterStore);

  const elasticsearchVariables = computed(() =>
    externalVariables.value.filter(
      ({ extraType, targetId }) =>
        extraType === 'ELASTICSEARCH' && targetId === targetServerId.value,
    ),
  );

  const getInUseVariables = (variableOptions: ExternalVariablesOptions[]) =>
    selectedGlobalVariables.value.filter(
      ({ use, globalFilterId }) =>
        use &&
        variableOptions.some(({ extraGlobalFilterId }) => extraGlobalFilterId === globalFilterId),
    );

  const inUseVariables = computed(() => getInUseVariables(elasticsearchVariables.value));

  const getGlobalVariableValues = (
    widgetGlobalVariables: { category: string; value: string }[],
  ): string[] => {
    const values = widgetGlobalVariables.reduce<string[]>((acc, { category, value }) => {
      if (category === 'globalVariable') {
        const variable = inUseVariables.value.find(
          ({ globalFilterId }) => `${globalFilterId}` === value,
        );
        if (!variable) {
          return acc;
        }
        const dashboardVariables =
          variable.selectedList && variable.selectedList.length > 0
            ? variable.selectedList.map(({ tagId }) => tagId)
            : elasticsearchVariables.value.find(
                ({ extraGlobalFilterId }) => `${extraGlobalFilterId}` === value,
              )?.values ?? [];
        acc.push(...dashboardVariables);
      } else if (value === ASTER_KEY) {
        acc.push(
          ...(elasticsearchVariables.value.find(
            ({ extraGlobalFilterId }) => `${extraGlobalFilterId}` === value,
          )?.values ?? []),
        );
      } else {
        acc.push(value);
      }
      return acc;
    }, []);
    return Array.from(new Set(values));
  };

  return { getGlobalVariableValues };
};

const setInterval = (interval: string, bucket: BucketAggregation) => {
  if (isDateHistogramBucket(bucket) || isHistogramBucket(bucket)) {
    bucket.config.interval = interval;
  }
  return bucket;
};

export const useElasticsearchApiParams = <ChartType extends WidgetChartType>(
  props: WidgetProps<ChartType>,
  options: WidgetApiOptions,
) => {
  const { getRequestPeriod } = useWidgetDataParams();
  const { defaultParams = {} } = options;

  const targetServerId = computed(() => props.chartData?.at(0)?.targets?.at(0)?.tagValue);

  const { getGlobalVariableValues } = useElasticsearchApiParamsGlobalFilterTargets(targetServerId);

  const changeElasticsearchApiParams = async (
    widgetChartData: WidgetChartDataWithIndex[],
  ): Promise<(ElasticSearchProcessRequest & { id: string })[]> => {
    return widgetChartData
      .filter(({ externalOptions }) => !isNil(externalOptions))
      .map(({ id, dataId, targets, externalOptions, chartDataIndex }) => {
        return {
          id,
          dataId: dataId ?? '',
          fromTime: defaultParams.fromTime,
          globalFilterTargets: getGlobalVariableValues(props.chartOption?.globalVariables ?? []),
          interpolateType: props.chartOption?.interpolation ?? 'Null',
          interval: defaultParams.interval,
          options: [
            externalOptions!.createBy === 'queryBuilder' || externalOptions!.createBy === undefined
              ? {
                  queryId: String.fromCharCode(chartDataIndex + 'A'.charCodeAt(0)),
                  queryType: externalOptions!.queryType === 'metrics' ? 'Metrics' : 'Logs',
                  buckets:
                    externalOptions!.queryType === 'metrics'
                      ? externalOptions!.buckets
                          .filter((bucket) => bucket.show)
                          .map((bucket) =>
                            setInterval(props.chartOption?.interval ?? 'auto', bucket),
                          )
                          .map(convertToBucketAggregations)
                      : [],
                  metrics:
                    externalOptions!.queryType === 'metrics'
                      ? externalOptions!.metrics
                          .filter((metric) => metric.show)
                          .map(convertToMetricsAggregations)
                      : [],
                  logSize:
                    externalOptions!.queryType === 'metrics'
                      ? undefined
                      : externalOptions!.options?.limit,
                  luceneQuery: externalOptions?.luceneQuery,
                }
              : {
                  queryId: String.fromCharCode(chartDataIndex + 'A'.charCodeAt(0)),
                  queryType: externalOptions!.queryType === 'metrics' ? 'Metrics' : 'Logs',
                  buckets: [],
                  metrics: [],
                  customQueries: externalOptions!.queries.map(({ query }) => query),
                  luceneQuery: externalOptions!.luceneQuery,
                },
          ],
          targetIds: targets.map(({ tagValue }) => tagValue),
          toTime: defaultParams.toTime,
          ...getRequestPeriod(props.timePeriod, props.chartType, props.calendarTimeRange),
        };
      });
  };

  return { changeElasticsearchApiParams };
};
