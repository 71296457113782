import { generateUUID } from '@/common/utils/commonUtils';
import {
  AvgMetricsAggregations,
  CountMetricsAggregations,
  MaxMetricsAggregations,
  MetricsAggregation,
  MetricsAggregationsType,
  MinMetricsAggregations,
  PercentilesMetricsAggregations,
  RateMetricsAggregations,
  StatsMetricsAggregations,
  SumMetricsAggregations,
  TopMetricsMetricsAggregations,
} from './MetricsAggregation';

export class MetricsAggregationFactory {
  static createMetricsAggregation(type: MetricsAggregationsType): MetricsAggregation {
    switch (type) {
      case 'count':
        return new CountMetricsAggregations(generateUUID());
      case 'avg':
        return new AvgMetricsAggregations(generateUUID());
      case 'max':
        return new MaxMetricsAggregations(generateUUID());
      case 'min':
        return new MinMetricsAggregations(generateUUID());
      case 'sum':
        return new SumMetricsAggregations(generateUUID());
      case 'percentiles':
        return new PercentilesMetricsAggregations(generateUUID());
      case 'rate':
        return new RateMetricsAggregations(generateUUID());
      case 'stats':
        return new StatsMetricsAggregations(generateUUID());
      case 'topMetrics':
        return new TopMetricsMetricsAggregations(generateUUID());
      default:
        throw new Error(`Unknown metrics aggregation type: ${type}`);
    }
  }
}
