import { useWidgetClickInject } from '@/dashboard/context';
import { getFilterValue, useFilterSearchStore } from '@/dashboard/stores/filter-search';
import { GlobalVariables, useGlobalFilterStore } from '@/dashboard/stores/global-filter';
import { ComputedRef } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterUUID, TargetTagType, WidgetUUID } from '@/common/utils';
import { getDataArrangeIntervalByTimeRange } from '@/common/utils/commonUtils';
import { WidgetChartType, WidgetCreateType, WidgetTarget } from '@/dashboard/utils/types';
import { decodeArgParam, encodeArgParam, isCustomStatId } from '@/dashboard/utils/dashboardUtils';
import { WidgetCommonArgument } from '@/dashboard/utils/define';
import dayjs from 'dayjs';
import { GLOBAL_VARIABLE_TAG_KEY, TAG_TYPE_KEY } from '@/common/define/widget.define';
import {
  CommonChartDataIndex,
  CommonStatId,
  ReferenceInfo,
  ReferenceVariable,
} from '../widgetSettingsWindow/referenceSettingOption/referenceSettingOption.setup';
import { useWidgetArgumentList } from '../widgetSettingsWindow/widgetSettingsWindow.composables';
import {
  PeriodTimeType,
  WidgetVariable,
} from '../widgetSettingsWindow/referenceSettingOption/variableListWindow/variable.types';
import { RequestType, useWidgetDataParams } from './uses';

const encodeGlobalVariableArgParam = (
  tags: {
    tagKey: WidgetTarget['tagKey'];
    tagType: TargetTagType | 'business_service' | 'business_service_group';
    tagValue: string;
  }[],
): string => {
  return tags
    .map(({ tagKey, tagType, tagValue }) => {
      return `${tagKey};${tagType};${tagValue}`;
    })
    .join(',');
};

export const decodeGlobalVariableArgParam = (
  value: string | undefined,
): { tagKey: string; tagType: string; tagValue: string }[] | undefined => {
  if (value == null) return undefined;

  const argParams = value.split(',');
  return argParams.map((argParam) => {
    const [tagKey, tagType, tagValue] = argParam.split(';');
    return { tagKey, tagType, tagValue };
  });
};

export type ArgParam = Record<string, { value: string; tags?: boolean; userTags?: boolean }>;

export const useWidgetArgParam = (_referenceInfo: ComputedRef<ReferenceInfo>) => {
  const { filterToSearch } = storeToRefs(useFilterSearchStore());
  const { clickedWidgetInfo } = useWidgetClickInject();
  const { getArgumentList } = useWidgetArgumentList();

  const { globalTimePeriod } = storeToRefs(useGlobalFilterStore());
  const { getRequestUserTags } = useWidgetDataParams();

  const globalFilterStore = useGlobalFilterStore();
  const { globalVariables } = storeToRefs(globalFilterStore);

  const getArgParamValue = (
    widgetUUID: WidgetUUID,
    filterUUID: FilterUUID,
    timeType?: PeriodTimeType,
  ) => {
    // NOTE: 클릭한 위젯의 데이터가 있는 경우, 해당 데이터를 반환합니다.
    const clickedWidgetData = clickedWidgetInfo.value?.get(widgetUUID)?.get(filterUUID);
    if (clickedWidgetData) {
      return encodeArgParam(clickedWidgetData);
    }
    const filterData = filterToSearch.value.getFilterDataForWidget(widgetUUID, filterUUID);
    if (!filterData) {
      return '';
    }
    // NOTE: 클릭한 위젯의 데이터가 없는 경우, 필터 검색 스토어에서 값을 찾아 반환합니다.
    return getFilterValue(filterData, timeType);
  };

  const extractArgParamValue = (
    widgetVariable: WidgetVariable,
  ): ArgParam[keyof ArgParam]['value'] => {
    switch (widgetVariable.type) {
      case 'fixedValue': {
        return encodeArgParam(widgetVariable.fixedValue) ?? '';
      }
      case 'globalTimePeriod': {
        if (widgetVariable.timeType === 'fromTime') {
          return encodeArgParam(globalTimePeriod.value.fromTimeUtc) ?? '';
        }
        if (widgetVariable.timeType === 'toTime') {
          return encodeArgParam(globalTimePeriod.value.toTimeUtc) ?? '';
        }
        return '';
      }
      case 'globalVariable': {
        const useGlobalVariable = globalVariables.value.filter(({ use }) => use);
        // NOTE: 기존에 세팅되어있던 globalVariableId가 string인 경우, 배열로 변환합니다.
        const variableId =
          typeof widgetVariable.variableId === 'string'
            ? [widgetVariable.variableId]
            : widgetVariable.variableId;
        const globalVariable = variableId.map((id) =>
          useGlobalVariable.find(({ globalFilterId }) => `${globalFilterId}` === id),
        );
        if (globalVariable.length === 0) {
          return '';
        }
        return encodeGlobalVariableArgParam(
          (globalVariable.filter((variable) => !!variable) as GlobalVariables[]).map(
            ({ globalFilterId }) => ({
              tagKey: GLOBAL_VARIABLE_TAG_KEY,
              tagType: TAG_TYPE_KEY.GLOBAL_VARIABLE,
              tagValue: `${globalFilterId}`,
            }),
          ),
        );
      }
      default: {
        return (
          getArgParamValue(
            widgetVariable.widgetId,
            widgetVariable.filterId,
            widgetVariable.timeType,
          ) ?? ''
        );
      }
    }
  };

  const extractArgParamTag = (widgetVariable: WidgetVariable): ArgParam[keyof ArgParam]['tags'] => {
    switch (widgetVariable.type) {
      case 'globalVariable':
        return true;
      default:
        return undefined;
    }
  };

  const setArgumentParamValue = (
    statId: string,
    argumentNames: Readonly<string[]>,
    variables: ReferenceVariable[],
  ) => {
    const args: ArgParam = {};
    argumentNames?.forEach((argumentName) => {
      const findValue = variables?.find(
        ({ key }) => key.statId === statId && key.argumentName === argumentName,
      )?.value;
      args[argumentName] = {
        value: findValue == null ? '' : extractArgParamValue(findValue),
        tags: findValue == null ? undefined : extractArgParamTag(findValue),
      };
    });
    return args;
  };

  const getArgParams = (
    chartType: WidgetChartType,
    chartDataIndex: number,
    statId: string,
    category: string,
    referenceInfo = _referenceInfo.value,
  ): ArgParam[] | undefined => {
    let args: ArgParam = {};

    const commonArguments = WidgetCommonArgument[chartType];
    const commonReferenceInfo = referenceInfo?.[`${CommonChartDataIndex}`];
    if (commonArguments) {
      args = { ...setArgumentParamValue(CommonStatId, commonArguments, commonReferenceInfo) };
    }
    const argumentNames = getArgumentList(commonArguments, statId, category);
    const referenceInfoValue = referenceInfo?.[`${chartDataIndex}`];
    args = { ...args, ...setArgumentParamValue(statId, argumentNames, referenceInfoValue) };

    if (Object.values(args).some(({ tags }) => tags === true)) {
      const referenceGlobalVariableIds = Object.values(args)
        .filter(({ tags }) => tags === true)
        .map(
          ({ value }) => decodeGlobalVariableArgParam(value)?.map(({ tagValue }) => tagValue) ?? [],
        );

      args.userTags = {
        userTags: true,
        value: encodeGlobalVariableArgParam(
          referenceGlobalVariableIds.reduce<Parameters<typeof encodeGlobalVariableArgParam>[0]>(
            (acc, id) => {
              const userTags = getRequestUserTags(id);

              userTags.forEach(({ tagKey, tagType, tagValue }) => {
                (tagValue ?? []).forEach((value) => {
                  acc.push({
                    tagKey: tagKey ?? '',
                    tagType: tagType ?? TAG_TYPE_KEY.GLOBAL_VARIABLE,
                    tagValue: value ?? '',
                  });
                });
              });

              return acc;
            },
            [],
          ),
        ),
      };
    }

    return [args];
  };

  return { getArgParams };
};

// NOTE: request에서 tags가 있는 경우, targetIds property는 무시됩니다.
export const assignReferenceParams = <ParamType extends Exclude<RequestType, 'TopologyV7Request'>>(
  param: Omit<ParamType, 'argParam'> & { id: string; argParam?: ArgParam[] },
  createType: WidgetCreateType,
) => {
  const { getFilteredTags } = useWidgetDataParams();
  const { selectedGlobalVariables } = storeToRefs(useGlobalFilterStore());

  const getArgParamTags = (argParamTags: string) => {
    const globalVariableIds = decodeGlobalVariableArgParam(argParamTags)?.map(
      ({ tagValue }) => tagValue,
    );
    const targets: WidgetTarget[] = selectedGlobalVariables.value
      .filter(({ globalFilterId }) => globalVariableIds?.includes(`${globalFilterId}`))
      .map(({ globalFilterId }) => ({
        tagKey: GLOBAL_VARIABLE_TAG_KEY,
        tagType: TAG_TYPE_KEY.GLOBAL_VARIABLE,
        tagValue: `${globalFilterId}`,
        tagValueId: `${globalFilterId}`,
      }));
    return getFilteredTags({ targets });
  };

  if (createType === 'reference') {
    const firstArgParam = param.argParam?.at(0);
    // NOTE: 커스텀 지표로 사용할 경우, targetIds가 적용되어야하기 때문에 tags값을 undefined로 만들어줘야합니다.
    const argParamFromTime = decodeArgParam(firstArgParam?.fromTime?.value) ?? '';
    const argParamToTime = decodeArgParam(firstArgParam?.toTime?.value) ?? '';
    if (!isCustomStatId(param.dataId)) {
      const argParamTags = firstArgParam?.targetIds?.value;
      const isGlobalVariableTargetId = firstArgParam?.targetIds?.tags === true;
      const { tags = [], userTags = [] } =
        argParamTags && isGlobalVariableTargetId ? getArgParamTags(argParamTags) : {};
      return {
        ...param,
        fromTime: argParamFromTime,
        toTime: argParamToTime,
        interval:
          argParamFromTime && argParamToTime
            ? getDataArrangeIntervalByTimeRange(+dayjs(argParamToTime) - +dayjs(argParamFromTime))
            : undefined,
        targetIds: isGlobalVariableTargetId
          ? undefined
          : argParamTags
              ?.split(',')
              .map((id) => decodeArgParam(id))
              .filter((id) => !!id),
        tags,
        userTags,
        period: undefined,
        argParam: undefined,
      };
    }
    return {
      ...param,
      period: undefined,
      fromTime: argParamFromTime,
      toTime: argParamToTime,
    };
  }
  return {
    ...param,
    argParam: undefined,
  };
};
