const MAIN_FRAMES = {
  HOST_VIEW: 'Host View',
  HOST_DETAIL: 'Host Detail',
  HOST_PROCESS_DETAIL: 'Process Detail - Host',
  HOST_NETWORK_DETAIL: 'Network Detail - Host',
  HOST_DISK_DETAIL: 'Disk Detail - Host',
  HOST_ALERT_DETAIL: 'Alert Detail - Host',

  CONTAINER_VIEW: 'Container View',
  CONTAINER_DETAIL: 'Container Detail',
  CONTAINER_ALERT_DETAIL: 'Alert Detail - Container',

  NETWORK_DEVICE_VIEW: 'Network Device View',
  NETWORK_DEVICE_DETAIL: 'Network Device Detail',
  NETWORK_DEVICE_ALERT_DETAIL: 'Alert Detail - Network Device',

  NPM: 'Network Performance Monitoring View',
  NETWORK_OBJECT_DETAIL: 'Network Object Detail',
  NETWORK_OBJECT_ALERT_DETAIL: 'Alert Detail - Network Object',
} as const;

export const INFRA_FRAME_NAMES = {
  // Host
  HOST_VIEW: {
    FILTERS: `${MAIN_FRAMES.HOST_VIEW}/Filters`,
    HOST_LIST: `${MAIN_FRAMES.HOST_VIEW}/Host List`,
    ALERT_COUNT: `${MAIN_FRAMES.HOST_VIEW}/Alert Count`,
  },
  HOST_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.HOST_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.HOST_DETAIL}/Metric`,
    PROCESS: `${MAIN_FRAMES.HOST_DETAIL}/Process`,
    NETWORK: `${MAIN_FRAMES.HOST_DETAIL}/Network`,
    DISK: `${MAIN_FRAMES.HOST_DETAIL}/Disk`,
    CONTAINER: `${MAIN_FRAMES.HOST_DETAIL}/Container`,
    INSTALLED_PACKAGE: `${MAIN_FRAMES.HOST_DETAIL}/Installed Package`,
    RELATIONAL_INFORMATION: `${MAIN_FRAMES.HOST_DETAIL}/Relational Information`,
    AGENT: `${MAIN_FRAMES.HOST_DETAIL}/Agent`,
    ALERT: `${MAIN_FRAMES.HOST_DETAIL}/Alert`,
  },
  K8S_NODE_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.HOST_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.HOST_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.HOST_DETAIL}/Clear System Alert`,
  },
  HOST_PROCESS_DETAIL: {
    LIMITS: `${MAIN_FRAMES.HOST_PROCESS_DETAIL}/Limits`,
    PROCESS_STAT: `${MAIN_FRAMES.HOST_PROCESS_DETAIL}/Process Stat`,
  },
  HOST_NETWORK_DETAIL: {
    NETWORK_STAT: `${MAIN_FRAMES.HOST_NETWORK_DETAIL}/Network Stat`,
  },
  HOST_DISK_DETAIL: {
    DISK_STAT: `${MAIN_FRAMES.HOST_DISK_DETAIL}/Disk Stat`,
  },
  HOST_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.HOST_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.HOST_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.HOST_ALERT_DETAIL}/Clear System Alert`,
  },

  // Container
  CONTAINER_VIEW: {
    FILTERS: `${MAIN_FRAMES.CONTAINER_VIEW}/Filters`,
    CONTAINER_LIST: `${MAIN_FRAMES.CONTAINER_VIEW}/Container List`,
    ALERT_COUNT: `${MAIN_FRAMES.CONTAINER_VIEW}/Alert Count`,
  },
  CONTAINER_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.CONTAINER_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.CONTAINER_DETAIL}/Metric`,
    RELATIONAL_INFORMATION: `${MAIN_FRAMES.CONTAINER_DETAIL}/Relational Information`,
    LOG: `${MAIN_FRAMES.CONTAINER_DETAIL}/Log`,
    APPLICATION_GET: `${MAIN_FRAMES.CONTAINER_DETAIL}/[GET] Application`,
    ACTIVES: `${MAIN_FRAMES.CONTAINER_DETAIL}/Actives`,
    ACTIVES_DETAIL: `${MAIN_FRAMES.CONTAINER_DETAIL}/Actives Detail`,
    ACTIVES_DETAIL_KILL_THREAD: `${MAIN_FRAMES.CONTAINER_DETAIL}/Actives Detail - Kill Thread`,
    DATA_SOURCE: `${MAIN_FRAMES.CONTAINER_DETAIL}/Data Source`,
    TRANSACTIONS: `${MAIN_FRAMES.CONTAINER_DETAIL}/Transactions`,
    ALERT: `${MAIN_FRAMES.CONTAINER_DETAIL}/Alert`,
  },
  CONTAINER_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CONTAINER_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CONTAINER_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CONTAINER_ALERT_DETAIL}/Clear System Alert`,
  },

  // Network Device
  NETWORK_DEVICE_VIEW: {
    DEVICE_LIST: `${MAIN_FRAMES.NETWORK_DEVICE_VIEW}/Device List`,
    FILTERS: `${MAIN_FRAMES.NETWORK_DEVICE_VIEW}/Filters`,
    TOP_5_LIST: `${MAIN_FRAMES.NETWORK_DEVICE_VIEW}/Top 5 List`,
    ALERT_COUNT: `${MAIN_FRAMES.NETWORK_DEVICE_VIEW}/Alert Count`,
  },
  NETWORK_DEVICE_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/Information`,
    INTERFACE: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/Interface`,
    METRIC: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/Metric`,
    SYSLOG: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/SysLog`,
    SNMP_TRAP: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/SNMP Trap`,
    ALERT: `${MAIN_FRAMES.NETWORK_DEVICE_DETAIL}/Alert`,
  },
  NETWORK_DEVICE_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.NETWORK_DEVICE_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.NETWORK_DEVICE_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.NETWORK_DEVICE_ALERT_DETAIL}/Clear System Alert`,
  },

  // NPM
  NPM: {
    FILTERS: `${MAIN_FRAMES.NPM}/Filters`,
    OVERVIEW: `${MAIN_FRAMES.NPM}/Overview`,
    PERFORMANCE_TREND_MONITORING: `${MAIN_FRAMES.NPM}/Performance Trend Monitoring`,
    NETWORK_TOPOLOGY: `${MAIN_FRAMES.NPM}/Network Topology`,
    ALERT_COUNT: `${MAIN_FRAMES.NETWORK_OBJECT_ALERT_DETAIL}/Alert Count`,
  },
  NETWORK_OBJECT_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.NETWORK_OBJECT_DETAIL}/Information`,
    METRIC: `${MAIN_FRAMES.NETWORK_OBJECT_DETAIL}/Metric`,
    SESSION: `${MAIN_FRAMES.NETWORK_OBJECT_DETAIL}/Session`,
    ALERT: `${MAIN_FRAMES.NETWORK_OBJECT_DETAIL}/Alert`,
  },
  NETWORK_OBJECT_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.NETWORK_OBJECT_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.NETWORK_OBJECT_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.NETWORK_OBJECT_ALERT_DETAIL}/Clear System Alert`,
  },
} as const;
