/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  AddExtraServerInfoRequest,
  ExtraGlobalFilterRequest,
  ExtraGlobalFilterResponse,
  ExtraServerListResponse,
  ExtraServerResponse,
  ModifyExtraServerInfoRequest,
  PrometheusStatusRequest,
  ResultResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getExtraV8ControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ExtraServerListResponse> => {
  const localVarPath = '/api/v8/extra-server/get';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getByExtraIdExtraV8ControllerAxios = ({
  extraId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  extraId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ExtraServerResponse> => {
  const localVarPath = '/api/v8/extra-server/get/{extraId}'.replace(
    `{${'extraId'}}`,
    encodeURIComponent(String(extraId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getByExtraTypeExtraV8ControllerAxios = ({
  extraType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  extraType: 'ALL' | 'ELASTICSEARCH' | 'PROMETHEUS';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ExtraServerListResponse> => {
  const localVarPath = '/api/v8/extra-server/get/extraType';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      extraType,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getExtraGlobalFiltersExtraV8ControllerAxios = ({
  dashboardId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ExtraGlobalFilterResponse> => {
  const localVarPath = '/api/v8/extra-server/global-filter/get';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dashboardId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getExtraGlobalFilterExtraV8ControllerAxios = ({
  dashboardId,
  extraGlobalFilterId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  extraGlobalFilterId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ExtraGlobalFilterResponse> => {
  const localVarPath = '/api/v8/extra-server/global-filter/get/{extraGlobalFilterId}'.replace(
    `{${'extraGlobalFilterId'}}`,
    encodeURIComponent(String(extraGlobalFilterId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dashboardId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const modifyExtraV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: ModifyExtraServerInfoRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/extra-server';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const removeExtraV8ControllerAxios = ({
  extraIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  extraIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/extra-server/delete';
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      extraIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addExtraV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: AddExtraServerInfoRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/extra-server';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addOrUpdateExtraGlobalFilterExtraV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: ExtraGlobalFilterRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v8/extra-server/global-filter';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const testExtraV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: PrometheusStatusRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/extra-server/test';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getExtraV8ControllerAxios,
  getByExtraIdExtraV8ControllerAxios,
  getByExtraTypeExtraV8ControllerAxios,
  getExtraGlobalFiltersExtraV8ControllerAxios,
  getExtraGlobalFilterExtraV8ControllerAxios,
  modifyExtraV8ControllerAxios,
  removeExtraV8ControllerAxios,
  addExtraV8ControllerAxios,
  addOrUpdateExtraGlobalFilterExtraV8ControllerAxios,
  testExtraV8ControllerAxios,
};
