export const VIEW_MODE = {
  EXEMONE: 'exemone',
  MAXGAUGE: 'maxgauge',
  INTERMAX: 'intermax',
  CLOUDMOA: 'cloudmoa',
} as const;

export const PLATFORM = {
  INFRA: 'infrastructure',
  MESSAGE_QUEUE: 'messageQueue',
  KUBE: 'kubernetes',
  APP: 'application',
  DB: 'database',
  CLOUD: 'cloud',
} as const;

export const MAXGAUGE_MODE_TARGET_LIST = ['database', 'host'] as const;

export const LOGIN_CUSTOMER = {
  EXEMONE: 'EXEMONE',
  HYUNDAICAPITAL: 'HYUNDAICAPITAL',
  HYUNDAICARD: 'HYUNDAICARD',
  LGE: 'LGE',
} as const;

export const SERVER_TAG_SEPARATOR = 'ect_::_';
