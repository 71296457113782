<script setup lang="ts">
import { type Emit, type Props, setup } from './alertStatusButtonRow.setup';

const props = withDefaults(defineProps<Props>(), {
  statusList: () => [],
});
const emit = defineEmits<Emit>();

const { isClickable, getIconType, onClickStatusButton } = setup(emit);
</script>

<template>
  <div class="alert-status-button-row">
    <button
      v-for="status in props.statusList"
      :key="`${props.rowIndex}_${status.methodType}`"
      :class="['alert-status-button', { 'alert-status-button--clickable': isClickable }]"
      type="button"
      @click="() => onClickStatusButton(status)"
    >
      <i
        :class="[
          'alert-status-button__icon',
          `alert-status-button__icon--${getIconType(status.methodType)}`,
        ]"
      />
      <span :class="['alert-status-button__badge', status.success ? 'succeed' : 'fail']">
        <ev-icon :icon="status.success ? 'icon-check-circle' : 'icon-error-circle'" />
      </span>
    </button>
  </div>
</template>

<style lang="scss">
$notification-icon-types: slack, telegram, mail, sms, webhook;
$base-path: '@/common/assets/logo-';
.alert-status-button-row {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  width: 100%;
  height: 100%;
}
.alert-status-button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: transparent;

  &--clickable {
    cursor: pointer;
  }

  &__icon {
    width: 100%;
    height: 100%;
    background-size: 18px 18px;

    @each $name in $notification-icon-types {
      &--#{$name} {
        background: url(#{$base-path}#{$name}.svg) no-repeat center center;
      }
    }

    &--on-site {
      background: url('@/common/assets/sparrow/icon-sparrow-color-on-site.svg') no-repeat center
        center;
    }
  }

  &__badge {
    display: flex;
    position: absolute;
    top: 1px;
    right: -7px;
    align-items: center;
    justify-content: center;
    padding: 2px 4px;
    border-radius: 5px;

    &.succeed {
      background-color: #50bc5e;
    }

    &.fail {
      background-color: #ff7171;
    }

    i {
      width: 11px;
      min-width: 11px !important;
      height: 11px;
      min-height: 11px !important;
      background-color: var(--color-d-gray-00);
      mask-size: contain;
    }
  }
}
</style>
