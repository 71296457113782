/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-empty-function */
import { UUID } from '@/common/utils';
import { METRICS_TYPES } from './metricsAggregation.defines';
import { MetricsAggregationFactory } from './MetricsAggregationFactory';

export type MetricsAggregationsType = (typeof METRICS_TYPES)[number];

export abstract class MetricsAggregation {
  constructor(
    public id: UUID,
    public type: MetricsAggregationsType,
    public show = true,
  ) {}

  abstract createClone(id: UUID): MetricsAggregation;

  abstract isValid(): boolean;

  abstract configure(config: unknown): void;

  abstract changeType(type: MetricsAggregationsType): MetricsAggregation;
}

class CountMetricsAggregationConfig {
  constructor(public field: string = '') {}
}

export class CountMetricsAggregations extends MetricsAggregation {
  public config: CountMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<CountMetricsAggregationConfig>) {
    super(id, 'count', show);
    this.config = new CountMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new CountMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return true;
  }

  override configure(config: Partial<CountMetricsAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    if (
      newMetric instanceof AvgMetricsAggregations ||
      newMetric instanceof MaxMetricsAggregations ||
      newMetric instanceof MinMetricsAggregations ||
      newMetric instanceof SumMetricsAggregations
    ) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof PercentilesMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof RateMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof StatsMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof TopMetricsMetricsAggregations) {
      newMetric.configure({ metrics: this.config.field ? [this.config.field] : [] });
    }
    return newMetric;
  }
}

class BasicStatsMetricsAggregationConfig {
  constructor(
    public field: string = '',
    public script: string = '',
    public missing: number | undefined = undefined,
  ) {}
}

export class AvgMetricsAggregations extends MetricsAggregation {
  public config: BasicStatsMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<BasicStatsMetricsAggregationConfig>) {
    super(id, 'avg', show);
    this.config = new BasicStatsMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new AvgMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<BasicStatsMetricsAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    if (newMetric instanceof CountMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (
      newMetric instanceof MaxMetricsAggregations ||
      newMetric instanceof MinMetricsAggregations ||
      newMetric instanceof SumMetricsAggregations
    ) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof PercentilesMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof RateMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof StatsMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof TopMetricsMetricsAggregations) {
      newMetric.configure({ metrics: this.config.field ? [this.config.field] : [] });
    }
    return newMetric;
  }
}

export class MaxMetricsAggregations extends MetricsAggregation {
  public config: BasicStatsMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<BasicStatsMetricsAggregationConfig>) {
    super(id, 'max', show);
    this.config = new BasicStatsMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new MaxMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<BasicStatsMetricsAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    if (newMetric instanceof CountMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (
      newMetric instanceof AvgMetricsAggregations ||
      newMetric instanceof MinMetricsAggregations ||
      newMetric instanceof SumMetricsAggregations
    ) {
      newMetric.configure({ ...this.config });
    } else if (newMetric instanceof PercentilesMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof RateMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof StatsMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof TopMetricsMetricsAggregations) {
      newMetric.configure({ metrics: this.config.field ? [this.config.field] : [] });
    }
    return newMetric;
  }
}

export class MinMetricsAggregations extends MetricsAggregation {
  public config: BasicStatsMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<BasicStatsMetricsAggregationConfig>) {
    super(id, 'min', show);
    this.config = new BasicStatsMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new MinMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<BasicStatsMetricsAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    if (newMetric instanceof CountMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (
      newMetric instanceof AvgMetricsAggregations ||
      newMetric instanceof MaxMetricsAggregations ||
      newMetric instanceof SumMetricsAggregations
    ) {
      newMetric.configure({ ...this.config });
    } else if (newMetric instanceof PercentilesMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof RateMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof StatsMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof TopMetricsMetricsAggregations) {
      newMetric.configure({ metrics: this.config.field ? [this.config.field] : [] });
    }
    return newMetric;
  }
}

export class SumMetricsAggregations extends MetricsAggregation {
  public config: BasicStatsMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<BasicStatsMetricsAggregationConfig>) {
    super(id, 'sum', show);
    this.config = new BasicStatsMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new SumMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<BasicStatsMetricsAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    if (newMetric instanceof CountMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (
      newMetric instanceof AvgMetricsAggregations ||
      newMetric instanceof MaxMetricsAggregations ||
      newMetric instanceof MinMetricsAggregations
    ) {
      newMetric.configure({ ...this.config });
    } else if (newMetric instanceof PercentilesMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof RateMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof StatsMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof TopMetricsMetricsAggregations) {
      newMetric.configure({ metrics: this.config.field ? [this.config.field] : [] });
    }
    return newMetric;
  }
}

class PercentilesMetricsAggregationConfig {
  constructor(
    public field: string = '',
    public percentiles: string = '25, 50, 75, 95, 99',
    public script: string = '',
    public missing: string = '',
  ) {}
}

export class PercentilesMetricsAggregations extends MetricsAggregation {
  public config: PercentilesMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<PercentilesMetricsAggregationConfig>) {
    super(id, 'percentiles', show);
    this.config = new PercentilesMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new PercentilesMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<PercentilesMetricsAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    if (newMetric instanceof CountMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (
      newMetric instanceof AvgMetricsAggregations ||
      newMetric instanceof MaxMetricsAggregations ||
      newMetric instanceof MinMetricsAggregations ||
      newMetric instanceof SumMetricsAggregations
    ) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof RateMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof StatsMetricsAggregations) {
      newMetric.configure({
        field: this.config.field,
        missing: this.config.missing,
      });
    } else if (newMetric instanceof TopMetricsMetricsAggregations) {
      newMetric.configure({ metrics: this.config.field ? [this.config.field] : [] });
    }
    return newMetric;
  }
}

class RateMetricsAggregationConfig {
  constructor(
    public field: string = '',
    public unit: string = 'second',
    public mode: string = 'sum',
    public script: string = '',
  ) {}
}

export class RateMetricsAggregations extends MetricsAggregation {
  public config: RateMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<RateMetricsAggregationConfig>) {
    super(id, 'rate', show);
    this.config = new RateMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new RateMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<RateMetricsAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    if (newMetric instanceof CountMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (
      newMetric instanceof AvgMetricsAggregations ||
      newMetric instanceof MaxMetricsAggregations ||
      newMetric instanceof MinMetricsAggregations ||
      newMetric instanceof SumMetricsAggregations
    ) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof PercentilesMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof StatsMetricsAggregations) {
      newMetric.configure({ field: this.config.field, script: this.config.script });
    } else if (newMetric instanceof TopMetricsMetricsAggregations) {
      newMetric.configure({ metrics: this.config.field ? [this.config.field] : [] });
    }
    return newMetric;
  }
}

class StatsMetricsAggregationConfig {
  constructor(
    public field: string = '',
    public stats: string[] = ['avg', 'count', 'max', 'min', 'sum'],
    public script: string = '',
    public missing: string = '',
  ) {}
}

export class StatsMetricsAggregations extends MetricsAggregation {
  public config: StatsMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<StatsMetricsAggregationConfig>) {
    super(id, 'stats', show);
    this.config = new StatsMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new StatsMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<StatsMetricsAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    if (newMetric instanceof CountMetricsAggregations) {
      newMetric.configure({ field: this.config.field });
    } else if (
      newMetric instanceof AvgMetricsAggregations ||
      newMetric instanceof MaxMetricsAggregations ||
      newMetric instanceof MinMetricsAggregations ||
      newMetric instanceof SumMetricsAggregations
    ) {
      newMetric.configure({ field: this.config.field });
    } else if (newMetric instanceof PercentilesMetricsAggregations) {
      newMetric.configure({ field: this.config.field, missing: this.config.missing });
    } else if (newMetric instanceof RateMetricsAggregations) {
      newMetric.configure({ field: this.config.field, script: this.config.script });
    } else if (newMetric instanceof TopMetricsMetricsAggregations) {
      newMetric.configure({ metrics: this.config.field ? [this.config.field] : [] });
    }
    return newMetric;
  }
}

class TopMetricsMetricsAggregationConfig {
  constructor(
    public metrics: string[] = [],
    public order: string = 'asc',
    public orderBy: string = '',
  ) {}
}

export class TopMetricsMetricsAggregations extends MetricsAggregation {
  public config: TopMetricsMetricsAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<TopMetricsMetricsAggregationConfig>) {
    super(id, 'topMetrics', show);
    this.config = new TopMetricsMetricsAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new TopMetricsMetricsAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.metrics.filter((metric) => metric.trim() !== '').length > 0;
  }

  override configure(config: Partial<TopMetricsMetricsAggregationConfig>) {
    Object.assign(this.config, config);
    if (this.config.order === 'top') {
      this.config.order = 'asc';
    } else if (this.config.order === 'bottom') {
      this.config.order = 'desc';
    }
  }

  override changeType(type: MetricsAggregationsType) {
    const newMetric = MetricsAggregationFactory.createMetricsAggregation(type);
    newMetric.id = this.id;
    return newMetric;
  }
}
