import { Module } from 'vuex';
import { RootState } from '@/common/store';
import { roundToDigitNumber, getAPIErrorStatusText } from '@/common/utils/commonUtils';
import { SlowQueryItemV7 } from '@/openapi/mysqlV7/model';
import { getMultiInstanceSlowPostMysqlV7ControllerAxios } from '@/openapi/mysqlV7/api/mysql-v7-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { ONE_SECOND, TimeRangeInfo } from '@/common/utils';

interface State {
  slowQueries: SlowQueryItemV7[];
  queryTime: TimeRangeInfo;
  errorStatusText: string;
}

export const slowQuery: Module<State, RootState> = {
  namespaced: true,
  state: {
    slowQueries: [],
    queryTime: {
      fromTime: '',
      toTime: '',
    },
    errorStatusText: '',
  },
  mutations: {
    setSlowQueries: (state: State, data: SlowQueryItemV7[]) => {
      state.slowQueries = data;
    },
    setSlowQueryQueryTime: (state: State, { fromTime, toTime }: TimeRangeInfo) => {
      state.queryTime.fromTime = fromTime;
      state.queryTime.toTime = toTime;
    },
    setErrorStatusText: (state: State, errorStatusText: string) => {
      state.errorStatusText = errorStatusText;
    },
  },
  actions: {
    async fetchSlowQueries({ commit }, instanceIds: string[]) {
      const frameName = 'SlowQuery';
      try {
        const {
          data: { data, query },
        } = await getMultiInstanceSlowPostMysqlV7ControllerAxios({
          frameName: FRAME_NAMES.MYSQL_MULTI_VIEW.SLOW_QUERY,
          queryParam: {
            instanceIds,
            period: 'p3m',
          },
        });

        commit('setSlowQueries', data);
        commit('setSlowQueryQueryTime', query);
        commit('setErrorStatusText', '');
        commit('mysqlMultiViewEnv/deleteFramesByFailedApi', frameName, { root: true });
      } catch (e: any) {
        const statusText = getAPIErrorStatusText(e);
        commit('setErrorStatusText', statusText);
        commit('mysqlMultiViewEnv/setFramesByFailedApi', { frameName, statusText }, { root: true });
      }
    },
  },
  getters: {
    getSlowQueries: (state: State) => state.slowQueries,
    getSlowQueriesSecTime: (state: State) =>
      state.slowQueries.map((value) => ({
        ...value,
        slows: value.slows!.map((slow) => ({
          ...slow,
          queryTime: roundToDigitNumber(slow.queryTime! / ONE_SECOND),
        })),
      })),
    getSlowQueryQueryTime: (state: State) => state.queryTime,
    getErrorStatusText: (state: State) => state.errorStatusText,
  },
};
