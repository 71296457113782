import { computed } from 'vue';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { useInstanceStore } from '@/common/stores/instance';
import router from '@/common/router';
import { MenuInfo } from '../types';
import { BASE_MENU_VALUE } from '../baseMenu.define';

export interface Props {
  menuInfo: MenuInfo;
  selected?: boolean;
}

export const setup = (props: Props) => {
  const { openSubPanel, closeSubPanel, selectCategory, selectSubPanelMenu } = useBaseMenuStore();
  const { changeGNBInstance } = useInstanceStore();

  const computedChildrenLength = computed(() => props.menuInfo.subMenuList?.length ?? 0);

  const isChildMenuAvailable = computed(() => {
    if (props.menuInfo.value === BASE_MENU_VALUE.DASHBOARD) return true;
    return !!props.menuInfo.subMenuList && props.menuInfo.subMenuList.length;
  });

  const onClickElement = () => {
    if (props.menuInfo.subMenuList) {
      // (우측 패널로 열리는) 하위 메뉴가 있을 경우
      openSubPanel(props.menuInfo);
      const firstSubMenu = props.menuInfo.subMenuList?.[0];
      if (!firstSubMenu) {
        return;
      }
      let routerPath: string | undefined;
      if (firstSubMenu.children?.length) {
        routerPath = firstSubMenu.children[0].path;
        selectSubPanelMenu(firstSubMenu.children?.[0].value);
      } else {
        routerPath = firstSubMenu.path;
        selectSubPanelMenu(firstSubMenu.value);
      }
      if (!routerPath) {
        return;
      }
      router.push(routerPath);
      return;
    }
    // 하위 메뉴가 없을 경우
    selectCategory({ subMenuValue: props.menuInfo.value });
    if (!props.menuInfo.path) {
      return;
    }

    closeSubPanel();

    const [, rootPath, secondPath] = props.menuInfo.path.split('/');
    if (secondPath === 'multiView' || secondPath === 'singleView') {
      changeGNBInstance(rootPath, secondPath);
      return;
    }
    if (props.menuInfo.path !== router.currentRoute.value.path) router.push(props.menuInfo.path);
  };

  return { computedChildrenLength, isChildMenuAvailable, onClickElement };
};
