import { useRoute, useRouter } from 'vue-router';
import { computed, Ref, ref, watch } from 'vue';
import { Command } from '@/worker/commands';
import { CommandOptions, useCommand } from '@/worker/composables/useCommand';
import { useAuthStore } from '@/common/stores/auth';
import { isEmpty, lowerCase, upperCase } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useViewManagerStore } from '@/common/stores/view-manager';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { useRolePermission } from '@/common/permission/permission.utils';
import { store } from '@/common/store';
import { getUtcOffset } from '@/common/utils/commonUtils';
import { OnSiteAlertParams } from '@/worker/commands/common/onSiteAlert';
import { ONE_HOUR, ONE_SECOND } from '@/common/utils';
import { LicenseOnSiteAlertItem, OnSiteAlertItem } from '@/openapi/alert/model';
import { OnSiteLicenseParams } from '@/worker/commands/common/onSiteLicense';
import { useInternational } from '@/common/locale';
import { getDisplayLicenseTargetName } from '@/config/views/management/license/license.util';

type Alarm = {
  type: 'alert' | 'license';
  data: {
    id: string;
    title: string;
    content: string;
    level?: 'critical' | 'warning';
    count: number;
  };
  isShow: boolean;
  isTriggered: boolean;
  theme: 'critical' | 'warning';
  cursor: 'pointer' | 'wait' | 'not-allowed';
  onClose: () => void;
  onMoveToView: () => Promise<void>;
};

const HIDE_ALARM_URLS = ['/', '/popup'] as const;

export const ON_SITE_ALERT_FROM = 'onSiteAlert';
export const ON_SITE_LICENSE_FROM = 'onSiteLicense';

const useOnsiteApi = <Data>({
  command,
  options,
}: {
  command: Command<'common'>;
  options: CommandOptions & {
    ignoreEnableConfig?: boolean;
  };
}) => {
  const FETCH_CYCLE = options?.repeatInfo.repeatFetchInfo[0];

  const { data, error, fetchData, clearFetch } = useCommand<Data>(command, options);
  let isRepeating = false;

  const initData = () => {
    data.value = {};
  };

  const fetch = () => {
    isRepeating = true;
    fetchData({}, FETCH_CYCLE, true);
  };

  const clear = () => {
    isRepeating = false;
    clearFetch!();
  };

  const setParams = () => {
    isRepeating = true;
    command.params.value = {
      utcOffset: getUtcOffset(),
      userId: store.getters['myInfo/getAccountInfo']?.userId,
    };
  };

  const onSiteEnabled = computed(
    () => store.getters['myInfo/getAccountInfo']?.onSiteEnabled === true,
  );
  const accessToken = computed(() => useAuthStore().getToken('accessToken'));
  const route = useRoute();

  watch(
    [() => accessToken.value, () => onSiteEnabled.value, () => route.path],
    ([curToken, curOnSiteEnabled, curPath]) => {
      if (HIDE_ALARM_URLS.includes(curPath)) {
        clear();
        return;
      }

      const isCallable = curToken && (curOnSiteEnabled || !!options.ignoreEnableConfig);
      if (!isRepeating && isCallable) {
        if (isEmpty(command.params!.value)) {
          setParams();
          return;
        }
        fetch();
      }

      if (isRepeating && !isCallable) {
        clear();
      }
    },
    { immediate: true },
  );

  watch(error, (curError) => {
    if (!isEmpty(curError)) {
      initData();
    }
  });

  return {
    data,
    initData,
  };
};

const useAlertApi = () => {
  const { data, initData } = useOnsiteApi<OnSiteAlertItem>({
    command: {
      namespace: 'common',
      method: 'onSiteAlert',
      params: ref<OnSiteAlertParams | {}>({}),
    },
    options: {
      initialData: {},
      repeatInfo: {
        isRepeat: true,
        isFixed: true,
        isSetup: false,
        isImmediate: false,
        repeatFetchInfo: [ONE_SECOND * 5, 'I5s'],
      },
      watchCallback: (_curParams, _prevParams, fetch) => {
        fetch();
      },
    },
  });

  return {
    data,
    initData,
  };
};

const useLicenseApi = () => {
  const { data, initData } = useOnsiteApi<
    LicenseOnSiteAlertItem & { kind: 'exceeded' | 'check failed' }
  >({
    command: {
      namespace: 'common',
      method: 'onSiteLicense',
      params: ref<OnSiteLicenseParams | {}>({}),
    },
    options: {
      ignoreEnableConfig: true,
      initialData: {},
      repeatInfo: {
        isRepeat: true,
        isFixed: true,
        isSetup: false,
        isImmediate: false,
        repeatFetchInfo: [ONE_HOUR, 'I1h'],
      },
      watchCallback: (_curParams, _prevParams, fetch) => {
        fetch();
      },
    },
  });

  return {
    data,
    initData,
  };
};

type UseApi = () => {
  data: Ref<any>;
  initData: () => void;
};

const useOnsite = <T extends UseApi>({ useApi }: { useApi: T }) => {
  const route = useRoute();

  const { data, initData } = useApi();

  const { isLoadingRouterForEach: isLoadingView } = storeToRefs(useViewManagerStore());

  const isShow = ref(false);
  const isTriggered = ref(false);
  const cursor = ref<Alarm['cursor']>('pointer');

  const preservedData = ref({ ...data.value });
  watch(data, (curData) => {
    if (!isEmpty(curData)) {
      preservedData.value = curData;
    }
  });

  watch(
    [preservedData, isLoadingView, () => route.path],
    ([curPreservedData, curIsLoadingView, curPath]) => {
      const isShowAvail =
        !HIDE_ALARM_URLS.includes(curPath) && !curIsLoadingView && !isEmpty(curPreservedData);
      if (isShowAvail) {
        cursor.value = 'pointer';
        isShow.value = true;
        setTimeout(() => {
          isTriggered.value = true;
        }, 500);
        return;
      }

      isShow.value = false;
      setTimeout(() => {
        isTriggered.value = false;
      }, 500);
    },
  );

  const onClosePreserved = async (event) => {
    const element = event.target.closest('.on-site-alarm');
    element.classList.add('hide');

    await new Promise((resolve) => setTimeout(resolve, 500));

    element.classList.remove('hide');
    preservedData.value = {};
    initData();
  };

  return {
    isShow,
    isTriggered,
    cursor,
    preservedData,
    onClosePreserved,
  };
};

const useAlert = () => {
  const { isShow, isTriggered, cursor, preservedData, onClosePreserved } = useOnsite<
    typeof useAlertApi
  >({
    useApi: useAlertApi,
  });

  const router = useRouter();
  const { isPermissionDenied } = useRolePermission();

  const onMoveToAlertView = async (event) => {
    const { ruleId, ruleName, ruleType } = preservedData.value;
    if (!ruleId) {
      return;
    }

    cursor.value = 'wait';
    const hasViewPerm = !isPermissionDenied({
      rolePermissionKey: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_LIST,
      type: 'menuOnOff',
    });
    if (!hasViewPerm) {
      cursor.value = 'not-allowed';
      return;
    }

    const queryParams = new URLSearchParams({
      from: ON_SITE_ALERT_FROM,
      ruleId,
      ruleName: ruleName || '',
      ruleType: ruleType || '',
    }).toString();

    await router.push(`/alert/list?${queryParams}`);

    await onClosePreserved(event);
  };

  const alertData = computed<Alarm>(() => ({
    type: 'alert',
    data: {
      ...preservedData.value,
      id: preservedData.value.pushId,
      level: lowerCase(preservedData.value.level),
    },
    isShow: isShow.value,
    isTriggered: isTriggered.value,
    theme: lowerCase(preservedData.value.level),
    cursor: cursor.value,
    onClose: onClosePreserved,
    onMoveToView: onMoveToAlertView,
  }));

  return {
    alertData,
  };
};

const useLicense = () => {
  const { isShow, isTriggered, cursor, preservedData, onClosePreserved } = useOnsite<
    typeof useLicenseApi
  >({
    useApi: useLicenseApi,
  });

  const router = useRouter();
  const { isPermissionDenied } = useRolePermission();

  const onMoveToLicenseView = async () => {
    const hasViewPerm = !isPermissionDenied({
      rolePermissionKey: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_LICENSE,
      type: 'menuOnOff',
    });
    if (!hasViewPerm) {
      cursor.value = 'not-allowed';
      return;
    }

    const { count, platform } = preservedData.value;
    const queryParams = new URLSearchParams({
      from: ON_SITE_LICENSE_FROM,
      platform: count > 0 ? 'overview' : lowerCase(platform),
    }).toString();

    await router.push(`/management/license?${queryParams}`);
  };

  const onMoveToInstanceView = async () => {
    const hasViewPerm = !isPermissionDenied({
      rolePermissionKey: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
      type: 'menuOnOff',
    });
    if (!hasViewPerm) {
      cursor.value = 'not-allowed';
      return;
    }

    const queryParams = new URLSearchParams({
      from: ON_SITE_LICENSE_FROM,
    }).toString();

    await router.push(`/database/instance?${queryParams}`);
  };

  const onMoveToView = async (event) => {
    cursor.value = 'wait';

    const { kind } = preservedData.value;
    if (kind === 'check failed') {
      await onMoveToInstanceView();
    } else {
      await onMoveToLicenseView();
    }

    await onClosePreserved(event);
  };

  const { t } = useInternational();

  const getLocaleByKind = ({ key, kind }: { key: string; kind: string }) => {
    const locale = {
      exceeded: {
        title: t('WORD.EXCEEDED'),
        content: t('MESSAGE.ISSUE_LICENSE'),
      },
      'check failed': {
        title: t('WORD.CHECK'),
        content: t('MESSAGE.CHECK_FAIL_LICENSE'),
      },
    };
    return locale[kind]?.[key] ?? '';
  };

  const licenseData = computed<Alarm>(() => ({
    type: 'license',
    data: {
      ...preservedData.value,
      id: preservedData.value.pushId,
      title: `${t('WORD.LICENSE')} ${getLocaleByKind({
        key: 'title',
        kind: preservedData.value.kind,
      })}`,
      content: `${t('WORD.PLATFORM')} : ${t(
        `WORD.GNB.${upperCase(preservedData.value.platform)}`,
        preservedData.value.platform,
      )}\n${t('WORD.LICENSE')} ${t('WORD.TARGET')} : ${getDisplayLicenseTargetName(
        preservedData.value.licenseType,
      )}\n\n${getLocaleByKind({
        key: 'content',
        kind: preservedData.value.kind,
      })}`,
    },
    isShow: isShow.value,
    isTriggered: isTriggered.value,
    theme: 'critical',
    cursor: cursor.value,
    onClose: onClosePreserved,
    onMoveToView,
  }));

  return {
    licenseData,
  };
};

export const setup = () => {
  const { alertData } = useAlert();
  const { licenseData } = useLicense();

  const alarms = ref<Alarm[]>([]);

  watch(
    [alertData, licenseData],
    (alarmData) => {
      alarmData.forEach((data) => {
        const foundIdx = alarms.value.findIndex(({ type }) => type === data.type);
        if (data.isShow) {
          if (foundIdx === -1) {
            alarms.value.unshift(data);
          } else {
            alarms.value.splice(foundIdx, 1, data);
          }
          return;
        }

        if (foundIdx !== -1) {
          alarms.value.splice(foundIdx, 1);
        }
      });
    },
    { deep: true },
  );

  return {
    alarms,
  };
};
