import { type Fill, type Borders, Workbook } from 'exceljs';
import dayjs from 'dayjs';
import { isNil, isObject } from 'lodash-es';
import BigNumber from 'bignumber.js';
import type { CustomColumn } from '@/common/utils/types';
import { convertMsToSec, fileDownload, roundToDigitNumber } from '@/common/utils/commonUtils';
import type { ExportExcelOption } from './excel.types';
import { colorStringToHex, mergeColorToHex } from '../colorConvertUtil';

const convertRgbaToHex = (rgba?: string | null) => {
  if (isNil(rgba)) {
    return undefined;
  }

  const hex = colorStringToHex(rgba, true).slice(1);
  if (hex.length === 8) {
    return `FF${hex.slice(0, 6)}`;
  }
  return `FF${hex}`;
};

const mergeColorAndConvert = (baseColor?: string, altColor?: string) =>
  convertRgbaToHex(mergeColorToHex(baseColor, altColor));

export const formatRowDataIfObjectOrArray = (rowData: any) => {
  if (isNil(rowData)) {
    return '';
  }
  if (rowData instanceof BigNumber) {
    return rowData.toString();
  }
  if (rowData instanceof Array) {
    return rowData.join(', ');
    // NOTE: 이 줄에서 버그 발생할 경우, rowData 가 객체형 배열이기 때문에 join이 불가능한 것
    // NOTE: 각 BaseGrid exportExcelOption에서 적절한 rowDataFormatter 지정 필요
    // NOTE: 사용자태그 컬럼은 기본적으로 hidden 이기 때문에 해당 함수에 진입 X
  }
  if (isObject(rowData)) {
    return Object.keys(rowData).join(', ');
  }
  return `${rowData}`;
};

export const formatRowData = (rowData: any, columnInfo: CustomColumn) => {
  if (!columnInfo.type) {
    return formatRowDataIfObjectOrArray(rowData);
  }
  const { type } = columnInfo;

  switch (type) {
    case 'float':
      if (!rowData) {
        return `${(0).toFixed(columnInfo.decimal ?? 3)}`;
      }
      return `${rowData.toFixed(columnInfo.decimal ?? 3)}`;
    case 'number':
      if (rowData === 0) {
        return '0';
      }
      if (isNil(rowData)) {
        return '';
      }
      return `${rowData}`;
    case 'string':
    case 'boolean':
    case 'stringNumber':
    default:
      return formatRowDataIfObjectOrArray(rowData);
  }
};

export const styleExcelFile = (
  workbook: Workbook,
  option: ExportExcelOption,
  headerRowLength = 1,
) => {
  const worksheet = workbook.getWorksheet(1);
  if (!worksheet) {
    return;
  }

  const { colorStyle } = option;
  const { cellStyle, rowStyle } = colorStyle || {};
  const commonFill: Fill = {
    type: 'pattern',
    pattern: 'solid',
  };
  const BORDER_RGBA = 'FFD9D9D9';
  const border: Partial<Borders> = {
    top: { style: 'thin', color: { argb: BORDER_RGBA } },
    left: { style: 'thin', color: { argb: BORDER_RGBA } },
    bottom: { style: 'thin', color: { argb: BORDER_RGBA } },
    right: { style: 'thin', color: { argb: BORDER_RGBA } },
  };

  // All Cells
  worksheet.eachRow((row, index) => {
    row.height = 24;
    const rowNumber = index - (headerRowLength + 1);

    const rowBgColor = rowStyle?.[rowNumber]?.bgColor;
    const rowFontColor = rowStyle?.[rowNumber]?.fontColor;
    row.eachCell((cell, colNumber) => {
      const colField = worksheet.getColumn(colNumber).key || '';
      const cellBgColor = cellStyle?.[colField]?.[rowNumber]?.bgColor;
      const cellFontColor = cellStyle?.[colField]?.[rowNumber]?.fontColor;
      const bgColor = mergeColorAndConvert(rowBgColor, cellBgColor);
      const fontColor = mergeColorAndConvert(rowFontColor, cellFontColor);

      cell.fill = {
        ...commonFill,
        fgColor: { argb: bgColor },
      };
      cell.font = {
        color: { argb: fontColor },
      };
      cell.border = border;
    });
  });

  // Top Column
  for (let ix = 1; ix <= headerRowLength; ix++) {
    const headerRow = worksheet.getRow(ix);
    headerRow.height = 30;
    headerRow.alignment = { horizontal: 'center', vertical: 'middle' };
    headerRow.eachCell((cell) => {
      cell.font = {
        size: 13,
        bold: true,
      };
      cell.border = border;
    });
  }
};

export const formatExcelSecFields = ({
  fields,
  toggle: _, // DSP-21544 머지후 삭제예정
}: {
  fields: string[];
  toggle?: string;
}) =>
  fields.reduce((acc, field) => {
    acc[field] = (data: number | string) => convertMsToSec(data, { digits: 3 });
    return acc;
  }, {});

export const formatExcelNumberFields = ({ fields }: { fields: string[] }) =>
  fields.reduce((acc, field) => {
    acc[field] = (data: number) => roundToDigitNumber(data, 3);
    return acc;
  }, {});

export const exportExcelFile = async (
  workbook: Workbook,
  option: ExportExcelOption = {},
  headerRowLength = 1,
) => {
  styleExcelFile(workbook, option, headerRowLength);
  await new Promise((resolve) => setTimeout(resolve, 500));
  const buffer = await workbook.xlsx.writeBuffer();
  const filename = `${option.title ?? 'export'}_${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`;
  fileDownload({ fileName: filename, fileData: buffer, fileType: 'excel' });
};
