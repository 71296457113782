/* eslint-disable class-methods-use-this */
/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
import { UUID } from '@/common/utils';
import { BUCKET_TYPES } from './bucketAggregation.defines';
import { BucketAggregationFactory } from './BucketAggregationFactory';

export type BucketAggregationsType = (typeof BUCKET_TYPES)[number];

export abstract class BucketAggregation {
  constructor(
    public id: UUID,
    public type: BucketAggregationsType,
    public show = true,
  ) {
    this.id = id;
    this.type = type;
  }

  abstract createClone(id: UUID): BucketAggregation;

  abstract isValid(): boolean;

  abstract configure(config: unknown): void;

  abstract changeType(type: BucketAggregationsType): BucketAggregation;
}

class DateHistogramBucketAggregationConfig {
  constructor(
    public field: string = '',
    public interval: string = 'auto',
    public minDocCount: number = 0,
    // public trimEdges: number = 0,
    public offset: string = '',
    // public timezone: string = 'coordinatedUniversalTime',
  ) {}
}

export class DateHistogramBucketAggregations extends BucketAggregation {
  public config: DateHistogramBucketAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<DateHistogramBucketAggregationConfig>) {
    super(id, 'dateHistogram', show);
    this.config = new DateHistogramBucketAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID) {
    const clone = new DateHistogramBucketAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<DateHistogramBucketAggregationConfig>) {
    const newConfig = {
      ...config,
      offset: config.offset ? `${config.offset}` : '',
    };
    Object.assign(this.config, newConfig);
  }

  override changeType(type: BucketAggregationsType) {
    const newBucket = BucketAggregationFactory.createBucketAggregation(type);
    newBucket.id = this.id;
    if (newBucket instanceof HistogramBucketAggregations) {
      newBucket.configure({
        field: this.config.field,
        minDocCount: this.config.minDocCount,
      });
    } else if (newBucket instanceof TermsBucketAggregations) {
      newBucket.configure({
        field: this.config.field,
        minDocCount: this.config.minDocCount,
      });
    }

    return newBucket;
  }
}

class FilterBucketAggregationConfig {
  constructor(
    public queryLabels: {
      query: string;
      label: string;
    }[] = [
      {
        query: '*',
        label: '',
      },
    ],
  ) {}
}

export class FiltersBucketAggregations extends BucketAggregation {
  public config: FilterBucketAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<FilterBucketAggregationConfig>) {
    super(id, 'filters', show);
    this.config = new FilterBucketAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID): BucketAggregation {
    const clone = new FiltersBucketAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.queryLabels.length > 0 && this.config.queryLabels.at(0)?.query.trim() !== '';
  }

  override configure(config: Partial<FilterBucketAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: BucketAggregationsType) {
    return BucketAggregationFactory.createBucketAggregation(type);
  }
}

class HistogramBucketAggregationConfig {
  constructor(
    public field: string = '',
    public interval: number = 1000,
    public minDocCount: number = 0,
  ) {}
}

export class HistogramBucketAggregations extends BucketAggregation {
  public config: HistogramBucketAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<HistogramBucketAggregationConfig>) {
    super(id, 'histogram', show);
    this.config = new HistogramBucketAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID): BucketAggregation {
    const clone = new HistogramBucketAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<HistogramBucketAggregationConfig>) {
    Object.assign(this.config, config);
  }

  override changeType(type: BucketAggregationsType) {
    const newBucket = BucketAggregationFactory.createBucketAggregation(type);
    newBucket.id = this.id;
    if (newBucket instanceof DateHistogramBucketAggregationConfig) {
      newBucket.configure({
        field: this.config.field,
        minDocCount: this.config.minDocCount,
      });
    } else if (newBucket instanceof TermsBucketAggregations) {
      newBucket.configure({
        field: this.config.field,
        minDocCount: this.config.minDocCount,
      });
    }

    return newBucket;
  }
}

class TermsBucketAggregationConfig {
  constructor(
    public field: string = '',
    public order: string = 'asc',
    public size: number = 10,
    public minDocCount: number = 0,
    public orderBy: string = '_term',
    public missing: string = '',
  ) {}
}

export class TermsBucketAggregations extends BucketAggregation {
  public config: TermsBucketAggregationConfig;

  constructor(id: UUID, show?: boolean, options?: Partial<TermsBucketAggregationConfig>) {
    super(id, 'terms', show);
    this.config = new TermsBucketAggregationConfig();
    Object.assign(this.config, options);
  }

  override createClone(id: UUID): BucketAggregation {
    const clone = new TermsBucketAggregations(id, this.show, this.config);
    return clone;
  }

  override isValid() {
    return this.config.field.trim() !== '';
  }

  override configure(config: Partial<TermsBucketAggregationConfig>) {
    Object.assign(this.config, config);
    if (this.config.order === 'top') {
      this.config.order = 'asc';
    } else if (this.config.order === 'bottom') {
      this.config.order = 'desc';
    }
  }

  override changeType(type: BucketAggregationsType) {
    const newBucket = BucketAggregationFactory.createBucketAggregation(type);
    newBucket.id = this.id;
    if (newBucket instanceof DateHistogramBucketAggregationConfig) {
      newBucket.configure({
        field: this.config.field,
        minDocCount: this.config.minDocCount,
      });
    } else if (newBucket instanceof HistogramBucketAggregations) {
      newBucket.configure({
        field: this.config.field,
        minDocCount: this.config.minDocCount,
      });
    }

    return newBucket;
  }
}
