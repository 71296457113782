import dayjs, { Dayjs } from 'dayjs';
import { standardTimeToUtcZeroTime, truthyNumber } from '@/common/utils/commonUtils';
import {
  GLOBAL_TIME_LIVE_FORMAT,
  UTC_TIME_FORMAT,
} from '../components/molecules/timePeriodIndicator/timePeriodIndicator.define';
import { TimeFormat } from '../components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { ONE_DAY_IN_SECOND } from './define';
import { TimePeriodType } from './types';

export const convertSecondToTime = (_seconds: number): string => {
  let seconds = _seconds;
  if (seconds < 0 || !Number.isInteger(seconds)) {
    console.warn('Invalid uptime value');
  }

  const days = Math.floor(seconds / (60 * 60 * 24));
  seconds %= 24 * 60 * 60;

  const hours = Math.floor(seconds / (60 * 60));
  seconds %= 60 * 60;

  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  const result: string[] = [];
  if (days > 0) result.push(`${days}d`);
  if (hours > 0) result.push(`${hours}h`);
  if (minutes > 0) result.push(`${minutes}m`);
  if (seconds > 0 || !result.length) result.push(`${seconds}s`);

  return result.join(' ');
};

export const getSecondTimeRangeByTimePeriod = (timePeriod: TimePeriodType): number => {
  const time = +timePeriod.slice(1, -1);
  const unit = timePeriod.slice(-1);
  let range: number;
  switch (unit) {
    case 'm':
      range = time * 60;
      break;
    case 'h':
      range = time * 3600;
      break;
    case 'd':
      range = time * ONE_DAY_IN_SECOND;
      break;
    default:
      range = time;
  }
  return range;
};

export const getSecondByDays = (days: number): number => {
  return days * ONE_DAY_IN_SECOND;
};

export const getDayBySecond = (second: number): number => {
  return Math.floor(second / ONE_DAY_IN_SECOND);
};

export const convertNanoSecondToMilliSecond = (nanoSecond: string): number => {
  return Math.floor(Number(BigInt(nanoSecond) / BigInt(1_000_000)));
};

export const convertNanoToFomattedTime = (nanoSecond: string): string => {
  return dayjs(convertNanoSecondToMilliSecond(nanoSecond)).format('YYYY-MM-DD HH:mm:ss');
};

export const isDateValid = (date: string): boolean => {
  return dayjs(date).isValid();
};

/**
 *  seconds를 period로 변환
 * @param seconds seconds
 * @example secondsToPeriod(7200) => { unit: 'h', value: 2, locale: 'HOURS' }
 */
export const secondsToPeriod = (
  seconds: number,
): {
  unit: string;
  value: number;
  locale: string;
} => {
  if (Math.floor(seconds / 60) === 0) {
    return {
      unit: 's',
      value: seconds,
      locale: seconds === 1 ? 'SECOND' : 'SECONDS',
    };
  }
  if (Math.floor(seconds / 3600) === 0) {
    return {
      unit: 'm',
      value: Math.floor(seconds / 60),
      locale: Math.floor(seconds / 60) === 1 ? 'MINUTE' : 'MINUTES',
    };
  }
  if (Math.floor(seconds / ONE_DAY_IN_SECOND) === 0) {
    return {
      unit: 'h',
      value: Math.floor(seconds / 3600),
      locale: Math.floor(seconds / 3600) === 1 ? 'HOUR' : 'HOURS',
    };
  }
  return {
    unit: 'd',
    value: Math.floor(seconds / ONE_DAY_IN_SECOND),
    locale: Math.floor(seconds / ONE_DAY_IN_SECOND) === 1 ? 'DAY' : 'DAYS',
  };
};

export const getPeriodToSeconds = (period: string): number => {
  const unit = period?.slice(-1);
  const value = parseInt(period?.slice(0, -1), 10);

  if (truthyNumber(value)) {
    switch (unit) {
      case 's':
        return value;
      case 'm':
        return value * 60;
      case 'h':
        return value * 60 * 60;
      case 'd':
        return value * 60 * 60 * 24;
      case 'M':
      case 'month':
        return value * 60 * 60 * 24 * 31;
      default:
        return -1;
    }
  }

  return -1;
};

export const isDateTime = (value: any) =>
  typeof value === 'string' &&
  /^\d{4}-\d{2}-\d{2}[T\s]\d{2}:\d{2}:\d{2}(.\d+)?/.test(value) &&
  isDateValid(value);

/**
 * utc 타임을 dayjs 타입으로 변환
 * @param utc string | number | Date | dayjs.Dayjs
 * @returns Dayjs
 */
export const getTzTimeFromUtc = (utc: string | number | Date | Dayjs): Dayjs => {
  return dayjs.utc(utc).tz();
};

/**
 * @description utc 타임을 timezone에 맞는 format time으로 변환
 */
export const getFormattedTzTimeFromUtc = (
  utc: string | number | Date | Dayjs,
  format: TimeFormat = GLOBAL_TIME_LIVE_FORMAT,
) => {
  return getTzTimeFromUtc(utc).format(format);
};

/**
 * @description timezone time을 utc time으로 변환
 */
export const getUtcTimeFromTzTime = (tzTime: string | number | Date | Dayjs): Dayjs => {
  return dayjs.tz(tzTime).utc();
};

/**
 * @description timezone time을 utc format으로 변환
 */
export const getFormattedUtcTimeFromTzTime = (
  tzTime: string | number | Date | Dayjs = dayjs.tz(),
  format: TimeFormat = UTC_TIME_FORMAT,
) => {
  return getUtcTimeFromTzTime(tzTime).format(format);
};

/**
 * @description 현재 utc time을 반환
 */
export const getUtcTimeNow = (): Dayjs => {
  return dayjs.utc(); /// ===  dayjs().utc();
};

/**
 * @description 현재 utc format time을 반환
 */
export const getFormattedUtcTimeNow = (format: TimeFormat = UTC_TIME_FORMAT): string => {
  return getUtcTimeNow().format(format);
};

/**
 * @description 현재 timezone에 맞는 time을 반환
 */
export const getTzTimeNow = (): Dayjs => {
  return dayjs.tz();
};

/**
 * @description 현재 timezone에 맞는 format time을 반환
 */
export const getFormattedTzTimeNow = (format: TimeFormat = GLOBAL_TIME_LIVE_FORMAT): string => {
  return getTzTimeNow().format(format);
};

/**
 * @description 두 날짜의 차이가 n일 이상인지 확인
 */
export const isDifferenceMoreThanNDays = (
  date1: dayjs.Dayjs,
  date2: dayjs.Dayjs,
  n: number,
): boolean => {
  const differenceInDays = date1.diff(date2, 'day');
  return Math.abs(differenceInDays) >= n;
};

export const convertRequestTime = () => {
  const currentTime = Date.now();

  return dayjs(currentTime).format('YYYY-MM-DD HH:mm:ss');
};
