/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  CheckGlobalFilterResponse,
  GlobalFilterResponse,
  IntegrateDashboardGlobalFilterRequest,
  IntegrateDashboardUserFilterAddResponse,
  IntegrateDashboardUserFilterRequest,
  IntegrateDashboardUserFilterV7Response,
  SuccessResponse,
  TagsRequest,
  TargetByTagResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getGlobalFilterIntegratedDashboardFilterV7ControllerAxios = ({
  dashboardId,
  globalFilterId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  globalFilterId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<GlobalFilterResponse> => {
  const localVarPath = '/api/v7/dashboards/{dashboardId}/global-filter/get'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      globalFilterId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const checkGlobalFilterUsedIntegratedDashboardFilterV7ControllerAxios = ({
  dashboardId,
  globalFilterId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  globalFilterId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<CheckGlobalFilterResponse> => {
  const localVarPath = '/api/v7/dashboards/{dashboardId}/global-filter/{globalFilterId}/used'
    .replace(`{${'dashboardId'}}`, encodeURIComponent(String(dashboardId)))
    .replace(`{${'globalFilterId'}}`, encodeURIComponent(String(globalFilterId)));
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getUserFilterIntegratedDashboardFilterV7ControllerAxios = ({
  dashboardId,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateDashboardUserFilterV7Response> => {
  const localVarPath = '/api/v7/dashboards/{dashboardId}/user-filter/get'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const deleteUserFilterIntegratedDashboardFilterV7ControllerAxios = ({
  dashboardId,
  userFilterId,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  userFilterId: number;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/{dashboardId}/user_filter/delete'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      userFilterId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateUserFilterIntegratedDashboardFilterV7ControllerAxios = ({
  dashboardId,
  userFilterId,
  name,
  userId,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  userFilterId: number;
  name?: string;
  userId?: number;
  request?: IntegrateDashboardUserFilterRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/{dashboardId}/user_filter/update'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      name,
      userFilterId,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const globalFilterIntegratedDashboardFilterV7ControllerAxios = ({
  dashboardId,
  request,
  deleteGlobalFilterIds,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  request: IntegrateDashboardGlobalFilterRequest;
  deleteGlobalFilterIds?: Array<number>;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v7/dashboards/{dashboardId}/global-filter'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      deleteGlobalFilterIds,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getTargetIdsListByTagIntegratedDashboardFilterV7ControllerAxios = ({
  requests,
  isBusiness,
  isDataTarget,
  targetCategory,
  targetIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  requests: Array<TagsRequest>;
  isBusiness?: boolean;
  isDataTarget?: boolean;
  targetCategory?: string;
  targetIds?: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<TargetByTagResponse> => {
  const localVarPath = '/api/v7/dashboards/global-filter/target-tag-values';
  const httpMethod = 'post';

  const data: any = requests;
  const config: any = {
    params: {
      isBusiness,
      isDataTarget,
      targetCategory,
      targetIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addUserFilterIntegratedDashboardFilterV7ControllerAxios = ({
  dashboardId,
  name,
  request,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  name: string;
  request: IntegrateDashboardUserFilterRequest;
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateDashboardUserFilterAddResponse> => {
  const localVarPath = '/api/v7/dashboards/{dashboardId}/user-filter/add'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      name,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getGlobalFilterIntegratedDashboardFilterV7ControllerAxios,
  checkGlobalFilterUsedIntegratedDashboardFilterV7ControllerAxios,
  getUserFilterIntegratedDashboardFilterV7ControllerAxios,
  deleteUserFilterIntegratedDashboardFilterV7ControllerAxios,
  updateUserFilterIntegratedDashboardFilterV7ControllerAxios,
  globalFilterIntegratedDashboardFilterV7ControllerAxios,
  getTargetIdsListByTagIntegratedDashboardFilterV7ControllerAxios,
  addUserFilterIntegratedDashboardFilterV7ControllerAxios,
};
