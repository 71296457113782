import {
  FilterSearchSpecificFilterFnKey,
  FilterSearchCustomFn,
} from '@/common/components/molecules/filterSearch/filterSearch.type';
import { compareNumberValues } from '@/common/components/molecules/filterSearch/filterSearch.utils';
import { BPS_BY_UNIT_LOWER_CASE, BYTES_BY_UNIT_LOWER_CASE, MS_BY_UNIT } from '@/common/utils';

type Visible = boolean;

const parseTimeValueUnits = (targetValue: string): { value: number; unit: string }[] => {
  const regex = new RegExp(`(\\d+(?:\\.\\d+)?)\\s*(${Object.keys(MS_BY_UNIT).join('|')})?`, 'gi');

  const matches = [...targetValue.matchAll(regex)];

  return matches.map((match) => ({
    value: parseFloat(match[1]),
    unit: match[2],
  }));
};

const parseDataValueUnit = (
  targetValue: string,
): {
  value: string | undefined;
  unit: string | undefined;
} => {
  const regex = /^(\d+(?:\.\d+)?)\s*([kmgtpezy]i?b|bytes?|b)?$/i;
  const match = targetValue.match(regex);
  return { value: match?.[1], unit: match?.[2] };
};

const parseBpsValueUnit = (
  targetValue: string,
): {
  value: string | undefined;
  unit: string | undefined;
} => {
  const regex = /^(\d+(?:\.\d+)?)\s*([kmgt]?i?b(?:it|yte)?\/s|bps|bit|byte)?$/i;
  const match = targetValue.match(regex);
  return { value: match?.[1], unit: match?.[2] };
};

export const stringToMs = (targetValue: string, defaultUnit?: string): number | undefined => {
  const parsedUnits = parseTimeValueUnits(targetValue);

  if (parsedUnits.length === 0) return undefined;

  return parsedUnits.reduce((totalMs, { value, unit }) => {
    const msByUnit = MS_BY_UNIT[unit] ?? MS_BY_UNIT[defaultUnit ?? ''];
    return totalMs + (msByUnit ? value * msByUnit : 0);
  }, 0);
};

const stringToBytes = (targetValue: string, defaultUnit?: string): number | undefined => {
  const { value, unit } = parseDataValueUnit(targetValue);
  const bytesByUnit = BYTES_BY_UNIT_LOWER_CASE[(unit || defaultUnit)?.toLowerCase() ?? ''];

  if (value === undefined) {
    return undefined;
  }

  if (bytesByUnit) {
    return parseFloat(value) * bytesByUnit;
  }

  return parseFloat(value);
};

const stringToBps = (targetValue: string, defaultUnit?: string): number | undefined => {
  const { value, unit } = parseBpsValueUnit(targetValue);
  const bpsByUnit = BPS_BY_UNIT_LOWER_CASE[(unit || defaultUnit)?.toLowerCase() ?? ''];

  if (value === undefined) {
    return undefined;
  }

  if (bpsByUnit) {
    return parseFloat(value) * bpsByUnit;
  }

  return parseFloat(value);
};

export const FILTER_CONDITION_BY_SEARCH_TYPE: Record<
  FilterSearchSpecificFilterFnKey,
  FilterSearchCustomFn
> = {
  toMs: (cellValue, token): Visible => {
    // cellValue: 단위를 가지고 있는 경우, 단위를 ms로 변환하여 비교. 없다면 ms로 취급하여 비교
    // searchValue: 단위를 가지고 있는 경우, 단위를 ms로 변환하여 비교. 없다면 visible: false
    const parsedSearchValueUnits = parseTimeValueUnits(token.value);
    return parsedSearchValueUnits?.length
      ? compareNumberValues({
          cellValue: stringToMs(`${cellValue}`, 'ms'),
          searchValue: stringToMs(`${token.value}`),
          operator: token.operator,
        })
      : false;
  },
  toBytes: (cellValue, token): Visible => {
    // cellValue: 단위를 가지고 있는 경우, 단위를 byte로 변환하여 비교. 없다면 byte로 취급하여 비교
    // searchValue: 단위를 가지고 있는 경우, 단위를 byte로 변환하여 비교. 없다면 visible: false
    const { unit: searchValueUnit } = parseDataValueUnit(token.value);
    return searchValueUnit
      ? compareNumberValues({
          cellValue: stringToBytes(`${cellValue}`, 'byte'),
          searchValue: stringToBytes(`${token.value}`),
          operator: token.operator,
        })
      : false;
  },
  MBtoBytes: (cellValue, token): Visible => {
    // cellValue: 단위를 가지고 있는 경우, 단위를 byte로 변환하여 비교. 없다면 MB로 취급하여 비교
    // searchValue: 단위를 가지고 있는 경우, 단위를 byte로 변환하여 비교. 없다면 visible: false
    const { unit: searchValueUnit } = parseDataValueUnit(token.value);
    return searchValueUnit
      ? compareNumberValues({
          cellValue: stringToBytes(`${cellValue}`, 'MB'),
          searchValue: stringToBytes(`${token.value}`),
          operator: token.operator,
        })
      : false;
  },
  rendererTypeSec: (cellValue, token): Visible => {
    // cellValue: 처음부터 ms 단위의 값이므로 변환 없이 사용
    // searchValue: 단위를 가지고 있는 경우, 단위를 ms로 변환하여 비교. 없다면 sec 로 취급하여 ms로 변환
    return compareNumberValues({
      cellValue,
      searchValue: stringToMs(token.value, 's'),
      operator: token.operator,
    });
  },
  attrsSecTrue: (cellValue, token): Visible => {
    // cellValue: 단위를 포함하고 있지 않다면 sec 로 취급하여 ms로 변환
    // searchValue: 단위를 가지고 있는 경우, 단위를 ms로 변환하여 비교. 없다면 sec 로 취급하여 ms로 변환
    return compareNumberValues({
      cellValue: stringToMs(`${cellValue}`, 's'),
      searchValue: stringToMs(token.value, 's'),
      operator: token.operator,
    });
  },
  toBps: (cellValue, token): Visible => {
    // cellValue: 단위를 가지고 있는 경우, 단위를 b/s로 변환하여 비교. 없다면 b/s로 취급하여 비교
    // searchValue: 단위를 가지고 있는 경우, 단위를 b/s로 변환하여 비교. 없다면 visible: false
    const { unit: searchValueUnit } = parseBpsValueUnit(token.value);
    return searchValueUnit
      ? compareNumberValues({
          cellValue: stringToBps(`${cellValue}`, 'bps'),
          searchValue: stringToBps(`${token.value}`),
          operator: token.operator,
        })
      : false;
  },
};
