import { StatDataType, StatInfo } from './statList.types';

type SortFn<T> = (a: T, b: T) => number;

const sortByLocale =
  (type: 'asce' | 'desc'): SortFn<StatInfo> =>
  (statInfo1, statInfo2) => {
    switch (type) {
      case 'asce':
        return statInfo1.name.localeCompare(statInfo2.name);
      case 'desc':
        return statInfo2.name.localeCompare(statInfo1.name);
      default:
        // TODO: 개선
        return statInfo1.name.localeCompare(statInfo2.name);
    }
  };

const sortByRecommend: SortFn<StatInfo> = (statInfo1, statInfo2) => {
  if (statInfo1.recommend) {
    if (!statInfo2.recommend) {
      return -1;
    }
    return sortByLocale('asce')(statInfo1, statInfo2);
  }
  if (statInfo2.recommend) {
    return 1;
  }
  return sortByLocale('asce')(statInfo1, statInfo2);
};

const sortByPopular: SortFn<StatInfo> = (statInfo1, statInfo2) => {
  if (statInfo1.popular) {
    if (!statInfo2.popular) {
      return -1;
    }
    return statInfo2.popular - statInfo1.popular;
  }
  if (statInfo2.popular) {
    return 1;
  }
  return sortByLocale('asce')(statInfo1, statInfo2);
};

export const StatInfoSortFunctions: Record<string, SortFn<StatInfo>> = {
  recommend: sortByRecommend,
  popular: sortByPopular,
  sortByAscend: sortByLocale('asce'),
  sortByDescend: sortByLocale('desc'),
};

export const convertToStatDataType = (apiDataType: string): StatDataType => {
  switch (apiDataType) {
    case 'metric':
    case 'metrics':
      return 'metrics';
    case 'current':
      return 'current';
    case 'table':
    case 'topList':
    case 'treeGrid':
    case 'object':
      return 'table';
    case 'scatter':
      return 'scatter';
    case 'topology':
      return 'topology';
    case 'heatmap':
      return 'heatmap';
    default:
      throw new Error(`새로운 지표 데이터 타입 ${apiDataType}이 추가되었습니다.`);
  }
};
