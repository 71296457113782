<script lang="ts" setup>
import TimePeriod from '@/common/components/molecules/timePeriod/TimePeriod.vue';
import TimePeriodV2 from '@/common/components/molecules/timePeriod/TimePeriodV2.vue';
import {
  setup,
  getInitPeriodInfo,
  Props,
  Emit,
  MODE,
  GLOBAL_TIME_PERIOD,
  BUTTON_TYPE,
} from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import StatusTag from '@/common/components/molecules/statusTag/StatusTag.vue';
import { DEFAULT_PERIOD_ITEMS } from './timePeriodIndicator.utils';

const props = withDefaults(defineProps<Props>(), {
  periodInfo: () => getInitPeriodInfo(),
  periodItems: () => DEFAULT_PERIOD_ITEMS,
  options: () => ({
    ...GLOBAL_TIME_PERIOD,
    interval: 'p5s',
    isInitializedOutside: false,
    timeRangeSecLimit: undefined,
    minTimeRangeSecLimit: undefined,
  }),
  disabled: false,
});
const emit = defineEmits<Emit>();

const {
  mode,
  periodItems,
  selectedPeriod,
  periodControlButtons,
  onChangePeriod,

  timePeriodInfo,
  onChangePeriodByCalendar,
} = setup(props, emit);

defineExpose({
  togglePause: () => {
    periodControlButtons.value.find((btn) => btn.key === BUTTON_TYPE.PAUSE)?.click();
  },
});
</script>

<template>
  <div class="time-period-indicator">
    <template v-if="mode === MODE.CURRENT">
      <span
        :class="[
          'time-period-indicator__current',
          {
            'time-period-indicator__current--no-live-tag': timePeriodInfo?.isPaused,
          },
        ]"
      >
        <status-tag
          v-show="!timePeriodInfo?.isPaused"
          type="succeeded"
          class="status-tag--live"
        >
          Live
        </status-tag>
        <span class="time-period-indicator__current-time">
          {{ props.periodInfo.toTime }}
        </span>
      </span>
    </template>
    <div
      v-else
      :class="[
        'time-period-indicator__period',
        {
          'time-period-indicator__period--live': mode !== MODE.PERIOD && !props.periodInfo.isPaused,
        },
      ]"
    >
      <status-tag
        v-if="props.periodInfo.timePeriod !== 'pa'"
        :type="props.disabled ? 'disabled' : 'succeeded'"
        class="status-tag--live"
      >
        Live
      </status-tag>
      <time-period-v2
        v-if="props.options.useCalendar || props.options.useOnlyCurrent"
        v-model="selectedPeriod"
        :disabled="props.disabled"
        :periods="periodItems"
        :from-time="timePeriodInfo.fromTime"
        :to-time="timePeriodInfo.toTime"
        :time-range-sec-limit="props.options.timeRangeSecLimit"
        :min-time-range-sec-limit="props.options.minTimeRangeSecLimit"
        :use-calendar="!!props.options.useCalendar"
        :use-input="!!props.options.useInput"
        @change-period="onChangePeriod"
        @change-period-by-calendar="onChangePeriodByCalendar"
      />
      <time-period
        v-else-if="!props.options.useCalendar"
        v-model="selectedPeriod"
        :periods="periodItems"
        :disabled="props.disabled"
        @change-period="onChangePeriod"
      />
    </div>
    <div class="time-period-indicator__button-list">
      <ev-button
        v-for="button in periodControlButtons"
        :key="button.key"
        type="icon"
        shape="border"
        :disabled="
          props.disabled || (button.disabled ?? false) || props.periodInfo.timePeriod === 'pa'
        "
        @click="button.click"
      >
        <ev-icon :icon="button.icon" />
      </ev-button>
    </div>
  </div>
</template>

<style lang="scss">
.time-period-indicator {
  display: flex;
  gap: 6px;
  align-items: center;

  .status-tag--live {
    min-width: 35px;
  }

  .time-period-v2,
  .time-period {
    width: 280px;
  }

  &__current {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 200px;
    height: 24px;
    padding: 0 4px;
    border: 1px solid var(--input-text-border-color);
    border-radius: 2px;
    color: var(--input-text-font-color);

    .time-period-indicator__current-time {
      margin-left: -4px;
      transition: margin-left $animate-base;
    }

    &--no-live-tag {
      .time-period-indicator__current-time {
        margin-left: 4px;
      }
    }
  }

  &__period {
    .status-tag--live {
      display: none;
    }
    &--live {
      position: relative;
      .status-tag--live {
        display: block;
        position: absolute;
        top: 4px;
        left: 42px;
        z-index: 1;
        /* stylelint-disable */
        + .time-period-v2,
        + .time-period {
          .time-period__select {
            .ev-select__wrapper {
              .ev-input {
                padding-left: 88px !important;
              }
            }
          }
        }
        /* stylelint-enable */
      }
    }
  }

  &__button-list {
    display: flex;
    gap: 2px;
    align-items: center;
  }
}
</style>
