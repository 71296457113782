export const REPORT = {
  GRIDCOLUMNS_report_reportHistory_reportHistoryList:
    'GRIDCOLUMNS_report_reportHistory_reportHistoryList',
  GRIDCOLUMNS_report_reportExport_reportExportList:
    'GRIDCOLUMNS_report_reportExport_reportExportList',
  GRIDCOLUMNS_report_reportExport_dashboardExportList:
    'GRIDCOLUMNS_report_reportExport_dashboardExportList',
  GRIDCOLUMNS_report_reportExport_businessExportList:
    'GRIDCOLUMNS_report_reportExport_businessExportList',
  FILTERSEARCH_report_reportHistory_reportHistoryList:
    'FILTERSEARCH_report_reportHistory_reportHistoryList',
  FILTERSEARCH_report_reportExport_reportList: 'FILTERSEARCH_report_reportExport_reportList',
  FILTER_dashboard_report_export: 'FILTER_dashboard_report_export',
  FILTER_export_business_report: 'FILTER_export_business_report',
  FILTER_export_dashboard_monitoring: 'FILTER_export_dashboard_monitoring',
  FILTER_export_dashboard_reportList: 'FILTER_export_dashboard_reportList',
  FILTER_export_business_reportList: 'FILTER_export_business_reportList',
} as const;
