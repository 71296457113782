import { WidgetChartType } from '@/dashboard/utils/types';
import { StatDataType } from '../statWindow';

export const getStatDataTypeByWidgetChartType = (
  widgetChartType: WidgetChartType,
): StatDataType => {
  switch (widgetChartType) {
    case 'TIME_SERIES':
    case 'ONE_DAY_COMPARE':
    case 'EXCLUSIVE':
      return 'metrics';
    case 'STACKED_PROPORTION_BAR':
    case 'SCOREBOARD':
    case 'TOPOLOGY':
    case 'GAUGE':
      return 'current';
    case 'SCATTER':
      return 'scatter';
    case 'HEATMAP':
      return 'heatmap';
    case 'EQUALIZER':
    case 'ACTION_VIEW':
    case 'TABLE':
    case 'STATUS_SUMMARY':
    case 'SERVICE_CARD':
    case 'PIE':
    case 'TOP_LIST':
    case 'TREE_GRID':
    case 'STATUS':
    case 'SERVER':
      return 'table';
    default:
      throw new Error(`새로운 위젯 차트 타입 ${widgetChartType}이 추가되었습니다.`);
  }
};
