import axios from 'axios';

export const checkFulfilled = <T>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
  p.status === 'fulfilled';
export const checkRejected = <T>(p: PromiseSettledResult<T>): p is PromiseRejectedResult =>
  p.status === 'rejected';

export const isDuplicateNameError = (e: unknown) => {
  return axios.isAxiosError(e) && e.response?.status === 409;
};

export const isCancelError = (e: unknown) => {
  return axios.isAxiosError(e) && e.code === 'ERR_CANCELED';
};
