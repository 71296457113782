const MAIN_FRAMES = {
  KAFKA_VIEW: 'Kafka View',
  KAFKA_DETAIL: 'Kafka Detail',
  KAFKA_ALERT_DETAIL: 'Alert Detail - Kafka',
} as const;

export const MESSAGE_QUEUE_FRAME_NAMES = {
  KAFKA_VIEW: {
    FILTERS: `${MAIN_FRAMES.KAFKA_VIEW}/Filters`,
    KAFKA_LIST: `${MAIN_FRAMES.KAFKA_VIEW}/Kafka List`,
    ALERT_COUNT: `${MAIN_FRAMES.KAFKA_VIEW}/Kafka Alert Count`,
  },

  KAFKA_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.KAFKA_DETAIL}/Information`,
    DASHBOARD_ID: `${MAIN_FRAMES.KAFKA_DETAIL}/[GET] Dashboard Id`,
    METRIC: `${MAIN_FRAMES.KAFKA_DETAIL}/Metric`,
    BROKER_LIST: `${MAIN_FRAMES.KAFKA_DETAIL}/Broker List`,
    TOPIC_LIST: `${MAIN_FRAMES.KAFKA_DETAIL}/Topic List`,
    CONSUMER_GROUP_LIST: `${MAIN_FRAMES.KAFKA_DETAIL}/Consumer Group List`,
  },

  KAFKA_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.KAFKA_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.KAFKA_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.KAFKA_ALERT_DETAIL}/Clear System Alert`,
  },
} as const;
