<script lang="ts" setup>
// TODO BaseGrid, BaesTreeGrid chip-cell 에 대한 기본 디자인 필요
import {
  GridRawRowDataType,
  GridRowDataType,
} from '@/common/components/molecules/modernGrid/modernGrid.types';

interface Props {
  items: string[];
  fieldName?: string | keyof GridRowDataType<GridRawRowDataType>;
  rowData?: any | GridRowDataType<GridRawRowDataType>;
  columnIndex?: number;
  isClickable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  fieldName: '',
  rowData: () => ({}),
  columnIndex: -1,
  isClickable: false,
});

const emit = defineEmits<{
  (
    e: 'click-item',
    event: MouseEvent,
    fieldName: string | keyof GridRowDataType<GridRawRowDataType>,
    value: string | GridRowDataType<GridRawRowDataType>[keyof GridRowDataType<GridRawRowDataType>],
    rowData: any | GridRowDataType<GridRawRowDataType>,
    columnIndex: number,
  ): void;
}>();

const onClickItem = (event: MouseEvent, item: string) => {
  if (props.isClickable) {
    emit('click-item', event, props.fieldName, item, props.rowData, props.columnIndex);
  }
};
</script>

<template>
  <ul class="chip-cell-tooltip-content">
    <li
      v-for="(item, index) in props.items"
      :key="index"
      :class="[
        'chip-cell-tooltip-content__item',
        { 'chip-cell-tooltip-content__item--clickable': props.isClickable },
      ]"
      @click="(event) => onClickItem(event, item)"
    >
      <span
        class="item__label"
        :title="item"
      >
        {{ item }}
      </span>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.chip-cell-tooltip-content {
  max-width: 200px;
  max-height: 180px;
  overflow-y: auto;
  border-radius: 3px;
  background-color: var(--custom-tooltip-bg-color);

  &__item {
    display: flex;
    height: 36px;
    padding: 8px 12px;
    line-height: 20px;
    color: var(--custom-tooltip-font-color);

    &--clickable {
      cursor: pointer;
    }

    &:hover {
      background-color: var(--custom-tooltip-hover-bg-color);
    }

    &:first-child {
      border-radius: 3px 3px 0 0;
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
    }

    .item__label {
      @include shortening();
    }
  }
}
</style>
