const MAIN_FRAMES = {
  BUSINESS_VIEW: 'Business View',
  BUSINESS_DETAIL: 'Business Detail',
  BUSINESS_MAP: 'Business Map',
  BUSINESS_ALERT_DETAIL: 'Business Alert Detail',
  BUSINESS_LIST_ALERT_DETAIL: 'Alert Detail - Business List',
  BUSINESS_MAP_ALERT_DETAIL: 'Alert Detail - Business Map',
} as const;

export const BUSINESS_FRAME_NAMES = {
  BUSINESS_VIEW: {
    FILTERS: `${MAIN_FRAMES.BUSINESS_VIEW}/Filters`,
    BUSINESS_LIST: `${MAIN_FRAMES.BUSINESS_VIEW}/Business List`,
    BUSINESS_DATABASE: `${MAIN_FRAMES.BUSINESS_VIEW}/DB Stat`,
    BUSINESS_WAS: `${MAIN_FRAMES.BUSINESS_VIEW}/WAS Stat`,
    ALERT_COUNT: `${MAIN_FRAMES.BUSINESS_VIEW}/Alert Count`,
  },
  BUSINESS_DETAIL: {
    TARGET_KINDS: `${MAIN_FRAMES.BUSINESS_DETAIL}/Target Kinds`,
    INFORMATION: `${MAIN_FRAMES.BUSINESS_DETAIL}/Information`,
    METRIC: (type: string) => `${MAIN_FRAMES.BUSINESS_DETAIL}/${type} Metric`,
    DB_METRIC: `${MAIN_FRAMES.BUSINESS_DETAIL}/DB Metric`,
    ALERT: `${MAIN_FRAMES.BUSINESS_DETAIL}/Alert`,
    ALERT_DETAIL: `${MAIN_FRAMES.BUSINESS_DETAIL}/Alert Detail`,
  },
  BUSINESS_MAP: {
    FILTERS: `${MAIN_FRAMES.BUSINESS_MAP}/Filters`,
    CIRCUIT_BREAKER: `${MAIN_FRAMES.BUSINESS_MAP}/Circuit Breaker`,
    NODE_STAT: `${MAIN_FRAMES.BUSINESS_MAP}/Circuit Breaker > Node Stat Information`,
    NODE_CONNECTION: `${MAIN_FRAMES.BUSINESS_MAP}/Circuit Breaker > Node Connection`,
    ALERT_COUNT: `${MAIN_FRAMES.BUSINESS_MAP}/Alert Count`,
  },
  BUSINESS_MAP_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.BUSINESS_MAP_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.BUSINESS_MAP_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.BUSINESS_MAP_ALERT_DETAIL}/Clear System Alert`,
  },
  BUSINESS_LIST_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.BUSINESS_LIST_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.BUSINESS_LIST_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.BUSINESS_LIST_ALERT_DETAIL}/Clear System Alert`,
  },
} as const;
