import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRtmApi } from '@/common/utils/apiUtils';
import {
  getExtraGlobalFiltersExtraV8ControllerAxios,
  addOrUpdateExtraGlobalFilterExtraV8ControllerAxios,
} from '@/openapi/extraMetric/api/extra-v8-controller-api';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import type { ExternalVariablesOptions } from '@/dashboard/components/externalVariableWindow/externalVariableWindow.type';
import type { ExtraGlobalFilterRequest } from '@/openapi/extraMetric/model/extra-global-filter-request';

export const useExternalResources = defineStore('externalResources', () => {
  const { callApi } = useRtmApi();

  const externalVariables = ref<ExternalVariablesOptions[]>([]);

  const getExternalVariables = async (dashboardId: number) => {
    const { data, error } = await callApi({
      fn: getExtraGlobalFiltersExtraV8ControllerAxios,
      params: {
        dashboardId,
      },
      frameName: FRAME_NAMES.DASHBOARD_EXTERNAL_VARIABLES.EXTRA_GLOBAL_VARIABLES,
    });

    if (error || !data) {
      console.log(error);
      return;
    }

    externalVariables.value = data.map(({ options, ...rest }) => {
      return {
        ...rest,
        ...JSON.parse(options || '{}'),
      };
    });
  };

  const setExternalVariables = (list: ExternalVariablesOptions[]) => {
    externalVariables.value = list;
  };

  const saveExternalVariables = async (request: ExtraGlobalFilterRequest) => {
    const { error } = await callApi({
      fn: addOrUpdateExtraGlobalFilterExtraV8ControllerAxios,
      params: {
        request,
      },
      frameName: FRAME_NAMES.DASHBOARD_EXTERNAL_VARIABLES.EXTRA_GLOBAL_VARIABLES_UPDATE,
    });

    if (error) {
      console.log(error);
    }
  };

  return {
    externalVariables,
    getExternalVariables,
    setExternalVariables,
    saveExternalVariables,
  };
});
