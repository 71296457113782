<script setup lang="ts">
import VueJsonPretty from 'vue-json-pretty';
import { Props, setup } from '@/common/components/molecules/textViewer/textViewer.setup';
import CopyButton from '@/common/components/molecules/copyButton/CopyButton.vue';

const props = withDefaults(defineProps<Props>(), {
  type: 'none',
  useLineNumbers: false,
  useBorder: true,
  title: '',
  text: '',
  copy: true,
  isShow: true,
  noDataMsg: '',
  useJsonVirtualScroll: false,
  option: () => ({ useSeeMore: false }),
  style: () => ({ height: '100%' }),
});

const {
  textAreaEl,
  jsonObj,
  jsonVirtualScrollOption,
  preWrapper,
  more,
  containerStyle,
  expandMessage,
  clickCopy,
  t,
} = setup(props);
</script>

<template>
  <div
    ref="textAreaEl"
    :class="[
      'text-viewer',
      { 'line-numbers': props.useLineNumbers },
      { 'no-border': !props.useBorder },
      { 'more-mode': props?.option?.useSeeMore },
    ]"
    :style="containerStyle"
  >
    <div
      v-if="props.title"
      class="detail-information__header detail-information__header-title"
    >
      {{ props.title }}
    </div>
    <div
      v-if="props.text"
      class="text-viewer__function-area"
    >
      <copy-button
        v-if="props.copy"
        title="Copy"
        @click="clickCopy"
      />
    </div>
    <div :class="['text-viewer__text-area', `${props.text ? props.type : ''}`]">
      <!-- json --->
      <template v-if="props.type === 'json' && props.text">
        <vue-json-pretty
          :data="jsonObj"
          :show-line="false"
          :show-line-number="props.useLineNumbers"
          :virtual="props.useJsonVirtualScroll"
          :height="jsonVirtualScrollOption.height"
          :item-height="jsonVirtualScrollOption.itemHeight"
          show-icon
        />
      </template>

      <!-- etc --->
      <template v-else-if="props.text">
        <pre
          ref="preWrapper"
          :class="`language-${props.type}`"
        ><code>{{ props.text }}</code></pre>
      </template>

      <!-- no data --->
      <template v-else>
        <div class="no-data">{{ noDataMsg || t('MESSAGE.NO_DATA', 'No data') }}</div>
      </template>
    </div>
    <div
      v-if="more.isExist"
      class="expand-area"
    >
      <ev-button
        type="default"
        shape="radius"
        size="xsmall"
        @click="expandMessage"
      >
        {{ more.text }}
        <ev-icon
          v-if="more.isExpand"
          icon="icon-arrow-down"
        />
        <ev-icon
          v-if="more.isFold"
          icon="icon-arrow-up"
        />
      </ev-button>
    </div>
  </div>
</template>

<style lang="scss">
.text-viewer {
  --text-viewer-font-color: var(--color-gray-00-12);
  --text-viewer-border-color: var(--color-gray-05-05);

  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px solid var(--text-viewer-border-color);
  border-radius: 2px;

  pre {
    height: 100%;
    padding: 4px 12px 12px;
    margin: 0 !important;
  }

  &.more-mode {
    pre {
      overflow: hidden;
    }
  }

  &.no-border {
    border: none;
  }

  &__function-area {
    display: flex;
    justify-content: flex-end;
    padding: 12px 12px 0;
  }
  &__text-area {
    flex: 1;
    overflow: auto;
    font-size: 13px !important;

    &.json {
      padding: 6px 12px;
    }

    .no-data {
      padding: 10px;
      color: var(--text-viewer-font-color);
      text-align: center;
    }
  }

  .expand-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 30px;
    margin-top: 16px;

    button {
      height: 20px !important;
      border-radius: 29px !important;
    }
  }

  .icon-outline-copy {
    background-color: var(--color-gray-02-10) !important;
  }

  /* stylelint-disable */
  code[class*='language-'],
  pre[class*='language-'] {
    line-height: 1.3;
    white-space: pre-wrap;
    word-break: break-all;
    background-color: transparent;
  }

  code.language-none {
    color: var(--text-viewer-font-color) !important;
  }
  /* stylelint-enable */

  .line-numbers .line-numbers-rows {
    border-right: 1px solid var(--text-viewer-border-color);
    color: var(--text-viewer-font-color);
  }

  /* vue-json-pretty */
  .vjs-tree-node {
    &:hover {
      background: none;
    }
  }

  &.line-numbers {
    .vjs-tree-node.has-carets {
      border-left: 1px solid var(--text-viewer-border-color);
      font-size: 13px;
    }
    .vjs-node-index {
      margin-right: 8px;
      color: var(--text-viewer-font-color);
    }
  }
}
</style>
