/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  CreateMetricGroupRequest,
  MetricGroupResponse,
  UpdateMetricGroupRequest,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const removeMetricGroupV7ControllerAxios = ({
  metricGroupId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  metricGroupId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v7/metric-groups/{metricGroupId}'.replace(
    `{${'metricGroupId'}}`,
    encodeURIComponent(String(metricGroupId)),
  );
  const httpMethod = 'delete';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getByUserIdAndTenantIdMetricGroupV7ControllerAxios = ({
  tenantId,
  userId,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  tenantId: string;
  userId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MetricGroupResponse> => {
  const localVarPath = '/api/v7/metric-groups';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      tenantId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateMetricGroupV7ControllerAxios = ({
  metricGroupId,
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  metricGroupId: number;
  request: UpdateMetricGroupRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<MetricGroupResponse> => {
  const localVarPath = '/api/v7/metric-groups/{metricGroupId}'.replace(
    `{${'metricGroupId'}}`,
    encodeURIComponent(String(metricGroupId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const addMetricGroupV7ControllerAxios = ({
  tenantId,
  userId,
  request,
  signal,
  frameName,
  isTimeout,
  responseType,
}: {
  tenantId: string;
  userId: number;
  request: CreateMetricGroupRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<string> => {
  const localVarPath = '/api/v7/metric-groups';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      tenantId,
      userId,
      frameName,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  removeMetricGroupV7ControllerAxios,
  getByUserIdAndTenantIdMetricGroupV7ControllerAxios,
  updateMetricGroupV7ControllerAxios,
  addMetricGroupV7ControllerAxios,
};
