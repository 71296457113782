import { useInternational } from '@/common/locale';
import { ref } from 'vue';

export interface Props {
  title: string;
  isExpand?: boolean;
  icon?: string;
}

export interface Emit {
  (e: 'update:isExpand', value: boolean): void;
}

export const setup = (props: Required<Props>, emit: Emit) => {
  const { t } = useInternational();

  const isExpand = ref(props.isExpand);
  const toggleExpand = () => {
    isExpand.value = !isExpand.value;
    emit('update:isExpand', isExpand.value);
  };

  return {
    isExpand,
    toggleExpand,
    t,
  };
};
