import { InjectionKey, Ref, inject, provide } from 'vue';
import { WidgetChartDataStatusInfo } from '@/common/utils';

const key = Symbol('widgetChartDataStatus') as InjectionKey<Ref<WidgetChartDataStatusInfo[]>>;

export const provideWidgetChartDataStatus = (data: Ref<WidgetChartDataStatusInfo[]>) => {
  provide(key, data);
};

export const useWidgetChartDataStatus = () => {
  const data = inject(key);

  if (!data) {
    throw new Error('useWidgetChartDataStatus must be used within a WidgetChartDataStatusProvider');
  }

  return data;
};
