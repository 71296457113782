const MAIN_FRAMES = {
  LOG_LOGGING: 'Log - Logging',
  LOG_LIVE_TAIL: 'Log - Live Tail',
  LOG_LOGGING_ALERT_DETAIL: 'Alert Detail - Logging',
  LOG_LIVE_TAIL_ALERT_DETAIL: 'Alert Detail - Live Tail',
} as const;

export const LOG_FRAME_NAMES = {
  LOG_LOGGING: {
    FILTERS: `${MAIN_FRAMES.LOG_LOGGING}/Filters`,
    LOGGING_LIST: `${MAIN_FRAMES.LOG_LOGGING}/Logging List`,
    LOGGING_CHART: `${MAIN_FRAMES.LOG_LOGGING}/Logging Chart`,
    LOGGING_PREVIOUS: `${MAIN_FRAMES.LOG_LOGGING}/Logging Previous`,
    LOGGING_KEYWORD: `${MAIN_FRAMES.LOG_LOGGING}/Logging Keyword`,
    LOGGING_KEYWORD_UPDATE: `${MAIN_FRAMES.LOG_LOGGING}/[Update] Logging Keyword`,
    LOGGING_CUSTOM_KEYWORD: `${MAIN_FRAMES.LOG_LOGGING}/Logging Custom Keyword`,
    LOGGING_CUSTOM_KEYWORD_UPDATE: `${MAIN_FRAMES.LOG_LOGGING}/[Update] Logging Custom Keyword`,
    ALERT_COUNT: `${MAIN_FRAMES.LOG_LOGGING}/Alert Count`,
  },
  LOG_LOGGING_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.LOG_LOGGING_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.LOG_LOGGING_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.LOG_LOGGING_ALERT_DETAIL}/Clear System Alert`,
  },
  LOG_LIVE_TAIL: {
    LIVE_TAIL_LIST: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail List`,
    LIVE_TAIL_LOG_TYPE: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail Log Type`,
    LIVE_TAIL_TARGET_LIST: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail Target List`,
    LIVE_TAIL_POD_LIST: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail Pod List`,
    LIVE_TAIL_WORKLOAD_LIST: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Live Tail Workload List`,
    ALERT_COUNT: `${MAIN_FRAMES.LOG_LIVE_TAIL}/Alert Count`,
  },
  LOG_LIVE_TAIL_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.LOG_LIVE_TAIL_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.LOG_LIVE_TAIL_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.LOG_LIVE_TAIL_ALERT_DETAIL}/Clear System Alert`,
  },
} as const;
