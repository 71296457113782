import { isEqual } from 'lodash-es';
import { DirectiveBinding, ObjectDirective } from 'vue';

const getEventType = ({ click, mouseup }: { click?: boolean; mouseup?: boolean }) => {
  if (click) return 'click';
  if (mouseup) return 'mouseup';
  return 'mousedown';
};
const clickOutsideKey = Symbol('vueClickOutside');

const onUnMounted = (el: any, binding: DirectiveBinding) => {
  const componentEl = el;
  const eventType = getEventType(binding.modifiers);

  if (componentEl[clickOutsideKey]) {
    const handler = componentEl[clickOutsideKey].get(binding.instance);
    if (handler) {
      document.removeEventListener(eventType, handler);
      componentEl[clickOutsideKey].delete(binding.instance);
    }

    if (componentEl[clickOutsideKey].size === 0) {
      delete componentEl[clickOutsideKey];
    }
  }
};
const onMounted = (el: any, binding: DirectiveBinding) => {
  const componentEl = el;
  const callback = binding.value;
  const eventType = getEventType(binding.modifiers);

  const handler = (e) => {
    if (
      callback &&
      componentEl &&
      typeof callback === 'function' &&
      componentEl !== e.target &&
      !componentEl.contains(e.target)
    ) {
      callback(e);
    }
  };

  if (!componentEl[clickOutsideKey]) {
    componentEl[clickOutsideKey] = new Map();
  }
  componentEl[clickOutsideKey].set(binding.instance, handler);
  document.addEventListener(eventType, handler);
};
const onUpdated = (el: any, binding: DirectiveBinding) => {
  if (isEqual(binding.value, binding.oldValue)) {
    return;
  }
  onMounted(el, binding);
};

const clickoutside: ObjectDirective = {
  mounted: onMounted,
  updated: onUpdated,
  unmounted: onUnMounted,
};

export { clickoutside };
