import { TargetTagType, WidgetApiStatusType } from '@/common/utils/types';

export const WIDGET_API_STATUS: Record<string, WidgetApiStatusType> = {
  SUCCESS: 'success',
  FAIL: 'fail',
};

export const WIDGET_INTERVAL = {
  I100ms: {
    value: 100,
  },
  I5s: {
    value: 5 * 1000,
  },
  I15s: {
    value: 15 * 1000,
  },
  I30s: {
    value: 30 * 1000,
  },
  I5m: {
    value: 5 * 60 * 1000,
  },
  I10m: {
    value: 10 * 60 * 1000,
  },
  I1m: {
    value: 60 * 1000,
  },
  I1h: {
    value: 60 * 60 * 1000,
  },
};

export const BY_TARGET_SERIES_TYPE = 'byTarget';

export const GLOBAL_VARIABLE_TAG_KEY = 'Global Variable';

export const TAG_TYPE_KEY: Record<
  'ATTRIBUTE' | 'GLOBAL_VARIABLE' | 'SERVICE_GROUP' | 'TAG',
  TargetTagType
> = {
  ATTRIBUTE: 'attribute',
  GLOBAL_VARIABLE: 'globalVariable',
  SERVICE_GROUP: 'serviceGroup',
  TAG: 'tag',
};

export const FAVORITE_GROUP = 'Favorite';

export const AXES_Y_AUTO_SCALE_RATIO = 0.1;
