import { computed, ref, watch, WritableComputedRef } from 'vue';
import { openBrowserPopup } from '@/common/utils/browserPopupUtils';
import { POPUP_MIN_SIZE } from '@/common/components/organisms/alertListDetail/alertListDetail.setup';
import { storeToRefs } from 'pinia';
import { useDashboardViewStore } from '@/dashboard/stores/dashboard-view';
import { useWidgetDataParams } from '@/dashboard/components/widgets/uses';
import { TagsRequest } from '@/openapi/data/model';
import { webStorageController } from '@/common/utils/webStorage.util';
import { useInternational } from '@/common/locale';
import {
  CurrentRouteInfo,
  InstanceId,
  useInstanceIds,
} from '@/database/components/overviewAlert/overviewAlert.uses';
import { USE_SLIDE_VIEW } from '@/common/components/organisms/alertListDetail/alertListDetail.define';
import { AlertInfoType } from '@/common/components/organisms/overviewAlert/overviewAlert.setup';
import { SlideViewType } from './alertListDetail.types';

export interface Props {
  isVisible: boolean;
  type: SlideViewType;
  targetIds?: string[];
  alertInfo?: AlertInfoType;
}
export interface Emit {
  (e: 'update:is-visible', value: boolean): void;
  (e: 'openDetail'): void;
}

const useUserTags = (isShow: WritableComputedRef<boolean>) => {
  const { monitoringDashboard } = storeToRefs(useDashboardViewStore());
  const { getRequestUserTags } = useWidgetDataParams();

  watch(
    (): [number, TagsRequest[]] => [monitoringDashboard.value.dashboardId, getRequestUserTags()],
    ([dashboardId, userTags]) => {
      isShow.value = false;
      webStorageController.setItem({
        type: 'session',
        key: 'alertDetailParams',
        value: JSON.stringify({ dashboard: { dashboardId, userTags } }),
      });
    },
  );
};
const useDatabaseInstanceIds = (isShow: WritableComputedRef<boolean>) => {
  const { currentRouteInfo, getInstanceIds } = useInstanceIds();
  const instanceIds = computed(() => getInstanceIds());
  watch(
    (): [CurrentRouteInfo, InstanceId[]] => [currentRouteInfo.value, instanceIds.value],
    ([currRoute, ids]) => {
      if (ids) {
        isShow.value = false;

        const { view, dbType } = currRoute;
        webStorageController.setItem({
          type: 'session',
          key: 'alertDetailParams',
          value: JSON.stringify({ database: { instanceIds: getInstanceIds(), view, dbType } }),
        });
      }
    },
    { deep: true, immediate: true },
  );
};

// NOTE: 화면별 type을 기준으로 셋팅하는 로직입니다
const usePropsTargetSubKind = (props: Props, isShow: WritableComputedRef<boolean>) => {
  watch(
    () => props.type,
    () => {
      if (props.type) {
        webStorageController.setItem({
          type: 'session',
          key: 'alertDetailParams',
          value: JSON.stringify({
            [props.type]: {
              alertInfo: props.alertInfo,
            },
          }),
        });
      }
      isShow.value = false;
    },
    { immediate: true },
  );
};

const useDefineTargetIds = {
  [USE_SLIDE_VIEW.DASHBOARD]: useUserTags,
  [USE_SLIDE_VIEW.DATABASE]: useDatabaseInstanceIds,
};

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();

  const isShow = computed({
    get: () => props.isVisible,
    set: (value) => emit('update:is-visible', value),
  });

  const slideWidth = ref('900px');
  const onExpandSlide = (isExpand: boolean) => {
    slideWidth.value = isExpand ? '1200px' : '900px';
  };
  const onClickOpenPopup = () => {
    isShow.value = false;
    openBrowserPopup({
      query: {
        viewName: 'alertListDetail',
        isShow: true,
        type: props.type,
      },
      popupStyle: { width: POPUP_MIN_SIZE.WIDTH, height: POPUP_MIN_SIZE.HEIGHT },
      isSingle: true,
      sharedStorageInfo: {
        session: ['alertDetailParams'],
      },
    });
  };

  watch(
    () => props.type,
    (type) => {
      if (useDefineTargetIds[type]) {
        useDefineTargetIds[type](isShow);
      } else {
        usePropsTargetSubKind(props, isShow);
      }
    },
    { immediate: true },
  );

  return {
    isShow,
    slideWidth,
    onExpandSlide,
    onClickOpenPopup,
    t,
  };
};
