<script setup lang="ts">
import {
  Props,
  Emit,
  setup,
} from '@/infrastructure/components/toggleContents/toggleContents.setup';

const props = withDefaults(defineProps<Props>(), {
  isExpand: true,
  icon: 'icon-fill-arrow-right',
});

const emit = defineEmits<Emit>();

const { isExpand, toggleExpand, t } = setup(props, emit);
</script>

<template>
  <section class="detail-information">
    <h3
      :class="[
        'detail-information__header',
        {
          isExpand,
        },
      ]"
      @click="toggleExpand"
    >
      <ev-icon
        :icon="props.icon"
        size="medium"
        class="detail-information__header-icon"
      />
      {{ props.title }}
      <slot name="header-suffix" />
    </h3>
    <div
      v-if="isExpand"
      class="detail-information__content"
    >
      <slot>
        <span class="detail-information__no-records">{{ t('MESSAGE.NO_DATA', 'No data') }}</span>
      </slot>
    </div>
  </section>
</template>

<style scoped lang="scss">
.detail-information {
  border: none;

  &__header {
    display: flex;
    align-items: center;
    margin-top: 4px;
    cursor: pointer;
    border: none;
    background-color: var(--detail-information-bg-color);

    &-icon {
      min-width: 12px;
      margin-right: 4px;
      color: var(--base-icon-color);
      transition: transform $animate-fast;
    }
    &.isExpand > &-icon {
      transform: rotate(90deg);
    }
  }

  &__no-records {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    color: var(--color-d-gray-04);
  }
  &__content {
    padding-bottom: 16px;
    margin-right: 16px;
    margin-left: 20px;
  }
}
</style>
