import { GridColumn } from '@/common/utils';
import { i18n } from '@/common/locale';
import { ValueOf } from '@/types/common.types';
import { DEFAULT_DASHBOARD_GROUP_ID } from '@/dashboard/utils/define';

export const GROUP_TYPE = {
  ALL: 'all',
  PRESET_DASHBOARD: 'featuredDashboard',
  KEY_DASHBOARD: 'keyDashboard',
  CUSTOM: 'custom',
} as const;

export const FEATURED_DASHBOARD_GROUP_LANGUAGE_KEY: Record<
  ValueOf<typeof DEFAULT_DASHBOARD_GROUP_ID>,
  string
> = {
  all: 'WORD.ALL',
  featuredDashboard: 'WORD.FEATURED_DASHBOARD',
  featuredReport: 'WORD.FEATURED_REPORT',
  favorite: 'WORD.FAVORITE',
  shared: 'WORD.SHARED',
  customReport: 'WORD.REPORT',
} as const;

export const LANGUAGE_KEYS_BY_MENU_PATH = {
  Infrastructure: 'WORD.INFRASTRUCTURE',
  Kubernetes: 'WORD.KUBERNETES',
  'Message-Queue': 'WORD.MESSAGE_QUEUE',
  Application: 'WORD.APPLICATION',
  Database: 'WORD.DATABASE',
  Business: 'WORD.BUSINESS',
  Logs: 'WORD.LOGS',
  Alert: 'WORD.ALERT',
  Cloud: 'WORD.GNB.CLOUD',
  Logging: 'WORD.LOGGING',
  Etc: 'WORD.ETC',
  Oracle: 'WORD.ORACLE',
  Mysql: 'WORD.MYSQL',
  Postgresql: 'WORD.POSTGRESQL',
  Sqlserver: 'WORD.SQLSERVER',
  Redis: 'WORD.REDIS',
  Cubrid: 'WORD.UPPER_CUBRID',
  Mongodb: 'WORD.MONGODB',
} as const;

export const dashboardGridColumns: GridColumn[] = [
  {
    caption: 'Name',
    field: 'name',
    type: 'string',
    align: 'left',
    width: 625,
    fixed: true,
    searchable: true,
  },
  {
    caption: 'Last Modified',
    field: 'lastModified',
    type: 'string',
    width: 180,
    align: 'center',
    searchable: true,
  },
  {
    caption: 'Dashboard Group',
    field: 'groupName',
    type: 'string',
    width: 256,
    align: 'center',
    searchable: true,
  },
  {
    caption: 'Dashboard by Menu',
    field: 'menuPaths',
    type: 'string',
    width: 256,
    align: 'center',
    searchable: true,
  },
  {
    caption: 'Analysis Board by Menu',
    field: 'analysisMenuPaths',
    type: 'string',
    width: 256,
    align: 'center',
    searchable: true,
  },
  {
    caption: '',
    field: 'id',
    type: 'number',
    hide: true,
    searchable: false,
  },
  {
    caption: '',
    field: 'groupId',
    type: 'number',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Favorite',
    field: 'favorite',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Start Screen',
    field: 'startScreen',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: '',
    field: 'createUser',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Featured Report',
    field: 'featuredReport',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Shared',
    field: 'shared',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: '',
    field: 'sharing',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Featured Dashboard',
    field: 'featuredDashboard',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Report',
    field: 'customReport',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Description',
    field: 'description',
    type: 'string',
    hide: true,
    searchable: false,
  },
  {
    caption: 'Creator',
    field: 'creator',
    type: 'string',
    width: 100,
    align: 'center',
    searchable: true,
  },
  {
    caption: '',
    field: 'iconId',
    type: 'string',
    hide: true,
    searchable: false,
  },
  {
    caption: '',
    field: 'dashboardOption',
    type: 'string',
    hide: true,
    searchable: false,
  },
];

export const DASHBOARD_VIEW_TEXT = {
  DASHBOARD: i18n.global.t('WORD.DASHBOARD'),
  DASHBOARD_LIST: i18n.global.t('WORD.DASHBOARD_LIST'),
  START_SCREEN: i18n.global.t('WORD.START_SCREEN'),
  FEATURED_DASHBOARD: i18n.global.t('WORD.FEATURED_DASHBOARD_TAG'),
  FEATURED_REPORT: i18n.global.t('WORD.FEATURED_REPORT_TAG'),
  CUSTOM_REPORT: i18n.global.t('WORD.CUSTOM_REPORT'),
  SHARED: i18n.global.t('WORD.SHARED_TAG'),
  SHARING: i18n.global.t('WORD.SHARING_TAG'),
  SHARING_TOOLTIP: i18n.global.t('MESSAGE.DASHBOARD_LIST.SHARING_TOOLTIP_CONTENT'),
};
