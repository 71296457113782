import { CustomColumn } from '@/common/utils/types';
import { mergeWith } from 'lodash-es';
import { roundToDigitNumber } from '@/common/utils/commonUtils';

const PROPERTIES_FOR_SAVE = ['index', 'field', 'width', 'hiddenDisplay', 'sortOption'];
export const getPickedColumn = (col: CustomColumn, isIncludeIndex: boolean = true) => {
  return PROPERTIES_FOR_SAVE.reduce((acc, cur) => {
    if (col[cur] === undefined || (!isIncludeIndex && cur === 'index')) {
      return acc;
    }
    acc[cur] = col[cur];
    return acc;
  }, {} as CustomColumn);
};

export const getColumnsToSave = <ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>>(
  columns: ColumnData,
) => {
  return columns?.map((col) => getPickedColumn(col, true)) ?? [];
};

const mergeFn = (base, stored, key) => {
  return key === 'index' ? base ?? stored : stored ?? base;
};
export const getMergedColumns = <ColumnData extends CustomColumn[] | Readonly<CustomColumn[]>>({
  baseColumns,
  storedColumns,
  dynamicColumnFields,
}: {
  baseColumns: ColumnData;
  storedColumns: ColumnData;
  dynamicColumnFields?: string[];
}) => {
  if (!storedColumns?.length) {
    return baseColumns;
  }

  const indexedBaseColumn = baseColumns.map((col: CustomColumn, index: number) => ({
    ...col,
    index,
  }));
  const baseColumnsMap = new Map<string, CustomColumn>(
    indexedBaseColumn.map((col: CustomColumn) => [col.field, col]),
  );
  const storedColumnsSet = new Set(storedColumns.map((col: CustomColumn) => col.field));
  const dynamicFieldsSet = new Set(dynamicColumnFields ?? []);

  const filteredStoredColumns = storedColumns
    .filter((col) => {
      if (dynamicFieldsSet.size) {
        return baseColumnsMap.has(col.field) || dynamicFieldsSet.has(col.field);
      }
      return baseColumnsMap.has(col.field);
    })
    .map((storedCol) => {
      const baseCol = baseColumnsMap.get(storedCol.field) ?? {};
      return baseCol ? mergeWith({}, baseCol, storedCol, mergeFn) : storedCol;
    });

  const addedColumns = indexedBaseColumn.filter((col) => !storedColumnsSet.has(col.field));
  const { frontColumn, defaultColumn } = [...filteredStoredColumns, ...addedColumns].reduce<{
    frontColumn: CustomColumn[];
    defaultColumn: CustomColumn[];
  }>(
    (acc, cur) => {
      if (cur.attrs && 'shouldFixedFront' in cur.attrs && cur.attrs.shouldFixedFront) {
        acc.frontColumn.push(cur);
      } else {
        acc.defaultColumn.push(cur);
      }
      return acc;
    },
    {
      frontColumn: [],
      defaultColumn: [],
    },
  );

  const mergedColumns = [...frontColumn, ...defaultColumn];

  if (dynamicFieldsSet.size) {
    return mergedColumns.map(({ index: _, ...rest }) => ({ ...rest }));
  }

  return mergedColumns;
};

export const formatDecimalFields = ({ columns }: { columns: CustomColumn[] }) => {
  const formatDecimal = (val: number) => roundToDigitNumber(val);

  return columns.reduce((acc, { field, attrs }) => {
    if (attrs?.decimal) {
      acc[field] = formatDecimal;
    }

    return acc;
  }, {});
};
