import { computed, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import { CustomColumn } from '@/common/utils/types';
import { confirmMsg, showErrorMsg, showSuccessMsg } from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import { getFilterItemsByGridColumns } from '@/common/components/molecules/filterSearch/filterSearch.utils';
import { useRolePermission } from '@/common/permission/permission.utils';
import { USER_ENV_KEYS } from '@/common/define/userEnv.define';
import { storeToRefs } from 'pinia';
import { useApi, useNoticeStore } from '@/config/stores/notice';
import { findGridColumnIndex } from '@/common/utils/gridUtils';
import { useViewManagerStore } from '@/common/stores/view-manager';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { listViewGridOption } from '@/common/define/grid.define';

interface ValueSettingInfo {
  isShow: boolean;
  isShowViewWindow: boolean;
  viewType: 'register' | 'edit';
}

export const noticeListColumns: CustomColumn[] = [
  {
    caption: 'Title',
    field: 'title',
    type: 'string',
    width: 400,
    clickable: true,
    searchable: true,
  },
  {
    caption: 'Creator',
    field: 'name',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Registration Time',
    field: 'registrationTime',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Notice Channel',
    field: 'noticeChannel',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Notice Start Date',
    field: 'noticeStartDate',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Popup Notice Start Date',
    field: 'popupNoticeStartDate',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Popup Notice End Date',
    field: 'popupNoticeEndDate',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Email Request',
    field: 'emailRequest',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Mail Sending Time',
    field: 'emailSendTime',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'temporarySaved',
    field: 'temporarySaved',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
  {
    caption: 'id',
    field: 'id',
    type: 'boolean',
    hide: true,
    searchable: false,
  },
];
const setup = () => {
  const { t } = useInternational();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const viewManagerStore = useViewManagerStore();
  const { isPermissionDenied } = useRolePermission();

  const selectedItems = ref<any[]>([]);
  const noticeId = ref<number>(-1);

  const { deleteNotice } = useApi();

  const noticeStore = useNoticeStore();
  const { refresh, hasPermission } = noticeStore;
  const { noticeListRows, refreshTime, isFailRequestTime } = storeToRefs(noticeStore);

  const columnIdx = computed(() =>
    findGridColumnIndex({
      fields: ['temporarySaved', 'emailRequest', 'id'],
      columns: noticeListColumns,
    }),
  );
  const noticeSettingInfo = reactive<ValueSettingInfo>({
    isShow: false,
    isShowViewWindow: false,
    viewType: 'register',
  });

  const useCheckbox = hasPermission.delete
    ? { use: true, mode: 'multi', headerCheck: true }
    : { use: false };
  const gridOption = {
    ...listViewGridOption,
    useCheckbox,
    useGridSetting: {
      use: true,
    },
  };
  const gridSearchBarOption = {
    use: true,
    filterSearch: {
      use: true,
      storageKey: USER_ENV_KEYS.MANAGEMENT.FILTERSEARCH_config_notice_list,
      filterItems: getFilterItemsByGridColumns(noticeListColumns),
    },
  };

  const onClickAddNotice = () => {
    if (isPermissionDenied({ type: 'add' })) {
      return;
    }
    noticeSettingInfo.isShow = true;
    noticeSettingInfo.viewType = 'register';
  };
  const onClickEditNotice = (id) => {
    if (isPermissionDenied({ type: 'edit' })) {
      return;
    }
    noticeId.value = id;
    noticeSettingInfo.isShowViewWindow = false;
    noticeSettingInfo.isShow = true;
    noticeSettingInfo.viewType = 'edit';
  };
  const onRefresh = async () => {
    viewManagerStore.setLoading(true);
    await refresh();
    selectedItems.value = [];
    viewManagerStore.setLoading(false);
  };
  const onClickDelete = () => {
    if (isPermissionDenied({ type: 'delete' })) {
      return;
    }

    const checkedIds = selectedItems.value.map((item) => ({ id: item[columnIdx.value.id] }));

    confirmMsg(ctx, {
      msgStr: t('MESSAGE.DELETE'),
      okCallback: async () => {
        try {
          const { error } = await deleteNotice({
            noticeIds: checkedIds,
            frameName: FRAME_NAMES.SETTING_NOTICE.DELETE_NOTICE,
          });
          if (error) {
            showErrorMsg(ctx, error);
          } else {
            showSuccessMsg(ctx, t('MESSAGE.DELETED'));
            await onRefresh();
          }
        } catch (e) {
          console.log(e);
        }
      },
    });
  };
  const onClickTitle = (info) => {
    noticeSettingInfo.isShowViewWindow = true;
    noticeId.value = info[columnIdx.value.id];
  };

  onMounted(async () => {
    viewManagerStore.setLoading(true);
    await refresh();
    viewManagerStore.setLoading(false);
  });

  return {
    hasPermission,
    refreshTime,
    isFailRequestTime,
    onRefresh,
    noticeListColumns,
    gridOption,
    gridSearchBarOption,
    onClickTitle,
    onClickAddNotice,
    onClickEditNotice,
    onClickDelete,
    noticeSettingInfo,
    noticeListRows,
    selectedItems,
    columnIdx,
    noticeId,
    t,
  };
};

export { setup };
