import { RouteMeta, RouteRecordRaw } from 'vue-router';
import { findPermissionByTranslateId } from '@/common/permission/permission.utils';
import { useViewModeStore } from '@/common/stores/view-mode';
import { VIEW_MODE } from '@/common/define/common.define';
import { i18n } from '@/common/locale';
import { type Role, type RoleId, RoleIdMap, Supervisors } from './auth.defines';

export const convertToRoleId = (role: Role) => {
  return RoleIdMap[role];
};

export const isRoleIdIncluded = (allowedRoles: Role[], roleId: RoleId) => {
  return allowedRoles.map(convertToRoleId).includes(roleId);
};

export const checkRole = {
  isAdmin: (roleId?: RoleId): boolean => roleId === RoleIdMap.Admin,
  isMaster: (roleId?: RoleId): boolean => roleId === RoleIdMap.Master,
  isUser: (roleId?: RoleId): boolean => roleId === RoleIdMap.User,
  isSupervisor: (roleId?: RoleId): boolean => !!roleId && isRoleIdIncluded(Supervisors, roleId),
};

export const getDisplayRoleName = (roleId?: RoleId): string => {
  return checkRole.isMaster(roleId) || checkRole.isAdmin(roleId)
    ? i18n.global.t('WORD.ADMIN')
    : i18n.global.t('WORD.USER');
};
export const getDisplayRoleType = (roleId?: RoleId): 'admin' | 'user' => {
  return checkRole.isMaster(roleId) || checkRole.isAdmin(roleId) ? 'admin' : 'user';
};

export const extractAccessibleRoutes = (
  routes: RouteRecordRaw[],
  roleId?: RoleId,
): RouteRecordRaw[] => {
  const { isMaxgaugeMode } = useViewModeStore();
  const filteredRoutes: RouteRecordRaw[] = [];

  // 뷰모드에 따른 메뉴 display 체크
  const shouldDisplayViewMode = (meta: RouteMeta | undefined) =>
    !isMaxgaugeMode || (isMaxgaugeMode && !meta?.invisibleViewMode?.includes(VIEW_MODE.MAXGAUGE));

  const hasAccess = (route: RouteRecordRaw) => {
    const roles = route.meta?.roles?.map(convertToRoleId);
    const permission = findPermissionByTranslateId(route.meta?.rolePermission);

    const roleCheck = !roles || (roleId != null && roles.includes(roleId));
    const permissionCheck = permission == null || permission.menuOnOff || permission.listOnOff;

    return roleCheck && permissionCheck;
  };

  routes.forEach((route: RouteRecordRaw) => {
    // 라우트의 meta.roles의 roleId가 유저의 roleId를 포함하는지 확인
    if (hasAccess(route)) {
      const { children, meta, ...restParams } = route;
      const isDisplayMenu = shouldDisplayViewMode(meta);
      // 라우트에 children이 있는 경우 재귀적으로 함수 호출하여 확인
      if (children && children.length > 0) {
        const childAdminRoutes = extractAccessibleRoutes(children, roleId);
        // 추출된 하위 라우트가 있는 경우 filteredRoutes에 추가
        if (childAdminRoutes.length > 0 && isDisplayMenu) {
          filteredRoutes.push({
            ...restParams,
            meta,
            children: childAdminRoutes,
          });
        }
      } else if (isDisplayMenu) {
        filteredRoutes.push({ ...restParams, meta, children: [] });
      }
    }
  });

  return filteredRoutes;
};
