import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import useAuthorize from '@/common/auth/useAuthorize';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { store } from '@/common/store';
import type {
  AddedKeyword,
  FilteredKeyword,
  Keyword,
} from '@/common/components/molecules/keywordSettingModal/keywordSettingModal.types';
import { fixColor } from '@/common/components/molecules/keywordSettingModal/keywordSettingModal.utils';
import {
  getCustomKeywordKeywordControllerAxios,
  getKeywordKeywordControllerAxios,
  updateCustomKeywordKeywordControllerAxios,
  updateKeywordKeywordControllerAxios,
} from '@/openapi/metaV6/api/keyword-controller-api';

export const useLogsKeywordSettingStore = defineStore('keywordSetting', () => {
  const userId = computed(() => store.getters?.['myInfo/getAccountInfo']?.userId);

  const configKeywordList = ref<FilteredKeyword[]>([]);
  const userKeywordList = ref<AddedKeyword[]>([]);

  const fetchConfigKeywordList = async () => {
    try {
      const {
        data: { data = [] },
      } = await getKeywordKeywordControllerAxios({
        frameName: FRAME_NAMES.LOG_LOGGING.LOGGING_KEYWORD,
      });

      configKeywordList.value =
        data
          .map<FilteredKeyword>((item) => ({
            type: item.type === 'regexp' ? 'regexp' : 'string',
            keyword: item.keyword ?? '',
            lineColor: fixColor('line', item.lineColor),
            bgColor: fixColor('bg', item.bgColor),
          }))
          .toSorted((a, b) => a.keyword.localeCompare(b.keyword)) ?? [];
    } catch (e) {
      configKeywordList.value = [];
    }
  };

  const fetchUserKeywordList = async () => {
    try {
      const {
        data: { data = [] },
      } = await getCustomKeywordKeywordControllerAxios({
        userId: userId.value,
        frameName: FRAME_NAMES.LOG_LOGGING.LOGGING_CUSTOM_KEYWORD,
      });
      userKeywordList.value = (data ?? [])
        .map((item) => ({
          id: item.customKeywordId,
          type: 'string' as const,
          keyword: item.keyword ?? '',
          bgColor: fixColor('bg', item.bgColor),
        }))
        .toSorted((a, b) => a.keyword.localeCompare(b.keyword));
    } catch (e) {
      userKeywordList.value = [];
    }
  };

  const updateConfigKeywordList = async (list: FilteredKeyword[]) => {
    try {
      await updateKeywordKeywordControllerAxios({
        updateKeywordRequests: list,
        frameName: FRAME_NAMES.LOG_LOGGING.LOGGING_KEYWORD_UPDATE,
      });
      configKeywordList.value = list;
    } catch (e) {
      throw new Error();
    }
  };

  const updateUserKeywordList = async (list: AddedKeyword[]) => {
    try {
      await updateCustomKeywordKeywordControllerAxios({
        request: list.map((item) => ({
          userId: userId.value,
          keywordId: item.id as number | undefined,
          keyword: item.keyword,
          bgColor: item.bgColor,
        })),
        frameName: FRAME_NAMES.LOG_LOGGING.LOGGING_CUSTOM_KEYWORD_UPDATE,
      });
      userKeywordList.value = list;
    } catch (e) {
      throw new Error();
    }
  };

  const hasKeywordEditAuth = computed(() => useAuthorize('logging', 'logKeyword'));

  const highlightInfo = computed<Keyword[]>(() => [
    ...configKeywordList.value,
    ...userKeywordList.value,
  ]);

  return {
    fetchConfigKeywordList,
    fetchUserKeywordList,
    configKeywordList,
    userKeywordList,

    hasKeywordEditAuth,
    highlightInfo,

    updateConfigKeywordList,
    updateUserKeywordList,
  };
});
