import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { ClickData } from '@/common/utils/types';
import {
  GridRawRowDataType,
  GridRowDataType,
} from '@/common/components/molecules/modernGrid/modernGrid.types';

const STYLE_INFO = {
  MORE_COUNT: 32,
  GAP: 4,
  CHIP_MAX_WIDTH: '100px',
};

// 그리드에서 shownChipCount n개로 사용할경우 content 가 잘릴 수 있음
// 그리드 아닌 부분에서도 사용 할 수 있도록 type 을 nubmer로 수정
export interface Props {
  rowIndex: number;
  chips: string[] | Record<string, string[]> | null;
  shownChipCount?: number;
  isMultipleValueChip?: boolean;
  customClassEachChips?: string[];
  fieldName?: string | keyof GridRowDataType<GridRawRowDataType>;
  rowData?: any | GridRowDataType<GridRawRowDataType>;
  columnIndex?: number;
  isClickable?: boolean;
}

export interface Emit {
  (
    e: 'click-count-clickable',
    value: ClickData & {
      fieldName?: string;
      rowData?: any | GridRowDataType<GridRawRowDataType>;
      columnIndex?: number;
    },
  ): void;
  (e: 'click-count', value: ClickData): void;
  (e: 'click-chip', value: ClickData): void;
  (e: 'resize'): void;
}

const setup = (props: Props, emit: Emit) => {
  const baseGridChipsEl = ref<HTMLElement | null>(null);
  const filteredChips = ref<string[]>([]);
  const moreCount = ref<number>(0);
  const chipsArray = computed<string[]>(() => {
    if (!props.chips) {
      return [];
    }
    return props.isMultipleValueChip ? Object.keys(props.chips) : (props.chips as string[]);
  });
  const chipsClass = computed(() => (props.chips?.length === 1 ? 'stretch' : ''));

  const setFilteredChips = async (newChips: string[]) => {
    filteredChips.value = [...newChips];

    await nextTick();
    if (baseGridChipsEl.value && newChips.length > 1) {
      const targetElWidth = baseGridChipsEl.value.getBoundingClientRect().width;
      const children = baseGridChipsEl.value?.querySelectorAll<HTMLElement>('.chip');

      if (children?.length) {
        let chipCount = 0;
        let childTotalWidth = 0;
        children.forEach((childEl) => {
          if (childEl.className.includes('count')) {
            return;
          }

          const { width } = childEl.getBoundingClientRect();
          childTotalWidth += width + STYLE_INFO.GAP;
          if (childTotalWidth + STYLE_INFO.MORE_COUNT < targetElWidth) {
            chipCount += 1;
          }
        });

        if (props.shownChipCount) {
          children[0].style.maxWidth = `${
            targetElWidth - STYLE_INFO.MORE_COUNT - STYLE_INFO.GAP
          }px`;
        } else if (newChips.length && !chipCount) {
          children[0].style.maxWidth = `${
            targetElWidth - STYLE_INFO.MORE_COUNT - STYLE_INFO.GAP
          }px`;
          chipCount++;
        } else {
          children[0].style.maxWidth = STYLE_INFO.CHIP_MAX_WIDTH;
        }

        filteredChips.value = filteredChips.value.slice(0, props.shownChipCount ?? chipCount);
        moreCount.value = newChips.length - (props.shownChipCount ?? chipCount);
      }
    } else {
      moreCount.value = 0;
    }
  };

  const onClickChip = (chip: string, evt?: MouseEvent) => {
    if (!props.isMultipleValueChip || !props.chips) {
      return;
    }
    emit('click-chip', {
      rowIndex: props.rowIndex,
      chips: (props.chips as Record<string, string[]>)[chip],
      totalChips: chipsArray.value,
      evt,
    });
  };

  const onClickCount = (evt?: MouseEvent) => {
    const chips = chipsArray.value.slice(moreCount.value * -1);

    emit('click-count', {
      rowIndex: props.rowIndex,
      chips,
      totalChips: chipsArray.value,
      evt,
    });
  };

  const onClickCountClickable = (evt?: MouseEvent) => {
    if (!props.isClickable) return;

    emit('click-count-clickable', {
      rowIndex: props.rowIndex,
      chips: chipsArray.value.slice(moreCount.value * -1),
      totalChips: chipsArray.value,
      evt,
      fieldName: props.fieldName,
      rowData: props.rowData,
      columnIndex: props.columnIndex,
    });
  };

  const ro = new ResizeObserver(async () => {
    await setFilteredChips(chipsArray.value);
  });

  watch(
    chipsArray,
    async (newArray) => {
      await setFilteredChips(newArray);
    },
    { immediate: true },
  );

  onMounted(() => {
    ro.observe(baseGridChipsEl.value!);
  });

  return {
    baseGridChipsEl,
    filteredChips,
    moreCount,
    chipsClass,
    onClickChip,
    onClickCount,
    onClickCountClickable,
  };
};

export { STYLE_INFO, setup };
