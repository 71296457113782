/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  ApiResponseNoticeDetailView,
  ApiResponseNoticeInfoResponse,
  ApiResponseNoticeOverView,
  ApiResponsePostPopupInfoResponse,
  IdInfo,
  NoticeInfoRequest,
  ResultResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getEditInfoNoticeControllerAxios = ({
  noticeId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  noticeId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseNoticeInfoResponse> => {
  const localVarPath = '/api/v8/notice/{noticeId}'.replace(
    `{${'noticeId'}}`,
    encodeURIComponent(String(noticeId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNoticeControllerAxios = ({
  noticeId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  noticeId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseNoticeDetailView> => {
  const localVarPath = '/api/v8/notice/{noticeId}/detail'.replace(
    `{${'noticeId'}}`,
    encodeURIComponent(String(noticeId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNoticeListNoticeControllerAxios = ({
  isHomeView,
  offset,
  paged,
  pageNumber,
  pageSize,
  sortSorted,
  sortUnsorted,
  unpaged,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  isHomeView?: boolean;
  offset?: number;
  paged?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortSorted?: boolean;
  sortUnsorted?: boolean;
  unpaged?: boolean;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponseNoticeOverView> => {
  const localVarPath = '/api/v8/notice/list';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      isHomeView,
      offset,
      paged,
      pageNumber,
      pageSize,
      sortSorted,
      sortUnsorted,
      unpaged,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const checkPopupTimeNoticeControllerAxios = ({
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ApiResponsePostPopupInfoResponse> => {
  const localVarPath = '/api/v8/notice/popup-check';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const updateNoticeControllerAxios = ({
  noticeId,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  noticeId: number;
  request: NoticeInfoRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/notice/{noticeId}/update'.replace(
    `{${'noticeId'}}`,
    encodeURIComponent(String(noticeId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const noticeClickedHistoryNoticeControllerAxios = ({
  noticeId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  noticeId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/notice/{noticeId}/clicked'.replace(
    `{${'noticeId'}}`,
    encodeURIComponent(String(noticeId)),
  );
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const deleteNoticeControllerAxios = ({
  noticeIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  noticeIds: Array<IdInfo>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/notice/delete';
  const httpMethod = 'post';

  const data: any = noticeIds;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const saveNoticeControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: NoticeInfoRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/notice/save';
  const httpMethod = 'post';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const testEmailNoticeControllerAxios = ({
  noticeId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  noticeId: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v8/notice/{noticeId}/test-email'.replace(
    `{${'noticeId'}}`,
    encodeURIComponent(String(noticeId)),
  );
  const httpMethod = 'post';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getEditInfoNoticeControllerAxios,
  getNoticeControllerAxios,
  getNoticeListNoticeControllerAxios,
  checkPopupTimeNoticeControllerAxios,
  updateNoticeControllerAxios,
  noticeClickedHistoryNoticeControllerAxios,
  deleteNoticeControllerAxios,
  saveNoticeControllerAxios,
  testEmailNoticeControllerAxios,
};
