import { useInternational } from '@/common/locale';
import { UUID, WidgetChartDataStatusInfo } from '@/common/utils/types';
import { getCurrentInstance, ref } from 'vue';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { DisplayStyleType, WidgetChartData, WidgetChartType } from '@/dashboard/utils/types';
import { ChartDataStat } from '@/common/components/organisms/statIndicatorSelect/statIndicatorSelect.types';
import {
  ChartDataStatus,
  EqualizerChartDataStatus,
  ScoreboardStyle2ChartDataStatus,
} from './ChartDataStatus';
import { ChartDataStatusFactory } from './ChartDataStatusFactory';
import { useWidgetChartDataStatus } from '../../widgetSettingsWindow/context/widgetChartDataStatus';
import { isCurrentRawData, isTableRawData } from '../widgets.utils';
import { ChartRawData } from '../widgets.types';

export function useChartDataStatus() {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;

  const chartDataStatus = ref<ChartDataStatus | null>(null);
  const widgetChartDataStatus = useWidgetChartDataStatus();

  const setChartDataStatus = (
    chartType: WidgetChartType,
    displayStyle: DisplayStyleType | undefined,
  ) => {
    chartDataStatus.value = ChartDataStatusFactory.createChartDataStatus(chartType, displayStyle);
  };

  const setRawData = (rawData: ChartRawData) => {
    if (!chartDataStatus.value) {
      return;
    }
    if (
      chartDataStatus.value instanceof ScoreboardStyle2ChartDataStatus &&
      isCurrentRawData(rawData)
    ) {
      chartDataStatus.value.setRawData(rawData);
    } else if (
      chartDataStatus.value instanceof EqualizerChartDataStatus &&
      isTableRawData(rawData)
    ) {
      chartDataStatus.value.setRawData(rawData);
    }
  };

  const checkChartDataStatus = (
    responseStatusInfo: WidgetChartDataStatusInfo[],
    chartData: WidgetChartData[],
    _isReferenceWidget: boolean,
  ) => {
    if (!chartDataStatus.value) {
      return;
    }
    const statusInfo = chartDataStatus.value.checkChartDataStatus(
      responseStatusInfo,
      chartData,
      _isReferenceWidget,
    );
    widgetChartDataStatus.value = statusInfo.getChartDataStatus();

    if (statusInfo.getChartDataStatus().some((status) => status.errorCode === 'CE003')) {
      showErrorMsg(ctx, useInternational().t('ERROR.CHART_DATA.CE003'));
    }
  };

  const setChartDataStatusByStatInfo = (
    chartDataId: UUID,
    chartDataStat: ChartDataStat | undefined,
    _isReferenceWidget: boolean = false,
  ) => {
    if (!chartDataStatus.value) {
      return;
    }
    widgetChartDataStatus.value = chartDataStatus.value.setChartDataStatusByStatInfo(
      chartDataId,
      chartDataStat,
      _isReferenceWidget,
    );
  };

  return {
    widgetChartDataStatus,
    setChartDataStatus,
    setRawData,
    checkChartDataStatus,
    setChartDataStatusByStatInfo,
  };
}
