import {
  BucketAggregation,
  DateHistogramBucketAggregations,
  FiltersBucketAggregations,
  HistogramBucketAggregations,
  TermsBucketAggregations,
} from './BucketAggregation';

export function isDateHistogramBucket(
  data: BucketAggregation,
): data is DateHistogramBucketAggregations {
  return data instanceof DateHistogramBucketAggregations;
}

export function isFiltersBucket(data: BucketAggregation): data is FiltersBucketAggregations {
  return data instanceof FiltersBucketAggregations;
}

export function isHistogramBucket(data: BucketAggregation): data is HistogramBucketAggregations {
  return data instanceof HistogramBucketAggregations;
}

export function isTermsBucket(data: BucketAggregation): data is TermsBucketAggregations {
  return data instanceof TermsBucketAggregations;
}
