import { TabPanel } from '@/common/utils/types';
import { EventListItem } from '@/alert/utils/types';
import { i18n } from '@/common/locale';
import { DEFAULT_PERIOD_ITEMS } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.utils';
import { SelectOption } from '@/types/common.types';
import { RuleCriteria } from '@/alert/components/alertDetail/alertDetail.types';

export const ALERT_TYPE_VALUE = {
  METRIC: 'Metric',
  EVENT: 'Event',
  PROCESS: 'Process',
} as const;
export const getAlertTypeItems = (): SelectOption<string>[] => [
  { name: i18n.global.t('WORD.METRIC'), value: ALERT_TYPE_VALUE.METRIC },
  { name: i18n.global.t('WORD.EVENT'), value: ALERT_TYPE_VALUE.EVENT },
];

export const EVENT_NETWORK_TARGET_CATEGORIES = ['network_device'];
export const EVENT_PROCESS_DOWN_TARGET_CATEGORIES = ['host'];
export const METRIC_PROCESS_TARGET_CATEGORIES = ['host'];

export const CHECK_BY_TARGET = 'Check by Target';
export const CHECK_BY_COMBINATION = 'Check by Combination';
export const RULE_CRITERIA_ITEMS: SelectOption<RuleCriteria>[] = [
  { name: CHECK_BY_TARGET, value: CHECK_BY_TARGET },
  { name: CHECK_BY_COMBINATION, value: CHECK_BY_COMBINATION },
];
export const getCheckIntervalItems = (): SelectOption<number>[] =>
  new Array(12).fill(null).map((v, idx) => {
    const sec = (idx + 1) * 5;
    return { name: i18n.global.t('WORD.SECONDS', { value: sec }), value: sec };
  });
export const getCheckHoldTimeItems = (): SelectOption<string>[] => [
  { name: i18n.global.t('WORD.SECONDS', { value: 0 }), value: 'p0s' },
  { name: i18n.global.t('WORD.SECONDS', { value: 30 }), value: 'p30s' },
  { name: i18n.global.t('WORD.MINUTE', { value: 1 }), value: 'p1m' },
  { name: i18n.global.t('WORD.MINUTES', { value: 10 }), value: 'p10m' },
  { name: i18n.global.t('WORD.MINUTES', { value: 30 }), value: 'p30m' },
  { name: i18n.global.t('WORD.HOUR', { value: 1 }), value: 'p1h' },
  { name: i18n.global.t('WORD.HOURS', { value: 6 }), value: 'p6h' },
  { name: i18n.global.t('WORD.HOURS', { value: 12 }), value: 'p12h' },
  { name: i18n.global.t('WORD.DAY', { value: 1 }), value: 'p1d' },
];
export const getWeeklyScheduleItems = (): SelectOption<string>[] => [
  { name: i18n.global.t('WORD.MONDAY'), value: 'Mon' },
  { name: i18n.global.t('WORD.TUESDAY'), value: 'Tue' },
  { name: i18n.global.t('WORD.WEDNESDAY'), value: 'Wed' },
  { name: i18n.global.t('WORD.THURSDAY'), value: 'Thu' },
  { name: i18n.global.t('WORD.FRIDAY'), value: 'Fri' },
  { name: i18n.global.t('WORD.SATURDAY'), value: 'Sat' },
  { name: i18n.global.t('WORD.SUNDAY'), value: 'Sun' },
];
export const getAggregationItems = (): SelectOption<string>[] => [
  { name: i18n.global.t('WORD.AVG'), value: 'avg' },
  { name: i18n.global.t('WORD.MAX'), value: 'max' },
  { name: i18n.global.t('WORD.MIN'), value: 'min' },
  { name: i18n.global.t('WORD.SUM'), value: 'sum' },
];
export const COMPARISON_ITEMS: SelectOption<string>[] = [
  { name: '>', value: '>' },
  { name: '>=', value: '>=' },
  { name: '<', value: '<' },
  { name: '<=', value: '<=' },
];
export const getEvaluateValueItems = (): SelectOption<string>[] => [
  { name: i18n.global.t('WORD.AVG'), value: 'avg' },
  { name: i18n.global.t('WORD.MAX'), value: 'max' },
  { name: i18n.global.t('WORD.MIN'), value: 'min' },
  { name: i18n.global.t('WORD.CURRENT'), value: 'current' },
  { name: i18n.global.t('WORD.CHANGE'), value: 'change' },
];

const timeUnitStr = {
  m: 'WORD.MINUTES',
  h: 'WORD.HOURS',
  d: 'WORD.DAY',
};
export const getDuringTimeItems = (): SelectOption<string>[] => {
  return DEFAULT_PERIOD_ITEMS.map((item) => {
    const numberStr = item.value.replace(/\D/g, '');
    const timeUnit = item.value.slice(-1);
    const name = `${i18n.global.t('WORD.LAST')} ${i18n.global.t(timeUnitStr[timeUnit], {
      value: parseInt(numberStr, 10),
    })}`;
    return {
      name,
      value: item.value,
    };
  });
};

export const { ENUM: EVENT_TYPE_ENUM, REQ_ENUM: EVENT_TYPE_REQ_ENUM } = (() => {
  const ENUM = {
    LOGGING: 'Logging',
    NETWORK_SYSLOG: 'Network Syslog',
    NETWORK_TRAP: 'Network Trap',
    KUBERNETES: 'Kubernetes',
    PROCESS_DOWN_DETECTION: 'Process Down Detection',
  } as const;
  const REQ_ENUM = Object.keys(ENUM).reduce(
    (acc, key) => {
      acc[ENUM[key]] = key;
      return acc;
    },
    {} as Record<(typeof ENUM)[keyof typeof ENUM], keyof typeof ENUM>,
  );

  return {
    ENUM,
    REQ_ENUM,
  };
})();

export const getEventTypeItems = (): SelectOption<string>[] => [
  { name: i18n.global.t('WORD.LOGGING'), value: EVENT_TYPE_ENUM.LOGGING },
  { name: 'Network Syslog', value: EVENT_TYPE_ENUM.NETWORK_SYSLOG },
  { name: 'Network Trap', value: EVENT_TYPE_ENUM.NETWORK_TRAP },
  { name: i18n.global.t('WORD.KUBERNETES'), value: EVENT_TYPE_ENUM.KUBERNETES },
  {
    name: i18n.global.t('WORD.PROCESS_DOWN_DETECTION'),
    value: EVENT_TYPE_ENUM.PROCESS_DOWN_DETECTION,
  },
];

export const FIXED_CONTENT = {
  [CHECK_BY_TARGET]: `
$alert$trigger_time$
$alert$rule_name$
$alert$targets$
$alert$alert_name$ : $alert$level$ ($alert$value$)
`,
  [CHECK_BY_COMBINATION]: `
$alert$trigger_time$
$alert$rule_name$ : $alert$level$
$alert$targets$
$alert$alert_name$ : $alert$level$ ($alert$value$),
$alert$targets$
$alert$alert_name$ : $alert$level$ ($alert$value$)
`,
} as const;

export const NOTIFICATION_FIXED_CONTENT_SAMPLE = {
  [CHECK_BY_TARGET]: `
2023-12-01 13:04:30.776
CPU 사용 알람
A target, B target, C target
Host CPU Usage(%): CRITICAL(93)
`,
  [CHECK_BY_COMBINATION]: `
2023-12-01 13:04:30.776
CPU 사용 알람 : CRITICAL
A target, B target, C target
Host CPU Usage(%): CRITICAL(93)
D target, E target, F target
Container CPU Usage(%): CRITICAL(93)
`,
} as const;

export const SYSLOG_FACILITY_LIST: EventListItem[] = [
  { level: '0', keyword: 'kern', description: '커널 메시지' },
  { level: '1', keyword: 'user', description: 'user-level 메시지' },
  { level: '2', keyword: 'mail', description: '메일 시스템' },
  { level: '3', keyword: 'daemon', description: '시스템 데몬' },
  { level: '4', keyword: 'auth', description: '보안 / 인증 메시지' },
  { level: '5', keyword: 'syslog', description: 'syslogd에 의해 내부적으로 생성된 메시지' },
  { level: '6', keyword: 'lpr', description: '라인 프린터 하위 시스템' },
  { level: '7', keyword: 'news', description: '네트워크 뉴스 하위 시스템' },
  { level: '8', keyword: 'uucp', description: 'UUCP 하위 시스템' },
  { level: '9', keyword: 'cron', description: '크론 하위 시스템' },
  { level: '10', keyword: 'authpriv', description: '보안 / 인증 메시지' },
  { level: '11', keyword: 'ftp', description: 'ftp 데몬' },
  { level: '12', keyword: 'ntp', description: 'ntp 하위 시스템' },
  { level: '13', keyword: 'security', description: '로그 감사' },
  { level: '14', keyword: 'console', description: '로그 경고' },
  { level: '15', keyword: 'solaris-cron', description: '스케줄링 데몬' },
  { level: '16', keyword: 'local0', description: '현지 사용 시설' },
  { level: '17', keyword: 'local1', description: '현지 사용 시설' },
  { level: '18', keyword: 'local2', description: '현지 사용 시설' },
  { level: '19', keyword: 'local3', description: '현지 사용 시설' },
  { level: '20', keyword: 'local4', description: '현지 사용 시설' },
  { level: '21', keyword: 'local5', description: '현지 사용 시설' },
  { level: '22', keyword: 'local6', description: '현지 사용 시설' },
  { level: '23', keyword: 'local7', description: '현지 사용 시설' },
];
export const SYSLOG_SEVERITY_LIST: EventListItem[] = [
  {
    level: '0',
    keyword: 'emergency',
    description:
      '시스템이 동작하지 않는 즉시 처리가 필요한 심각한 문제. 네트워크에 큰 영향을 미침.',
  },
  {
    level: '1',
    keyword: 'alert',
    description: '즉시 조치가 필요한 문제. DB 손상이나 ISP 연결이 유실된 경우.',
  },
  {
    level: '2',
    keyword: 'critical',
    description:
      '문제가 발생할 수 있으니 즉시 수정이 필요한 문제. 시스템 기본 응용프로그램이나 만료된 라이센스의 오류일 수 있음.',
  },
  {
    level: '3',
    keyword: 'error',
    description:
      '긴급하지 않지만 주의가 필요한 시스템 오류. 응용프로그램이 스토리지, 메모리 할당, 파일을 찾을 수 없는 경우 등.',
  },
  {
    level: '4',
    keyword: 'warning',
    description:
      '기간 내에 처리되지 않으면 문제가 발생할 수 있음. 파일 시스템이 높은 사용률을 보이거나 여유 공간이 부족한 경우 등.',
  },
  {
    level: '5',
    keyword: 'notification',
    description:
      '시스템은 정상적이지만 중요한 내용으로 잠재적으로 문제가 발생할 수 있는 이벤트. 지원되지 않는 명령, 설치 된 라이선스 알림, 루트 관리자 작업 등.',
  },
  {
    level: '6',
    keyword: 'information',
    description: '유익하지만 중요하지 않는 내용. 정상 작업에 대한 정보가 포함된 로그.',
  },
  {
    level: '7',
    keyword: 'debugging',
    description:
      '불필요한 메시지. 개발자 전용으로 기존 오류 및 잠재적 오류를 식별하고 제거하는 개발자에게 필요함. 모든 log를 전달하므로 시스템에 부하를 줄 수 있어 주의를 요함.',
  },
];

export const GENERIC_TRAP_TYPE_LIST: EventListItem[] = [
  {
    level: '0',
    keyword: 'coldStart',
    description:
      'coldStart 트랩은 송신 프로토콜 엔티티가 에이전트의 구성 또는 프로토콜 엔티티 구현을 변경할 수 있도록 자신을 다시 초기화하고 있음을 의미합니다.',
  },
  {
    level: '1',
    keyword: 'warmStart',
    description:
      'warmStart 트랩은 송신 프로토콜 엔티티가 에이전트 구성이나 프로토콜 엔티티 구현을 변경할 수 없도록 재초기화하고 있음을 의미합니다.',
  },
  {
    level: '2',
    keyword: 'linkDown',
    description:
      'linkDown 트랩은 송신 프로토콜 엔티티가 에이전트 구성에 표시된 통신 링크 중 하나에서 장애를 인식하는 것을 의미합니다.',
  },
  {
    level: '3',
    keyword: 'linkUp',
    description:
      'linkUp 트랩은 송신 프로토콜 엔티티가 에이전트 구성에 표시된 통신 링크 중 하나가 발생했음을 인식하는 것을 의미합니다.',
  },
  {
    level: '4',
    keyword: 'authenticationFailure',
    description:
      'authenticationFailure 트랩은 송신 프로토콜 엔티티가 제대로 인증되지 않은 프로토콜 메시지의 수신인임을 의미합니다.',
  },
  {
    level: '5',
    keyword: 'egpNeighborLoss',
    description:
      'egpNeighborLoss 트랩은 송신 프로토콜 엔티티가 EGP 피어였던 EGP 네이버가 마크다운되어 피어 관계가 더 이상 존재하지 않음을 의미합니다.',
  },
  {
    level: '6',
    keyword: 'enterpriseSpecific',
    description:
      'EnterpriseSpecific trap은 송신 프로토콜 엔티티가 일부 Enterprise 특정 이벤트가 발생했음을 인식하는 것을 의미합니다.',
  },
];

const KUBE_TARGET_KIND_ITEMS: SelectOption<string>[] = [
  { name: 'Pod', value: 'pod' },
  { name: 'Node', value: 'node' },
  { name: 'Deployment', value: 'deployment' },
  { name: 'Statefulset', value: 'statefulset' },
  { name: 'Daemonset', value: 'daemonset' },
  { name: 'Replicaset', value: 'replicaset' },
  { name: 'Job', value: 'job' },
  { name: 'Cronjob', value: 'cronjob' },
  { name: 'Service', value: 'service' },
];

const getSelectOptionsFromEventList = (list: EventListItem[]): SelectOption<string>[] => {
  return list.map((item) => ({ name: `${item.keyword} : ${item.level}`, value: `${item.level}` }));
};
export const EVENT_VARIABLE_CONDITION_ITEMS = {
  facility: getSelectOptionsFromEventList(SYSLOG_FACILITY_LIST),
  severity: getSelectOptionsFromEventList(SYSLOG_SEVERITY_LIST),
  genericTrapType: getSelectOptionsFromEventList(GENERIC_TRAP_TYPE_LIST),
  kind: KUBE_TARGET_KIND_ITEMS,
};

export const ALERT_NOTIFICATION_TAB_PANELS: TabPanel<string>[] = [
  {
    text: 'Email',
    value: 'email',
    iconClass: 'icon-color-message',
  },
  {
    text: 'Slack',
    value: 'slack',
    iconClass: 'icon-color-slack',
  },
  {
    text: 'Telegram',
    value: 'telegram',
    iconClass: 'icon-color-telegram',
  },
  {
    text: 'Messaging',
    value: 'sms',
    iconClass: 'icon-color-sms',
  },
  {
    text: 'Webhook',
    value: 'webhook',
    iconClass: 'icon-color-webhook',
  },
  {
    text: 'On Site',
    value: 'onSite',
    iconClass: 'icon-color-on-site',
  },
];

export const NOTI_SENT_INFO = {
  true: 'O',
  false: 'X',
};
export const DEFAULT_TRANSPORT_SERVER_TYPE = {
  email: 'SMTP',
  sms: 'SMS_DB',
};
