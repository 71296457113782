const MAIN_FRAMES = {
  HOME: 'Home',
  HOME_ALERT_SUMMARY: 'Home Alert Summary',
  HOME_ALERT_SUMMARY_ALERT_DETAIL: 'Alert Detail - Home Alert Summary',
  HOME_ALERT_DETAIL: 'Alert Detail - Home',
} as const;

export const HOME_FRAME_NAMES = {
  HOME: {
    HOME_ALERT_DETAIL: `${MAIN_FRAMES.HOME}/Alert List`,
    NOTICE_LIST: `${MAIN_FRAMES.HOME}/Notice List`,
    RECENT_VISIT_BOARD: `${MAIN_FRAMES.HOME}/Recently Visited Dashboard`,
  },

  HOME_ALERT_SUMMARY: {
    TOTAL_ALERT_COUNT: `${MAIN_FRAMES.HOME_ALERT_SUMMARY}/Total Alert Count`,
    TREND_ALERT_COUNT: `${MAIN_FRAMES.HOME_ALERT_SUMMARY}/Trend Alert Count`,
    ALERT_HISTORY_DETAIL: `${MAIN_FRAMES.HOME_ALERT_SUMMARY}/Alert History Detail`,
  },

  HOME_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.HOME_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.HOME_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.HOME_ALERT_DETAIL}/Clear System Alert`,
  },
} as const;
