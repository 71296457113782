<script setup lang="ts">
import { computed } from 'vue';
import { IconType } from '@/types/icon.types';
import { formatNumWithCommasAndDecimalStr } from '@/common/utils/commonUtils';
import { isNil } from 'lodash-es';

const props = withDefaults(
  defineProps<{
    decimal?: number;
    value?: number | null;
  }>(),
  {
    decimal: 3,
    value: 0,
  },
);

const symbolIcon = computed<IconType | ''>(() => {
  if (!props.value) {
    return '';
  }
  return props.value > 0 ? 'icon-increase-arrow-color' : 'icon-decrease-arrow-color';
});

const displayValue = computed<string>(() => {
  return isNil(props.value) ? '' : formatNumWithCommasAndDecimalStr(props.value, props.decimal);
});
</script>

<template>
  <div
    class="delta-symbol-cell"
    :title="displayValue"
  >
    <ev-icon
      v-if="symbolIcon"
      :icon="symbolIcon"
      size="large"
    />
    <span class="delta-symbol-cell__value">
      {{ displayValue }}
    </span>
  </div>
</template>

<style lang="scss">
.delta-symbol-cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;

  &__value {
    @include shortening(inline, auto);
  }
}
</style>
