import { useExternalResources } from '@/dashboard/stores/external-variables';
import { useGlobalFilterStore } from '@/dashboard/stores/global-filter';
import { storeToRefs } from 'pinia';
import { computed, toValue, MaybeRefOrGetter } from 'vue';

export const usePrometheusLabels = (targetServerId: MaybeRefOrGetter<string>) => {
  const externalResources = useExternalResources();
  const { externalVariables } = storeToRefs(externalResources);

  const globalVariables = computed(() =>
    externalVariables.value.filter(
      ({ extraType, targetId }) =>
        extraType === 'PROMETHEUS' && targetId === toValue(targetServerId),
    ),
  );

  const globalVariableLabels = computed(() => globalVariables.value.map(({ name }) => `$${name}`));

  const getGlobalVariableValues = (aliasName: string) => {
    const variableId = globalVariables.value.find(({ name }) => name === aliasName)
      ?.extraGlobalFilterId;

    return (
      externalVariables.value.find(
        ({ extraType, targetId, extraGlobalFilterId }) =>
          extraType === 'PROMETHEUS' &&
          targetId === toValue(targetServerId) &&
          extraGlobalFilterId === variableId,
      )?.values ?? []
    );
  };

  return { globalVariableLabels, getGlobalVariableValues };
};

export const uesPrometheusApiParams = () => {
  const externalResources = useExternalResources();
  const { externalVariables } = storeToRefs(externalResources);

  const globalFilterStore = useGlobalFilterStore();

  const { selectedGlobalVariables } = storeToRefs(globalFilterStore);
  const prometheusGlobalVariables = computed(() =>
    selectedGlobalVariables.value.filter(({ category }) => category === 'PROMETHEUS'),
  );

  const isQueryUseGlobalVariable = (query: string) => {
    return prometheusGlobalVariables.value.some(({ aliasName }) => query.includes(`$${aliasName}`));
  };

  const setPrometheusGlobalVariables = (query: string) => {
    if (isQueryUseGlobalVariable(query) === false) {
      return query;
    }

    return prometheusGlobalVariables.value.reduce(
      (acc, { globalFilterId, aliasName, selectedList }) => {
        let values = '';
        if (selectedList?.length === 0) {
          const externalVariable = externalVariables.value.find(
            ({ extraGlobalFilterId }) => extraGlobalFilterId === globalFilterId,
          );
          values = externalVariable?.values?.join('|') ?? '';
        } else {
          values = selectedList?.map(({ tagId }) => tagId)?.join('|') ?? '';
        }
        return acc.replaceAll(`$${aliasName}`, values);
      },
      query,
    );
  };

  return { setPrometheusGlobalVariables, isQueryUseGlobalVariable };
};
