import { computed, ComputedRef, reactive, ref, watch } from 'vue';
import { ChartData, ChartOption, CustomColumn } from '@/common/utils/types';
import { columnFormatter, getTimeInfoApiParams, useAlertFormatter } from '@/alert/utils/utils';
import { useSlideDetailApi } from '@/alert/components/alertDetail/alertDetail.use';
import {
  getHistoryAlertRuleControllerAxios,
  getHistoryBarChartAlertRuleControllerAxios,
} from '@/openapi/alert/api/alert-rule-controller-api';
import { useAbortApi } from '@/common/utils/apiUtils';
import { getInitPeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import {
  AlertDetailEmit,
  AlertDetailTabProps,
  AlertHistoryParams,
  AlertSubType,
  AlertType,
  RuleCriteria,
} from '@/alert/components/alertDetail/alertDetail.types';
import { getSlideDetailStore } from '@/common/stores/slide-detail';
import {
  getHistoryBarChartSystemRuleControllerAxios,
  getHistorySystemRuleControllerAxios,
} from '@/openapi/systemAlert/api/system-rule-controller-api';
import { transformResData, TransformResDataOpt } from '@/worker/utils';
import { TARGET_TYPE, useAlertTagsAndTargets } from '@/alert/utils/tagsAndTargets.uses';
import { useEvChartStyle } from '@/common/utils/chartUtils';
import { CHECK_BY_COMBINATION, CHECK_BY_TARGET, NOTI_SENT_INFO } from '@/alert/utils/define';
import { useInternational } from '@/common/locale';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { AlertRowFormatter, useEventTypeValue } from '@/alert/components/alertGrid/alertGrid.uses';
import { ExportExcelOption } from '@/common/utils/exportExcelUtils';
import { useExportExcelFromServer } from '@/common/composables/useExportExcel';
import { AlertBarChartItem, Query0 } from '@/openapi/alert/model';
import { USER_ENV_KEYS } from '@/common/define/userEnv.define';
import { useDashboardPopupEnvStore } from '@/common/stores/dashboard-popup';
import { useDashboardListViewStore } from '@/dashboard/stores/dashboard-list-view';
import { storeToRefs } from 'pinia';
import { useAlertDetailStore } from '@/alert/stores/alert-detail';

export const CHART_COLOR = {
  CRITICAL: '#FF1F3F',
  WARNING: '#FFD759',
};
const useChart = () => {
  const { axisStyle, tooltipStyle } = useEvChartStyle();
  const chartOption = reactive<ChartOption>({
    type: 'bar',
    width: '100%',
    height: '100',
    title: {
      show: false,
    },
    thickness: 0.6,
    legend: {
      show: false,
    },
    padding: {
      top: 4,
      right: 0,
      left: -10,
      bottom: 4,
    },
    indicator: {
      use: false,
    },
    axesX: [
      {
        ...axisStyle,
        type: 'time',
        showAxis: true,
        showGrid: false,
        timeFormat: 'HH:mm:ss',
      },
    ],
    axesY: [
      {
        ...axisStyle,
        type: 'linear',
        showGrid: true,
        showAxis: false,
        startToZero: true,
        autoScaleRatio: 0.1,
      },
    ],
    tooltip: {
      ...tooltipStyle,
    },
  });

  const chartData = ref<ChartData>({
    series: {
      critical: { name: 'Critical', color: CHART_COLOR.CRITICAL },
      warning: { name: 'Warning', color: CHART_COLOR.WARNING },
    },
    groups: [['critical', 'warning']],
    labels: [],
    data: {},
  });

  const initChartData = () => {
    chartData.value.labels = [];
    chartData.value.data = {
      critical: [],
      warning: [],
    };
  };

  const { callApi } = useSlideDetailApi();
  const setChartData = ({
    data,
    query,
    config,
  }: {
    data: AlertBarChartItem[];
    query: Query0;
    config: any;
  }) => {
    if (!query) {
      initChartData();
      return;
    }
    const transformDataOpt: TransformResDataOpt = {
      type: 'chart',
      fromTime: query?.fromTime || '',
      toTime: query?.toTime || '',
      interval: query?.interval || '1m',
      fields: ['triggerTime', 'criticalCount', 'warningCount'],
      needDateTime: true,
      apiConfig: config,
    };
    const { data: seriesData, dateTime: labels } = transformResData(
      data ?? [],
      transformDataOpt,
    ) ?? {
      data: null,
      dateTime: [],
    };
    chartData.value.labels = labels.map((v) => +v) ?? [];
    chartData.value.data = {
      critical: seriesData?.criticalCount ?? [],
      warning: seriesData?.warningCount ?? [],
    };
    if (query?.interval?.includes('m')) {
      chartOption.axesX![0].timeFormat = 'MM-DD HH:mm:ss';
    } else {
      chartOption.axesX![0].timeFormat = 'HH:mm:ss';
    }
  };
  const dataSettingController = {
    user: async (apiParams: AlertHistoryParams) => {
      const { data, error, query, config } = await callApi({
        fn: getHistoryBarChartAlertRuleControllerAxios,
        params: apiParams,
        frameName: FRAME_NAMES.ALERT_DETAIL.HISTORY_TREND,
      });
      if (!error) {
        setChartData({ data: data ?? [], query, config });
      }
    },
    system: async (apiParams: AlertHistoryParams) => {
      const { data, error, query, config } = await callApi({
        fn: getHistoryBarChartSystemRuleControllerAxios,
        params: apiParams,
        frameName: FRAME_NAMES.ALERT_DETAIL.HISTORY_TREND,
      });
      if (!error) {
        setChartData({ data: data ?? [], query, config });
      }
    },
  };
  const fetchData = async (type: AlertType, apiParams: AlertHistoryParams) => {
    await dataSettingController[type]?.(apiParams);
  };

  return {
    chartOption,
    chartData,
    fetchData,
    initChartData,
  };
};

const BASE_GRID_COLUMNS: CustomColumn[] = [
  {
    caption: 'Alert Name',
    field: 'alertName',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Alert',
    field: 'lastAlert',
    type: 'string',
    rendererType: 'label',
    searchable: true,
  },
  {
    caption: 'Target',
    field: 'targets',
    type: 'string',
    rendererType: 'chip-cell',
    sortable: false,
    searchable: true,
  },
  {
    caption: 'Triggered Time',
    field: 'triggeredTime',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Alert Value',
    field: 'value',
    type: 'string',
    sortable: false,
    searchable: true,
  },
  // {
  //   caption: 'Description',
  //   field: 'description',
  //   type: 'string',
  // },
  {
    caption: 'Notification Status',
    field: 'notiStatus',
    type: 'string',
    sortable: false,
    width: 150,
    searchable: true,
  },
  {
    caption: 'Detail',
    field: 'detail',
    type: 'string',
    align: 'center',
    sortable: false,
  },
  {
    caption: 'resultId',
    field: 'resultId',
    hide: true,
  },
  {
    caption: 'Event Type',
    field: 'eventType',
    hide: true,
  },
];

const useTab = ({
  ruleCriteria,
  subType,
}: {
  ruleCriteria: ComputedRef<RuleCriteria>;
  subType: ComputedRef<AlertSubType | undefined>;
}) => {
  const { t } = useInternational();

  const tabPanels = [
    {
      text: t('WORD.SORT_BY_TIME'),
      value: 'sortByTime',
    },
  ];
  const selectedTab = ref('sortByTime');

  const gridColumns = computed<CustomColumn[]>(() => {
    if (ruleCriteria.value === CHECK_BY_COMBINATION) {
      return [
        {
          caption: 'Rule Name',
          field: 'ruleName',
          type: 'string',
          searchable: true,
        },
        ...BASE_GRID_COLUMNS,
      ];
    }

    return BASE_GRID_COLUMNS;
  });
  const gridData = ref<any[][]>([]);

  const { callApi } = useSlideDetailApi();
  const { makeAlertRows, makeAlertTreeRows } = useAlertTagsAndTargets();
  const alertFormatter = useAlertFormatter();

  const makeRowsController = {
    [CHECK_BY_TARGET]: makeAlertRows,
    [CHECK_BY_COMBINATION]: makeAlertTreeRows,
  };

  const setGridData = (rows, formatter: AlertRowFormatter = {}) => {
    if (!rows) {
      return;
    }
    if (rows && !rows.length) {
      gridData.value = [];
      return;
    }

    const makeRows = makeRowsController[ruleCriteria.value];
    gridData.value = makeRows({
      data: rows,
      columns: gridColumns.value,
      targetFieldName: 'target',
      targetType: TARGET_TYPE.TARGETS,
      formatter: {
        lastAlert: (_, { alert }) => columnFormatter.alert(alert),
        notiSent: (v) => NOTI_SENT_INFO[v] ?? '-',
        ...(formatter || {}),
      },
      hasNullRow: true,
    });
  };
  const fetchTabData = {
    sortByTime: async (type: AlertType, apiParams: AlertHistoryParams) => {
      if (type === 'user') {
        const { data, error } = await callApi({
          fn: getHistoryAlertRuleControllerAxios,
          params: apiParams,
          frameName: FRAME_NAMES.ALERT_DETAIL.HISTORY_SORT_BY_TIME,
        });

        if (!error) {
          setGridData(data, {
            alertName: (v) => {
              if (subType.value === 'metric') {
                return alertFormatter.alertName(v);
              }
              return v;
            },
          });
        }
      } else {
        const { data, error } = await callApi({
          fn: getHistorySystemRuleControllerAxios,
          params: apiParams,
          frameName: FRAME_NAMES.ALERT_DETAIL.HISTORY_SORT_BY_TIME,
        });

        if (!error) {
          setGridData(data);
        }
      }
    },
  };

  const lastApiParams = ref<{ type: AlertType; apiParams: AlertHistoryParams }>({
    type: 'user',
    apiParams: {} as AlertHistoryParams,
  });
  const fetchData = async (type: AlertType, apiParams: AlertHistoryParams) => {
    lastApiParams.value = { type, apiParams };
    await fetchTabData[selectedTab.value]?.(type, apiParams);
  };

  const exportExcelFromServer = useExportExcelFromServer();
  const exportToExcelFromServer = async (exportExcelOption: ExportExcelOption) => {
    const { apiParams, type } = lastApiParams.value;
    if (type === 'user') {
      await exportExcelFromServer(getHistoryAlertRuleControllerAxios, apiParams, exportExcelOption);
    } else {
      await exportExcelFromServer(
        getHistorySystemRuleControllerAxios,
        apiParams,
        exportExcelOption,
      );
    }
  };

  watch(selectedTab, (tabValue) => {
    fetchTabData[tabValue]?.();
  });

  return {
    tabPanels,
    selectedTab,
    gridColumns,
    gridData,
    fetchData,
    setGridData,
    exportToExcelFromServer,
  };
};

export const setup = (props: AlertDetailTabProps, emit: AlertDetailEmit) => {
  const { t } = useInternational();

  const { abortApi, getSignal } = useAbortApi();
  const { selectedRuleInfo: ruleInfo } = storeToRefs(useAlertDetailStore());

  const periodInfo = ref<TimePeriodInfo>(getInitPeriodInfo());

  const { chartOption, chartData, fetchData: fetchChartData, initChartData } = useChart();

  const {
    tabPanels,
    selectedTab,
    gridColumns,
    gridData,
    fetchData: fetchTabData,
    setGridData,
    exportToExcelFromServer,
  } = useTab({
    ruleCriteria: computed(() => ruleInfo.value.ruleCriteria || CHECK_BY_TARGET),
    subType: computed(() => ruleInfo.value.subType),
  });

  const getApiParamsFromType = () => {
    return ruleInfo.value.type === 'user'
      ? {
          alertRuleId: ruleInfo.value.ruleId,
        }
      : {
          systemKind: ruleInfo.value.platform,
          systemEvent: ruleInfo.value.event,
        };
  };
  const onUpdatedIndicator = async (info: TimePeriodInfo) => {
    periodInfo.value = info;
    if (!props.isShow) {
      return;
    }
    abortApi();
    const apiParams: AlertHistoryParams = {
      ...getApiParamsFromType(),
      ...getTimeInfoApiParams(info, true),
      signal: getSignal(),
    };
    await fetchChartData(ruleInfo.value.type, apiParams);
    await fetchTabData(ruleInfo.value.type, apiParams);
  };

  const { isShowMessageWindow, eventAlertInfo, onClickValueCell } = useEventTypeValue();
  const onClickCell = async ({ field, value, row }) => {
    if (field === 'value') {
      await onClickValueCell({ value, row, ruleId: ruleInfo.value.ruleId });
    }
  };

  let prevIsPaused = false;
  watch(isShowMessageWindow, (val) => {
    if (val) {
      prevIsPaused = periodInfo.value.isPaused;
      periodInfo.value.isPaused = true;
    } else {
      periodInfo.value.isPaused = prevIsPaused;
    }
  });

  const slideDetailStore = getSlideDetailStore();
  const { fetchDashboardList } = useDashboardListViewStore();
  watch(
    [() => props.isShow, ruleInfo],
    async ([isShow]) => {
      if (isShow) {
        await fetchDashboardList();
        emit('update:isLoading', true);
        await onUpdatedIndicator(periodInfo.value);
        emit('update:isLoading', false);

        const { fetchDashboardPopupEnvInfoForKey } = useDashboardPopupEnvStore();
        const envKey = USER_ENV_KEYS.ALERT_LIST_DETAIL_HISTORY_LIST_DASHBOARD_SETTING_LIST;
        await fetchDashboardPopupEnvInfoForKey(envKey);
      } else {
        initChartData();
        setGridData([]);
        abortApi();
      }
    },
    { immediate: true },
  );

  return {
    periodInfo,
    onUpdatedIndicator,

    ruleInfo,

    chartOption,
    chartData,

    tabPanels,
    selectedTab,
    gridColumns,
    gridData,

    isShowMessageWindow,
    eventAlertInfo,
    onClickCell,
    exportToExcelFromServer,
    t,
  };
};
