<script lang="ts" setup>
import { clickoutside as vClickOutside } from '@/common/directives/clickoutside';
import { setup, Props, Emit } from '@/common/components/molecules/filterSearch/filterSearch.setup';
import FilterSearchDropdownList from '@/common/components/molecules/filterSearch/FilterSearchDropdownList.vue';
import FilterSearchToken from '@/common/components/molecules/filterSearch/FilterSearchToken.vue';
import { wheeloutside as vWheelOutside } from '@/common/directives/wheeloutside';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();

const {
  logicalOperatorListItems,
  selectedLogicalOperator,
  onChangeLogicalOperator,

  isPin,
  onClickPin,

  isFocus,
  filterMainRef,
  filterSearchRef,
  tokensMoreBtnWrapperRef,
  tokensWrapperRef,
  setElementRef,
  moreCount,
  isExpand,
  expand,
  onClickOutside,
  onWheelOutside,

  isShowDropdown,
  dropdownListInfo,
  hoveredDropdownItemIndex,
  dropdownListRef,
  dropdownListWrapperStyle,
  initHoveredDropdownItemIndex,
  onClickDropdownItem,

  mainInputRef,
  mainInputValue,
  onInput,
  onKeyEnter,
  onKeyCtrlEnter,
  onKeyUpDown,
  onKeyBackspace,
  onKeyEsc,
  onFocusMainInput,

  tokenList,
  editingToken,
  lastToken,
  onClickRemoveToken,
  onClickTokenParts,
  isActiveAddMultiValueBtn,
  onClickAddMultiValue,
  onClickClearAll,

  filterHistory,
  isShowFilterHistoryList,
  addTokenByHistory,
  onClickMoreCount,
} = setup(props, emit);
</script>
<template>
  <div
    ref="filterSearchRef"
    v-click-outside="onClickOutside"
    v-wheel-outside="onWheelOutside"
    class="filter-search"
  >
    <div class="filter-search__logical-operator">
      <ev-select
        v-model="selectedLogicalOperator"
        :items="logicalOperatorListItems"
        class="size-medium"
        @change="onChangeLogicalOperator"
      />
    </div>
    <div
      ref="filterMainRef"
      :class="['filter-search__filter-main', { focus: isFocus, expand: isExpand }]"
    >
      <div class="filter-search__filter-input-wrapper">
        <div class="filter-search__filter-icon-wrapper">
          <ev-icon icon="icon-filter-list" />
        </div>
        <ev-button
          type="icon"
          size="small"
          :class="['filter-search__pin-button', { active: isPin }]"
          @click.stop="onClickPin"
        >
          <ev-icon :icon="isPin ? 'icon-pin' : 'icon-pin-outline'" />
        </ev-button>
        <div
          ref="tokensMoreBtnWrapperRef"
          :class="['filter-search__tokens-more-btn-wrapper', { expand: isExpand }]"
        >
          <ul
            ref="tokensWrapperRef"
            class="filter-search__tokens-wrapper"
            :style="{
              flexFlow: isExpand ? 'row wrap' : 'row nowrap',
            }"
            @click.stop="expand"
          >
            <li
              v-for="(token, tokenIndex) in tokenList"
              :ref="(el) => setElementRef(el, tokenIndex)"
              :key="tokenIndex"
              class="filter-search__token-wrapper"
            >
              <filter-search-token
                :token-data="token"
                :is-editing-token="token.id === editingToken?.id"
                :is-last-token="token.id === lastToken?.id"
                :dropdown-list-category="dropdownListInfo.category"
                :is-show-dropdown-list="isShowDropdown"
                :is-active-add-multi-value-btn="isActiveAddMultiValueBtn(token)"
                @input="onInput"
                @key-enter="onKeyEnter"
                @key-arrow="(param) => onKeyUpDown(param.event, param.direction)"
                @key-esc="onKeyEsc"
                @click-parts="onClickTokenParts"
                @click-add-multi-value="onClickAddMultiValue"
                @key-ctrl-enter="onKeyCtrlEnter"
                @remove="() => onClickRemoveToken(tokenIndex, token.id === editingToken?.id)"
              />
            </li>
            <li class="filter-search__main-input-wrapper">
              <input
                v-if="!moreCount || isExpand"
                ref="mainInputRef"
                v-model="mainInputValue"
                :placeholder="tokenList.length ? '' : props.placeholder ?? ''"
                @input="onInput"
                @focus="onFocusMainInput"
                @keydown.enter.exact="onKeyEnter"
                @keydown.up.exact="(e) => onKeyUpDown(e, 'up')"
                @keydown.down.exact="(e) => onKeyUpDown(e, 'down')"
                @keydown.delete.exact="onKeyBackspace"
                @keydown.esc.exact="onKeyEsc"
                @keydown.ctrl.enter.exact="onKeyCtrlEnter"
              />
            </li>
          </ul>
          <button
            v-if="moreCount && !isExpand"
            class="filter-search__more-count-button"
            type="button"
            @click.stop="onClickMoreCount"
          >
            {{ `+${moreCount}` }}
          </button>
        </div>
        <div
          v-show="tokenList?.length"
          class="filter-search__clear-all-btn-wrapper"
        >
          <ev-button
            type="icon"
            size="small"
            class="filter-search__clear-all-button"
            @click.stop="onClickClearAll"
          >
            <ev-icon icon="icon-close" />
          </ev-button>
        </div>
      </div>
      <div
        v-if="isShowDropdown"
        ref="dropdownListRef"
        class="filter-search__dropdown-list-wrapper"
        :style="dropdownListWrapperStyle"
      >
        <filter-search-dropdown-list
          :dropdown-list-info="dropdownListInfo"
          :hovered-dropdown-item-index="hoveredDropdownItemIndex"
          :show-filter-history-list="isShowFilterHistoryList"
          :filter-history="filterHistory"
          @mouseenter-to-item="initHoveredDropdownItemIndex"
          @click-list-item="(item) => onClickDropdownItem(item)"
          @click-history-item="(item) => addTokenByHistory(item)"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.filter-search {
  --filter-search-height: 28px;
  --filter-search-icon-width: 30px;
  --filter-search-clear-all-icon-width: 30px;
  --filter-search-logical-operator-width: 100px;

  display: flex;
  position: relative;
  flex-direction: row;
  align-content: stretch;
  width: 100%;
  height: var(--filter-search-height) !important;
  background-color: var(--filter-search-bg-color);
}
.filter-search__logical-operator {
  display: inline-flex;
  align-items: center;
  width: var(--filter-search-logical-operator-width);

  .ev-input {
    --grid-toolbar-height: var(--filter-search-height);

    height: var(--filter-search-height) !important;
    border-right-width: 0 !important;
    border-radius: 2px 0 0 2px !important;
  }

  .ev-select-dropbox {
    .disabled {
      font-size: 11px !important;
      color: var(--filter-search-list-sub-title-color) !important;
    }
  }
}
.filter-search__filter-main {
  display: inline-block;
  position: relative;
  width: calc(100% - 100px);
  border: 1px solid var(--filter-search-border-color);
  border-radius: 0 2px 2px 0 !important;
  transition: border 0.3s !important;

  &:hover {
    border-color: var(--input-text-hover-border-color);
  }

  &.focus {
    border-color: var(--filter-search-active-border-color) !important;
  }

  &.expand {
    position: absolute;
    top: 0;
    right: 0;
    left: var(--filter-search-logical-operator-width);
    z-index: 299;
    min-height: var(--filter-search-height);
    background-color: var(--filter-search-bg-color);
    box-shadow: var(--filter-search-dropdown-list-box-shadow);
  }
}
.filter-search__filter-input-wrapper {
  display: flex;
  flex-grow: 0;
  gap: 5px;
  align-items: center;
  height: 100%;

  .filter-search__filter-icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--filter-search-icon-width);
    height: 100%;
  }

  .filter-search__pin-button {
    cursor: pointer;
    i {
      background-color: var(--filter-search-pin-default-icon-color) !important;
    }

    &.active {
      i {
        background-color: var(--filter-search-pin-active-icon-color) !important;
      }
    }
  }

  .filter-search__tokens-more-btn-wrapper {
    display: inline-flex;
    flex-flow: row;
    gap: 5px;
    align-items: center;
    width: calc(100% - 30px - 30px - 5px - 5px);
    height: auto;
    overflow: clip;
    cursor: text;

    .filter-search__clear-all-btn-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: var(--filter-search-clear-all-icon-width);
      height: 100%;
    }

    &.expand {
      display: block;
      max-height: 250px;
      overflow: auto;
    }
  }
}
.filter-search__tokens-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  padding: 3px 0;

  .filter-search__token-wrapper {
    max-width: 100%;
  }

  .filter-search__main-input-wrapper {
    flex: auto;
    height: 20px;

    input {
      width: 100%;
      height: 100%;
      padding: 0 5px;
      outline: none;
      background-color: transparent !important;
      font-size: 13px;
      color: var(--input-text-font-color) !important;

      &::placeholder {
        color: var(--input-text-placeholder-color);
      }
    }

    &:hover {
      border-color: var(--input-text-hover-border-color);
    }

    &::placeholder {
      color: var(--input-text-placeholder-color);
    }
  }
}
.filter-search__more-count-button {
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 29px;
  background-color: var(--badge-bg-color);
  font-size: 12px;
  color: var(--badge-font-color);

  &:hover {
    background-color: var(--badge-active-bg-color);
  }
}
.filter-search__clear-all-button {
  .icon-close {
    background-color: var(--filter-search-clear-all-icon-color) !important;
  }
}
.filter-search__dropdown-list-wrapper {
  position: fixed;
  z-index: 300;
  height: auto;
  max-height: 450px;
  overflow-y: auto;
  border-radius: 2px;
  background-color: var(--filter-search-dropdown-list-bg-color);
  box-shadow: var(--filter-search-dropdown-list-box-shadow);
}
</style>
