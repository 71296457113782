import { useInternational } from '@/common/locale';
import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { useDashboardListViewStore } from '@/dashboard/stores/dashboard-list-view';
import { storeToRefs } from 'pinia';
import { checkValid, generateUUID, showSuccessMsg } from '@/common/utils/commonUtils';
import { REG_EXP } from '@/common/utils';
import { debounce } from 'lodash-es';
import { UrlInfoForUi, useDashboardPopupEnvStore } from '@/common/stores/dashboard-popup';
import { store } from '@/common/store';
import { DashboardData } from '@/dashboard/types/dashboard';
import { checkRole } from '@/common/auth/auth.utils';

export interface Props {
  envKey: string;
  isAlert?: boolean;
  isShow: boolean;
}
export interface Emit {
  (e: 'close'): void;
  (e: 'update:isShow', value: boolean): void;
}

const useWindowHeight = () => {
  const DEFAULT_HEIGHT = {
    WINDOW: 480,
    ROW: 28,
    MAX: 200,
  };
  const windowHeight = ref(`${DEFAULT_HEIGHT.WINDOW}px`);

  const changeWindowHeight = (itemCount: number = 0) => {
    if (!itemCount) {
      windowHeight.value = `${DEFAULT_HEIGHT.WINDOW}px`;
      return;
    }

    const rowsHeight = itemCount * DEFAULT_HEIGHT.ROW + 8;
    const totalHeight = DEFAULT_HEIGHT.WINDOW + Math.min(rowsHeight, DEFAULT_HEIGHT.MAX) + 8;
    windowHeight.value = `${totalHeight}px`;
  };
  return {
    windowHeight,
    changeWindowHeight,
  };
};

const setup = (props: Props, emit: Emit) => {
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const isBottomFilterExpand = ref<boolean>(true);
  const searchValue = ref<string>('');
  const dashboardListViewStore = useDashboardListViewStore();
  const { dashboardList } = storeToRefs(dashboardListViewStore);
  const checkedList = ref<number[]>([]);
  const searchFilterList = ref<DashboardData[]>([]);
  const { fetchDashboardList } = dashboardListViewStore;
  const isLoading = ref<boolean>(false);
  const { fetchDashboardPopupEnvInfoForKey, saveEnvInfoForKey, initSetupDashboardData } =
    useDashboardPopupEnvStore();
  const { setupDashBoardData, urlInfoList } = storeToRefs(useDashboardPopupEnvStore());

  const { windowHeight, changeWindowHeight } = useWindowHeight();

  const getDefaultUrlField = (): UrlInfoForUi => ({
    checked: false,
    url: '',
    urlName: '',
    isNewWindow: false,
  });
  const isMaster = computed(() => {
    const myRole = store.getters?.['myInfo/getAccountInfo'].role;
    return checkRole.isMaster(myRole);
  });

  const nameErrorMsg = ref({ name: '' });

  const isPopupShow = computed<boolean>({
    get: () => props.isShow,
    set: (isShow) => emit('update:isShow', isShow),
  });

  const fetchDashboardPopup = async () => {
    initSetupDashboardData();
    await fetchDashboardList();
    await fetchDashboardPopupEnvInfoForKey(props.envKey);

    checkedList.value = setupDashBoardData.value.dashboardList
      .filter((i) => i.dashboardId)
      .map((v: any) => v.dashboardId);
  };

  const onClosePopup = async () => {
    isPopupShow.value = false;
    emit('close');
    await fetchDashboardPopup();
  };

  const onClickSave = async () => {
    setupDashBoardData.value.dashboardList = dashboardList.value
      .filter(({ dashboardId }) => checkedList.value.includes(dashboardId))
      .map(({ dashboardId, dashboardName }) => {
        return {
          dashboardId,
          dashboardName,
        };
      });

    setupDashBoardData.value.urlList = urlInfoList.value.map((v, idx) => {
      return {
        checked: v.checked,
        url: v.url,
        urlName: v.urlName,
        index: idx,
        isGlobal: v.isGlobal ? v.isGlobal : isMaster.value,
        id: v.id ? v.id : generateUUID(),
        isNewWindow: v.isNewWindow ?? false,
      };
    });

    const setupDashBoard = JSON.stringify(setupDashBoardData.value);
    await saveEnvInfoForKey(props.envKey, setupDashBoard);
    showSuccessMsg(ctx, t('MESSAGE.SAVED'));
    isPopupShow.value = false;
    emit('close');
  };

  const isValid = ref(false);

  const validateName = (value: FocusEvent) => {
    // TODO: 문구 변경
    if (!checkValid(REG_EXP.BUTTON_NAME, value)) {
      nameErrorMsg.value.name = t('NOTI.VALIDATION.NOT_ENGLISH');
    } else {
      nameErrorMsg.value.name = '';
    }
  };
  const validateUrl = (value, idx) => {
    if (value.trim() === '') {
      urlInfoList.value[idx].urlErrorMsg = t('MESSAGE.REQUIRE_DATA');
    } else {
      urlInfoList.value[idx].urlErrorMsg = '';
    }
  };
  const validateUrlName = (value, idx) => {
    if (value.trim() === '') {
      urlInfoList.value[idx].nameErrorMsg = t('MESSAGE.REQUIRE_DATA');
    } else {
      urlInfoList.value[idx].nameErrorMsg = '';
    }
  };

  const onClickAddBtn = () => {
    urlInfoList.value.push(getDefaultUrlField());
    isValid.value = false;
  };

  const onClickDeleteBtn = (idx: number) => {
    urlInfoList.value.splice(idx, 1);
  };

  watch(
    searchValue,
    debounce(() => {
      searchFilterList.value = dashboardList.value.filter(
        (v) => v.dashboardName?.toLowerCase().includes(searchValue.value.toLowerCase()),
      );
    }, 300),
  );

  watch(
    [urlInfoList, nameErrorMsg],
    ([urlList, error]) => {
      const hasError = error.name !== '';
      const hasUrlError = urlList.some((v) => v.nameErrorMsg || v.urlErrorMsg);
      const isEmpty = urlList.some((item) => item.url.trim() === '' || item.urlName.trim() === '');

      isValid.value = !(hasUrlError || hasError || isEmpty);

      changeWindowHeight(urlInfoList.value?.length);
    },
    { deep: true },
  );

  onMounted(async () => {
    if (!props.envKey) return;

    isLoading.value = true;

    await fetchDashboardPopup();

    isLoading.value = false;
    searchFilterList.value = dashboardList.value;
  });

  return {
    isPopupShow,
    urlInfoList,
    setupDashBoardData,
    onClickSave,
    isValid,
    searchFilterList,
    onClosePopup,

    isBottomFilterExpand,
    searchValue,
    nameErrorMsg,
    validateName,
    validateUrl,
    validateUrlName,
    checkedList,
    isLoading,
    t,
    onClickAddBtn,
    onClickDeleteBtn,
    isMaster,

    windowHeight,
  };
};

export { setup };
