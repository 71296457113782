import { Seconds } from '@/common/utils';
import {
  sessionUpdateV7ControllerAxios,
  sessionValidateV7ControllerAxios,
} from '@/openapi/gateway/api/gateway-controller-api';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { AppConfig, computed, getCurrentInstance, ref } from 'vue';
import { useInternational } from '../locale';
import router from '../router';
import { confirmMsg } from '../utils/commonUtils';

const useSessionTimerStore = defineStore('sessionTimer', () => {
  const ctx: AppConfig['globalProperties'] =
    getCurrentInstance()!.appContext.config.globalProperties;
  const { t } = useInternational();
  const isOpenModal = ref<boolean>(false);

  const isTimerRunning = ref<boolean>(false);
  const remainingSessionTime = ref<Seconds>(-1);
  const displaySessionTime = computed<string>(() => {
    if (remainingSessionTime.value < 0) {
      return '00:00';
    }
    return dayjs.duration(remainingSessionTime.value, 'seconds').format('mm:ss');
  });
  const isExpiredSessionTime = computed<boolean>(() => remainingSessionTime.value <= 0);

  const fetchSessionTime = async (sessionToken: string) => {
    try {
      if (sessionToken) {
        const { data } = await sessionValidateV7ControllerAxios({ request: { sessionToken } });
        const { remain } = data;
        remainingSessionTime.value = remain;
      }
    } catch (e: any) {
      if (e.response.data.errorCode === 'GE003') {
        if (!isOpenModal.value) {
          confirmMsg(ctx, {
            msgStr: t('MESSAGE.RESTRICT_DUPLICATE_USERS'),
            showCancelBtn: false,
            useHTML: true,
            okCallback: async () => {
              await router.push('/logout');
              isOpenModal.value = false;
            },
          });

          isOpenModal.value = true;
          remainingSessionTime.value = -1;
        }
      } else console.log(e);
    }
  };

  const updateSessionTime = async (params) => {
    try {
      if (Object.values(params).every((val) => !!val)) {
        await sessionUpdateV7ControllerAxios({ request: params });
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  return {
    isTimerRunning,
    isExpiredSessionTime,
    remainingSessionTime,
    displaySessionTime,
    fetchSessionTime,
    updateSessionTime,
    isOpenModal,
  };
});

export { useSessionTimerStore };
