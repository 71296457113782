/**
 * Check color string and return what type it is. ('HEX', 'RGB', 'RGBA' or 'NONE')
 * @param colorStr
 * @returns {string} color type
 */
const getColorStringType = (colorStr: string): string => {
  const noneWhiteSpaceColorStr = colorStr.replace(/ /g, '');
  const isHEX = /^#(?:[A-Fa-f0-9]{3}){1,2}$/.exec(noneWhiteSpaceColorStr);
  const isRGB =
    /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/.exec(
      noneWhiteSpaceColorStr,
    );
  const isRGBA =
    /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*,){3}\s*0*(?:\.\d+|1?)\s*[)]$/.exec(
      noneWhiteSpaceColorStr,
    );

  switch (true) {
    case !!isHEX:
      return 'HEX';
    case !!isRGB:
      return 'RGB';
    case !!isRGBA:
      return 'RGBA';
    default:
      return 'NONE';
  }
};

/**
 * get opacity value on rgba color string
 * ex) input  : rgba(255, 255, 255, 0.1)
 *     return : 0.1
 * @param rgbaColorString
 * @returns {string} opacity
 */
const getOpacity = (rgbaColorString: string): string => {
  const noneWhiteSpaceColorStr = rgbaColorString.replace(/ /g, '');
  const colorType = getColorStringType(noneWhiteSpaceColorStr);

  if (colorType === 'RGBA') {
    return noneWhiteSpaceColorStr.replace(/^.*,(.+)\)/, '$1');
  }

  return '1';
};

const hexToRGBNumber = (hex: string): number[] | null => {
  if (!hex) {
    return null;
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return null;
  }

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return [r, g, b];
};

const hexToRgb = (hex: string): string => {
  const result = hexToRGBNumber(hex);

  if (!result) {
    return hex;
  }

  const [r, g, b] = result;
  return `${r},${g},${b}`;
};

/**
 * Transforming color string to rgba code
 * Return BLACK ('rgba(0, 0, 0, ${opacity})') if fail transforming
 * @param colorStr        hex color code, rgb, rgba .. etc
 * @param opacity            color opacity. (default 1)translate
 * @returns {string} transformed rgba
 */
const colorStringToRgba = (colorStr: string, opacity = 1): string => {
  const noneWhiteSpaceColorStr = colorStr.replace(/ /g, '');
  const colorType = getColorStringType(noneWhiteSpaceColorStr);

  switch (colorType) {
    case 'HEX':
      return `rgba(${hexToRgb(noneWhiteSpaceColorStr)},${opacity})`;
    case 'RGB':
      return noneWhiteSpaceColorStr.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
    case 'RGBA':
      return noneWhiteSpaceColorStr.replace(`${getOpacity(colorStr)})`, `${opacity})`);
    default:
      return `rgba(0, 0, 0, ${opacity})`;
  }
};

/**
 * Transforming Color number into hex value (XX)
 * Pads 0 when the value is under 16 (F)
 * @param number        number value of a color code .. 0 ~ 255
 * @returns {string} transformed hex code
 */
const toHex = (c: number): string => {
  const hex = Math.floor(c).toString(16);
  return hex.padStart(2, '0');
};

const getRGBANumber = (colorStr: string, type?: string): number[] => {
  const colorType = type || getColorStringType(colorStr);

  switch (colorType) {
    case 'RGB': {
      const rgbMatch = /^rgb\((\d+),(\d+),(\d+)\)$/.exec(colorStr);
      if (rgbMatch) {
        const r = parseInt(rgbMatch[1], 10);
        const g = parseInt(rgbMatch[2], 10);
        const b = parseInt(rgbMatch[3], 10);
        return [r, g, b];
      }
      break;
    }
    case 'RGBA': {
      const rgbaMatch = /^rgba\((\d+),(\d+),(\d+),([\d.]+)\)$/.exec(colorStr);
      if (rgbaMatch) {
        const r = parseInt(rgbaMatch[1], 10);
        const g = parseInt(rgbaMatch[2], 10);
        const b = parseInt(rgbaMatch[3], 10);
        const alpha = parseFloat(rgbaMatch[4]);
        return [r, g, b, alpha];
      }
      break;
    }
    case 'HEX': {
      const rgbNumber = hexToRGBNumber(colorStr);
      if (rgbNumber) {
        return rgbNumber;
      }
      break;
    }
    default:
      return [0, 0, 0];
  }
  return [0, 0, 0];
};

const colorStringToHex = (colorStr: string, applyOpacity: boolean = false): string => {
  const colorType = getColorStringType(colorStr);
  if (colorType === 'HEX') {
    return colorStr;
  }

  const rgbNumber = getRGBANumber(colorStr, colorType);
  if (!rgbNumber) {
    return colorStr;
  }

  const [r, g, b, alpha] = rgbNumber;
  if (applyOpacity && alpha !== undefined) {
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${alpha < 1 ? toHex(alpha * 255) : ''}`.toUpperCase();
  }
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
};

/**
 * Merge two RGBA color if the opacity is set
 * uses alpha value to define the ratio of each color
 * @param baseColor        base color
 * @param altColor        another color which is to be merged into base color
 * @returns {string} Hex code of a merged color
 */
const mergeColorToHex = (baseColor?: string, altColor?: string): string | null => {
  if (!baseColor && !altColor) {
    return null;
  }
  if (!baseColor || !altColor) {
    return mergeColorToHex('#FFFFFF', baseColor || altColor);
  }

  const [baseR, baseG, baseB, baseA = 1] = getRGBANumber(baseColor);
  const [altR, altG, altB, altA = 1] = getRGBANumber(altColor);
  const mix = [0, 0, 0, 0];

  mix[3] = 1 - (1 - altA) * (1 - baseA); // alpha
  mix[0] = Math.round((altR * altA) / mix[3] + (baseR * baseA * (1 - altA)) / mix[3]);
  mix[1] = Math.round((altG * altA) / mix[3] + (baseG * baseA * (1 - altA)) / mix[3]);
  mix[2] = Math.round((altB * altA) / mix[3] + (baseB * baseA * (1 - altA)) / mix[3]);

  if (mix[3] < 1) {
    const WHITE = 255;
    const whiteA = 1 - mix[3];
    mix[0] = Math.round(WHITE * whiteA + mix[0] * mix[3] * (1 - whiteA));
    mix[1] = Math.round(WHITE * whiteA + mix[1] * mix[3] * (1 - whiteA));
    mix[2] = Math.round(WHITE * whiteA + mix[2] * mix[3] * (1 - whiteA));
  }

  return `#${toHex(mix[0])}${toHex(mix[1])}${toHex(mix[2])}`.toUpperCase();
};

export { getColorStringType, getOpacity, colorStringToRgba, colorStringToHex, mergeColorToHex };
