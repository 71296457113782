import { type Column, Workbook } from 'exceljs';
import { isNil } from 'lodash-es';
import type { CustomColumn } from '@/common/utils/types';
import { roundToDigitNumber } from '@/common/utils/commonUtils';
import { exportExcelFile, formatRowData } from './excel.utils';
import type { ExportExcelOption } from './excel.types';

const isTreeGridRow = (rows: any[]) => {
  if (rows.length === 0) {
    return false;
  }
  return !(rows[0] instanceof Array);
};

const isColumnHidden = (gridColumn: CustomColumn, option: ExportExcelOption) => {
  return (
    (gridColumn.hide || option.hiddenField?.includes(gridColumn.field)) &&
    !option.shownField?.includes(gridColumn.field)
  );
};

const formatGridColumnsToExcelColumns = (
  gridColumns: Readonly<CustomColumn[]>,
  option: ExportExcelOption,
) => {
  return gridColumns.reduce<Partial<Column>[]>((acc, cur) => {
    if (!isColumnHidden(cur, option)) {
      const optionWidth = option.fieldWidth?.[cur.field] ?? (cur.width ?? 400) / 6;
      acc.push({
        header: cur.caption,
        key: cur.field,
        width: optionWidth,
        style: {
          font: {
            size: 12,
          },
          alignment: {
            vertical: 'middle',
          },
        },
      });
    }

    return acc;
  }, []);
};

const formatGridDataToExcelData = (
  gridColumns: Readonly<CustomColumn[]>,
  gridRows: any[], // flatten already
  option: ExportExcelOption,
) => {
  if (gridRows.length === 0) {
    // Empty Array
    return [];
  }
  const { rowDataFormatter } = option;
  const isTreeGrid = isTreeGridRow(gridRows);
  if (isTreeGrid) {
    // BaseTreeGrid
    return gridRows.reduce<Record<string, string | number>[]>(
      (acc: Record<string, string | number>[], cur: Record<string, any>, curIndex: number) => {
        const row = {};
        gridColumns.forEach((column, index) => {
          const { field } = column;
          if (!field || isColumnHidden(column, option)) {
            return;
          }
          if (rowDataFormatter?.[field]) {
            row[field] = rowDataFormatter[field](cur[field], cur, curIndex);
          } else if (column.rendererType === 'ratio') {
            row[field] = isNil(column.decimal)
              ? roundToDigitNumber(cur[field])
              : roundToDigitNumber(cur[field], column.decimal);
          } else {
            row[field] = formatRowData(cur[field], gridColumns[index]);
          }
        });
        acc.push(row);
        return acc;
      },
      [],
    );
  }

  // BaseGrid
  const columnsMap = new Map(gridColumns.map((col) => [col.index, col]));

  return gridRows.reduce<Record<string, string>[]>((acc, cur, curIndex) => {
    const row = {};
    cur.forEach((rowData: any, index: number) => {
      const column = columnsMap.get(index);
      if (!column || isColumnHidden(column, option)) {
        return;
      }
      if (rowDataFormatter?.[column.field]) {
        row[column.field] = rowDataFormatter[column.field](rowData, cur, curIndex);
      } else if (column.rendererType === 'ratio') {
        row[column.field] = isNil(column.decimal)
          ? roundToDigitNumber(rowData)
          : roundToDigitNumber(rowData, column.decimal);
      } else {
        row[column.field] = formatRowData(rowData, column);
      }
    });
    acc.push(row);
    return acc;
  }, []);
};

const getColumns = (gridColumns: Readonly<CustomColumn[]>, isTreeGrid: boolean): CustomColumn[] => {
  return isTreeGrid
    ? [{ field: 'level', caption: '', width: 60 }, ...gridColumns]
    : [...gridColumns];
};

const getRows = (gridRows: any[], isTreeGrid: boolean): any[] => {
  if (!isTreeGrid) {
    return gridRows;
  }

  // 부모: ▼
  // 자식: └
  // 자식이 있는 형제 이후에 등장하는 자식: 종속관계를 표현하기 위해 ▼
  // DSP-18904 정현님 코멘트 참고
  const recurFlattenTreeGridData = (
    gridRow: any,
    currentLevel: number,
    isSiblingWithChildrenAppearedBefore: boolean, // 이전에 등장한 형제중 자식있는 형제 등장 여부
  ): [(Record<string, any> & { level: string })[], boolean] => {
    let isAfterSiblingWithChildren: boolean = false;
    const hasChildren = !!gridRow.children?.length;
    let symbol = hasChildren || isSiblingWithChildrenAppearedBefore ? '▼' : '└';
    if (currentLevel === 0 && !hasChildren) {
      symbol = '';
    }

    const flattenChildren = (gridRow.children ?? []).flatMap((row: any) => {
      const [newChild, newChildHasChildren] = recurFlattenTreeGridData(
        row,
        currentLevel + 1,
        isAfterSiblingWithChildren,
      );
      if (newChildHasChildren) {
        isAfterSiblingWithChildren = true;
      }

      return newChild;
    });

    return [
      [{ ...gridRow, level: '  '.repeat(currentLevel) + symbol }, ...flattenChildren],
      hasChildren,
    ];
  };

  return gridRows.flatMap((row) => recurFlattenTreeGridData(row, 0, false)[0]);
};

const createExcelFile = (
  gridColumns: Readonly<CustomColumn[]>,
  gridRows: any[],
  option: ExportExcelOption,
) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();

  const isTreeGrid = isTreeGridRow(gridRows);
  const columns = getColumns(gridColumns, isTreeGrid);
  const rows = getRows(gridRows, isTreeGrid);

  const data = formatGridDataToExcelData(columns, rows, option);
  worksheet.columns = formatGridColumnsToExcelColumns(columns, option);
  worksheet.insertRows(2, data);
  return workbook;
};

export const exportToExcelFromClient = async (
  gridColumns: Readonly<CustomColumn[]>,
  gridRows: any[],
  option: ExportExcelOption = {},
) => {
  const workbook = createExcelFile(gridColumns, gridRows, option);
  await exportExcelFile(workbook, option);
};
