export const DEFAULT_KEYWORD_BG_COLOR = '#0044CC';
export const DEFAULT_KEYWORD_LINE_COLOR = '#6699FF';

export const KEYWORD_SETTING_LOCATION = {
  LOGGING: 'logging',
  LIVE_TAIL: 'liveTail',
  BROWSER_ERROR_TRACKING: 'browserErrorTracking',
  USER_MONITORING: 'userMonitoring',
} as const;

export const KEYWORD_SETTING_TYPE = {
  ADDED: 'added',
  FILTERED: 'filtered',
} as const;

export const KEYWORD_COLOR_TYPE = {
  BG: 'bg',
  LINE: 'line',
} as const;

export const KEYWORD_TYPE = {
  CONFIG: 'config',
  USER: 'user',
} as const;
