<script setup lang="ts">
import AlertTargetList from '@/alert/components/alertTargetList/AlertTargetList.vue';
import AlertDetail from '@/alert/components/alertDetail/AlertDetail.vue';
import {
  Emit,
  Props,
  setup,
} from '@/common/components/organisms/alertListDetail/alertDetailList.setup';
import { getRuleNameIcon } from '@/alert/components/alertGrid/alertGrid.utils';
import SimpleTextTooltip from '@/common/components/molecules/simpleTextTooltip/SimpleTextTooltip.vue';
import { CHECK_BY_TARGET } from '@/alert/utils/define';

const props = withDefaults(defineProps<Props>(), {
  title: 'Alert List',
});
const emit = defineEmits<Emit>();

const {
  selectedId,
  selectedRuleProp,
  onClickItem,
  onClickClear,
  onClickCloseDetail,
  onDetail,
  t,

  criteriaTooltipMouseEvent,
  criteriaTooltipText,
  onCriteriaMouse,
} = setup(props, emit);
</script>

<template>
  <div class="alert-detail-list">
    <div class="alert-detail-list__header">
      <p>{{ props.title }}</p>
      <ev-button
        class="alert-detail-list__all-clear"
        size="xsmall"
        :disabled="props.list.length === 0"
        @click="onClickClear"
      >
        <ev-icon icon="ev-icon-redo" />
        {{ t('WORD.CLEAR_ALL') }}
      </ev-button>
    </div>
    <div class="alert-detail-list__body">
      <div
        v-if="props.list.length === 0"
        class="alert-count-item no-records"
      >
        <p>{{ t('MESSAGE.NO_DATA', 'No data') }}</p>
      </div>
      <ol v-else>
        <li
          v-for="item in props.list"
          :key="item.cardId"
          :class="['alert-detail-item', item.level, { selected: selectedId === item.cardId }]"
          @click.stop="() => onClickItem(item)"
        >
          <div class="alert-detail-item__row">
            <div class="alert-detail-item__time">
              <ev-icon
                :class="getRuleNameIcon({ ruleCriteria: item.ruleCriteria || CHECK_BY_TARGET })"
                @mouseenter="
                  (e) => onCriteriaMouse({ text: item.ruleCriteria || CHECK_BY_TARGET, e })
                "
                @mouseleave="(e) => onCriteriaMouse({ text: '', e })"
              />
              <span>{{ item.collectTime }}</span>
            </div>
            <span
              class="alert-detail-item__icon delete"
              @click.stop="() => onClickClear(item)"
            >
              <ev-icon icon="icon-outline-delete" />
            </span>
          </div>
          <div class="alert-detail-item__row">
            <span class="alert-detail-item__name">{{ item.name }}</span>
          </div>
          <div class="alert-detail-item__row target">
            <div class="alert-detail-item__label">{{ t('WORD.TARGET') }}</div>
            <div class="alert-detail-item__target-list">
              <alert-target-list
                v-if="item.targetList?.length"
                :target-list="item.targetList ?? []"
                is-clickable
              />
            </div>
          </div>
        </li>
      </ol>
    </div>
  </div>
  <div
    v-if="selectedId"
    class="alert-detail-tabs"
  >
    <div class="alert-detail-tabs__header">
      <ev-button
        type="icon"
        size="large"
        @click.stop="onClickCloseDetail"
      >
        <ev-icon icon="icon-right-panel" />
      </ev-button>
      <p>{{ t('WORD.ALERT') }}: {{ selectedRuleProp?.name ?? '' }}</p>
    </div>
    <div class="alert-detail-tabs__body">
      <alert-detail
        :rule-info="selectedRuleProp"
        @open-detail="onDetail"
        @clear="() => emit('reFetch')"
      />
    </div>
  </div>
  <simple-text-tooltip
    :mouse-event="criteriaTooltipMouseEvent"
    :text="criteriaTooltipText"
    base-pos="bottom"
  />
</template>

<style lang="scss">
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.alert-detail-list {
  height: 100%;
  padding: 8px 10px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding: 0 2px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
  }
  &__body {
    flex: 1;
    max-height: calc(100% - 40px);
    overflow-y: auto;
  }
}
.alert-detail-item {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  padding: 12px 10px;
  cursor: pointer;
  border: 1px solid var(--alert-detail-item-border);
  border-left: 8px solid var(--alert-detail-item-border);
  border-radius: 4px;
  background: var(--alert-detail-item-bg);
  animation: fade-in-down 1s;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.critical {
    border-left: 8px solid var(--alert-detail-item-critical-border);
    &:hover,
    &.selected {
      border: 1px solid var(--alert-detail-item-critical-border);
      border-left: 8px solid var(--alert-detail-item-critical-border);
      background: var(--alert-detail-item-critical-bg);
    }
  }
  &.warning {
    border-left: 8px solid var(--alert-detail-item-warning-border);
    &:hover,
    &.selected {
      border: 1px solid var(--alert-detail-item-warning-border);
      border-left: 8px solid var(--alert-detail-item-warning-border);
      background: var(--alert-detail-item-warning-bg);
    }
  }
  &.no-records {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    color: var(--alert-detail-item-no-records-color);
  }
  &__row {
    display: flex;
    &:first-child {
      padding-right: 30px;
    }
  }
  &__name {
    $time-width: 75px;

    display: inline-block;
    max-width: calc(100% - 100px);
    padding-right: 8px;
    margin-right: 8px;
    font-weight: 500;

    span {
      display: block;

      @include shortening();
    }
  }
  &__time {
    display: flex;
    align-items: center;
    color: var(--alert-detail-item-sub-color);
    i {
      margin: 0 2px 0 -2px;
      background-color: var(--alert-detail-item-rule-criteria-color);
    }
  }
  &__icon.delete {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  &__label {
    width: 40px;
    margin-right: 10px;
    color: var(--alert-detail-item-sub-color);
  }
  &__target-list {
    width: calc(100% - 50px);
    .tag-item {
      margin: 0 4px 0 0;
    }
  }
  &:hover {
    .alert-detail-item__icon.delete {
      display: block;
    }
  }
}
.alert-detail-tabs {
  width: calc(100% - 600px);
  min-width: 600px;
  height: 100%;
  padding: 8px 0 0;
  border-left: 1px solid var(--alert-detail-divider);
  &__header {
    display: flex;
    gap: 6px;
    align-items: center;
    height: 32px;
    padding: 0 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
  }
  &__body {
    position: relative;
    height: calc(100% - 40px);
    .ev-tabs {
      height: 100%;
    }
    .ev-tabs-body {
      padding: 16px 10px;
      background-color: var(--tabs-header-active-bg-color);
    }
  }
}
</style>
