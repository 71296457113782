import { i18n } from '@/common/locale';
import { FilterData } from '@/dashboard/types/filter';
import { isJsonType } from '@/dashboard/utils/dashboardUtils';
import { FilterUUID } from '@/common/utils';
import { PeriodTimeType } from '../../referenceSettingOption/variableListWindow/variable.types';

export const getInitFilterValue = <Filter extends FilterData>(
  filterData: Filter,
): Filter['value'] => {
  switch (filterData.filterType) {
    case 'toggle':
    case 'checkbox':
      return false;
    case 'text':
      return '';
    case 'number':
      return undefined;
    case 'selectbox':
    case 'selectbox2':
      return { optionItem: undefined, optionItems: [] };
    case 'calendar':
      return '';
    case 'period-calendar':
      return ['', ''];
    case 'target':
      return { optionItem: undefined, optionItems: [] };
    default:
      return undefined;
  }
};

export const setInitFilterValue = <Filter extends FilterData>(filterData: Filter): Filter => {
  return { ...filterData, value: getInitFilterValue(filterData) };
};

export const isFilterDataValueSetted = ({ filterType, value }: FilterData): boolean => {
  switch (filterType) {
    case 'toggle':
    case 'checkbox':
      return true;
    case 'text':
      return value !== '';
    case 'number':
      return value != null;
    case 'selectbox':
    case 'selectbox2':
      return value.optionItem != null && value.optionItems.length > 0;
    case 'calendar':
      return value !== '';
    case 'period-calendar':
      return value.at(0) !== '' && value.at(1) !== '';
    case 'target':
      return value.optionItem != null && value.optionItems.length > 0;
    default:
      throw new Error('Invalid filter type');
  }
};

type PeriodFilterKey = {
  filterUUID: FilterUUID;
  timeType: PeriodTimeType;
};

export const createPeriodFilterValue = (key: PeriodFilterKey): string => {
  return JSON.stringify({ filterUUID: key.filterUUID, timeType: key.timeType });
};

export const parsePeriodFilterKey = (key: string): PeriodFilterKey => {
  return isJsonType(key) ? JSON.parse(key) : { filterUUID: key };
};

export const createPeriodFilterName = (name: string, timeType: PeriodTimeType): string => {
  switch (timeType) {
    case 'fromTime':
      return `${name}(${i18n.global.t('WORD.START_TIME')})`;
    case 'toTime':
      return `${name}(${i18n.global.t('WORD.END_TIME')})`;
    default:
      return '';
  }
};
