const MAIN_FRAMES = {
  WAS_VIEW: 'WAS View',
  WAS_DETAIL: 'WAS Detail',
  ACTIVE_TRANSACTION_LIST: 'Active Transaction List',
  WAS_ALERT_DETAIL: 'Alert Detail - WAS',

  TRANSACTION_VIEW: 'Transaction View',
  TRANSACTION_DETAIL: 'Transaction Detail',
  TRANSACTION_ALERT_DETAIL: 'Alert Detail - Transaction',

  URL_MONITORING_VIEW: 'URL Monitoring View',
  URL_MONITORING_DETAIL: 'URL Monitoring Detail',
  URL_MONITORING_ALERT_DETAIL: 'Alert Detail - URL Monitoring',

  WEB_APPLICATION_VIEW: 'Web Application View',
  WEB_APPLICATION_DETAIL: 'Web Application Detail',
  WEB_APPLICATION_PAGE_ANALYSIS_DETAIL: 'Web Application Page Analysis Detail',
  WEB_APPLICATION_ALERT_DETAIL: 'Alert Detail - Web Application',
  AJAX_REQUEST_HISTORY_DETAIL: 'Ajax Request History Detail',

  PAGE_PERFORMANCE_MONITORING_VIEW: 'Page Performance Monitoring View',
  PAGE_PERFORMANCE_MONITORING_ALERT_DETAIL: 'Alert Detail - Page Performance Monitoring',

  RUM_USER_MONITORING_VIEW: 'RUM User Monitoring View',
  RUM_USER_MONITORING_DETAIL: 'RUM User Monitoring Detail',
  USER_MONITORING_ALERT_DETAIL: 'Alert Detail - User Monitoring',

  RUM_BROWSER_ERROR_TRACKING_VIEW: 'RUM Browser Error Tracking View',
  RUM_BROWSER_ERROR_TRACKING_DETAIL: 'RUM Browser Error Tracking Detail',
  RUM_BROWSER_ERROR_TRACKING_ALERT_DETAIL: 'Alert Detail - Browser Error Tracking',

  TRACES_VIEW: 'Traces View',
} as const;

export const APPLICATION_FRAME_NAMES = {
  WAS_VIEW: {
    FILTERS: `${MAIN_FRAMES.WAS_VIEW}/Filters`,
    WAS_LIST: `${MAIN_FRAMES.WAS_VIEW}/WAS List`,
    ALERT_COUNT: `${MAIN_FRAMES.WAS_VIEW}/Alert Count`,
  },
  WAS_DETAIL: {
    ACTIVES: `${MAIN_FRAMES.WAS_DETAIL}/Actives`,
    ACTIVES_DETAIL: `${MAIN_FRAMES.WAS_DETAIL}/Actives Detail`,
    ACTIVES_DETAIL_KILL_THREAD: `${MAIN_FRAMES.WAS_DETAIL}/Actives Detail - Kill Thread`,
    CONFIG: `${MAIN_FRAMES.WAS_DETAIL}/Config`,
    DATA_SOURCE: `${MAIN_FRAMES.WAS_DETAIL}/Data Source`,
    HEAP_DUMP: `${MAIN_FRAMES.WAS_DETAIL}/Heap Dump`,
    HEAD_DUMP_EXEC: `${MAIN_FRAMES.WAS_DETAIL}/[Exec] Heap Dump`,
    GARBAGE_COLLECTION: `${MAIN_FRAMES.WAS_DETAIL}/Garbage Collection`,
    GARBAGE_COLLECTION_JVM_GC_STAT: `${MAIN_FRAMES.WAS_DETAIL}/Garbage Collection - JVM/GC Stat`,
    METRIC: `${MAIN_FRAMES.WAS_DETAIL}/Metric`,
    INFORMATION: `${MAIN_FRAMES.WAS_DETAIL}/Information`,
    INFORMATION_WAS_GROUP: `${MAIN_FRAMES.WAS_DETAIL}/Information - WAS Group`,
    TRANSACTIONS: `${MAIN_FRAMES.WAS_DETAIL}/Transactions`,
    THREAD_DUMP: `${MAIN_FRAMES.WAS_DETAIL}/Thread Dump`,
    THREAD_DUMP_DETAIL: `${MAIN_FRAMES.WAS_DETAIL}/Thread Dump Detail`,
    LIBRARIES: `${MAIN_FRAMES.WAS_DETAIL}/Libraries`,
    LOADED_CLASS: `${MAIN_FRAMES.WAS_DETAIL}/Loaded Class`,
    ALERT: `${MAIN_FRAMES.WAS_DETAIL}/Alert`,
  },
  WAS_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.WAS_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.WAS_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.WAS_ALERT_DETAIL}/Clear System Alert`,
  },
  ACTIVE_TRANSACTION_LIST: {
    DETAIL: `${MAIN_FRAMES.ACTIVE_TRANSACTION_LIST}/Detail`,
  },

  TRANSACTION_VIEW: {
    FILTERS: `${MAIN_FRAMES.TRANSACTION_VIEW}/Filters`,
    CURRENT_STAT_TREND: `${MAIN_FRAMES.TRANSACTION_VIEW}/Current Stat Trend`,
    TOP_5_CARD: `${MAIN_FRAMES.TRANSACTION_VIEW}/Top 5 Card`,
    TRANSACTION_TREND: `${MAIN_FRAMES.TRANSACTION_VIEW}/Transaction Trend`,
    TRANSACTION_COUNT: `${MAIN_FRAMES.TRANSACTION_VIEW}/Transaction Count`,
    ALERT_COUNT: `${MAIN_FRAMES.TRANSACTION_VIEW}/Alert Count`,
  },
  TRANSACTION_DETAIL: {
    ETOE_TARGET: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Target`,
    ETOE_PATH: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Path,List`,
    ETOE_INFORMATION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Information`,
    ETOE_METRIC: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Metric`,
    ETOE_CALL_TREE_DETAIL: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Call Tree - Detail`,
    ETOE_CALL_TREE_SUMMARY: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Call Tree - Summary`,
    ETOE_SQL_TEXT: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/SQL Text`,
    ETOE_EXCEPTION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Exception`,
    ETOE_ACTIVE_HISTORY: `${MAIN_FRAMES.TRANSACTION_DETAIL} - EtoE/Active History`,
    SPAN_PATH: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Path`,
    SPAN_LIST: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/List`,
    SPAN_INFORMATION: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Information`,
    SPAN_TAGS: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Tags`,
    SPAN_LOGS: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Logs`,
    SPAN_ERROR: `${MAIN_FRAMES.TRANSACTION_DETAIL} - OpenTelemetry/Error`,
  },
  TRANSACTION_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.TRANSACTION_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.TRANSACTION_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.TRANSACTION_ALERT_DETAIL}/Clear System Alert`,
  },

  URL_MONITORING_VIEW: {
    FILTERS: `${MAIN_FRAMES.URL_MONITORING_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.URL_MONITORING_VIEW}/URL List`,
    ALERT_COUNT: `${MAIN_FRAMES.URL_MONITORING_VIEW}/Alert Count`,
  },
  URL_MONITORING_DETAIL: {
    DETAIL: `${MAIN_FRAMES.URL_MONITORING_DETAIL}/Detail`,
    LIST: `${MAIN_FRAMES.URL_MONITORING_DETAIL}/Response List`,
  },
  URL_MONITORING_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.URL_MONITORING_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.URL_MONITORING_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.URL_MONITORING_ALERT_DETAIL}/Clear System Alert`,
  },

  WEB_APPLICATION_VIEW: {
    FILTERS: `${MAIN_FRAMES.WEB_APPLICATION_VIEW}/Filters`,
    APPLICATION_LIST: `${MAIN_FRAMES.WEB_APPLICATION_VIEW}/Application List`,
    TOP_APPLICATIONS: `${MAIN_FRAMES.WEB_APPLICATION_VIEW}/Top Applications`,
    METRIC: `${MAIN_FRAMES.WEB_APPLICATION_VIEW}/Metric`,
  },
  WEB_APPLICATION_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.WEB_APPLICATION_DETAIL}/Information`,
    ANALYSIS: `${MAIN_FRAMES.WEB_APPLICATION_DETAIL}/Analysis`,
    SCATTER: `${MAIN_FRAMES.WEB_APPLICATION_DETAIL}/Scatter`,
    USER_META_INFO: `${MAIN_FRAMES.WEB_APPLICATION_DETAIL}/User Meta Info`,
    METRIC: `${MAIN_FRAMES.WEB_APPLICATION_DETAIL}/Metric`,
    EVENT: `${MAIN_FRAMES.WEB_APPLICATION_DETAIL}/Event`,
    ALERT: `${MAIN_FRAMES.WEB_APPLICATION_DETAIL}/Alert`,
  },
  WEB_APPLICATION_PAGE_ANALYSIS_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.WEB_APPLICATION_PAGE_ANALYSIS_DETAIL}/Information`,
    LOAD_TIMELINE: `${MAIN_FRAMES.WEB_APPLICATION_PAGE_ANALYSIS_DETAIL}/Load Timeline`,
    RESOURCE_TIMELINE: `${MAIN_FRAMES.WEB_APPLICATION_PAGE_ANALYSIS_DETAIL}/Resource Timeline`,
    AJAX_REQUEST_HISTORY: `${MAIN_FRAMES.WEB_APPLICATION_PAGE_ANALYSIS_DETAIL}/Ajax Request History`,
    METRIC: `${MAIN_FRAMES.WEB_APPLICATION_PAGE_ANALYSIS_DETAIL}/Metric`,
  },
  WEB_APPLICATION_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.WEB_APPLICATION_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.WEB_APPLICATION_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.WEB_APPLICATION_ALERT_DETAIL}/Clear System Alert`,
  },
  AJAX_REQUEST_HISTORY_DETAIL: {
    INFORMATION: `${MAIN_FRAMES.AJAX_REQUEST_HISTORY_DETAIL}/Information`,
    CALL_HISTORY: `${MAIN_FRAMES.AJAX_REQUEST_HISTORY_DETAIL}/Call History`,
  },

  PAGE_PERFORMANCE_MONITORING_VIEW: {
    FILTERS: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Filters`,
    PAGE_LOAD_TIME_SCATTER: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Page Load Time Scatter`,
    PAGE_LOAD_TIME_FILTER_META_INFO: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Page Load Time Filter Meta Info`,
    AJAX_PERFORMANCE_METRIC: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Ajax Performance Metric`,
    PAGE_CALLS_PER_SECOND: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Page Calls Per Second`,
    WEB_VITALS_CHART: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Web Vitals Chart`,
    ALERT_COUNT: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_VIEW}/Alert Count`,
  },
  PAGE_PERFORMANCE_MONITORING_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.PAGE_PERFORMANCE_MONITORING_ALERT_DETAIL}/Clear System Alert`,
  },
  RUM_USER_MONITORING_VIEW: {
    FILTERS: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/User Session Log List`,
    ACTIVE_SESSIONS: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/Active Sessions`,
    ACTIVE_SESSIONS_REGION_RANK: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/Active Sessions Region Rank`,
    SESSION_DURATION_RATIO: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/Session Duration Ratio`,
    KEYWORD: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/Log Keyword`,
    KEYWORD_SAVE: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/[Save] Log Keyword`,
    KEYWORD_DELETE: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/[Delete] Log Keyword`,
    ALERT_COUNT: `${MAIN_FRAMES.RUM_USER_MONITORING_VIEW}/Alert Count`,
  },
  RUM_USER_MONITORING_DETAIL: {
    USER_SESSION_INFO: `${MAIN_FRAMES.RUM_USER_MONITORING_DETAIL}/User Session Info`,
    USER_SESSION_FLOW: `${MAIN_FRAMES.RUM_USER_MONITORING_DETAIL}/User Session Flow`,
    USER_EVENT: `${MAIN_FRAMES.RUM_USER_MONITORING_DETAIL}/User Event`,
    USER_SESSION_REPLAY: `${MAIN_FRAMES.RUM_USER_MONITORING_DETAIL}/User Session Replay`,
  },
  USER_MONITORING_ALERT_DETAIL: {
    USER_SESSION_INFO: `${MAIN_FRAMES.USER_MONITORING_ALERT_DETAIL}/User Session Info`,
    USER_SESSION_FLOW: `${MAIN_FRAMES.USER_MONITORING_ALERT_DETAIL}/User Session Flow`,
    USER_EVENT: `${MAIN_FRAMES.USER_MONITORING_ALERT_DETAIL}/User Event`,
    USER_SESSION_REPLAY: `${MAIN_FRAMES.USER_MONITORING_ALERT_DETAIL}/User Session Replay`,
  },
  RUM_BROWSER_ERROR_TRACKING_VIEW: {
    FILTERS: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Filters`,
    LIST: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Browser Error Log List`,
    COUNT_CHART: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Browser Error Count Chart`,
    OCCURRENCE_CHART: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Browser Error Occurrence Chart`,
    KEYWORD: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Log Keyword`,
    KEYWORD_SAVE: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/[Save] Log Keyword`,
    KEYWORD_DELETE: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/[Delete] Log Keyword`,
    ALERT_COUNT: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_VIEW}/Alert Count`,
  },
  RUM_BROWSER_ERROR_TRACKING_DETAIL: {
    ANALYSIS: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_DETAIL}/Browser Error Analysis`,
  },
  BROWSER_ERROR_TRACKING_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.RUM_BROWSER_ERROR_TRACKING_ALERT_DETAIL}/Clear System Alert`,
  },
  TRACES_VIEW: {
    FILTERS: `${MAIN_FRAMES.TRACES_VIEW}/Filters`,
    TRACES_LIST: `${MAIN_FRAMES.TRACES_VIEW}/Traces List`,
  },
} as const;
