<script lang="ts" setup>
import AlertInformation from '@/alert/components/alertDetail/AlertInformation.vue';
import AlertCurrent from '@/alert/components/alertDetail/AlertCurrent.vue';
import AlertHistory from '@/alert/components/alertDetail/AlertHistory.vue';
import { Emit, Props, setup } from '@/alert/components/alertDetail/alertDetail.setup';

const props = defineProps<Props>();
const emit = defineEmits<Emit>();
const { isTabLoading, tabPanels, selectedTab, isLoadingRuleInfo } = setup(props, emit);
</script>

<template>
  <ev-loading :model-value="isTabLoading || isLoadingRuleInfo" />
  <ev-tabs
    v-model="selectedTab"
    :panels="tabPanels"
    :class="['alert-detail', selectedTab]"
  >
    <ev-tab-panel
      v-for="item in tabPanels"
      :key="item.value"
      :text="item.text"
      :value="item.value"
    >
      <alert-information
        v-if="item.value === 'information'"
        v-model:is-loading="isTabLoading"
        :is-show="selectedTab === 'information' && !isLoadingRuleInfo"
      />
      <alert-current
        v-if="item.value === 'current'"
        v-model:is-loading="isTabLoading"
        :is-show="selectedTab === 'current' && !isLoadingRuleInfo"
        @clear="() => emit('clear')"
      />
      <alert-history
        v-if="item.value === 'history' && !isLoadingRuleInfo"
        v-model:is-loading="isTabLoading"
        :is-show="selectedTab === 'history'"
      />
    </ev-tab-panel>
  </ev-tabs>
</template>

<style lang="scss">
.alert-detail {
  .ev-tabs-body {
    height: calc(100% - 31px);
  }
  .ev-tab-panel {
    height: 100%;
  }
  &.information > .ev-tabs-body {
    overflow-y: auto;
  }
  &.history > .ev-tabs-body {
    padding: 16px 0 0 !important;
  }
}
</style>
