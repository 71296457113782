import { defineStore } from 'pinia';
import { ref } from 'vue';
import { AxiosResponse } from 'axios';
import { utcZeroTimeToStandardTime } from '@/common/utils/commonUtils';
import dayjs from 'dayjs';
import { UTC_TIME_FORMAT_MILLISECOND } from '../components/molecules/timePeriodIndicator/timePeriodIndicator.define';

export interface ApiTraceInfo {
  isError: boolean;
  url: string;
  status: string; // `{statusCode} {statusText}`
  requestTime: string;
  elapsedTime: string;
  params: string;
  data: string;
  sql: string;
}

export interface ApiTraceData {
  path: string;
  apiTraceInfo: Record<string, ApiTraceInfo>;
  snapTime: string;
}

export interface TargetFrameInfo {
  type: '' | 'widget';
  id: string;
}

export const useApiTraceStore = defineStore('ApiTraceWindow', () => {
  const targetFrameInfo = ref<TargetFrameInfo>({ type: '', id: '' });
  const infoByFrameName = ref<Record<string, ApiTraceInfo>>({});
  let path = '';

  const init = () => {
    targetFrameInfo.value = {
      type: '',
      id: '',
    };
    infoByFrameName.value = {};
  };

  const isJsonParams = (methodType: string) => {
    const methodTypesUsingJsonParams = ['post', 'put', 'patch'];
    return methodTypesUsingJsonParams.includes(methodType.toLowerCase());
  };

  const convertTraceInfo = (apiResponse: AxiosResponse, isError = false): ApiTraceInfo => {
    const { config, data: rawData, status, statusText } = apiResponse;
    const { elapsedTime = -1, id = '', sqlText = '' } = rawData?.trace?.[0] ?? {};
    const requestTime = isError ? rawData?.timestamp ?? '' : rawData?.requestTime ?? '';
    const data = JSON.stringify(isError ? rawData : rawData?.data ?? rawData);
    const params = isJsonParams(config?.method ?? '')
      ? config?.data
      : JSON.stringify(config?.params);
    const methodType = (config?.method ?? '').toUpperCase();
    const sqlPrefix = id ? `/* ID\n ${id} */\n` : '';

    return {
      data,
      isError,
      params,
      requestTime: utcZeroTimeToStandardTime(requestTime),
      url: `${methodType} ${config?.url ?? ''}`,
      status: `${status} ${statusText}`,
      elapsedTime: elapsedTime === -1 ? '' : (elapsedTime / 1000).toFixed(3),
      sql: `${sqlPrefix}${sqlText}`,
    };
  };

  const setTraceInfo = ({
    frameName,
    apiResponse,
    isError = false,
  }: {
    frameName: string;
    apiResponse: AxiosResponse;
    isError?: boolean;
  }) => {
    if (!frameName) {
      return;
    }

    infoByFrameName.value[frameName] = convertTraceInfo(apiResponse, isError);
  };

  const changePath = (newPath: string) => {
    if (path === newPath) {
      return;
    }

    path = newPath;
    infoByFrameName.value = {};
    targetFrameInfo.value = {
      id: '',
      type: '',
    };
  };

  const getApiTraceInfo = (): ApiTraceData => ({
    path,
    apiTraceInfo: { ...infoByFrameName.value },
    snapTime: dayjs().format(UTC_TIME_FORMAT_MILLISECOND),
  });

  const setTargetFrameInfo = (info: TargetFrameInfo) => {
    targetFrameInfo.value = info;
  };

  return {
    targetFrameInfo,
    setTraceInfo,
    init,
    changePath,
    getApiTraceInfo,
    setTargetFrameInfo,
  };
});
