import { i18n } from '@/common/locale';
import {
  PaginationOption,
  TokenFilterOption,
  TextFilterOption,
  GridSortingOption,
  GridTreeViewOption,
  GridRowSelectionOption,
  GridStyleOption,
  GridDetailRowOption,
  GridExpandingOption,
  GridColumnResizingOption,
  GridColumnOrderingOption,
  GridColumnPinningOption,
  GridColumnVisibilityOption,
  GridSettingOption,
  GridRowMenuOption,
  GridColumnAutoFitOption,
  GridGroupingOption,
} from './modernGrid.types';

export const SCROLL_BAR_WIDTH = 6;
export const INITIAL_PAGE_INDEX = 0;
export const INITIAL_PAGE_SIZE = 20;
export const DEFAULT_EXTRA_COLUMN_SIZE = 40;
export const DEFAULT_COLUMN_SIZE = 120;
export const DEFAULT_COLUMN_MIN_SIZE = 40;
export const TREE_INDENT_SIZE = 13;
export const COLUMN_RESIZING_HANDLE_BAR_SIZE = 4;
export const CHECKBOX_COLUMN_ID = 'checkbox';
export const EXPAND_BTN_COLUMN_ID = 'expandBtn';
export const NUMBER_COLUMN_ID = 'number';

export const defaultPagination: PaginationOption = {
  use: false,
  useClient: false,
  align: 'right',
  showSetting: false,
  showPageInfo: false,
  perData: 20,
  items: [20, 30, 40, 50],
  total: 0,
};

export const defaultTokenFilter: TokenFilterOption = {
  use: false,
  useClient: false,
  mode: 'full',
  storageKey: '',
  filterItems: [],
};

export const defaultTextFilter: TextFilterOption = {
  use: false,
  useClient: false,
  mode: 'full',
  placeholder: '',
  maxLength: 50,
};

export const defaultSortingOption: GridSortingOption = {
  enable: true,
  multi: true,
  columnMenu: {
    ascending: {
      use: true,
      title: i18n.global.t('WORD.ASCENDING'),
      icon: 'ev-icon-allow2-up',
    },
    descending: {
      use: true,
      title: i18n.global.t('WORD.DESCENDING'),
      icon: 'ev-icon-allow2-down',
    },
  },
};

export const defaultGroupingOption: GridGroupingOption = {
  use: false,
  groupByColumns: [],
  groupedColumnMode: false,
};

export const defaultExpandingOption: GridExpandingOption = {
  useHeaderExpanding: false,
  expandAllOnLoad: true,
  expandedIcon: 'icon-fill-arrow-down',
  collapsedIcon: 'icon-fill-arrow-right',
  filterFromLeafRows: true,
  paginateExpandedRows: false,
};

export const defaultTreeViewOption: GridTreeViewOption = {
  use: false,
  subRowKey: 'children',
};

export const defaultDetailRowOption: GridDetailRowOption = {
  use: false,
  detailRowKey: 'detail',
  detailRowHeight: 100,
};

export const defaultRowSelectionOption: GridRowSelectionOption = {
  enableSelect: true,
  useCheckbox: false,
  useCheckAll: true,
  useMultiCheck: true,
  enableSubRowCheck: true,
};

export const defaultColumnResizingOption: GridColumnResizingOption = {
  use: true,
  columnResizeMode: 'onEnd',
  columnMinSize: DEFAULT_COLUMN_MIN_SIZE,
  columnMaxSize: undefined,
  enableReset: true,
};

export const defaultColumnAutoFitOption: GridColumnAutoFitOption = {
  use: false,
  columnMinSize: DEFAULT_COLUMN_MIN_SIZE,
};

export const defaultColumnOrderingOption: GridColumnOrderingOption = {
  use: true,
  useHandleBtn: false,
  handleIcon: 'ev-icon-hamburger2',
};

export const defaultColumnPinningOption: GridColumnPinningOption = {
  leftPinningKeys: [],
  rightPinningKeys: [],
  useLeftSticky: false,
  useRightSticky: false,
};

export const defaultColumnVisibilityOption: GridColumnVisibilityOption = {
  columnMenu: {
    hidden: {
      use: true,
      title: i18n.global.t('WORD.HIDE'),
      icon: 'icon-visibility-off',
    },
  },
};

export const defaultGridSettingOption: GridSettingOption = {
  use: true,
  useExportExcel: {
    use: true,
    title: i18n.global.t('WORD.EXPORT_EXCEL'),
    icon: '',
  },
  useColumnVisibility: {
    use: true,
    title: i18n.global.t('WORD.COLUMN_LIST'),
    icon: '',
  },
};

export const defaultRowMenuOption: GridRowMenuOption = {
  use: false,
  menuItems: [],
};

export const defaultStyleOption: GridStyleOption = {
  width: '100%',
  height: '100%',
  showBorder: true,
  header: {
    show: true,
    useSticky: true,
    height: 40,
    showColumnLine: true,
    showRowLine: true,
    textSize: '13px',
    textWeight: '700',
  },
  body: {
    rowHeight: 30,
    useStripe: false,
    showColumnLine: true,
    showRowLine: true,
    textSize: '13px',
    textWeight: '400',
  },
};

export const defaultUseNumberColumn = false;

export const defaultShownChipCount = 1;

export const defaultTooltipOption = 'list' as const;
