import { computed, onMounted, onUnmounted, type Ref, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useBaseMenuStore } from '@/common/stores/base-menu';
import { useSlideDetailStore } from '@/common/stores/slide-detail';
import { useDraggableWindowIndexStore } from '@/common/stores/draggable-window';
import type { HeaderAction, HeaderActionInfo, SlideOutWindowOption } from './slideOutWindow.types';
import { DEFAULT_OPTION, EXCLUDE_SELECTORS, SLIDE_OUT_WIDTH } from './slideOutWindow.define';

export interface Props {
  isVisible: boolean;
  isLoading?: boolean;
  isPopup?: boolean; // 팝업 여부
  isHeightFixed?: boolean; // 높이 고정 여부
  title?: string;
  options?: SlideOutWindowOption;
}

export interface Emit {
  (e: 'update:isVisible', value: boolean): void;
  (e: 'update:isHeightFixed', value: boolean): void;
  (e: 'open-popup');
  (e: 'close-slide');
  (e: 'mouseover-slide');
  (e: 'mouseenter-slide');
  (e: 'mouseleave-slide');
}

const useSlideDetailOutsideClick = (
  isDetailVisible: Ref<boolean>,
  slideOutRef: Ref<HTMLElement | null>,
  closeDetail: () => void,
  excludeSelectors: {
    always: string[];
    withCursor: string[];
  },
) => {
  const handleOutsideClick = (event: MouseEvent) => {
    const clickedElement = event.target as HTMLElement;

    if (isDetailVisible.value && slideOutRef.value && !slideOutRef.value.contains(clickedElement)) {
      // 1차: 무조건 제외할 영역 체크
      const isAlwaysExcluded = excludeSelectors.always.some(
        (selector) => clickedElement.closest(selector) !== null,
      );
      if (isAlwaysExcluded) {
        return;
      }

      // 2차: 커서 조건과 함께 체크할 영역 확인
      const isConditionallyExcluded = excludeSelectors.withCursor.some(
        (selector) => clickedElement.closest(selector) !== null,
      );
      if (isConditionallyExcluded) {
        const cursorStyle = window.getComputedStyle(clickedElement).cursor;
        if (cursorStyle === 'pointer') {
          return;
        }
      }

      closeDetail();
    }
  };

  onMounted(() => {
    document.addEventListener('mousedown', handleOutsideClick);
  });

  onUnmounted(() => {
    document.removeEventListener('mousedown', handleOutsideClick);
  });
};

export const setup = (props: Props, emit: Emit) => {
  const SLIDE_OUT_DEFAULT_Z_INDEX = 353; // $on-slide-out-window

  const { mainPanelInfo, subPanelInfo } = storeToRefs(useBaseMenuStore());
  const slideDetailStore = useSlideDetailStore();
  const { setOpened, setIsExpand } = slideDetailStore;
  const { isExpand } = storeToRefs(slideDetailStore);
  const { setDraggableWindowZIndexByIterate, resetDraggableWindowZIndexByIterate } =
    useDraggableWindowIndexStore();

  const slideOutRef = ref<HTMLElement | null>(null);

  const isVisible = computed<boolean>({
    get: () => props.isVisible as boolean,
    set: (val) => emit('update:isVisible', val),
  });
  const options = computed<Props['options']>(() => ({ ...DEFAULT_OPTION, ...props.options }));
  const slideStyleObj = computed(() => {
    const { top, width, sizeType, translateX, minWidth, minHeight, bodyYScroll } = options.value!;
    const headerHeight = 'var(--gnb-header-height)';
    const slideWidth = width || (sizeType ? SLIDE_OUT_WIDTH[sizeType] : SLIDE_OUT_WIDTH.MEDIUM);

    let height = 'auto';
    let bodyHeight = 'auto';
    let bodyMaxHeight = `calc(${Math.floor(
      window.innerHeight * 0.95,
    )}px - ${top} - ${headerHeight})`;

    if (props.isHeightFixed) {
      height = top ? `calc(100% - ${top})` : '100%';
      bodyHeight = `calc(100% - ${headerHeight})`;
      bodyMaxHeight = 'initial';
    }

    return {
      top,
      width: slideWidth,
      height,
      minWidth,
      minHeight,
      bodyMaxHeight,
      bodyHeight,
      bodyYScroll,
      headerHeight,
      translateX,
    };
  });

  const headerActions = computed<HeaderActionInfo[]>(
    () =>
      (options.value?.headerActions ?? []).map((item) => {
        const icon = item === 'open' ? 'ev-icon-external-link3' : 'ev-icon-close3';
        return {
          type: item,
          icon,
        };
      }) ?? [],
  );

  const gnbCondition = computed<string>(() => {
    if (subPanelInfo.value.openedSubPanelMenuInfo && mainPanelInfo.value.isMainPanelExpanded)
      return 'expanded-and-opened';
    if (subPanelInfo.value.openedSubPanelMenuInfo) return 'opened';
    if (mainPanelInfo.value.isMainPanelExpanded) return 'expanded';
    return '';
  });

  const closeSlide = () => {
    const isFocusedFilterSearch = !!document.querySelector(
      '.slide-detail .filter-search__filter-main.focus.expand',
    );
    if (isFocusedFilterSearch) {
      return;
    }

    isVisible.value = false;
    setIsExpand(false);
    emit('close-slide');
    setOpened(false);
  };

  useSlideDetailOutsideClick(isVisible, slideOutRef, closeSlide, EXCLUDE_SELECTORS);

  const onClickExpand = () => {
    setIsExpand(true);
  };

  const onClickCollapse = () => {
    if (isExpand.value) {
      setIsExpand(false);
    } else {
      closeSlide();
    }
  };

  const onClickSlideWindow = () => {
    // draggable window z-index setting

    if (!isVisible.value) {
      return;
    }

    setDraggableWindowZIndexByIterate(SLIDE_OUT_DEFAULT_Z_INDEX - 2);
  };

  const onClickAction = (type: HeaderAction) => {
    switch (type) {
      case 'close':
        closeSlide();
        break;
      case 'open':
        emit('open-popup');
        break;
      default:
        break;
    }
  };
  const emitMouseHover = (type: 'enter' | 'over' | 'leave') => {
    switch (type) {
      case 'enter':
        emit('mouseenter-slide');
        break;
      case 'over':
        emit('mouseover-slide');
        break;
      case 'leave':
        emit('mouseleave-slide');
        break;
      default:
        break;
    }
  };

  const escapeCloseSlide = (e: KeyboardEvent) => {
    if (
      e.key === 'Escape' &&
      isVisible.value &&
      !document.getElementById('ev-window-modal')?.children.length &&
      !document.getElementsByClassName('fullscreen-wrapper').length
    ) {
      closeSlide();
    }
  };

  onMounted(() => {
    document.addEventListener('keydown', escapeCloseSlide);
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', escapeCloseSlide);
  });

  watch(isVisible, (visible) => {
    if (visible) {
      setDraggableWindowZIndexByIterate(SLIDE_OUT_DEFAULT_Z_INDEX - 2);
    } else {
      resetDraggableWindowZIndexByIterate();
    }
  });

  return {
    isVisible,
    isExpand,
    gnbCondition,

    slideStyleObj,
    headerActions,
    options,

    slideOutRef,

    onClickAction,
    emitMouseHover,
    onClickExpand,
    onClickCollapse,
    onClickSlideWindow,
  };
};
