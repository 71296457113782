/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  AwsAlarmResponse,
  AwsElastiCacheNodesResponse,
  AwsInformationResponse,
  AwsMetricStatusResponse,
  AwsNodesResponse,
  AwsShardsAndNodesResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const setAwsAlarmAwsDetailV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AwsAlarmResponse> => {
  const localVarPath = '/api/v8/cloud/aws/detail/{instanceId}/aws-alarms'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getNodesAwsDetailV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AwsNodesResponse> => {
  const localVarPath = '/api/v8/cloud/aws/detail/elasticache/{instanceId}/nodes'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getShardsAndNodesAwsDetailV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AwsShardsAndNodesResponse> => {
  const localVarPath = '/api/v8/cloud/aws/detail/elasticache/{instanceId}/shards-nodes'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getInformationAwsDetailV8ControllerAxios = ({
  instanceId,
  serviceType,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  serviceType:
    | 'AURORA'
    | 'COST_EXPLORER'
    | 'DMS_REPLINSTANCE'
    | 'DMS_TASK'
    | 'DOCUMENTDB'
    | 'DYNAMODB'
    | 'EC2'
    | 'ELASTICACHE_CLUSTER'
    | 'ELASTICACHE_NODE'
    | 'ELASTICACHE_SERVERLESS'
    | 'ELASTICACHE_SHARD'
    | 'LAMBDA'
    | 'LIGHTSAIL_DATABASE'
    | 'LIGHTSAIL_INSTANCE'
    | 'RDS'
    | 'REDSHIFT'
    | 'S3'
    | 'VPC';
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AwsInformationResponse> => {
  const localVarPath = '/api/v8/cloud/aws/detail/information';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      instanceId,
      serviceType,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getMetricCollectorStatusAwsDetailV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AwsMetricStatusResponse> => {
  const localVarPath = '/api/v8/cloud/aws/detail/metric-collector/status';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      instanceId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getElasticacheNodesAwsDetailV8ControllerAxios = ({
  instanceId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  instanceId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AwsElastiCacheNodesResponse> => {
  const localVarPath = '/api/v8/cloud/aws/detail/metric-elasticache/{instanceId}/nodes'.replace(
    `{${'instanceId'}}`,
    encodeURIComponent(String(instanceId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  setAwsAlarmAwsDetailV8ControllerAxios,
  getNodesAwsDetailV8ControllerAxios,
  getShardsAndNodesAwsDetailV8ControllerAxios,
  getInformationAwsDetailV8ControllerAxios,
  getMetricCollectorStatusAwsDetailV8ControllerAxios,
  getElasticacheNodesAwsDetailV8ControllerAxios,
};
