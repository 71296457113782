const MAIN_FRAMES = {
  CLOUD_AWS_EC2_VIEW: 'AWS EC2 View',
  CLOUD_AWS_LAMBDA_VIEW: 'AWS Lambda View',
  CLOUD_AWS_S3_VIEW: 'AWS S3 View',
  CLOUD_AWS_DOCUMENTDB_VIEW: 'AWS DocumentDB View',
  CLOUD_AWS_DYNAMODB_VIEW: 'AWS DynamoDB View',
  CLOUD_AWS_ELASTICACHE_VIEW: 'AWS Elasticache View',
  CLOUD_AWS_REDSHIFT_VIEW: 'AWS Redshift View',
  CLOUD_AWS_DMS_VIEW: 'AWS Database Migration Service View',
  AWS_COST_EXPLORER: 'AWS - Cost Explorer',
  CLOUD_AWS_DETAIL: 'AWS Detail',
  CLOUD_AWS_DOCUMENTDB_ALERT_DETAIL: 'Alert Detail - AWS DocumentDB',
  CLOUD_AWS_DYNAMODB_ALERT_DETAIL: 'Alert Detail - AWS DynamoDB',
  CLOUD_AWS_ELASTICACHE_ALERT_DETAIL: 'Alert Detail - AWS ElastiCache',
  CLOUD_AWS_REDSHIFT_ALERT_DETAIL: 'Alert Detail - AWS Redshift',
  CLOUD_AWS_DMS_ALERT_DETAIL: 'Alert Detail - AWS Database Migration Service',
  CLOUD_AWS_EC2_ALERT_DETAIL: 'Alert Detail - AWS EC2',
  CLOUD_AWS_LAMBDA_ALERT_DETAIL: 'Alert Detail - AWS Lambda',
  CLOUD_AWS_S3_ALERT_DETAIL: 'Alert Detail - AWS S3',
} as const;

export const CLOUD_FRAME_NAMES = {
  CLOUD: {
    AWS_EC2: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_EC2_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_EC2_VIEW}/Recent AWS Alarm List`,
      AWS_INSTANCE_CHART: `${MAIN_FRAMES.CLOUD_AWS_EC2_VIEW}/Instance Status Chart`,
      AWS_NETWORK_IO_CHART: `${MAIN_FRAMES.CLOUD_AWS_EC2_VIEW}/Network I/O Chart`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_EC2_VIEW}/Summary List`,
      AWS_INSTANCE_LIST: `${MAIN_FRAMES.CLOUD_AWS_EC2_VIEW}/Instance List`,
      ALERT_COUNT: `${MAIN_FRAMES.CLOUD_AWS_EC2_VIEW}/Alert Count`,
    },
    AWS_LAMBDA: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_CARD: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_VIEW}/Summary Card`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_VIEW}/Summary List`,
      AWS_FUNCTION_LIST: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_VIEW}/Function List`,
      ALERT_COUNT: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_VIEW}/Alert Count`,
    },
    AWS_S3: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_S3_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_S3_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_S3_VIEW}/Summary List`,
      AWS_BUCKET_LIST: `${MAIN_FRAMES.CLOUD_AWS_S3_VIEW}/Bucket List`,
      ALERT_COUNT: `${MAIN_FRAMES.CLOUD_AWS_S3_VIEW}/Alert Count`,
    },
    AWS_DOCUMENTDB: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_CARD: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Summary Card`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Summary List`,
      AWS_CLUSTER_LIST: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Cluster List`,
      ALERT_COUNT: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Alert Count`,
    },
    AWS_DYNAMODB: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Recent AWS Alarm List`,
      AWS_CHART: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Chart`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Summary List`,
      AWS_TABLE_LIST: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Table List`,
      ALERT_COUNT: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_VIEW}/Alert Count`,
    },
    AWS_ELASTICACHE: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Summary List`,
      AWS_CACHE_LIST: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Cache List`,
      ALERT_COUNT: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_VIEW}/Alert Count`,
    },
    AWS_REDSHIFT: {
      AWS_ACCOUNT_LIST: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Account List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_CARD: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Summary Card`,
      AWS_SUMMARY_LIST: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Summary List`,
      AWS_CLUSTER_LIST: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Cluster List`,
      ALERT_COUNT: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_VIEW}/Alert Count`,
    },
    AWS_DMS: {
      FILTER: `${MAIN_FRAMES.CLOUD_AWS_DMS_VIEW}/Filter `,
      TASK_LIST: `${MAIN_FRAMES.CLOUD_AWS_DMS_VIEW}/Task List`,
      AWS_ALARM_LIST: `${MAIN_FRAMES.CLOUD_AWS_DMS_VIEW}/Recent AWS Alarm List`,
      AWS_SUMMARY_CARD: `${MAIN_FRAMES.CLOUD_AWS_DMS_VIEW}/Summary Card`,
      AWS_TOP_VALUE: `${MAIN_FRAMES.CLOUD_AWS_DMS_VIEW}/Summary Top Value`,
      ALERT_COUNT: `${MAIN_FRAMES.CLOUD_AWS_DMS_VIEW}/Alert Count`,
    },
    AWS_COST_EXPLORER: {
      FILTERS: `${MAIN_FRAMES.AWS_COST_EXPLORER}/Filter`,
      COST: `${MAIN_FRAMES.AWS_COST_EXPLORER}/Cost`,
    },
    AWS_DETAIL: {
      INFORMATION: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Information`,
      METRIC_COLLECTOR_STATUS: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Metric Collector Status`,
      METRIC: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Metric`,
      LOG: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Log`,
      AWS_ALARM: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Aws Alarm`,
      ELASTICACHE_SHARD: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/ElastiCache Shard and Node`,
      ELASTICACHE_NODE: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/ElastiCache Node `,
      ELASTICACHE_NODE_LIST: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/ElastiCache Nodes List in Cluster`,
      ALERT: `${MAIN_FRAMES.CLOUD_AWS_DETAIL}/Alert`,
    },
  },
  AWS_DOCUMENTDB_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_VIEW}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CLOUD_AWS_DOCUMENTDB_ALERT_DETAIL}/Clear System Alert`,
  },
  AWS_DYNAMODB_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CLOUD_AWS_DYNAMODB_ALERT_DETAIL}/Clear System Alert`,
  },
  AWS_ELASTICACHE_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CLOUD_AWS_ELASTICACHE_ALERT_DETAIL}/Clear System Alert`,
  },
  AWS_REDSHIFT_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CLOUD_AWS_REDSHIFT_ALERT_DETAIL}/Clear System Alert`,
  },
  AWS_DMS_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CLOUD_AWS_DMS_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CLOUD_AWS_DMS_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CLOUD_AWS_DMS_ALERT_DETAIL}/Clear System Alert`,
  },
  AWS_EC2_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CLOUD_AWS_EC2_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CLOUD_AWS_EC2_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CLOUD_AWS_EC2_ALERT_DETAIL}/Clear System Alert`,
  },
  AWS_LAMBDA_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CLOUD_AWS_LAMBDA_ALERT_DETAIL}/Clear System Alert`,
  },
  AWS_S3_ALERT_DETAIL: {
    ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.CLOUD_AWS_S3_ALERT_DETAIL}/Alert Group and Rules`,
    CLEAR_ALERT_RULE: `${MAIN_FRAMES.CLOUD_AWS_S3_ALERT_DETAIL}/Clear User Alert`,
    CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.CLOUD_AWS_S3_ALERT_DETAIL}/Clear System Alert`,
  },
} as const;
