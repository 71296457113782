export const MESSAGE_QUEUE = {
  MESSAGE_QUEUE_KAFKA_DETAIL_DASHBOARD_SETTING_LIST:
    'MESSAGE_QUEUE_KAFKA_DETAIL_DASHBOARD_SETTING_LIST',

  FILTER_messageQueue_kafka: 'FILTER_messageQueue_kafka',
  GRIDCOLUMNS_messageQueue_kafka_kafkaList: 'GRIDCOLUMNS_messageQueue_kafka_kafkaList',

  GRIDCOLUMNS_messageQueue_kafka_detail_brokerList:
    'GRIDCOLUMNS_messageQueue_kafka_detail_brokerList',
  GRIDCOLUMNS_messageQueue_kafka_detail_topicList:
    'GRIDCOLUMNS_messageQueue_kafka_detail_topicList',
  GRIDCOLUMNS_messageQueue_kafka_detail_consumergroupList:
    'GRIDCOLUMNS_messageQueue_kafka_detail_consumergroupList',
  GRIDCOLUMNS_messageQueue_kafka_detail_topic_infoList:
    'GRIDCOLUMNS_messageQueue_kafka_detail_topic_infoList',

  FILTERSEARCH_messageQueue_kafka_kafkaList: 'FILTERSEARCH_messageQueue_kafka_kafkaList',
  FILTERSEARCH_messageQueue_kafka_detail_brokerList:
    'FILTERSEARCH_messageQueue_kafka_detail_brokerList',
  FILTERSEARCH_messageQueue_kafka_detail_topicList:
    'FILTERSEARCH_messageQueue_kafka_detail_topicList',
  FILTERSEARCH_messageQueue_kafka_detail_consumerGroupList:
    'FILTERSEARCH_messageQueue_kafka_detail_consumerGroupList',
};
