import { DisplayStyleType, WidgetChartType } from '@/dashboard/utils/types';
import { getStatDataTypeByWidgetChartType } from '@/common/components/organisms/statIndicatorSelect/statIndicatorSelect.utils';
import {
  ChartDataStatus,
  DefaultChartDataStatus,
  EqualizerChartDataStatus,
  ScoreboardStyle2ChartDataStatus,
} from './ChartDataStatus';
import { getLimitSeriesCountByChartType } from '../widgets.defines';

export class ChartDataStatusFactory {
  static createChartDataStatus(
    chartType: WidgetChartType,
    displayStyle: DisplayStyleType | undefined,
  ): ChartDataStatus {
    const seriesCountLimit = getLimitSeriesCountByChartType(chartType, displayStyle);
    const dataType = getStatDataTypeByWidgetChartType(chartType);

    switch (chartType) {
      case 'SCOREBOARD': {
        switch (displayStyle) {
          case 'STYLE_2': {
            return ScoreboardStyle2ChartDataStatus.getInstance(seriesCountLimit, dataType);
          }
          default: {
            return DefaultChartDataStatus.getInstance(seriesCountLimit, dataType);
          }
        }
      }
      case 'EQUALIZER': {
        return EqualizerChartDataStatus.getInstance(seriesCountLimit, dataType);
      }
      default: {
        return DefaultChartDataStatus.getInstance(seriesCountLimit, dataType);
      }
    }
  }
}
