export const KUBERNETES = {
  KUBERNETES_POD_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_POD_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_NAMESPACE_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_NAMESPACE_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_CLUSTER_TARGETS: 'KUBERNETES_CLUSTER_TARGETS',

  KUBERNETES_NODE_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_NODE_DETAIL_DASHBOARD_SETTING_LIST',

  KUBERNETES_DEPLOYMENT_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_DEPLOYMENT_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_STATEFULSET_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_STATEFULSET_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_DAEMONSET_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_DAEMONSET_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_REPLICASET_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_REPLICASET_DETAIL_DASHBOARD_SETTING_LIST',

  KUBERNETES_HPA_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_HPA_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_JOB_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_JOB_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_CRONJOB_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_CRONJOB_DETAIL_DASHBOARD_SETTING_LIST',

  KUBERNETES_SERVICE_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_SERVICE_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_INGRESS_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_INGRESS_DETAIL_DASHBOARD_SETTING_LIST',

  KUBERNETES_CONFIG_MAP_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_CONFIG_MAP_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_PVC_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_PVC_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_PV_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_PV_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_STORAGE_CLASS_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_STORAGE_CLASS_DETAIL_DASHBOARD_SETTING_LIST',

  KUBERNETES_ROLE_DETAIL_DASHBOARD_SETTING_LIST: 'KUBERNETES_ROLE_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_ROLE_BINDING_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_ROLE_BINDING_DETAIL_DASHBOARD_SETTING_LIST',

  KUBERNETES_CLUSTER_ROLE_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_CLUSTER_ROLE_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_CLUSTER_ROLE_BINDING_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_CLUSTER_ROLE_BINDING_DETAIL_DASHBOARD_SETTING_LIST',
  KUBERNETES_SERVICE_ACCOUNT_DETAIL_DASHBOARD_SETTING_LIST:
    'KUBERNETES_SERVICE_ACCOUNT_DETAIL_DASHBOARD_SETTING_LIST',

  GRIDCOLUMNS_kubernetes_pod_podList: 'GRIDCOLUMNS_kubernetes_pod_podList',
  GRIDCOLUMNS_kubernetes_podsListDetail_containers:
    'GRIDCOLUMNS_kubernetes_podsListDetail_containers',
  GRIDCOLUMNS_kubernetes_podsListDetail_pvc: 'GRIDCOLUMNS_kubernetes_podsListDetail_pvc',
  GRIDCOLUMNS_kubernetes_namespace_namespaceList: 'GRIDCOLUMNS_kubernetes_namespace_namespaceList',
  GRIDCOLUMNS_kubernetes_node_nodeList: 'GRIDCOLUMNS_kubernetes_node_nodeList',
  GRIDCOLUMNS_kubernetes_nodeDetail_podList: 'GRIDCOLUMNS_kubernetes_nodeDetail_podList',
  GRIDCOLUMNS_kubernetes_workloads_deploymentsList:
    'GRIDCOLUMNS_kubernetes_workloads_deploymentsList',
  GRIDCOLUMNS_kubernetes_workloads_stateFulSetsList:
    'GRIDCOLUMNS_kubernetes_workloads_stateFulSetsList',
  GRIDCOLUMNS_kubernetes_workloads_daemonSetsList:
    'GRIDCOLUMNS_kubernetes_workloads_daemonSetsList',
  GRIDCOLUMNS_kubernetes_workloads_replicaSetsList:
    'GRIDCOLUMNS_kubernetes_workloads_replicaSetsList',
  GRIDCOLUMNS_kubernetes_deploymentsDetail_podList:
    'GRIDCOLUMNS_kubernetes_deploymentsDetail_podList',
  GRIDCOLUMNS_kubernetes_statefulSetsDetail_podList:
    'GRIDCOLUMNS_kubernetes_statefulSetsDetail_podList',
  GRIDCOLUMNS_kubernetes_daemonSetsDetail_podList:
    'GRIDCOLUMNS_kubernetes_daemonSetsDetail_podList',
  GRIDCOLUMNS_kubernetes_replicaSetsDetail_podList:
    'GRIDCOLUMNS_kubernetes_replicaSetsDetail_podList',
  GRIDCOLUMNS_kubernetes_hpa_hpaList: 'GRIDCOLUMNS_kubernetes_hpa_hpaList',
  GRIDCOLUMNS_kubernetes_hpaDetail_podList: 'GRIDCOLUMNS_kubernetes_hpaDetail_podList',
  GRIDCOLUMNS_kubernetes_jobs_jobList: 'GRIDCOLUMNS_kubernetes_jobs_jobList',
  GRIDCOLUMNS_kubernetes_jobsDetail_podList: 'GRIDCOLUMNS_kubernetes_jobsDetail_podList',
  GRIDCOLUMNS_kubernetes_cronJobs_cronJobList: 'GRIDCOLUMNS_kubernetes_cronJobs_cronJobList',
  GRIDCOLUMNS_kubernetes_cronJobsDetail_jobList: 'GRIDCOLUMNS_kubernetes_cronJobsDetail_jobList',
  GRIDCOLUMNS_kubernetes_cronJobsDetail_podList: 'GRIDCOLUMNS_kubernetes_cronJobsDetail_podList',
  GRIDCOLUMNS_kubernetes_service_serviceList: 'GRIDCOLUMNS_kubernetes_service_serviceList',
  GRIDCOLUMNS_kubernetes_serviceDetail_portList: 'GRIDCOLUMNS_kubernetes_serviceDetail_portList',
  GRIDCOLUMNS_kubernetes_serviceDetail_podList: 'GRIDCOLUMNS_kubernetes_serviceDetail_podList',
  GRIDCOLUMNS_kubernetes_ingress_ingressList: 'GRIDCOLUMNS_kubernetes_ingress_ingressList',
  GRIDCOLUMNS_kubernetes_ingressDetail_rulesList: 'GRIDCOLUMNS_kubernetes_ingressDetail_rulesList',
  GRIDCOLUMNS_kubernetes_ingressDetail_loadBalancerList:
    'GRIDCOLUMNS_kubernetes_ingressDetail_loadBalancerList',
  GRIDCOLUMNS_kubernetes_configMaps_configMapsList:
    'GRIDCOLUMNS_kubernetes_configMaps_configMapsList',
  GRIDCOLUMNS_kubernetes_persistentVolumeClaim_persistentVolumeClaimList:
    'GRIDCOLUMNS_kubernetes_persistentVolumeClaim_persistentVolumeClaimList',
  GRIDCOLUMNS_kubernetes_persistentVolumeClaimDetail_podList:
    'GRIDCOLUMNS_kubernetes_persistentVolumeClaimDetail_podList',
  GRIDCOLUMNS_kubernetes_persistentVolume_persistentVolumeList:
    'GRIDCOLUMNS_kubernetes_persistentVolume_persistentVolumeList',
  GRIDCOLUMNS_kubernetes_storageClasses_storageClassesList:
    'GRIDCOLUMNS_kubernetes_storageClasses_storageClassesList',
  GRIDCOLUMNS_kubernetes_storageClassesDetail_persistentVolumesList:
    'GRIDCOLUMNS_kubernetes_storageClassesDetail_persistentVolumesList',
  GRIDCOLUMNS_kubernetes_roles_rolesList: 'GRIDCOLUMNS_kubernetes_roles_rolesList',
  GRIDCOLUMNS_kubernetes_rolesBindings_rolesBindingsList:
    'GRIDCOLUMNS_kubernetes_rolesBindings_rolesBindingsList',
  GRIDCOLUMNS_kubernetes_clusterRoles_clusterRolesList:
    'GRIDCOLUMNS_kubernetes_clusterRoles_clusterRolesList',
  GRIDCOLUMNS_kubernetes_clusterRolesBindings_clusterRolesBindingsList:
    'GRIDCOLUMNS_kubernetes_clusterRolesBindings_clusterRolesBindingsList',
  GRIDCOLUMNS_kubernetes_clusterRolesBindingsDetail_referenceList:
    'GRIDCOLUMNS_kubernetes_clusterRolesBindingsDetail_referenceList',
  GRIDCOLUMNS_kubernetes_clusterRolesBindingsDetail_bindingsList:
    'GRIDCOLUMNS_kubernetes_clusterRolesBindingsDetail_bindingsList',
  GRIDCOLUMNS_kubernetes_serviceAccounts_serviceAccountsList:
    'GRIDCOLUMNS_kubernetes_serviceAccounts_serviceAccountsList',
  GRIDCOLUMNS_kubernetes_detailEventGrid: 'GRIDCOLUMNS_kubernetes_detailEventGrid',
  GRIDCOLUMNS_kubernetes_top10Stat: 'GRIDCOLUMNS_kubernetes_top10Stat',
  GRIDCOLUMNS_kubernetes_scListDetail_storageClassList:
    'GRIDCOLUMNS_kubernetes_scListDetail_storageClassList',

  FILTER_kubernetes_objectTopology: 'FILTER_kubernetes_objectTopology',
  FILTER_kubernetes_clusterTopology: 'FILTER_kubernetes_clusterTopology',
  FILTER_kubernetes_cluster: 'FILTER_kubernetes_cluster',
  FILTER_kubernetes_pod: 'FILTER_kubernetes_pod',
  FILTER_kubernetes_namespace: 'FILTER_kubernetes_namespace',
  FILTER_kubernetes_node: 'FILTER_kubernetes_node',

  FILTER_kubernetes_workLoad: 'FILTER_kubernetes_workLoad',

  FILTER_kubernetes_workLoad_hpa: 'FILTER_kubernetes_workLoad_hpa',
  FILTER_kubernetes_workLoad_job: 'FILTER_kubernetes_workLoad_job',
  FILTER_kubernetes_workLoad_cronJob: 'FILTER_kubernetes_workLoad_cronJob',

  FILTER_kubernetes_network_service: 'FILTER_kubernetes_network_service',
  FILTER_kubernetes_network_ingress: 'FILTER_kubernetes_network_ingress',
  FILTER_kubernetes_configStorage_configMap: 'FILTER_kubernetes_configStorage_configMap',
  FILTER_kubernetes_configStorage_persistentVolumeClaim:
    'FILTER_kubernetes_configStorage_persistentVolumeClaim',
  FILTER_kubernetes_configStorage_persistentVolume:
    'FILTER_kubernetes_configStorage_persistentVolume',
  FILTER_kubernetes_configStorage_storageClass: 'FILTER_kubernetes_configStorage_storageClass',
  FILTER_kubernetes_accessControl_role: 'FILTER_kubernetes_accessControl_role',
  FILTER_kubernetes_accessControl_roleBinding: 'FILTER_kubernetes_accessControl_roleBinding',
  FILTER_kubernetes_accessControl_clusterRole: 'FILTER_kubernetes_accessControl_clusterRole',
  FILTER_kubernetes_accessControl_clusterRoleBinding:
    'FILTER_kubernetes_accessControl_clusterRoleBinding',
  FILTER_kubernetes_accessControl_serviceAccount: 'FILTER_kubernetes_accessControl_serviceAccount',
  FILTERSEARCH_kubernetes_clusterTopology: 'FILTERSEARCH_kubernetes_clusterTopology',
  FILTERSEARCH_kubernetes_objectTopology: 'FILTERSEARCH_kubernetes_objectTopology',
  FILTERSEARCH_kubernetes_podListView_podList: 'FILTERSEARCH_kubernetes_podListView_podList',
  FILTERSEARCH_kubernetes_namespaceView_namespaceList:
    'FILTERSEARCH_kubernetes_namespaceView_namespaceList',
  FILTERSEARCH_kubernetes_nodeView_nodeList: 'FILTERSEARCH_kubernetes_nodeView_nodeList',
  FILTERSEARCH_kubernetes_deployment_deploymentList:
    'FILTERSEARCH_kubernetes_deployment_deploymentList',
  FILTERSEARCH_kubernetes_statefulSets_statusSetsList:
    'FILTERSEARCH_kubernetes_statefulSets_statusSetsList',
  FILTERSEARCH_kubernetes_daemonSet_daemonSetList:
    'FILTERSEARCH_kubernetes_daemonSet_daemonSetList',
  FILTERSEARCH_kubernetes_replicaSets_replicaSetsList:
    'FILTERSEARCH_kubernetes_replicaSets_replicaSetsList',
  FILTERSEARCH_kubernetes_hpaView_hpaList: 'FILTERSEARCH_kubernetes_hpaView_hpaList',
  FILTERSEARCH_kubernetes_job_jobList: 'FILTERSEARCH_kubernetes_job_jobList',
  FILTERSEARCH_kubernetes_cronJabs_cronJobsList: 'FILTERSEARCH_kubernetes_cronJabs_cronJobsList',
  FILTERSEARCH_kubernetes_serviceView_serviceList:
    'FILTERSEARCH_kubernetes_serviceView_serviceList',
  FILTERSEARCH_kubernetes_ingressView_ingressList:
    'FILTERSEARCH_kubernetes_ingressView_ingressList',
  FILTERSEARCH_kubernetes_configMapView_configMapsList:
    'FILTERSEARCH_kubernetes_configMapView_configMapsList',
  FILTERSEARCH_kubernetes_persistentVolumeClaimView_persistentVolumeClaimList:
    'FILTERSEARCH_kubernetes_persistentVolumeClaimView_persistentVolumeClaimList',
  FILTERSEARCH_kubernetes_persistentVolumeView_persistentVolumeList:
    'FILTERSEARCH_kubernetes_persistentVolumeView_persistentVolumeList',
  FILTERSEARCH_kubernetes_storageClassView_storageClassList:
    'FILTERSEARCH_kubernetes_storageClassView_storageClassList',
  FILTERSEARCH_kubernetes_roleView_roleList: 'FILTERSEARCH_kubernetes_roleView_roleList',
  FILTERSEARCH_kubernetes_roleBinding_roleBindingList:
    'FILTERSEARCH_kubernetes_roleBinding_roleBindingList',
  FILTERSEARCH_kubernetes_clusterRoles_clusterRolesList:
    'FILTERSEARCH_kubernetes_clusterRoles_clusterRolesList',
  FILTERSEARCH_kubernetes_clusterRoleBinding_clusterRolesBindingList:
    'FILTERSEARCH_kubernetes_clusterRoleBinding_clusterRolesBindingList',
  FILTERSEARCH_kubernetes_serviceAccountView_serviceAccountList:
    'FILTERSEARCH_kubernetes_serviceAccountView_serviceAccountList',

  FILTERSEARCH_kubernetes_podListDetail_eventList:
    'FILTERSEARCH_kubernetes_podsListDetail_eventList',
  FILTERSEARCH_kubernetes_podListDetail_containerList:
    'FILTERSEARCH_kubernetes_podsListDetail_containerList',
  FILTERSEARCH_kubernetes_podListDetail_pvcList: 'FILTERSEARCH_kubernetes_podsListDetail_pvcList',

  FILTERSEARCH_kubernetes_namespaceDetail_podList:
    'FILTERSEARCH_kubernetes_namespaceDetail_podList',

  FILTERSEARCH_kubernetes_nodeDetail_eventList: 'FILTERSEARCH_kubernetes_nodeDetail_eventList',
  FILTERSEARCH_kubernetes_nodeDetail_podList: 'FILTERSEARCH_kubernetes_nodeDetail_podList',

  FILTERSEARCH_kubernetes_deploymentsDetail_eventList:
    'FILTERSEARCH_kubernetes_deploymentsDetail_eventList',
  FILTERSEARCH_kubernetes_deploymentsDetail_podList:
    'FILTERSEARCH_kubernetes_deploymentsDetail_podList',

  FILTERSEARCH_kubernetes_statefulSetsDetail_eventList:
    'FILTERSEARCH_kubernetes_statefulSetsDetail_eventList',
  FILTERSEARCH_kubernetes_statefulSetsDetail_podList:
    'FILTERSEARCH_kubernetes_statefulSetsDetail_podList',

  FILTERSEARCH_kubernetes_daemonSetsDetail_eventList:
    'FILTERSEARCH_kubernetes_daemonSetsDetail_eventList',
  FILTERSEARCH_kubernetes_daemonSetsDetail_podList:
    'FILTERSEARCH_kubernetes_daemonSetsDetail_podList',

  FILTERSEARCH_kubernetes_replicaSetsDetail_eventList:
    'FILTERSEARCH_kubernetes_replicaSetsDetail_eventList',
  FILTERSEARCH_kubernetes_replicaSetsDetail_podList:
    'FILTERSEARCH_kubernetes_replicaSetsDetail_podList',

  FILTERSEARCH_kubernetes_hpaDetail_eventList: 'FILTERSEARCH_kubernetes_hpaDetail_eventList',
  FILTERSEARCH_kubernetes_hpaDetail_podList: 'FILTERSEARCH_kubernetes_hpaDetail_podList',

  FILTERSEARCH_kubernetes_jobDetail_eventList: 'FILTERSEARCH_kubernetes_jobsDetail_eventList',
  FILTERSEARCH_kubernetes_jobDetail_podList: 'FILTERSEARCH_kubernetes_jobDetail_podList',

  FILTERSEARCH_kubernetes_cronJobsDetail_eventList:
    'FILTERSEARCH_kubernetes_cronJobsDetail_eventList',
  FILTERSEARCH_kubernetes_cronJobsDetail_jobList: 'FILTERSEARCH_kubernetes_cronJobsDetail_jobList',
  FILTERSEARCH_kubernetes_cronJobsDetail_podList: 'FILTERSEARCH_kubernetes_cronJobsDetail_podList',

  FILTERSEARCH_kubernetes_network_serviceDetail_eventList:
    'FILTERSEARCH_kubernetes_network_serviceDetail_eventList',
  FILTERSEARCH_kubernetes_network_serviceDetail_podList:
    'FILTERSEARCH_kubernetes_network_serviceDetail_podList',
  FILTERSEARCH_kubernetes_network_ingressDetail_loadBalancerList:
    'FILTERSEARCH_kubernetes_network_ingressDetail_loadBalancerList',
  FILTERSEARCH_kubernetes_network_ingressDetail_eventList:
    'FILTERSEARCH_kubernetes_network_ingressDetail_eventList',

  FILTERSEARCH_kubernetes_configStorage_configMapsDetail_eventList:
    'FILTERSEARCH_kubernetes_configStorage_configMapsDetail_eventList',
  FILTERSEARCH_kubernetes_configStorage_persistentVolumesClaimsDetail_eventList:
    'FILTERSEARCH_kubernetes_configStorage_persistentVolumesClaimsDetail_eventList',
  FILTERSEARCH_kubernetes_configStorage_persistentVolumesDetail_eventList:
    'FILTERSEARCH_kubernetes_configStorage_persistentVolumesDetail_eventList',
  FILTERSEARCH_kubernetes_configStorage_storageClassesDetail_eventList:
    'FILTERSEARCH_kubernetes_configStorage_storageClassesDetail_eventList',
  FILTERSEARCH_kubernetes_configStorage_storageClassesDetail_persistentVolumeList:
    'FILTERSEARCH_kubernetes_configStorage_storageClassesDetail_persistentVolumeList',

  FILTERSEARCH_kubernetes_accessControl_roleDetail_eventList:
    'FILTERSEARCH_kubernetes_accessControl_roleDetail_eventList',
  FILTERSEARCH_kubernetes_accessControl_roleBindingDetail_eventList:
    'FILTERSEARCH_kubernetes_accessControl_roleBindingDetail_eventList',
  FILTERSEARCH_kubernetes_accessControl_roleBindingDetail_referenceList:
    'FILTERSEARCH_kubernetes_accessControl_roleBindingDetail_referenceList',
  FILTERSEARCH_kubernetes_accessControl_roleBindingDetail_BindingList:
    'FILTERSEARCH_kubernetes_accessControl_roleBindingDetail_BindingList',
  FILTERSEARCH_kubernetes_accessControl_clusterRolesDetail_eventList:
    'FILTERSEARCH_kubernetes_accessControl_clusterRolesDetail_eventList',
  FILTERSEARCH_kubernetes_accessControl_clusterRoleBindingDetail_eventList:
    'FILTERSEARCH_kubernetes_accessControl_clusterRoleBindingDetail_eventList',
  FILTERSEARCH_kubernetes_accessControl_clusterRoleBindingDetail_referenceList:
    'FILTERSEARCH_kubernetes_accessControl_clusterRoleBindingDetail_referenceList',
  FILTERSEARCH_kubernetes_accessControl_clusterRoleBindingDetail_bindingList:
    'FILTERSEARCH_kubernetes_accessControl_clusterRoleBindingDetail_bindingList',
  FILTERSEARCH_kubernetes_accessControl_serviceAccountsDetail_eventList:
    'FILTERSEARCH_kubernetes_accessControl_serviceAccountsDetail_eventList',

  FILTERSEARCH_kubernetes_pvListDetail_persistentVolumeList:
    'FILTERSEARCH_kubernetes_pvListDetail_persistentVolumeList',
  FILTERSEARCH_kubernetes_nsListDetail_namespaceList:
    'FILTERSEARCH_kubernetes_nsListDetail_namespaceList',
  FILTERSEARCH_kubernetes_podListDetail_podList: 'FILTERSEARCH_kubernetes_podListDetail_podList',
  FILTERSEARCH_kubernetes_svListDetail_serviceList:
    'FILTERSEARCH_kubernetes_svListDetail_serviceList',
  FILTERSEARCH_kubernetes_ingressListDetail_ingressList:
    'FILTERSEARCH_kubernetes_ingressListDetail_ingressList',
  FILTERSEARCH_kubernetes_scListDetail_storageClassList:
    'FILTERSEARCH_kubernetes_scListDetail_storageClassList',
  FILTERSEARCH_kubernetes_pvcListDetail_persistentVolumeClaimList:
    'FILTERSEARCH_kubernetes_pvcListDetail_persistentVolumeClaimList',
  FILTERSEARCH_kubernetes_pvcListDetail_podList: 'FILTERSEARCH_kubernetes_pvcListDetail_podList',
  FILTERSEARCH_kubernetes_nodeListDetail_nodeList:
    'FILTERSEARCH_kubernetes_nodeListDetail_nodeList',
};
