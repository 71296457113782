import { ComponentCustomProperties } from 'vue';
import { i18n } from '@/common/locale';
import { showErrorMsg, showInfoMsg } from './commonUtils';

const copyContext = (e, ctx, className) => {
  let context = e.target.parentNode;
  let wrapper;
  for (let i = 0; i < 3; i++) {
    // 최대 3번까지 부모 노드를 찾아감
    [wrapper] = context.getElementsByClassName(className);

    if (wrapper) {
      break;
    }

    context = context.parentNode;
  }

  if (!wrapper) {
    console.log('not found: copy wrapper element');
    return;
  }

  const tempElem = document.createElement('textarea');
  tempElem.value = wrapper.textContent.trim();
  context.appendChild(tempElem);
  tempElem.select();
  document.execCommand('copy');
  context.removeChild(tempElem);
  showInfoMsg(ctx, i18n.global.t('NOTI.UI.COPY'));
};

// execCommand 기반은 동기적 특성으로 인해 더이상 사용하지 않음.
// 새로운 Promise 기반의 비동기 클립보드 API를 사용
// 참고: https://web.dev/async-clipboard/#copy_writing_text_to_the_clipboard
// https, localhost 환경에서만 navigator.clipboard가 동작함
// 참고 : https://curryyou.tistory.com/480
const copyTextToClipboard = async (
  ctx: (ComponentCustomProperties & Record<string, any>) | null,
  text: { format: string; raw: string } | string,
) => {
  let realText: string = '';
  if (typeof text !== 'string' && text?.format) {
    realText = text.format;
  } else {
    realText = text as string;
  }
  try {
    if (navigator.clipboard !== undefined) {
      await navigator.clipboard.writeText(realText);
    } else {
      const tempElem = document.createElement('textarea');
      // prismjs의 text를 복사하는 경우는 format 값을 가져오게 한다.
      tempElem.value = realText;
      document.body.appendChild(tempElem);
      tempElem.select();
      document.execCommand('copy');
      document.body.removeChild(tempElem);
    }

    if (ctx) {
      showInfoMsg(ctx, i18n.global.t('NOTI.UI.COPY'));
    }
  } catch (error) {
    console.log(`Error in copying text: ${error}`);

    if (ctx) {
      showErrorMsg(ctx, i18n.global.t('NOTI.UI.CANNOT_COPY'));
    }
  }
};

export { copyContext, copyTextToClipboard };
